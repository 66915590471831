import { differenceInDays } from "date-fns";
import { PurchaseTypes, OfferTypes } from "./Steps/PurchaseCriteria";
import { DurationOptions } from "constants/options";

export const calStartDate = (startDate) => {
  if (typeof startDate === "string") {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    let response = today;

    if (startDate === "Today") {
      response = today;
    } else if (startDate === "Tomorrow") {
      response = tomorrow;
    } else if (startDate === "This weekend") {
      const currentDayOfWeek = today.getDay();
      const daysUntilWeekend = currentDayOfWeek === 0 ? 6 : 5 - currentDayOfWeek;
      const weekendDate = new Date(today);
      weekendDate.setDate(today.getDate() + daysUntilWeekend);
      response = weekendDate;
    } else if (startDate === "Next month") {
      const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
      response = nextMonth;
    }
    return response.toISOString().split("T")[0];
  }
  return startDate.toISOString().split("T")[0];
};

export const calDuration = (duration) => {
  if (duration?.select) {
    const durationParts = duration.select.split(" ");
    const value = parseInt(durationParts[0]);
    const unit = durationParts[1].toLowerCase();

    let totalDays = 0;

    if (unit.includes("day")) {
      totalDays = value;
    } else if (unit.includes("week")) {
      totalDays = value * 7;
    } else if (unit.includes("month")) {
      totalDays = value * 30; // Assuming 30 days per month
    } else if (unit.includes("year")) {
      totalDays = value * 365; // Assuming 365 days per year
    }
    return totalDays;
  }

  return duration?.input;
};

export const calEndDate = (startDate, duration) => {
  const start = new Date(startDate);
  const end = new Date(start.getTime() + duration * 24 * 60 * 60 * 1000);
  const formattedEndDate = end.toISOString().split("T")[0];
  return formattedEndDate;
};

export const calCampaignDuration = (data) => {
  const start_time = calStartDate(data?.startDate);
  let end_time;

  if (data?.endDate) {
    end_time = data?.endDate.toISOString().split("T")[0];
  } else if (data?.duration) {
    const duration = calDuration(data?.duration);
    end_time = calEndDate(start_time, duration);
  }

  return {
    start_time,
    end_time,
  };
};

// const convertPurchaseRequirementValue = (item) => {
//   return [1, 2].includes(parseInt(item?.code))
//     ? item?.value?.toString()?.replace(".", "")
//     : `${item?.value}`; // Only converts if it's a dollar value
// };

const createPayload = (data, isEditable, isAllowMultipleBrands) => {
  const save_value_code = OfferTypes[data?.requirements?.offer?.type]?.code;
  const { start_time: campaign_start_time, end_time: campaign_end_time } = calCampaignDuration(data?.campaignDuration);
  const { start_time: redemption_start_time, end_time: redemption_end_time } = calCampaignDuration(
    data?.redemptionDuration
  );

  const primary_purchase_requirements = convertToPurchaseRequirementAmount(
    data?.requirements?.primary?.type,
    data?.requirements?.primary?.value
  );
  const second_purchase_requirements = convertToPurchaseRequirementAmount(
    data?.requirements?.secondary?.type,
    data?.requirements?.secondary?.value
  );
  const third_purchase_requirements = convertToPurchaseRequirementAmount(
    data?.requirements?.tertiary?.type,
    data?.requirements?.tertiary?.value
  );

  const save_value = convertToOfferAmount(
    data?.requirements?.offer?.type,
    data?.requirements?.offer?.value,
    data?.requirements?.offer?.appliesToWhichItems,
    data?.requirements
  );

  const rolling_expiration = data?.moreInfo?.rollingExpiration === "Yes" ? "1" : "0";

  const rolling_expiration_days =
    rolling_expiration === "1" ? calDuration(data?.moreInfo?.rollingExpirationDuration) : null;

  const total_circulation = data?.moreInfo?.estimatedCirculation?.select
    ? data?.moreInfo?.estimatedCirculation?.select?.replaceAll(",", "")
    : `${data?.moreInfo?.estimatedCirculation?.input}`;

  const max_quantity_to_group =
    data?.moreInfo?.max_quantity_to_group?.select || data?.moreInfo?.max_quantity_to_group?.input;

  const max_limit_to_avail_offer =
    data?.moreInfo?.max_limit_to_avail_offer?.select || data?.moreInfo?.max_limit_to_avail_offer?.input;

  const additional_purchase_rules_code = data?.requirements?.offer?.purchaseRequirementsRelation ?? 0;

  const store_coupon =
    data?.moreInfo?.store_coupon === "Yes" ? "1" : data?.moreInfo?.store_coupon === "No" ? "0" : null;

  // const save_value_products = data?.requirements?.offer?.data?.map((x) => x?.product_id);
  const primary_purchase_products = data?.requirements?.primary?.data?.map((x) => x?.product_id);
  const second_purchase_products = data?.requirements?.secondary?.data?.map((x) => x?.product_id);
  const third_purchase_products = data?.requirements?.tertiary?.data?.map((x) => x?.product_id);

  const providers = data?.providers?.map((x) => x?.email_domain);
  const retailers = data?.retailers?.map((x) => x?.email_domain);

  let payload = {
    base_gs1: data?.base_gs1,
    update_mode: !isEditable ? "0" : "1" /** 0-> new mof 1-> edit mof*/,
    brand_id: data?.brand?.internal_id,
    description: data?.description,
    campaign_start_time,
    campaign_end_time,
    redemption_start_time,
    redemption_end_time,
    rolling_expiration,
    rolling_expiration_days,
    total_circulation,
    free_quantity_type: save_value_code == 2 ? data?.requirements?.offer?.free_quantity_type : null,
    primary_purchase_save_value: `${save_value}`,
    primary_purchase_requirements,
    primary_purchase_req_code: `${PurchaseTypes[data?.requirements?.primary?.type]?.code}`,
    primary_purchase_products,

    save_value_code: `${save_value_code}`,
    additional_purchase_rules_code,
    store_coupon,
    // save_value_products,
    max_quantity_to_group,
    max_limit_to_avail_offer,
    campaign_metadata: {
      title: data?.title,
      description: data?.description,
      mobile_image_url: data?.image,
      desktop_image_url: data?.image,
      terms: data?.moreInfo?.terms,
      dollar_amount: [0, 1, 6].includes(save_value_code) ? `${data?.requirements?.offer?.value}` : "0",
    },
    providers,
    retailers,
  };

  if (isAllowMultipleBrands) {
    payload = {
      ...payload,
      primary_purchase_brand_id: data?.requirements?.primary?.brand?.internal_id,
      primary_purchase_brand_name: data?.requirements?.primary?.brand?.name,
    };
  }

  if (data?.requirements?.secondary?.value) {
    payload = {
      ...payload,
      second_purchase_requirements,
      second_purchase_gs1_company_prefix: null,
      second_purchase_req_code: `${PurchaseTypes[data?.requirements?.secondary?.type]?.code}`,
      second_purchase_products,
      ...(isAllowMultipleBrands && {
        second_purchase_brand_id: data?.requirements?.secondary?.brand?.internal_id,
        second_purchase_brand_name: data?.requirements?.secondary?.brand?.name,
      }),
    };
  }

  if (data?.requirements?.tertiary?.value) {
    payload = {
      ...payload,
      third_purchase_requirements,
      third_purchase_gs1_company_prefix: null,
      third_purchase_req_code: `${PurchaseTypes[data?.requirements?.tertiary?.type]?.code}`,
      third_purchase_products,
      ...(isAllowMultipleBrands && {
        third_purchase_brand_id: data?.requirements?.tertiary?.brand?.internal_id,
        third_purchase_brand_name: data?.requirements?.tertiary?.brand?.name,
      }),
    };
  }

  if (
    (data?.requirements?.secondary?.value || data?.requirements?.tertiary?.value) &&
    data?.requirements?.offer?.appliesToWhichItems !== -1
  ) {
    payload = {
      ...payload,
      applies_to_which_item: data?.requirements?.offer?.appliesToWhichItems,
    };
  }

  return payload;
};

export default createPayload;

const setOfferAmount = (type, value, atwi = 0, requirements) => {
  const amount = parseFloat(value);
  const code = parseInt(type);
  const dollarOfferCodes = [0, 1, 6];

  // If offer type is in `dollars`, then convert amount to cents
  if (dollarOfferCodes.includes(code)) {
    return (amount / 100).toFixed(2);
  }

  // If any purchase requirement is selected as `applies to which item' and has type 'kilograms' or 'pounds'  and offer type also is in `kilograms` or `pounds` or `free quantity`, then convert offer value to `grams` or 'ounces'
  if (requirements?.[atwi ?? 0] == 3 && code === 2) {
    return (amount / 100).toFixed(2);
  } else if (requirements?.[atwi ?? 0] == 4 && code === 2) {
    return (amount / 1000).toFixed(2);
  }

  return amount;
};

const setPurchaseRequirementAmount = (type, value) => {
  if (value === null || value === undefined || value === "") return null;

  const amount = parseFloat(value);
  const code = parseInt(type);

  // If requirement type is in `dollars`, then convert amount `cents` to `dollars`
  if (code === 1 || code === 2) return (amount / 100).toFixed(2);
  // If requirement type is in `pounds`, then convert amount `ounces` to `pounds`
  if (code === 3) return (amount / 100).toFixed(2);
  // If requirement type is in `kilograms`, then convert amount `grams` to `kilograms`
  if (code === 4) return (amount / 1000).toFixed(2);

  return amount;
};

const convertToPurchaseRequirementAmount = (type, value) => {
  if (value === null || value === undefined || value === "") return null;

  const amount = parseFloat(value);
  const code = PurchaseTypes?.[type]?.code;

  // If requirement type is in `dollars`, then convert amount to `cents`
  if (code === 1 || code === 2) return `${(amount * 100).toFixed(2)}`;
  // If requirement type is in `pounds`, then convert amount to `ounces`
  if (code === 3) return `${(amount * 100).toFixed(2)}`;
  // If requirement type is in `kilograms`, then convert amount to `grams`
  if (code === 4) return `${(amount * 1000).toFixed(2)}`;

  return `${amount}`;
};

const convertToOfferAmount = (type, value, atwi = 0, requirements) => {
  const amount = parseFloat(value);
  const dollarOfferCodes = [0, 1, 6];
  const code = OfferTypes?.[type]?.code;
  const atwi_value = atwi <= 0 ? 0 : atwi;

  // Applies to which item
  const atwi_map = {
    0: requirements?.primary,
    1: requirements?.secondary,
    2: requirements?.tertiary,
  };

  // If offer type is in `dollars`, then convert amount to cents
  if (dollarOfferCodes.includes(code)) {
    return `${(amount * 100).toFixed(2)}`;
  }

  // If any purchase requirement is selected as `applies to which item' and has type 'kilograms' or 'pounds'  and offer type also is in `kilograms` or `pounds` or `free quantity`, then convert offer value to `grams` or 'ounces'
  if (PurchaseTypes[atwi_map[atwi_value]?.type]?.code === 3 && code === 2) {
    return `${(amount * 100).toFixed(2)}`;
  } else if (PurchaseTypes[atwi_map[atwi_value]?.type]?.code === 4 && code === 2) {
    return `${(amount * 1000).toFixed(2)}`;
  }

  return `${amount}`;
};

const findTypeKey = (code, offer = false) =>
  Object.entries(offer ? OfferTypes : PurchaseTypes)?.find((x) => x?.[1]?.code == code)?.[0];

export const handleCampaignDataForEdit = (campaign, setFormData, modiftyDates, allProviders, allRetailers) => {
  const data = {
    isSettled: modiftyDates ? false : campaign?.settled === 1,
    link: campaign?.link,
    base_gs1: campaign?.base_gs1,
    brand: {
      internal_id: campaign?.brand_id,
      name: campaign?.brand_name,
      provider: "",
    },
    title: campaign?.campaign_metadata?.title,
    description: campaign?.campaign_metadata?.description,
    campaignDuration: {
      startDate: modiftyDates ? new Date() : new Date(campaign?.campaign_start_time),
      // duration: {
      //   input: differenceInDays(new Date(campaign?.campaign_end_time), new Date(campaign?.campaign_start_time)),
      // },
      duration: modiftyDates ? { select: DurationOptions?.[0] } : null,
      endDate: modiftyDates ? null : new Date(campaign?.campaign_end_time),
    },
    redemptionDuration: {
      startDate: modiftyDates ? new Date() : new Date(campaign?.redemption_start_time),
      // duration: {
      //   input: differenceInDays(new Date(campaign?.redemption_end_time), new Date(campaign?.redemption_start_time)),
      // },
      duration: modiftyDates ? { select: DurationOptions?.[0] } : null,
      endDate: modiftyDates ? null : new Date(campaign?.redemption_end_time),
    },
    moreInfo: {
      rollingExpiration: campaign?.rolling_expiration == 1 ? "Yes" : "No",
      rollingExpirationDuration: {
        input: campaign?.rolling_expiration_days,
      },
      max_quantity_to_group: {
        input: campaign?.max_quantity_to_group,
      },
      // max_limit_to_avail_offer: {
      //   input: campaign?.max_limit_to_avail_offer,
      // },
      terms: campaign?.campaign_metadata?.terms,
      estimatedCirculation: { input: campaign?.total_circulation },
      product_image: campaign?.campaign_metadata?.desktop_image_url,
      store_coupon: campaign?.store_coupon == 1 ? "Yes" : "No",
    },
    requirements: {
      noOfRequirement: campaign?.tertiary_purchase_requirements ? 3 : campaign?.secondary_purchase_requirements ? 2 : 1,
      offer: {
        type: findTypeKey(campaign?.save_value_code ?? 0, true),
        value: setOfferAmount(
          campaign?.save_value_code ?? 0,
          campaign?.primary_purchase_save_value,
          campaign?.applies_to_which_item,
          {
            0: campaign?.primary_purchase_req_code,
            1: campaign?.second_purchase_req_code,
            2: campaign?.third_purchase_req_code,
          }
        ),
        appliesToWhichItems: campaign?.applies_to_which_item ?? -1,
        free_quantity_type: campaign?.free_quantity_type ?? "kg",
        purchaseRequirementsRelation: campaign?.additional_purchase_rules_code ?? 0,
        // data: campaign?.save_value_products,
      },
      primary: {
        title: "Primary",
        type: findTypeKey(campaign?.primary_purchase_req_code ?? 0),
        value: setPurchaseRequirementAmount(
          campaign?.primary_purchase_req_code ?? 0,
          campaign?.primary_purchase_requirements
        ),
        data: campaign?.primary_purchase_products,
        search: null,
        expanded: true,
        brand: {
          internal_id: campaign?.primary_purchase_brand_id ?? campaign?.brand_id,
          name: campaign?.primary_purchase_brand_name ?? campaign?.brand_name,
          provider: "",
        },
      },
      secondary: {
        title: "Secondary",
        type: findTypeKey(campaign?.second_purchase_req_code ?? 0),
        value: setPurchaseRequirementAmount(
          campaign?.second_purchase_req_code ?? 0,
          campaign?.second_purchase_requirements
        ),
        data: campaign?.second_purchase_products,
        search: null,
        expanded: false,
        brand: {
          internal_id: campaign?.second_purchase_brand_id ?? campaign?.brand_id,
          name: campaign?.second_purchase_brand_name ?? campaign?.brand_name,
          provider: "",
        },
      },
      tertiary: {
        title: "Tertiary",
        type: findTypeKey(campaign?.third_purchase_req_code ?? 0),
        value: setPurchaseRequirementAmount(
          campaign?.third_purchase_req_code ?? 0,
          campaign?.third_purchase_requirements
        ),
        data: campaign?.third_purchase_products,
        search: null,
        expanded: false,
        brand: {
          internal_id: campaign?.third_purchase_brand_id ?? campaign?.brand_id,
          name: campaign?.third_purchase_brand_name ?? campaign?.brand_name,
          provider: "",
        },
      },
    },
    providers: allProviders?.data?.filter((x) => campaign?.providers?.includes(x?.email_domain)),
    retailers: allRetailers?.data?.filter((x) => campaign?.allowed_retailers?.includes(x?.email_domain)),
  };

  setFormData({ ...data, campaignResponse: data });
};
