import CustomTextField from "./CustomTextField";
import {
  AppliesToWhichItems,
  OfferTypes,
  PurchaseRequirementsRelation,
  noOfRequirementOptions,
  useCriteriaContext,
} from ".";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import Criteria from "./Criteria";

const ComplexOffer = () => {
  const { selected, handleSetValue, setSelected } = useCriteriaContext();
  const currOffer = OfferTypes?.[selected?.offer?.type];
  const currType = currOffer?.units
    ? {
        ...currOffer,
        placeholder: currOffer?.placeholder + selected?.offer?.free_quantity_type,
      }
    : currOffer;

  const handleAppliesToWhichItem = (e) => {
    handleSetValue(e.target.value, "appliesToWhichItems", "offer");
  };

  return (
    <div className="d-flex gap-3 my-3 flex-column w-100">
      <Typography variant="subtitle1">How many purchase requirements you like to include?</Typography>
      <div className="d-flex gap-2 align-items-center w-100">
        {Object.values(noOfRequirementOptions)?.map((option) => (
          <Button
            onClick={() =>
              setSelected((state) => ({
                ...state,
                noOfRequirement: option?.label,
              }))
            }
            variant={selected?.noOfRequirement === option?.label ? "circularContained" : "circularOutlined"}
            key={option?.label}
          >
            {option?.label}
          </Button>
        ))}
      </div>
      <Grid container spacing={2}>
        <Grid item md={12} lg={7}>
          <div className="mb-4">
            <Typography variant="subtitle1">Which offer type would you like to create?</Typography>
            <Typography color="gray.400" variant="subtitle2">
              The maximum offer amount
            </Typography>
          </div>
          <div className="d-flex align-items-start gap-2 w-100">
            <FormControl sx={{ width: "30%" }}>
              <InputLabel htmlFor="grouped-select-1">Offer type</InputLabel>
              <Select
                value={selected?.offer?.type}
                defaultValue={Object.entries(OfferTypes)[0][0]}
                id="grouped-select-1"
                label={"Offer type"}
                onChange={(e) => {
                  if (e.target.value !== "free_quantity" && selected?.offer?.data?.length) {
                    handleSetValue([], "data", "offer");
                  }
                  handleSetValue(e.target.value, "type", "offer");
                }}
              >
                {Object.entries(OfferTypes)?.map((option) => (
                  <MenuItem
                    value={option[0]}
                    key={option[0]}
                    onClick={() => {
                      handleSetValue(option[1]?.code, "code", "offer");
                      if (option[1]?.code === 2) {
                        handleSetValue("kg", "free_quantity_type", "offer");
                      }
                    }}
                  >
                    {option[1]?.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {currOffer?.units ? (
              <FormControl sx={{ width: "10%" }}>
                <InputLabel htmlFor="units-dropdown">Unit</InputLabel>
                <Select
                  value={selected?.offer?.free_quantity_type ?? currOffer?.units?.[0]}
                  defaultValue={currOffer?.units?.[0]}
                  id="units-dropdown"
                  label={"Unit"}
                  sx={{ minWidth: 60 }}
                >
                  {currOffer?.units?.map((item) => (
                    <MenuItem
                      value={item}
                      key={item}
                      onClick={() => handleSetValue(item, "free_quantity_type", "offer")}
                    >
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}
            <FormControl sx={{ width: "20%" }}>
              <CustomTextField
                item={"offer"}
                type={currType}
                value={selected?.offer?.value}
                error={selected?.offer?.error}
                decimalNotAllowed={currOffer?.units && selected?.offer?.free_quantity_type === "unit"}
              />
            </FormControl>
          </div>
        </Grid>

        {selected?.noOfRequirement !== 1 ? (
          <Grid item md={12} lg={5}>
            <div className="mb-4">
              <Typography variant="subtitle1">Offer applies to products</Typography>
              <Typography color="gray.400" variant="subtitle2">
                Description
              </Typography>
              <Typography color="gray.400" variant="subtitle2"></Typography>
            </div>
            <div className="d-flex align-items-center gap-2">
              <FormControl sx={{ width: "40%" }}>
                <InputLabel htmlFor="grouped-select-2">type</InputLabel>
                <Select
                  defaultValue={AppliesToWhichItems[0]?.code}
                  id="grouped-select-2"
                  label={"type"}
                  value={selected?.offer?.appliesToWhichItems}
                  onChange={handleAppliesToWhichItem}
                >
                  {AppliesToWhichItems?.slice(0, selected?.noOfRequirement + 1)?.map((option) => (
                    <MenuItem value={option?.code} key={option?.code}>
                      {option?.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </Grid>
        ) : null}
      </Grid>
      {selected?.noOfRequirement !== 1 ? (
        <Grid container spacing={2}>
          <Grid item md={12} lg={7}>
            <div>
              <div className="mb-4">
                <Typography variant="subtitle1">Purchase Requirement Relation</Typography>
                <Typography color="gray.400" variant="subtitle2">
                  This field determines the relationship between purchase requirements in order to fulfill the overall
                  purchase requirements for the offer.
                </Typography>
              </div>
              <div className="d-flex align-items-center gap-2">
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    defaultValue={PurchaseRequirementsRelation[0]?.code}
                    value={selected?.offer?.purchaseRequirementsRelation}
                    onChange={(e) => handleSetValue(e.target.value, "purchaseRequirementsRelation", "offer")}
                  >
                    {PurchaseRequirementsRelation?.map((option) => (
                      <MenuItem value={option?.code} key={option?.code}>
                        {option?.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          </Grid>
        </Grid>
      ) : null}
      <Criteria item="primary" label="First" />
      <Criteria item="secondary" label="Second" />
      <Criteria item="tertiary" label="Third" />
    </div>
  );
};

export default ComplexOffer;
