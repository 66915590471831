import React from "react";
import { Helmet } from "react-helmet";
import { Breadcrumb } from "components";
import usePageDetails from "hooks/usePageDetails";

const SEOHelmet = () => {
  const pageDetails = usePageDetails();
  const tabTitle = `${pageDetails?.title} | Gitsy For Retail`;
  return (
    <>
      {tabTitle && (
        <Helmet>
          <title>{tabTitle}</title>
        </Helmet>
      )}
      {pageDetails?.title && <Breadcrumb title={pageDetails?.title} />}
    </>
  );
};

export default SEOHelmet;
