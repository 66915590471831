import { useEffect, useMemo } from "react";
import "swiper/css";

import "aos/dist/aos.css";
import { Footer, Header, RightSidebar, SEOHelmet, Sidebar } from "components";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Pathnames } from "routes/Pathnames";
import { useIsFetching, useIsMutating } from "@tanstack/react-query";
import { Alert, AlertTitle, Backdrop, Box, Button, CircularProgress } from "@mui/material";
import { getLocalStorageItem } from "hooks";
import { LocalStorageKeys } from "constants";

import { ErrorBoundary } from "react-error-boundary";
import Graphic from "assets/images/something-went-wrong.png";
import { QueryKeys } from "constants";
import { useGetUserDetails } from "queries/auth";

function MyFallbackComponent({ error, resetErrorBoundary }) {
  return (
    <Box
      style={{ height: "100vh" }}
      className="d-flex flex-column align-items-center justify-content-center w-auto m-auto"
    >
      <img src={Graphic} height={300} alt="gitsy-graphic" />
      <Alert
        severity="warning"
        action={
          <Button color="secondary" onClick={() => window.location.reload()}>
            Reload
          </Button>
        }
      >
        <AlertTitle color="#663C00">Something went wrong. Please try to reload this page</AlertTitle>
      </Alert>
    </Box>
  );
}

const App = (props) => {
  // const initialState = {
  //   ltr: true,
  //   divName: "RTL",
  // };
  const location = useLocation();

  // const isFetching = useIsFetching({
  //   predicate: (query) => {
  //     // this condition ignores full screen loader for these queries
  //     return [QueryKeys.ME].includes(query?.options?.queryKey?.[0]);
  //   },
  // });

  const isMutating = useIsMutating({
    predicate: (mutation) => {
      // this condition ignores full screen loader for these mutation
      return ![QueryKeys.MUTATE_PROVIDERS, QueryKeys.MUTATE_RETAILERS, QueryKeys.MUTATE_CAMPAIGN].includes(
        mutation?.options?.mutationKey
      );
    },
  });

  const isDashboardNotVisible = useMemo(
    () =>
      [Pathnames?.JOIN_OR_CREATE_ENTERPRISE, Pathnames?.ENTERPRISE_STATUS, Pathnames?.ENTERPRISE_REQUESTS].some((x) => {
        return location?.pathname.includes(x);
      }),
    [location?.pathname]
  );

  const accessToken = getLocalStorageItem(LocalStorageKeys.ACCESS_TOKEN);

  // const [side, setSide] = useState(initialState);

  // const ChangeRtl = (divName) => {
  //   if (divName === "RTL") {
  //     document.body.classList.add("rtl");
  //     setSide({ divName: "LTR" });
  //   } else {
  //     document.body.classList.remove("rtl");
  //     setSide({ divName: "RTL" });
  //   }
  // };

  const { data: userDetails } = useGetUserDetails();

  useEffect(
    () =>
      window?.scrollTo({
        top: 0,
        behavior: "instant",
      }),
    [location]
  );

  // if (location.pathname.includes(Pathnames.ENTERPRISE_REQUESTS) && !userDetails?.email?.endsWith("@spectivlabs.com")) {
  //   return <Navigate to={Pathnames.DASHBOARD} replace />;
  // }

  return (
    <ErrorBoundary
      FallbackComponent={MyFallbackComponent}
      onReset={() => {
        // reset the state of your app here
      }}
      resetKeys={["someKey"]}
      onError={() => {}}
    >
      {Boolean(accessToken) ? (
        <>
          {isDashboardNotVisible ? (
            <div className="page-body">
              <Outlet />
            </div>
          ) : (
            <div>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.modal + 100,
                }}
                open={!!isMutating}
              >
                <CircularProgress color="primary" />
              </Backdrop>
              <div className="page-wrapper">
                <Header />
                <div className="page-body-wrapper">
                  <Sidebar />
                  <RightSidebar />
                  <div className="page-body">
                    <SEOHelmet />
                    <Outlet />
                  </div>
                  <Footer />
                </div>
              </div>
              {/* <div
                className="btn-light custom-theme"
                onClick={() => ChangeRtl(side.divName)}
              >
                {side.divName}
              </div> */}
            </div>
          )}
        </>
      ) : (
        <Navigate to={Pathnames.LOGIN} replace state={{ from: location }} />
      )}
    </ErrorBoundary>
  );
};

export default App;
