import { Archive, Camera, Home, Key, LogIn, Tag } from "react-feather";
import { Pathnames } from "routes/Pathnames";

// import { AppRoutes } from "routes/AppRoutes";

// import Brands from "assets/icons/BrandIcon";
// import Campaigns from "assets/icons/Campaigns";
// import Dashboard from "assets/icons/Dashboard";
// import Galleries from "assets/icons/Galleries";
// import Products from "assets/icons/Products";
// import Users from "assets/icons/Users";
import Stores from "assets/icons/Stores";
import Campaigns from "@mui/icons-material/CampaignOutlined";
import Settings from "@mui/icons-material/SettingsOutlined";
import Users from "@mui/icons-material/PeopleOutlineOutlined";
import Dashboard from "@mui/icons-material/DashboardOutlined";
import Brands from "@mui/icons-material/CategoryOutlined";
import Products from "@mui/icons-material/ViewInArOutlined";
import Galleries from "@mui/icons-material/CollectionsBookmarkOutlined";

export const MENUITEMS = [
  {
    path: Pathnames.DASHBOARD,
    title: "Dashboard",
    icon: Dashboard,
    type: "link",
    badgeType: "primary",
    active: false,
  },
  {
    path: "/brands",
    title: "Brands",
    icon: Brands,
    type: "link",
    badgeType: "primary",
    active: false,
  },
  // {
  //   title: "Campaign",
  //   icon: Tag,
  //   type: "sub",
  //   active: false,
  //   children: [
  //     {
  //       path: Pathnames.LIST_CAMPAIGN,
  //       title: "List Campaign",
  //       type: "link",
  //     },
  //   ],
  // },
  {
    title: "Products",
    icon: Products,
    type: "link",
    active: false,
    path: Pathnames.LIST_PRODUCTS,
  },
  {
    title: "Campaign",
    icon: Campaigns,
    type: "sub",
    active: false,
    children: [
      {
        path: Pathnames.LIST_CAMPAIGN,
        title: "All Campaigns",
        type: "link",
        icon: Campaigns,
      },
      {
        path: Pathnames.LIST_GALLERY,
        title: "Galleries",
        type: "link",
        icon: Galleries,
      },
    ],
  },

  // {
  //   title: "Grocery Chains",
  //   icon: Tag,
  //   type: "link",
  //   active: false,
  //   path: Pathnames.LIST_GROCERY_CHAINS,
  // },
  // {
  //   title: "Store",
  //   icon: Stores,
  //   type: "link",
  //   active: false,
  //   path: Pathnames.LIST_STORES,
  //   // children: [
  //   //   {
  //   //     path: Pathnames.LIST_STORES,
  //   //     title: "List Stores",
  //   //     type: "link",
  //   //   },
  //   //   {
  //   //     path: Pathnames.CREATE_STORE,
  //   //     title: "Create Store",
  //   //     type: "link",
  //   //   },
  //   // ],
  // },
  {
    title: "Users",
    icon: Users,
    type: "link",
    active: false,
    path: Pathnames.LIST_USER,
    // children: [
    //   {
    //     path: Pathnames.INVITE_USER,
    //     title: "Invite User",
    //     type: "link",
    //   },
    //   {
    //     path: Pathnames.LIST_USER,
    //     title: "List User",
    //     type: "link",
    //   },
    // ],
  },
  // {
  //   title: "eReceipts",
  //   path: Pathnames.ERECEIPTS,
  //   icon: Archive,
  //   type: "link",
  //   active: false,
  // },
  // {
  //   title: "Media",
  //   path: "/media",
  //   icon: Camera,
  //   type: "link",
  //   active: false,
  // },

  {
    title: "Settings",
    path: Pathnames.SETTINGS,
    icon: Settings,
    type: "link",
    active: false,
  },

  // {
  //   title: "Forgot Password",
  //   path: "/forgot-password",
  //   icon: Key,
  //   type: "link",
  //   active: false,
  // },
  // {
  //   title: "Login",
  //   path: "/auth/login",
  //   icon: LogIn,
  //   type: "link",
  //   active: false,
  // },
];
