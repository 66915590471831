import { useRef } from "react";
import { useCalendarCell } from "@react-aria/calendar";
import { isSameDay, getDayOfWeek, isSameMonth } from "@internationalized/date";

import { Button } from "@mui/material";
import { getButtonStyles } from "./CalendarCell.config";

export function CalendarCell({ state, date, currentMonth }) {
  let ref = useRef();
  let { cellProps, buttonProps, isSelected, isDisabled, formattedDate, isInvalid } = useCalendarCell(
    { date },
    state,
    ref
  );

  let isOutsideMonth = !isSameMonth(currentMonth, date);

  let isSelectionStart = state.highlightedRange ? isSameDay(date, state.highlightedRange.start) : isSelected;
  let isSelectionEnd = state.highlightedRange ? isSameDay(date, state.highlightedRange.end) : isSelected;

  let dayOfWeek = getDayOfWeek(date, "en-US");
  let isRoundedLeft = isSelected && (isSelectionStart || dayOfWeek === 0 || date.day === 1);
  let isRoundedRight =
    isSelected && (isSelectionEnd || dayOfWeek === 6 || date.day === date.calendar.getDaysInMonth(date));

  const buttonStyles = getButtonStyles(
    isRoundedLeft,
    isRoundedRight,
    isSelected,
    isInvalid,
    isDisabled,
    isSelectionStart,
    isSelectionEnd
  );

  const handleOnClick = (e) => {
    if (state.anchorDate) state.setValue({ start: state.anchorDate, end: state.anchorDate });
    buttonProps.onClick(e);
  };

  return (
    <td {...cellProps}>
      {isOutsideMonth ? null : (
        <Button
          variant="ghost"
          {...buttonProps}
          onClick={handleOnClick}
          hidden={isOutsideMonth}
          sx={{
            p: "10px",
            minWidth: "20px",
            height: "30px",
            my: "2px",
            gap: "12px",
            fontSize: "14px",
            ...buttonStyles,
          }}
          fullWidth
          ref={ref}
          size="small"
          disabled={isDisabled}
        >
          {formattedDate}
        </Button>
      )}
    </td>
  );
}
