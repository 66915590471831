import { IconButton, Typography } from "@mui/material";
import React, { forwardRef, useRef } from "react";
import PrintIcon from "@mui/icons-material/Print";
import "./index.scss";
import { QRCode } from "react-qrcode-logo";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ReactToPrint from "react-to-print";

const PrintContent = forwardRef(({ link, title, description }, ref) => {
  return (
    <div className="offer-qr-code position-hidden-1" ref={ref}>
      <div className="actions-wrapper">
        <div className="qr-border">
          <QRCode
            size={200}
            logoHeight={40}
            logoPadding={5}
            quietZone={0}
            logoPaddingStyle="circle"
            fgColor="#50A1EB"
            eyeColor={"#50A1EB"}
            eyeRadius={2}
            qrStyle="dots"
            // logoImage={Logo}
            value={link}
          />
        </div>
      </div>
      <Typography align="center" variant="h6" fontWeight={600}>
        {title}
      </Typography>
      <Typography align="center" variant="caption" color="gray.400" width={300}>
        {description}
      </Typography>
    </div>
  );
});

const QRCodeDisplay = ({ link, label, title, description }) => {
  const ref = useRef();
  const downloadRef = useRef();
  
  const handleDownload = () => {
    if (!downloadRef.current) return;

    html2canvas(downloadRef.current, { scale: 2.5 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "portrait", // or 'landscape'
        unit: "mm", // can be 'mm', 'cm', 'in', or 'pt'
        format: "A4", // or an array [width, height] for custom dimensions
      });
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      // Calculate the position to center the image
      const imgWidth = canvas.width / 3.78; // Convert pixels to mm
      const imgHeight = canvas.height / 3.78; // Convert pixels to mm
      const x = (pdfWidth - imgWidth) / 2;
      const y = (pdfHeight - imgHeight) / 2;

      // Add the image to the PDF at the calculated position
      pdf.addImage(imgData, "PNG", x, y, imgWidth, imgHeight);
      pdf.save("download.pdf");
    });
  };

  const handleOnPrint = () => {
    // const printMe = window.open(``, ``, `width=800,height=600`);
    // printMe.document.write(ref.current.innerHTML);
    // printMe.document.close();
    // printMe.focus();
    // printMe.print();
    // printMe.close();
    html2canvas(ref.current, { scale: 2.5 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      var pri = document.getElementById("ifmcontentstoprint").contentWindow;
      pri.document.open();
      pri.document.write(imgData);
      pri.document.close();
      pri.focus();
      pri.print();
      // const pdf = new jsPDF({
      //   orientation: "portrait", // or 'landscape'
      //   unit: "mm", // can be 'mm', 'cm', 'in', or 'pt'
      //   format: "A4", // or an array [width, height] for custom dimensions
      // });
      // const pdfWidth = pdf.internal.pageSize.getWidth();
      // const pdfHeight = pdf.internal.pageSize.getHeight();
      // // Calculate the position to center the image
      // const imgWidth = canvas.width / 3.78; // Convert pixels to mm
      // const imgHeight = canvas.height / 3.78; // Convert pixels to mm
      // const x = (pdfWidth - imgWidth) / 2;
      // const y = (pdfHeight - imgHeight) / 2;

      // // Add the image to the PDF at the calculated position
      // pdf.addImage(imgData, "PNG", x, y, imgWidth, imgHeight);
      // pdf.save("download.pdf");
    });
  };

  // const triggerDownload = (pngUrl) => {
  //   const downloadLink = document.createElement("a");
  //   downloadLink.href = pngUrl;
  //   downloadLink.download = "div-content.png";
  //   document.body.appendChild(downloadLink);
  //   downloadLink.click();
  //   document.body.removeChild(downloadLink);
  // };

  return (
    <>
      {/* <iframe
        title="document"
        id="ifmcontentstoprint"
        style={{ height: "0px", width: "0px", position: "absolute" }}
      ></iframe> */}
      <PrintContent link={link} ref={downloadRef} title={title} description={description} />
      <div className="print-hidden">
        <div className="offer-qr-code">
          <div className="qr-border">
            <QRCode
              size={130}
              logoHeight={40}
              logoPadding={5}
              quietZone={0}
              logoPaddingStyle="circle"
              fgColor="#50A1EB"
              eyeColor={"#50A1EB"}
              eyeRadius={2}
              qrStyle="dots"
              // logoImage={Logo}
              value={link}
            />
          </div>
          <div className="qr-code-actions">
            <IconButton onClick={handleDownload}>
              <FileDownloadIcon />
            </IconButton>
            {/* <IconButton onClick={handleOnPrint}>
              <PrintIcon />
            </IconButton> */}
            <ReactToPrint
              trigger={() => (
                <IconButton>
                  <PrintIcon />
                </IconButton>
              )}
              content={() => ref.current}
            />
          </div>
          <Typography align="center" width={400} variant="body2">
            {label ? (
              label
            ) : (
              <>
                Explore this offer on Gitsy app by scanning <br /> this QR Code or{" "}
                <a target="_blank" href={link}>
                  click here
                </a>
              </>
            )}
          </Typography>
        </div>
        <div className="print-visible" ref={ref}>
          <div className="qr-border">
            <QRCode
              size={200}
              logoHeight={40}
              logoPadding={5}
              quietZone={0}
              logoPaddingStyle="circle"
              fgColor="#50A1EB"
              eyeColor={"#50A1EB"}
              eyeRadius={2}
              qrStyle="dots"
              // logoImage={Logo}
              value={link}
            />
          </div>
          <Typography align="center" variant="h6" fontWeight={600}>
            {title}
          </Typography>
          <Typography align="center" variant="caption" color="gray.400" width={300}>
            {description}
          </Typography>
        </div>
      </div>
    </>
  );
};

export default QRCodeDisplay;
