import React, { useEffect, useState } from "react";
import TabsetProfile from "./tabset-profile";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { useGetUserDetails } from "queries/auth";
import { UploadImage } from "components/add-brands";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { yupSchema } from "constants";
import { uploadToS3Bucket } from "services";
import { useNavigate } from "react-router";
import SaveIcon from "@mui/icons-material/Save";
import { Button } from "@mui/material";
import { usePatchProfile } from "queries/user";
import { Pathnames } from "routes/Pathnames";
import ChangePassword from "./ChangePassword";

const Profile = () => {
  const navigate = useNavigate();
  const {
    reset,
    control,
    setError,
    register,
    trigger,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(yupSchema.MY_PROFILE),
    defaultValues: {},
  });

  const [openChangePassword, setOpenChangePassword] = useState(false);
  const handleCloseChangePassword = () => {
    setOpenChangePassword(false);
  };
  const handleOpenChangePassword = () => {
    setOpenChangePassword(true);
  };

  const { data: userDetails, isFetching } = useGetUserDetails();
  const { mutate: patchProfile, isLoading } = usePatchProfile();

  const image_path = useWatch({ control, name: "image_path" });

  const handleUpload = (event) => {
    const fileUploaded = event.target.files[0];
    setValue("image_path", fileUploaded);
    trigger("image_path");
  };

  const handleOnSubmit = (formData) => {
    if (formData?.image_path && typeof formData?.image_path !== "string") {
      const payload = {
        file: formData?.image_path,
        image_type: "user_image",
      };
      uploadToS3Bucket(payload, userDetails?.current_enterprise?.id).then((imageUrl) => {
        patchProfile(
          {
            ...formData,
            image_path: imageUrl,
          },
          {
            onSuccess: () => {},
            onError: (res) => setError("name", { type: "custom", message: res?.message }, { shouldFocus: true }),
          }
        );
      });
    } else {
      patchProfile(formData, {
        onSuccess: () => {},
        onError: (res) => setError("name", { type: "custom", message: res?.message }, { shouldFocus: true }),
      });
    }
  };

  useEffect(() => {
    if (userDetails) {
      reset({
        first_name: userDetails?.first_name,
        last_name: userDetails?.last_name,
        image_path: userDetails?.image_path,
      });
    }
  }, [userDetails]);

  return (
    <Container fluid={true}>
      <Row>
        <Col xl="4">
          <Card>
            <CardBody>
              <div className="profile-details text-center">
                <UploadImage src={image_path} handleUpload={handleUpload} errors={errors} />
                {/* <img src={designer} alt="" className="img-fluid img-90 rounded-circle blur-up lazyloaded" /> */}
                <h5 className="f-w-600 f-16 mb-0 mt-2">
                  {userDetails?.first_name} {userDetails?.last_name}
                </h5>
                <span>{userDetails?.email}</span>
                {/* <div className="social">
                    <div className="form-group btn-showcase">
                     <Button color="btn social-btn btn-fb d-inline-block">
                        {" "}
                        <i className="fa fa-facebook"></i>
                      </Button>
                      <Button color="btn social-btn btn-twitter d-inline-block">
                        <i className="fa fa-google"></i>
                      </Button>
                      <Button color="btn social-btn btn-google d-inline-block me-0">
                        <i className="fa fa-twitter"></i>
                      </Button> 
                    </div>
                  </div> */}
              </div>
              {/* <hr /> */}
              {/* <div className="project-status">
                  <h5 className="f-w-600 f-16">Employee Status</h5>
                  <Media>
                    <Media body>
                      <h6>
                        Performance <span className="pull-right">80%</span>
                      </h6>
                      <div className="progress sm-progress-bar">
                        <div
                          className="progress-bar bg-primary"
                          role="progressbar"
                          style={{ width: "90%" }}
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </Media>
                  </Media>
                  <Media>
                    <Media body>
                      <h6>
                        Overtime <span className="pull-right">60%</span>
                      </h6>
                      <div className="progress sm-progress-bar">
                        <div
                          className="progress-bar bg-secondary"
                          role="progressbar"
                          style={{ width: "60%" }}
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </Media>
                  </Media>
                  <Media>
                    <Media body>
                      <h6>
                        Leaves taken <span className="pull-right">50%</span>
                      </h6>
                      <div className="progress sm-progress-bar">
                        <div
                          className="progress-bar bg-danger"
                          role="progressbar"
                          style={{ width: "50%" }}
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </Media>
                  </Media>
                </div> */}
            </CardBody>
          </Card>
        </Col>
        <Col xl="8">
          <Card className="profile-card">
            <CardBody>
              <form onSubmit={handleSubmit(handleOnSubmit)} id="edit-profile">
                <TabsetProfile
                  control={control}
                  errors={errors}
                  register={register}
                  handleOpenChangePassword={handleOpenChangePassword}
                />
              </form>
            </CardBody>
          </Card>
        </Col>
        <ChangePassword open={openChangePassword} handleClose={handleCloseChangePassword} />

        <div className="d-flex gap-2 w-100 my-2 align-items-center justify-content-end">
          <Button variant="outlined" onClick={() => navigate(Pathnames.DASHBOARD)}>
            CANCEL
          </Button>
          <Button type="submit" disabled={isLoading} color="primary" variant="contained" endIcon={<SaveIcon />}>
            SAVE
          </Button>
        </div>
      </Row>
    </Container>
  );
};

export default Profile;
