import { Divider, InputLabel, Typography, FormControl, Select, MenuItem, Grid } from "@mui/material";
import CustomTextField from "./CustomTextField";
import SelectProducts from "./SelectProducts";
import { PurchaseTypes, noOfRequirementOptions, useCriteriaContext } from ".";

const Criteria = ({ item, products, isLoading, label }) => {
  const { handleSetValue, selected } = useCriteriaContext();

  const currState = selected?.[item];
  const currType = PurchaseTypes?.[currState?.type];

  return (
    <>
      {noOfRequirementOptions[item]?.label <= selected?.noOfRequirement ? (
        <div className="d-flex gap-3 my-3 flex-column">
          <div>
            <Typography variant="h6" fontWeight={500} sx={{ textTransform: "capitalize" }}>
              {label} Purchase Requirement
            </Typography>
            <Divider />
          </div>
          <div>
            <Typography variant="subtitle1">Minimum Purchase Requirement</Typography>
            <Typography color="gray.400" variant="subtitle2">
              The minimum quantity or amount of a product a consumer must buy to qualify for this offer.
            </Typography>
          </div>
          <Grid container spacing={2}>
            <Grid item md={12} lg={6}>
              <div className="d-flex gap-2 my-2 align-items-start">
                <FormControl sx={{ width: "40%" }}>
                  <InputLabel htmlFor="grouped-select">Requirement type</InputLabel>
                  <Select
                    value={currState?.type}
                    id="grouped-select"
                    label={"Requirement Type"}
                    onChange={(e) => handleSetValue(e.target.value, "type", item)}
                  >
                    {Object.entries(PurchaseTypes)?.map((option) => (
                      <MenuItem
                        value={option[0]}
                        key={`${item}-${option[0]}`}
                        onClick={() => {
                          handleSetValue(option[0], "type", item);
                          handleSetValue(option[1]?.code, "code", item);
                        }}
                      >
                        {option[1]?.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl sx={{ width: "40%" }}>
                  <CustomTextField
                    item={item}
                    type={currType}
                    value={currState?.value}
                    error={currState?.error}
                    decimalNotAllowed={currType?.code === 0}
                  />
                </FormControl>
              </div>
            </Grid>
          </Grid>

          <SelectProducts item={item} />
        </div>
      ) : null}
    </>
  );
};

export default Criteria;
