import { useMemo } from "react";
import { useLocation, useParams } from "react-router";
import { AppRoutes } from "routes/AppRoutes";

const usePageDetails = () => {
  const { pathname } = useLocation();
  const params = useParams();
  const details = useMemo(() => {
    if (params?.id) return Object.values(AppRoutes).find((route) => route.pathname === "/campaign/:id");
    if (params?.brand_id) return Object.values(AppRoutes).find((route) => route.pathname === "/brands/:brand_id");
    if (params?.user_id) return Object.values(AppRoutes).find((route) => route.pathname === "/users/:user_id");
    if (params?.product_id) return Object.values(AppRoutes).find((route) => route.pathname === "/products/:product_id");
    if (params?.gallery_id)
      return Object.values(AppRoutes).find((route) => route.pathname === "/campaign/gallery/:gallery_id");
    return Object.values(AppRoutes).find((route) => route.pathname === pathname);
  }, [pathname, params]);
  return details;
};

export default usePageDetails;
