import React, { Fragment } from "react";
import {
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import LayoutRoutes from "./LayoutRoutes";
import Register from "../components/auth/Register/Register";
import { Pathnames } from "./Pathnames";
import Login from "../components/auth/Login";
import ForgotPassword from "components/auth/ForgotPassword";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route
        exac
        path={`${process.env.PUBLIC_URL}${Pathnames.LOGIN}`}
        element={<Login />}
      />
      <Route
        exact
        path={`${process.env.PUBLIC_URL}${Pathnames.REGISTER}`}
        element={<Register />}
      />
      <Route
        exact
        path={`${process.env.PUBLIC_URL}${Pathnames.FORGOT_PASSWORD}`}
        element={<ForgotPassword />}
      />
      <Route path={`/*`} element={<LayoutRoutes />} />
    </Route>
  )
);

const Routers = () => {
  return (
    <Fragment>
      <RouterProvider router={router} />
    </Fragment>
  );
};

export default Routers;
