import { Button, CircularProgress } from "@mui/material";
import React from "react";

const MuiLoadingButton = ({ children, endIcon, isLoading, disabled, ...props }) => {
  return (
    <Button
      {...props}
      disabled={isLoading || disabled}
      endIcon={isLoading ? <CircularProgress color="white" size={20} /> : endIcon}
    >
      {children}
    </Button>
  );
};

export default MuiLoadingButton;
