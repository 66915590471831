import { Avatar, Button, Chip, CircularProgress, IconButton, Typography } from "@mui/material";
import { Table } from "components";
import { useGetUserDetails, usePostLogout } from "queries/auth";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Input, Row } from "reactstrap";
import { Pathnames } from "routes/Pathnames";
import LogoutIcon from "@mui/icons-material/Logout";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useQueryClient } from "@tanstack/react-query";
import { QueryKeys } from "constants";
import useGlobalState from "store";
import { usePostDeclineInviteForEnterprise, usePostJoinEnterprise, usePostSwitchEnterprise } from "queries/enterprise";

const ActionColumn = ({ data }) => {
  const { mutate: postJoinEnterprise } = usePostJoinEnterprise();
  const { mutate: postDeclineInviteforEnterprise } = usePostDeclineInviteForEnterprise();

  const handleAcceptInvite = () => {
    postJoinEnterprise({
      code: data?.id,
    });
  };

  const handleDeclineInvite = () => {
    postDeclineInviteforEnterprise({
      code: data?.id,
    });
  };

  return (
    <div className="d-flex align-items-center gap-2">
      <Button size="small" className="p-2" onClick={handleAcceptInvite}>
        Join
      </Button>
      <Button size="small" className="p-2" color="light-error" onClick={handleDeclineInvite}>
        Ignore
      </Button>
    </div>
  );
};

const columns = [
  { label: "ENTERPRISE", align: "left", key: "name" },
  { label: "EMAIL", align: "left", key: "email" },
  { label: "ROLE", align: "left", key: "role" },
  {
    label: "STATUS",
    align: "left",
    key: "status",
    render: (value) => (
      <Chip
        label={value}
        color={
          value === "Pending"
            ? "default"
            : value === "Approved"
            ? "secondary"
            : value === "Declined"
            ? "error"
            : value === "Blocked"
            ? "warning"
            : "default"
        }
      />
    ),
  },
];

const invitationColumns = [
  { label: "ENTERPRISE", align: "left", key: "enterprise_name" },
  // { label: "EMAIL", align: "left", key: "email" },
  { label: "ROLE", align: "left", key: "role" },
  {
    label: "ACTION",
    align: "left",
    key: "",
    render: (_, row, isActive) => <ActionColumn data={row} />,
  },
];

// const filters = [
//   {
//     label: "Grocery Chain",
//     options: ["All", "GC10", "GC9", "GC8"],
//   },
//   {
//     label: "Store",
//     options: ["All", "Store1", "Store2", "Store3"],
//   },
// ];

const EnterpriseStatus = () => {
  const navigate = useNavigate();
  const QueryClient = useQueryClient();
  const { approvedEnterprises } = useGlobalState();
  const [search, setSearch] = useState("");
  const [invitationSearch, setInvitationSearch] = useState("");
  const [isRefreshDisabled, setIsRefreshDisabled] = useState(false);

  const { mutate: postLogout, isLoading } = usePostLogout();
  const { data: userDetails, isFetching: isLoadingUserDetails } = useGetUserDetails();
  const { handleSwitchEnterprise } = usePostSwitchEnterprise(true);

  const handleLogout = () => {
    postLogout(null, {
      onSuccess: () => navigate(Pathnames.LOGIN),
    });
  };

  const handleOnRefresh = () => {
    setIsRefreshDisabled(true);
    QueryClient.invalidateQueries([QueryKeys.ME]);
  };

  useEffect(() => {
    if (isRefreshDisabled) setIsRefreshDisabled(false);
    // if (approvedEnterprises?.length) navigate(Pathnames.DASHBOARD);
  }, [approvedEnterprises]);

  return (
    <Fragment>
      <div className="page-wrapper authentication-bg">
        <div className="authentication-box">
          <Container className="container w-100 my-5">
            <Col className="col-md-12 p-0 card-right">
              <Card className="tab2-card">
                <CardBody>
                  <div
                    // data-aos="fade-left"
                    className="gap-2 d-flex flex-column"
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <Typography variant="h5" sx={{ textDecoration: "capitalize" }}>
                        Hello {userDetails?.first_name},👋
                        <br />
                      </Typography>
                      <div className="d-flex gap-2 align-items-center">
                        <Button
                          disabled={isLoading}
                          variant="text"
                          endIcon={
                            <>
                              {isLoading ? (
                                <CircularProgress color="white" size={20} sx={{ margin: "-5px 13px" }} />
                              ) : (
                                <LogoutIcon />
                              )}
                            </>
                          }
                          onClick={handleLogout}
                        >
                          Logout
                        </Button>
                        <Button disabled={isLoading} color="secondary" variant="text" onClick={() => navigate(-1)}>
                          Back
                        </Button>
                      </div>
                    </div>
                    <Typography variant="body1" color="gray.500">
                      Below are your enterprise requests.
                    </Typography>
                    <div className="category-table media-table coupon-list-delete">
                      <Table
                        actions={[{}]}
                        search={search}
                        searchKeys={["name"]}
                        height={"300px"}
                        isLoading={isLoadingUserDetails}
                        selectionKey={"id"}
                        toolbar={[
                          <Link to={Pathnames.JOIN_OR_CREATE_ENTERPRISE}>
                            <Button color="primary">JOIN / CREATE</Button>
                          </Link>,
                          <Button color="gray" onClick={handleOnRefresh} disabled={isRefreshDisabled}>
                            <RefreshIcon />
                          </Button>,
                        ]}
                        columns={columns}
                        data={userDetails?.enterprises}
                        handleOnClickRow={(row) => {
                          if (row?.status === "Approved") handleSwitchEnterprise(row);
                        }}
                        disabledRowCondition={(row) => row?.status !== "Approved" && row?.status !== "Blocked"}
                        handleUpdateSearch={(e) => setSearch(e.target.value)}
                      />
                    </div>
                    <Typography variant="body1" color="gray.500" className="mt-2">
                      Below are the invitations from other enterprises.
                    </Typography>
                    <div className="category-table media-table coupon-list-delete">
                      <Table
                        actions={[{}]}
                        search={invitationSearch}
                        height={"300px"}
                        isLoading={isLoadingUserDetails}
                        selectionKey={"id"}
                        columns={invitationColumns}
                        data={userDetails?.enterprise_invite}
                        handleUpdateSearch={(e) => setInvitationSearch(e.target.value)}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Container>
        </div>
      </div>
    </Fragment>
  );
};

export default EnterpriseStatus;
