import {
  ClickAwayListener,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Grow,
  Typography,
  Divider,
  IconButton,
  ListSubheader,
  Button,
  List,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Pathnames } from "routes/Pathnames";
import AddIcon from "@mui/icons-material/Add";
import { usePostDeclineInviteForEnterprise, usePostJoinEnterprise, usePostSwitchEnterprise } from "queries/enterprise";
import useGlobalState from "store";
import BusinessIcon from "@mui/icons-material/Business";
import { useGetUserDetails } from "queries/auth";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import "./header.scss";

const EnterpriseDropdown = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="enterprise-dropdown">
      <div
        onClick={handleOpen}
        ref={anchorEl}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
      >
        <BusinessIcon />
      </div>
      <Popper
        open={!!open}
        anchorEl={anchorEl}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        sx={{ zIndex: 100 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "right top",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button">
                  <SwitchEnterpriseOptions handleClose={handleClose} />
                  <EnterpriseInvites handleClose={handleClose} />
                  {/* <ListSubheader>
                    <Button color="light-error" fullWidth onClick={() => navigate(Pathnames.ENTERPRISE_STATUS)}>
                      View all requests
                    </Button>
                  </ListSubheader> */}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

const SwitchEnterpriseOptions = ({ handleClose }) => {
  const navigate = useNavigate();

  const { approvedEnterprises } = useGlobalState();
  const { data: userDetails } = useGetUserDetails();
  const { handleSwitchEnterprise } = usePostSwitchEnterprise(true, handleClose);

  const handleClick = (item) => handleSwitchEnterprise(item);

  return (
    <ul className="enterprise-menu">
      <ListSubheader className="sub-header">
        My Enterprises
        <IconButton onClick={() => navigate(Pathnames.JOIN_OR_CREATE_ENTERPRISE)}>
          <AddIcon color="primary" className="add-icon" />
        </IconButton>
      </ListSubheader>
      {approvedEnterprises?.map((item) => {
        const selected = userDetails?.current_enterprise?.id === item?.id;
        return (
          <MenuItem selected={selected} key={item?.id} disableRipple onClick={() => handleClick(item)}>
            <ListItemText>
              <div className="invite">
                <div className="menu-label">
                  <Typography align="left">{item?.name}</Typography>
                  <Typography align="left" color="gray.400" variant="caption">
                    as {item?.role}
                  </Typography>
                </div>
                <div className="invite-actions">
                  {!selected ? (
                    <Button size="small" color="secondary" className="py-1 px-3">
                      Switch
                    </Button>
                  ) : null}
                </div>
              </div>
            </ListItemText>
          </MenuItem>
        );
      })}
    </ul>
  );
};

const EnterpriseInvites = ({ handleClose }) => {
  const navigate = useNavigate();
  const { data: userDetails } = useGetUserDetails();
  const { mutate: postJoinEnterprise } = usePostJoinEnterprise();
  const { mutate: postDeclineInviteforEnterprise } = usePostDeclineInviteForEnterprise();

  const handleAcceptInvite = (invite) => {
    postJoinEnterprise(
      {
        code: invite?.id,
      },
      {
        onSuccess: () => handleClose(),
      }
    );
  };

  const handleDeclineInvite = (invite) => {
    postDeclineInviteforEnterprise(
      {
        code: invite?.id,
      },
      {
        onSuccess: () => handleClose(),
      }
    );
  };

  return (
    <>
      {!!userDetails?.enterprise_invite?.length ? (
        <List className="enterprise-menu">
          <Divider />
          <ListSubheader className="sub-header">
            <div className="d-flex w-100 justify-content-between align-items-center">
              <span>Invites ({userDetails?.enterprise_invite?.length})</span>
              <Button
                size="small"
                color="light-error"
                className="py-2 px-3"
                onClick={() => navigate(Pathnames.ENTERPRISE_STATUS)}
              >
                View all
              </Button>
            </div>
          </ListSubheader>
          <MenuItem disableRipple>
            {userDetails?.enterprise_invite?.map((invite) => (
              <ListItemText>
                <div className="invite">
                  <div className="menu-label">
                    <Typography align="left">{invite?.enterprise_name}</Typography>
                    <Typography align="left" color="gray.400" variant="caption">
                      as {invite?.role}
                    </Typography>
                  </div>
                  <div className="invite-actions">
                    <Button size="small" className="p-2" onClick={() => handleAcceptInvite(invite)}>
                      Join
                    </Button>
                    <Button
                      size="small"
                      className="p-2"
                      color="light-error"
                      onClick={() => handleDeclineInvite(invite)}
                    >
                      Ignore
                    </Button>
                  </div>
                </div>
              </ListItemText>
            ))}
          </MenuItem>
        </List>
      ) : null}
    </>
  );
};

export default EnterpriseDropdown;
