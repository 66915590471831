import { Button, Chip, Typography } from "@mui/material";
import { Table } from "components";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Card, CardBody, Col, Container } from "reactstrap";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useQueryClient } from "@tanstack/react-query";
import { QueryKeys } from "constants";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useGetAllEnterpriseRequests, usePatchUpdateEntepriseStatus } from "queries/superAdmin";
import { useToasts } from "react-toast-notifications";

const columns = [
  { label: "ENTERPRISE", align: "left", key: "name" },
  { label: "CREATED BY", align: "left", key: "created_by" },
  {
    label: "STATUS",
    align: "left",
    key: "status",
    render: (value) => (
      <Chip
        label={value}
        color={
          value === "Pending"
            ? "default"
            : value === "Approved"
            ? "secondary"
            : value === "Declined"
            ? "error"
            : value === "Blocked"
            ? "warning"
            : "default"
        }
      />
    ),
  },
];

// const filters = [
//   {
//     label: "Grocery Chain",
//     options: ["All", "GC10", "GC9", "GC8"],
//   },
//   {
//     label: "Store",
//     options: ["All", "Store1", "Store2", "Store3"],
//   },
// ];

const EnterpriseRequests = () => {
  const { addToast } = useToasts();
  const QueryClient = useQueryClient();
  const [search, setSearch] = useState("");
  const [isRefreshDisabled, setIsRefreshDisabled] = useState(false);

  const { data: allRequests, isLoading: isLoadingAllRequests, dataUpdatedAt } = useGetAllEnterpriseRequests();

  const { mutate: patchUpdateEntepriseStatus } = usePatchUpdateEntepriseStatus();

  const handleUpdateStatus = (status, row) => {
    const payload = { data: { status }, enterprise_id: row?.id };
    patchUpdateEntepriseStatus(payload);
    addToast("Successfully updated enterprise status", {
      appearance: "success",
    });
  };

  const handleOnRefresh = () => {
    setIsRefreshDisabled(true);
    QueryClient.invalidateQueries([QueryKeys.ENTERPRISE_REQUESTS]);
  };

  const actions = useMemo(
    () => [
      {
        label: () => "Approve",
        icon: () => <CheckCircleIcon color="secondary" />,
        onClick: (row) => handleUpdateStatus("Approved", row),
        enabled: (row) => row?.status === "Pending" || row?.status === "Declined",
      },
      {
        label: () => "Decline",
        icon: () => <RemoveCircleIcon color="error" />,
        onClick: (row) => handleUpdateStatus("Declined", row),
        enabled: (row) => row?.status === "Pending",
      },
      {
        label: () => "Block",
        icon: () => <RemoveCircleIcon color="warning" />,
        onClick: (row) => handleUpdateStatus("Blocked", row),
        enabled: (row) => row?.status === "Approved",
      },
      {
        label: () => "Unblock",
        icon: () => <CheckCircleIcon color="secondary" />,
        onClick: (row) => handleUpdateStatus("Approved", row),
        enabled: (row) => row?.status === "Blocked",
      },
    ],
    []
  );

  useEffect(() => {
    setIsRefreshDisabled(false);
  }, [dataUpdatedAt]);

  return (
    <Fragment>
      <div className="page-wrapper authentication-bg">
        <div className="authentication-box">
          <Container className="container w-100 my-5">
            <Col className="col-md-12 p-0 card-right">
              <Card className="tab2-card">
                <CardBody>
                  <div
                    // data-aos="fade-left"
                    className="gap-2 d-flex flex-column"
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <Typography variant="h5" sx={{ textDecoration: "capitalize" }}>
                        Hello Admin,👋
                      </Typography>
                    </div>
                    <Typography variant="body1" color="gray.500">
                      Below are the new enterprise requests.
                    </Typography>
                    <div className="category-table media-table coupon-list-delete">
                      <Table
                        actions={actions}
                        search={search}
                        searchKeys={["name"]}
                        height={"600px"}
                        isLoading={isLoadingAllRequests}
                        selectionKey={"id"}
                        toolbar={[
                          <Button color="gray" onClick={handleOnRefresh} disabled={isRefreshDisabled}>
                            <RefreshIcon />
                          </Button>,
                        ]}
                        columns={columns}
                        data={allRequests}
                        handleUpdateSearch={(e) => setSearch(e.target.value)}
                        actionsCondition={() => true}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Container>
        </div>
      </div>
    </Fragment>
  );
};

export default EnterpriseRequests;
