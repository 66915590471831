import { Button, FormGroup, FormHelperText, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { LoadingWrapper, CampaignCard } from "components";
import { useFormContext } from "react-hook-form";
import EditCampaignsDrawer from "./EditCampaignsDrawer";
import AddIcon from "@mui/icons-material/Add";
import { Label } from "reactstrap";
import { useShuffleOnDrag } from "hooks";
import { isObjectsAreEqual } from "utils";

const SelectCampaigns = ({ isEditable, galleryDetails, isLoading, setDisabled }) => {
  const location = useLocation();
  const {
    reset,
    trigger,
    setValue,
    formState: { errors },
  } = useFormContext();

  const [openSelectGalleryinEditMode, setOpenSelectGalleryinEditMode] = useState(false);

  const {
    removed,
    selected,
    setSelected,
    handleDragStart,
    handleDragEnter,
    handleDragEnd,
    handleOnPin,
    handleOnSelect,
  } = useShuffleOnDrag();

  const handleCloseSelectedCampaignsinEditMode = () => setOpenSelectGalleryinEditMode(false);
  const handleOpenSelectedCampaignsinEditMode = () => setOpenSelectGalleryinEditMode(true);

  const includedCampaigns = useMemo(() => selected?.filter((x) => !removed.includes(x?.base_gs1)), [selected, removed]);

  useEffect(() => {
    if (includedCampaigns?.length)
      setValue("campaigns", includedCampaigns, {
        shouldValidate: true,
      });
    else setValue("campaigns", includedCampaigns);

    if (isEditable && isObjectsAreEqual(includedCampaigns, galleryDetails?.base_gs1s ?? [])) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [includedCampaigns]);

  useEffect(() => {
    if (location?.state?.selectedCampaigns && !selected?.length) {
      setSelected(location?.state?.selectedCampaigns);
    }

    if (galleryDetails) {
      reset({
        title: galleryDetails?.name,
        description: galleryDetails?.description,
        image: galleryDetails?.image_path,
      });
      setSelected(galleryDetails?.base_gs1s);
    }
  }, [location?.state?.selectedCampaigns, galleryDetails, isEditable]);

  console.log("errors", errors);

  return (
    <div style={{ height: 519 }}>
      <FormGroup className="w-100">
        {selected?.length !== 0 && (
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <Label className="col-form-label">Included campaigns: {includedCampaigns?.length}</Label>
              <br />
              <Typography variant="caption" color="gray.500">
                Click and drag the campaigns to arrange the display order.
              </Typography>
            </div>
            <Button
              size="small"
              color="secondary"
              startIcon={<AddIcon />}
              onClick={handleOpenSelectedCampaignsinEditMode}
            >
              Add more
            </Button>
          </div>
        )}
      </FormGroup>
      <FormHelperText error>{errors?.campaigns?.message}</FormHelperText>
      <LoadingWrapper isLoading={isLoading}>
        <div className="d-flex position-relative flex-column gap-2 overflow-scroll" style={{ height: 450 }}>
          {selected?.map((campaign, i) => {
            const isSelected = !removed.some((x) => x === campaign?.base_gs1);
            const isPinned = campaign?.display_order >= 0;
            return (
              <CampaignCard
                isSelected={isSelected}
                campaign={campaign}
                key={campaign?.base_gs1}
                handleOnSelect={() => handleOnSelect(campaign, isSelected, i, isPinned)}
                isPinned={isPinned}
                onDragStart={(e) => handleDragStart(e, i)}
                onDragEnter={(e) => handleDragEnter(e, i)}
                onDragEnd={handleDragEnd}
                handleOnPin={() => handleOnPin(i)}
              />
            );
          })}
          {selected?.length === 0 && (
            <div className="h-100 w-100 d-flex gap-2 flex-column justify-content-center align-items-center">
              <Typography variant="body2" color="gray.500">
                Click here to include campaigns in this gallery
              </Typography>
              <Button startIcon={<AddIcon />} onClick={handleOpenSelectedCampaignsinEditMode}>
                Add campaigns
              </Button>
            </div>
          )}
        </div>
      </LoadingWrapper>
      <EditCampaignsDrawer
        selected={selected}
        open={openSelectGalleryinEditMode}
        handleClose={handleCloseSelectedCampaignsinEditMode}
        handleOnSelect={handleOnSelect}
      />
    </div>
  );
};

export default SelectCampaigns;
