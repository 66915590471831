import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { useRegisterContext } from "./Register";
import { Pathnames } from "routes/Pathnames";
import { Link, useSearchParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { yupSchema } from "constants";
import { useForm, useWatch } from "react-hook-form";
import { Checkbox, CircularProgress, FormHelperText } from "@mui/material";
import { usePostRegister } from "queries/auth";
import { useEffect, useState } from "react";

const formFields = [
  {
    name: "email",
    type: "email",
    placeholder: "Email Address",
  },
  {
    name: "password",
    type: "password",
    placeholder: "Enter Password",
  },
  {
    name: "confirm_password",
    type: "password",
    placeholder: "Confirm Password",
  },
];

const BasicDetails = ({ inviteDetails }) => {
  const [searchParams] = useSearchParams();
  const [termsAndConditions, setTermsAndConditions] = useState(false);

  const {
    control,
    setError,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(yupSchema.REGISTER) });

  const email = useWatch({ control, name: "email" });
  const first_name = useWatch({ control, name: "first_name" });
  const last_name = useWatch({ control, name: "last_name" });

  const { setStep, setData } = useRegisterContext();
  const { mutateAsync: postRegister, isLoading } = usePostRegister();

  const handleOnSubmit = (formData) => {
    const invite_code = searchParams.get("invite_code");

    postRegister(formData)
      .then((res) => {
        if (invite_code) {
          // postJoinEnterprise({ code: invite_code })
          //   .then(() => {
          //     navigate(Pathnames.DASHBOARD);
          //   })
          //   .catch(() => {
          //     addToast("Something went wrong, please try again", {
          //       appearance: "error",
          //     });
          //   });
          setData((curr) => ({
            ...curr,
            email: formData?.email,
            user_id: res?.user_id,
          }));
          setStep((curr) => curr + 1);
        } else {
          setData((curr) => ({
            ...curr,
            email: formData?.email,
            user_id: res?.user_id,
          }));
          setStep((curr) => curr + 1);
        }
      })
      .catch((res) => {
        setError("confirm_password", {
          type: "custom",
          message: res?.error?.message,
        });
      });
  };

  useEffect(() => {
    if (inviteDetails?.email && inviteDetails?.first_name && inviteDetails?.last_name) {
      setValue("email", inviteDetails?.email);
      setValue("first_name", inviteDetails?.first_name);
      setValue("last_name", inviteDetails?.last_name);
    }
  }, [inviteDetails]);

  return (
    <Form className="form-horizontal auth-form" onSubmit={handleSubmit(handleOnSubmit)}>
      <FormGroup>
        <div className="d-flex gap-2 align-items-center">
          <Input
            value={first_name}
            onChange={(e) => setValue("first_name", e.target.value)}
            name={`Layout[firstname]`}
            type="text"
            className="form-control"
            placeholder="First Name"
          />
          <Input
            value={last_name}
            onChange={(e) => setValue("last_name", e.target.value)}
            name={`Layout[lastname]`}
            type="text"
            className="form-control"
            placeholder="Last Name"
          />
        </div>
        <div style={{ width: "fit-content" }}>
          {errors?.first_name?.message || errors?.last_name?.message ? (
            <FormHelperText error>{errors?.first_name?.message || errors?.last_name?.message}</FormHelperText>
          ) : null}
        </div>
      </FormGroup>

      {formFields?.map((field) => {
        return (
          <div key={field?.name}>
            <FormGroup>
              <Input
                disabled={inviteDetails?.email && field?.name === "email" ? true : false}
                value={field?.name === "email" ? email : null}
                onChange={(e) => setValue(field?.name, e.target.value)}
                name={`Layout[${field?.name}]`}
                type={field?.type}
                className="form-control"
                placeholder={field?.placeholder}
              />
              <div style={{ width: "fit-content" }}>
                {errors?.[field?.name]?.message && (
                  <FormHelperText error>{errors?.[field?.name]?.message}</FormHelperText>
                )}
              </div>
            </FormGroup>
          </div>
        );
      })}

      <div className="form-terms">
        <FormGroup className="d-flex align-items-center">
          <Checkbox checked={termsAndConditions} onChange={(e) => setTermsAndConditions(e.target.checked)} />
          <Label className="d-block pb-0">
            I agree all statements in{" "}
            <span>
              <a target="_blank" href="https://spectivlabs.com/termsandconditions/">
                Terms &amp; Conditions
              </a>
            </span>
          </Label>
        </FormGroup>
      </div>
      <div className="form-button mt-3">
        <Button color="primary" disabled={isLoading || !termsAndConditions} type="submit">
          {isLoading ? <CircularProgress color="white" size={20} sx={{ margin: "-5px 13px" }} /> : "Next"}
        </Button>
      </div>
      <Label className="d-block pt-3">
        Already have an account?{" "}
        <Link to={Pathnames.LOGIN} style={{ textDecoration: "none", fontWeight: 600 }}>
          LOGIN
        </Link>
      </Label>
    </Form>
  );
};

export default BasicDetails;
