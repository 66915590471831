import React from "react";
import "./index.scss";
import { Avatar, Button, Divider, IconButton, Typography } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import PushPinIcon from "@mui/icons-material/PushPin";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import CampaignLogo from "@mui/icons-material/CampaignOutlined";
import { useNavigate } from "react-router";
import { Pathnames } from "routes/Pathnames";
import { format } from "date-fns";

const CampaignCard = ({
  isPreview,
  campaign,
  isSelected,
  handleOnSelect,
  isPinned,
  onDragStart,
  onDragEnter,
  onDragEnd,
  handleOnPin,
}) => {
  const navigate = useNavigate();
  return (
    <div
      onDragOver={(e) => e.preventDefault()}
      // onDrag={(e) => e.preventDefault()}
      onClick={() => !handleOnSelect && navigate(Pathnames.CAMPAIGN.replace(":id", campaign?.base_gs1))}
      draggable={isPinned}
      onDragStart={onDragStart}
      onDragEnter={onDragEnter}
      onDragEnd={onDragEnd}
      className={`campaign-card ${!handleOnSelect && "cursor-pointer"} ${
        campaign?.display_order >= 0 && "campaign-card-pinned"
      } ${isPinned && "campaign-card-draggable"} ${!isSelected && !isPreview && "campaign-card-removed-bg"}`}
    >
      <section className={`details ${!isSelected && !isPreview && "campaign-card-removed"}`}>
        <Avatar
          src={campaign?.campaign_metadata?.desktop_image_url}
          alt={campaign?.campaign_metadata?.title}
          sx={{ bgcolor: "primary.100", color: "primary.400", height: "70px", width: "70px" }}
          height={70}
          width={70}
        >
          <CampaignLogo color="primary" sx={{ height: "28px", width: "28px" }} />
        </Avatar>
        <div style={{ padding: "10px 0px 10px 10px" }}>
          <Typography fontWeight={500} variant="body1" className="line-clamp-2" maxWidth={300}>
            {campaign?.campaign_metadata?.title}
          </Typography>
          <Typography fontWeight={400} variant="caption" color="gray.500">
            {campaign?.brand_name}
          </Typography>
        </div>
      </section>
      <section className={`description ${!isSelected && !isPreview && "campaign-card-removed"}`}>
        <Typography fontWeight={400} variant="caption" color="gray.500" className="line-clamp">
          {campaign?.campaign_metadata?.description}
        </Typography>
      </section>

      <section className={`dates ${!isSelected && !isPreview && "campaign-card-removed"}`}>
        <Typography fontWeight={500} variant="caption" color="gray.600" textAlign={"left"}>
          <FiberManualRecordIcon fontSize="10px" color="success" />{" "}
          <b>
            {campaign?.campaign_start_time
              ? format(new Date(campaign?.campaign_start_time), "do MMM yyyy")
              : campaign?.campaign_start_time}
          </b>
        </Typography>

        <Typography fontWeight={400} variant="caption" color="gray.600" textAlign={"left"}>
          <FiberManualRecordIcon fontSize="10px" color="error" />{" "}
          <b>
            {campaign?.campaign_end_time
              ? format(new Date(campaign?.campaign_end_time), "do MMM yyyy")
              : campaign?.campaign_end_time}
          </b>
        </Typography>
      </section>
      <section className={`controls`}>
        {handleOnPin && isSelected ? (
          <IconButton size="small" onClick={handleOnPin} sx={{ transform: "rotate(45deg)" }}>
            {campaign?.display_order >= 0 ? <PushPinIcon /> : <PushPinOutlinedIcon />}
          </IconButton>
        ) : null}
        {handleOnSelect && !isPinned ? (
          <>
            {isSelected ? (
              <IconButton size="small" onClick={handleOnSelect}>
                <RemoveCircleIcon color="error" />
              </IconButton>
            ) : (
              <>
                {!isPreview ? (
                  <Button size="small" variant="text" onClick={handleOnSelect}>
                    Restore
                  </Button>
                ) : (
                  <IconButton size="small" onClick={handleOnSelect}>
                    <AddCircleIcon color="primary" />
                  </IconButton>
                )}
              </>
            )}
          </>
        ) : null}
      </section>
    </div>
  );
};

export default CampaignCard;
