import { Button, FormHelperText, TextField } from "@mui/material";
import { ConnectToTCBAlert, MuiLoadingButton, MyDropzone } from "components";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { Card, CardBody, Col, Container, FormGroup, Label, Row } from "reactstrap";
import { Pathnames } from "routes/Pathnames";
import SaveIcon from "@mui/icons-material/Save";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useWatch } from "react-hook-form";
import { yupSchema } from "constants";
import { uploadToS3Bucket } from "services";
import { useGetABrand, usePatchUpdateBrand, usePostAddBrand } from "queries/brands";
import { useGetUserDetails } from "queries/auth";
import AddIcon from "@mui/icons-material/Add";
import BlockNavigationModal from "pages/campaign/CreateCampaign/BlockNavigationModal";

const AddBrand = ({ isEditable }) => {
  const navigate = useNavigate();
  const params = useParams();
  const {
    trigger,
    reset,
    control,
    setValue,
    setError,
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm({
    resolver: yupResolver(yupSchema.ADD_BRAND),
    defaultValues: {
      // don't remove this values, ties with isDirty
      name: "",
      image_path: "",
    },
  });

  const image = useWatch({ control, name: "image_path" });
  const name = useWatch({ control, name: "name" });
  const authorized_partners = useWatch({ control, name: "authorized_partners" });

  const { mutate: postAddBrand, isLoading: isLoadingAdd } = usePostAddBrand();
  const { mutate: patchUpdateBrand, isLoading: isLoadingEdit } = usePatchUpdateBrand();
  const { data: userDetails } = useGetUserDetails();
  const { data: brand, isFetching: isLoadingBrandDetails } = useGetABrand(params?.brand_id);

  const handleOnSelectProvider = (_, value) => {
    setValue("provider", value);
  };

  const handleOnSelectAuthorizedPartner = (_, value) => {
    setValue("authorized_partners", [...(authorized_partners ?? []), value]);
  };

  const handleOnRemoveAuthorizedPartner = (value) => {};

  const handleOnSubmit = (formData) => {
    if (isEditable) {
      if (typeof formData?.image_path === "string") {
        patchUpdateBrand(
          {
            data: {
              name: formData?.name,
              image_path: formData?.image_path,
            },
            internal_id: params?.brand_id,
          },
          {
            onSuccess: () => {
              navigate(Pathnames.LIST_BRANDS);
            },
            onError: (res) => setError("name", { type: "custom", message: res?.message }, { shouldFocus: true }),
          }
        );
      } else {
        const payload = {
          file: formData?.image_path,
          image_type: "brand_image",
        };
        uploadToS3Bucket(payload, userDetails?.current_enterprise?.id).then((imageUrl) => {
          patchUpdateBrand(
            {
              data: {
                name: formData?.name,
                image_path: imageUrl,
              },
              internal_id: params?.brand_id,
            },
            {
              onSuccess: () => {
                navigate(Pathnames.LIST_BRANDS);
              },
              onError: (res) => setError("name", { type: "custom", message: res?.message }, { shouldFocus: true }),
            }
          );
        });
      }
    } else {
      const payload = {
        file: formData?.image_path,
        image_type: "brand_image",
      };
      uploadToS3Bucket(payload, userDetails?.current_enterprise?.id).then((imageUrl) => {
        postAddBrand(
          {
            name: formData?.name,
            image_path: imageUrl,
          },
          {
            onSuccess: () => {
              navigate(Pathnames.LIST_BRANDS);
            },
            onError: (res) => setError("name", { type: "custom", message: res?.message }, { shouldFocus: true }),
          }
        );
      });
    }
  };

  useEffect(() => {
    if (isEditable && params?.brand_id && brand) {
      reset({
        // image_type: brand?.image_path,
        name: brand?.name,
      });
      setValue("image_path", brand?.image_path);
    }
  }, [isEditable, params?.brand_id, brand]);

  return (
    <ConnectToTCBAlert isLoading={isLoadingBrandDetails}>
      <form onSubmit={handleSubmit(handleOnSubmit)} className="needs-validation user-add">
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <FormGroup className="row">
                    <Label className="col-xl-3 col-md-4">
                      <span>*</span> Name
                    </Label>
                    <div className="col-xl-8 col-md-7">
                      <TextField
                        fullWidth
                        {...register("name")}
                        placeholder="Enter a brand name"
                        error={Boolean(errors?.name?.message)}
                        helperText={errors?.name?.message}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-xl-3 col-md-4">
                      <span>*</span> Logo
                    </Label>
                    <div className="col-xl-8 col-md-7">
                      <MyDropzone setValue={setValue} trigger={trigger} value={image} id="image_path" />
                      <FormHelperText error={Boolean(errors?.image_path)}>{errors?.image_path?.message}</FormHelperText>
                    </div>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* <Row>
            <Col xl="6">
              <Card>
                <CardBody style={{ heigth: "" }}>
                  <FormGroup>
                    <InputLabel>
                      <span className="text-danger">*</span> Name
                    </InputLabel>
                    <TextField
                      fullWidth
                      {...register("name")}
                      placeholder="Enter a brand name"
                      error={Boolean(errors?.name?.message)}
                      helperText={errors?.name?.message}
                    />
                  </FormGroup>
                  <FormGroup>
                    <InputLabel>Select primary provider</InputLabel>
                    <Autocomplete
                      options={["Kabir Batra", "Joice Joy", "Purnima Srivastava"]}
                      // getOptionLabel={(option) => ""}
                      renderOption={(props, option) => {
                        return (
                          <MenuItem
                            {...props}
                            onClick={(e) => {
                              handleOnSelectProvider(e, option);
                              props.onClick(e);
                            }}
                            className="d-flex gap-2 align-items-center p-2"
                          >
                            <ListItemIcon>
                              <Avatar src={option} variant="circular" sx={{ height: 34, width: 34 }} alt={option} />
                            </ListItemIcon>
                            <ListItemText>{option}</ListItemText>
                            {true && <CancelIcon color="gray" />}
                          </MenuItem>
                        );
                      }}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          placeholder="Search for providers..."
                          autoComplete="off"
                        />
                      )}
                    />
                    {provider ? (
                      <Chip
                        label={provider}
                        avatar={<Avatar variant="circular" src={provider} alt={provider} />}
                        onDelete={() => setValue("provider", null)}
                      />
                    ) : null}
                  </FormGroup>
                  <FormGroup>
                    <InputLabel>Select authorized partners</InputLabel>
                    <Autocomplete
                      options={["Kabir Batra", "Joice Joy", "Purnima Srivastava"]}
                      // getOptionLabel={(option) => ""}
                      renderOption={(props, option) => {
                        return (
                          <MenuItem
                            {...props}
                            onClick={(e) => {
                              handleOnSelectAuthorizedPartner(e, option);
                              props.onClick(e);
                            }}
                            className="d-flex gap-2 align-items-center p-2"
                          >
                            <ListItemIcon>
                              <Avatar src={option} variant="circular" sx={{ height: 34, width: 34 }} alt={option} />
                            </ListItemIcon>
                            <ListItemText>{option}</ListItemText>
                            {true && <CancelIcon color="gray" />}
                          </MenuItem>
                        );
                      }}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          placeholder="Search for authorized partners..."
                          autoComplete="off"
                        />
                      )}
                    />
                    {authorized_partners?.length ? (
                      <div className="d-flex my-2 gap-1 flex-wrap">
                        {authorized_partners?.map((x) => (
                          <Chip label={x} avatar={<Avatar variant="circular" src={x} alt={x} />} onDelete={() => {}} />
                        ))}
                      </div>
                    ) : null}
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
            <Col xl="6">
              <Card>
                <CardBody>
                  <InputLabel>
                    <span className="text-danger">*</span> Logo
                  </InputLabel>
                  <MyDropzone setValue={setValue} trigger={() => {}} value={image} id="image_path" />
                </CardBody>
              </Card>
            </Col>
          </Row> */}
          <div className="d-flex align-items-center gap-2 my-2">
            <Button variant="outlined" onClick={() => navigate(Pathnames.LIST_BRANDS)}>
              CANCEL
            </Button>
            <MuiLoadingButton
              type="submit"
              isLoading={isLoadingAdd || isLoadingEdit}
              disabled={isEditable && image === brand?.image_path && name === brand?.name}
              // color="primary"
              // variant="contained"
              endIcon={isEditable ? <SaveIcon /> : <AddIcon />}
            >
              {isEditable ? "SAVE" : "Add Brand"}
            </MuiLoadingButton>
          </div>
        </Container>
      </form>
      <BlockNavigationModal
        title={isEditable ? "editing a brand" : "creating a brand"}
        condition={!isEditable && (!!name || !!image) && !isSubmitted}
      />
    </ConnectToTCBAlert>
  );
};

export default AddBrand;
