import React, { useMemo } from "react";
import { Card, CardBody, CardHeader, Col, Media, Row } from "reactstrap";
import InstallMobileIcon from "@mui/icons-material/InstallMobile";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { ResponsiveLine } from "@nivo/line";
import { IconButton, Tooltip } from "@mui/material";
import { Icons } from "components";
import { format } from "date-fns";
import useGlobalState from "store";

const SecondarWidgetsData = [
  {
    title: "Daily Clips",
    sub_title: "Unique Users",
    helper_text: "Total users contributing to clippings for ",
    key: "clips",
    user_count_key: "total_clips_by_users_count",
    Icon: LocalOfferIcon,
    bg_color: "#0659b2",
  },
  {
    title: "Daily Redemptions",
    sub_title: "Unique Users",
    helper_text: "Total users contributing to redemptions for ",
    key: "redemptions",
    user_count_key: "total_redemptions_by_users_count",
    Icon: CurrencyExchangeIcon,
    bg_color: "#50a1eb",
  },
  {
    title: "Daily User Interactions",
    sub_title: "New users",
    key: "clips_by_users",
    user_count_key: "new_users",
    Icon: PeopleAltOutlinedIcon,
    bg_color: "#9ad1f9",
  },
  // {
  //   title: "Daily Installs",
  //   sub_title: "----",
  //   key: "installs",
  //   Icon: InstallMobileIcon,
  //   color: "warning",
  // },
];

const SecondaryWidgets = ({ data }) => {
  return (
    <>
      {SecondarWidgetsData?.map((x) => {
        const records = data?.records?.map((row) => ({ x: row?.date, y: row?.[x?.key] })) ?? [];
        return (
          <Col xl="4 xl-50" md="6" className="mb-4">
            <Card className="order-graph sales-carousel">
              <CardHeader style={{ height: "300px" }}>
                <h6>{x?.title}</h6>
                <ResponsiveLine
                  data={[
                    {
                      id: x?.key,
                      data: records,
                    },
                  ]}
                  margin={{ top: 7, right: 7, bottom: 7, left: 25 }}
                  xScale={{ type: "point" }}
                  yScale={{
                    type: "linear",
                    min: "auto",
                    max: "auto",
                    stacked: true,
                    reverse: false,
                  }}
                  // yFormat=" >-.2f"
                  axisTop={null}
                  axisRight={null}
                  axisBottom={null}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "",
                    legendOffset: -40,
                    legendPosition: "middle",
                  }}
                  pointSize={10}
                  pointColor={{ theme: "background" }}
                  pointBorderWidth={2}
                  pointBorderColor={{ from: "serieColor" }}
                  pointLabelYOffset={-12}
                  enableArea={true}
                  areaOpacity={0.1}
                  useMesh={true}
                />

                {/* <Col className="col-6">
                <div className="value-graph">
                  <h3>
                    42%{" "}
                    <span>
                      <i className="fa fa-angle-up font-primary"></i>
                    </span>
                  </h3>
                </div>
              </Col> */}
              </CardHeader>
              <CardBody>
                <Media>
                  <Media body>
                    <div
                      style={{
                        width: "fit-content",
                        position: "relative",
                      }}
                    >
                      <span>{x?.sub_title}</span>
                      <Tooltip
                        title={
                          x?.helper_text
                          // `${format(dashboardFilter?.start_date, "MMMM yyyy")}-${format(
                          //   dashboardFilter?.end_date,
                          //   "MMMM yyyy"
                          // )}`
                        }
                        placement="right"
                      >
                        <IconButton
                          size="small"
                          sx={{
                            position: "absolute",
                            right: "-23px",
                            top: "-6px",
                          }}
                        >
                          {Icons.INFO}
                        </IconButton>
                      </Tooltip>
                    </div>
                    <h3 className="mb-0">{data?.[x?.user_count_key]}</h3>
                    {/* <p>
                  0.25%{" "}
                  <span>
                    <i className="fa fa-angle-up"></i>
                  </span>
                </p> */}
                  </Media>
                  <div
                    className={`b-r-8 ${x?.color ? `bg-${x?.color}` : ""}`}
                    style={{ background: x?.bg_color, color: "white" }}
                  >
                    <div className="small-box">
                      <x.Icon />
                    </div>
                  </div>
                </Media>
              </CardBody>
            </Card>
          </Col>
        );
      })}
    </>
  );
};

export default SecondaryWidgets;
