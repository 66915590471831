export const Pathnames = {
  LOGIN: "/",
  FORGOT_PASSWORD: "/forgot-password",
  REGISTER: "/register",
  DASHBOARD: "/dashboard",
  CREATE_CAMPAIGN: "/campaign/create-campaign",
  LIST_CAMPAIGN: "/campaign/list-campaign",
  LIST_GALLERY: "/campaign/gallery",
  CREATE_GALLERY: "/campaign/create-gallery",
  EDIT_GALLERY: "/campaign/gallery/:gallery_id",
  CAMPAIGN: "/campaign/:id",
  CREATE_GROCERY_CHAIN: "/grocery-chain/create-grocery-chain",
  LIST_GROCERY_CHAINS: "/grocery-chain/list-grocery-chains",
  CREATE_STORE: "/store/create-store",
  LIST_STORES: "/store/list-stores",
  ADD_PRODUCT: "/products/add-product",
  LIST_PRODUCTS: "/products",
  EDIT_PRODUCTS: "/products/:product_id",
  INVITE_USER: "/invite-user",
  LIST_USER: "/users",
  USER_DETAILS: "/users/:user_id",
  ERECEIPTS: "/e-receipts",
  ENTERPRISE_STATUS: "/enterprise-status",
  JOIN_OR_CREATE_ENTERPRISE: "/join-enterprise",
  SETTINGS: "/settings",
  LIST_BRANDS: "/brands",
  ADD_BRANDS: "/brands/add-brands",
  EDIT_BRANDS: "/brands/:brand_id",
  MY_PROFILE: "/profile/me",
  ENTERPRISE_REQUESTS: "/admin/enterprise-requests",
};
