import { useMemo, useState } from "react";

const useShuffleOnDrag = () => {
  const [selected, setSelected] = useState([]);
  const [removed, setRemoved] = useState([]);
  const [draggedItem, setDraggedItem] = useState(null);

  const handleDragStart = (e, index) => {
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/plain", index.toString());
    setDraggedItem(index);
  };

  const handleDragEnter = (e, index) => {
    if (selected[index].display_order < 0) return; // Prevent dropping onto unpinned items
    if (draggedItem === index) return;

    const newItems = [...selected];
    const draggingValue = newItems[draggedItem];

    newItems.splice(draggedItem, 1);
    newItems.splice(index, 0, draggingValue);

    setSelected(newItems);
    setDraggedItem(index);
  };

  const handleDragEnd = () => {
    setDraggedItem(null);
  };

  const handleOnPin = (index) => {
    let newItems = [...selected];
    const pinItem = newItems[index];
    newItems.splice(index, 1);

    let firstUnpinnedItem = selected?.findIndex((x) => x?.display_order < 0 || x?.display_order === undefined);

    if (firstUnpinnedItem === -1) firstUnpinnedItem = selected?.length - 1;
    else if (firstUnpinnedItem === 0) firstUnpinnedItem = 0;

    if (pinItem?.display_order >= 0) {
      newItems.splice(firstUnpinnedItem - 1, 0, { ...pinItem, display_order: -1 });
    } else {
      newItems.splice(firstUnpinnedItem, 0, { ...pinItem, display_order: firstUnpinnedItem });
    }

    setSelected(newItems);
  };

  const handleOnSelect = (item, isSelected, index, isPinned) => {
    if (isSelected) {
      setRemoved((state) => [...state, item?.base_gs1]);
      if (isPinned) handleOnPin(index);
      // setSelected((state) => state?.filter((x) => x?.base_gs1 !== item?.base_gs1));
    } else {
      if (index !== null) {
        setRemoved((state) => state.filter((x) => x !== item?.base_gs1));
      } else {
        setSelected((state) => [...state, item]);
      }
    }
  };

  return {
    handleDragStart,
    handleDragEnter,
    handleDragEnd,
    handleOnPin,
    handleOnSelect,
    removed,
    selected,
    setSelected,
  };
};

export default useShuffleOnDrag;
