import React from "react";
import { useTableActionContext } from "components/Table";
import { useGetUserDetails } from "queries/auth";
import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import {
  useDeleteUserFromEnterprise,
  usePatchBlockFromEnterprise,
} from "queries/enterprise";

const BlockUser = () => {
  const { setIsModalOpen, row: user } = useTableActionContext();
  const { mutate: patchBlockUserFromEnterprise } =
    usePatchBlockFromEnterprise();
  const { data: userDetails } = useGetUserDetails();

  const handleSubmit = () => {
    patchBlockUserFromEnterprise({
      id: userDetails?.current_enterprise?.id,
      user_id: user?.user_id,
    });
    setIsModalOpen(false);
  };

  return (
    <>
      <DialogTitle>Block User</DialogTitle>
      <DialogContent sx={{ width: "400px" }}>
        <Alert severity="warning">
          Are you sure you want to block this user from the enterprise?
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button color="light-error" onClick={handleSubmit}>
          Yes
        </Button>
        <Button onClick={() => setIsModalOpen(false)}>No</Button>
      </DialogActions>
    </>
  );
};

export default BlockUser;
