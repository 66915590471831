import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryKeys } from "constants";
import { ApiConfig } from "constants";
import { useNavigate } from "react-router";
import { Pathnames } from "routes/Pathnames";
import { AxiosInstance } from "services";
import { useGetUserDetails } from "./auth";

const postAddNewEnterprise = (data) => {
  const method = "POST";
  const url = ApiConfig.ENTERPRISE;
  return AxiosInstance({ method, url, data });
};

export const usePostAddNewEnterprise = () => {
  const queryClient = useQueryClient();
  return useMutation(postAddNewEnterprise, {
    onSuccess: () => queryClient.invalidateQueries([QueryKeys.ME]),
  });
};

const postJoinEnterprise = (data) => {
  const method = "POST";
  const url = ApiConfig.ACCEPT_INVITE;
  return AxiosInstance({ method, url, data });
};

export const usePostJoinEnterprise = () => {
  const queryClient = useQueryClient();
  return useMutation(postJoinEnterprise, {
    // onSuccess: () => queryClient.invalidateQueries([QueryKeys.ME]),
  });
};

const postDeclineInviteForEnterprise = (data) => {
  const method = "POST";
  const url = ApiConfig.DECLINE_INVITE;
  return AxiosInstance({ method, url, data });
};

export const usePostDeclineInviteForEnterprise = () => {
  const queryClient = useQueryClient();
  return useMutation(postDeclineInviteForEnterprise, {
    onSuccess: () => queryClient.invalidateQueries([QueryKeys.ME]),
  });
};

const postInviteUser = (payload) => {
  const { enterprise_id, ...data } = payload;
  const method = "POST";
  const url = ApiConfig.INVITE_USER.replace(":id", enterprise_id);
  return AxiosInstance({ method, url, data });
};

export const usePostInviteUser = () => {
  const queryClient = useQueryClient();

  return useMutation(postInviteUser, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.INVITES]);
    },
  });
};

const postRevokeInvite = (data) => {
  const method = "POST";
  const url = ApiConfig.REVOKE_INVITE;
  return AxiosInstance({ method, url, data });
};

export const usePostRevokeInvite = () => {
  const queryClient = useQueryClient();
  return useMutation(postRevokeInvite, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.INVITES]);
    },
  });
};

const patchUpdateUserRole = ({ enterprise_id, user_id, data }) => {
  const method = "PATCH";
  const url = ApiConfig.UPDATE_USER_ROLE.replace(":id", enterprise_id).replace(":user_id", user_id);
  return AxiosInstance({ method, url, data });
};

export const usePatchUpdateUserRole = () => {
  const queryClient = useQueryClient();
  const { data: userDetails } = useGetUserDetails();
  return useMutation(patchUpdateUserRole, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.ENTERPRISE_DETAILS, userDetails?.current_enterprise?.id]);
    },
  });
};

const patchSwitchEnterprise = (data) => {
  const method = "PATCH";
  const url = ApiConfig.SWITCH_ENTERPRISE;
  return AxiosInstance({ method, url, data });
};

export const usePostSwitchEnterprise = (isNavigate, handleOnSuccess) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate: switchEnterprise, isLoading } = useMutation(patchSwitchEnterprise);

  const userDetails = queryClient.getQueryData([QueryKeys.ME]);

  const handleSwitchEnterprise = (enterprise, authResponse) => {
    // if (userDetails?.current_enterprise?.id !== enterprise?.id) {
    switchEnterprise(enterprise, {
      onSuccess: (res) => {
        queryClient.setQueryData([QueryKeys.ME], {
          ...(authResponse ?? userDetails), // after login when switch enterprise is immidiately called, there's a delay in getting `userDetails` which leads to undefined data, so we are `userDetail` as optional 2nd parameter `authResponse` in this function
          current_enterprise: res?.current_enterprise,
        });
        if (isNavigate) navigate(Pathnames.DASHBOARD, { replace: true });
        if (handleOnSuccess) handleOnSuccess();
      },
    });
    // }
  };

  return { handleSwitchEnterprise, isLoading };
};

const patchDefaultEnterprise = (data) => {
  const method = "PATCH";
  const url = ApiConfig.DEFAULT_ENTERPRISE;
  return AxiosInstance({ method, url, data });
};

export const usePatchDefaultEnterprise = () => {
  const queryClient = useQueryClient();
  const userDetails = queryClient.getQueryData([QueryKeys.ME]);

  return useMutation(patchDefaultEnterprise, {
    onSuccess: (res) => {
      queryClient.setQueryData([QueryKeys.ME], {
        ...userDetails,
        default_enterprise: res?.current_enterprise,
      });
    },
  });
};

const patchEnterpriseSettings = ({ payload, id }) => {
  const method = "PATCH";
  const url = ApiConfig.ENTERPRISE_SETTINGS.replace(":id", id);
  return AxiosInstance({ method, url, data: payload });
};

export const usePatchEnterpriseSettings = () => {
  const queryClient = useQueryClient();
  const { data: userDetails } = useGetUserDetails();
  return useMutation(patchEnterpriseSettings, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.ENTERPRISE_DETAILS, userDetails?.current_enterprise?.id]);
      queryClient.invalidateQueries([QueryKeys.ME]);
    },
  });
};


const getEnterpriseDetails = (id) => {
  const method = "GET";
  const url = ApiConfig.ENTERPRISE_DETAILS.replace(":id", id);
  return AxiosInstance({ method, url });
};

export const useGetEnterpriseDetails = () => {
  const { data: userDetails } = useGetUserDetails();
  return useQuery(
    [QueryKeys.ENTERPRISE_DETAILS, userDetails?.current_enterprise?.id],
    () => getEnterpriseDetails(userDetails?.current_enterprise?.id),
    {
      enabled: !!userDetails?.current_enterprise?.id,
    }
  );
};

const deleteUserFromEnterprise = ({ id, user_id }) => {
  const method = "DELETE";
  const url = ApiConfig.DELETE_USER.replace(":id", id).replace(":user_id", user_id);
  return AxiosInstance({ method, url });
};

export const useDeleteUserFromEnterprise = () => {
  const queryClient = useQueryClient();
  const { data: userDetails } = useGetUserDetails();

  return useMutation(deleteUserFromEnterprise, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.ENTERPRISE_DETAILS, userDetails?.current_enterprise?.id]);
    },
  });
};

const patchBlockFromEnterprise = ({ id, user_id }) => {
  const method = "PATCH";
  const url = ApiConfig.BLOCK_USER.replace(":id", id).replace(":user_id", user_id);
  return AxiosInstance({ method, url });
};

export const usePatchBlockFromEnterprise = () => {
  const queryClient = useQueryClient();
  const { data: userDetails } = useGetUserDetails();

  return useMutation(patchBlockFromEnterprise, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.ENTERPRISE_DETAILS, userDetails?.current_enterprise?.id]);
    },
  });
};

const getAllEnterprises = () => {
  const method = "GET";
  const url = ApiConfig.ENTERPRISE;
  return AxiosInstance({ method, url });
};

export const useGetAllEnterprises = (enabled) => {
  return useQuery([QueryKeys.LIST_ENTEPRISES], () => getAllEnterprises(), {
    enabled,
  });
};

const postTcbSecrets = ({ data, enterprise_id }) => {
  const method = "POST";
  const url = ApiConfig.TCB_SECRETS.replace(":id", enterprise_id);
  return AxiosInstance({ method, url, data });
};

export const usePostTcbSecrets = () => {
  return useMutation(postTcbSecrets);
};

const getInvites = (id) => {
  const method = "GET";
  const url = ApiConfig.INVITES.replace(":id", id);
  return AxiosInstance({ method, url });
};

export const useGetInvites = () => {
  const { data: userDetails } = useGetUserDetails();
  return useQuery(
    [QueryKeys.INVITES, userDetails?.current_enterprise?.id],
    () => getInvites(userDetails?.current_enterprise?.id),
    {
      enabled: !!userDetails?.current_enterprise?.id,
    }
  );
};
