import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { Typography, Tooltip, IconButton, FormControlLabel, Switch, FormGroup, FormHelperText } from "@mui/material";

import { Accordion, AccordionSummary, AccordionDetails, Icons } from "components";
import { useCampaignContext } from "../..";
import { DollarSign } from "react-feather";

import StepperControls from "../../StepperControls";
import Criteria from "./Criteria";
import AddProductModal from "./AddProductModal";
import { isObjectsAreEqual } from "utils";
import BasicOffer from "./BasicOffer";
import ComplexOffer from "./ComplexOffer";

export const OfferTypes = {
  discount_on_item: {
    label: "Discount on Item",
    code: 0,
    placeholder: "0.00",
    iconType: "start",
    icon: <DollarSign width={20} height={17} color="#50B47F" />,
  },
  max_discount: {
    label: "Maximum discount on items",
    code: 1,
    placeholder: "0.00",
    iconType: "start",
    icon: <DollarSign width={20} height={17} color="#50B47F" />,
  },
  free_quantity: {
    label: "Free Quantity",
    code: 2,
    placeholder: "e.g. 10 ",
    iconType: "end",
    icon: "",
    units: ["lb", "kg", "unit"],
  },
  percent: {
    label: "Percentage",
    code: 5,
    placeholder: "e.g. 20%",
    iconType: "end",
    icon: "%",
  },
  discount_on_order: {
    label: "Discount on Order",
    code: 6,
    placeholder: "0.00",
    iconType: "start",
    icon: <DollarSign width={20} height={17} color="#50B47F" />,
  },
};

export const PurchaseTypes = {
  item: {
    label: "Number of Items",
    code: 0,
    placeholder: "e.g. 10",
    iconType: "end",
    icon: "unit",
  },
  discount_on_item: {
    label: "Discount on Item",
    code: 1,
    placeholder: "0.00",
    iconType: "start",
    icon: <DollarSign width={20} height={17} color="#50B47F" />,
  },
  discount_on_order: {
    label: "Discount on Total Amount",
    code: 2,
    placeholder: "0.00",
    iconType: "start",
    icon: <DollarSign width={20} height={17} color="#50B47F" />,
  },
  // discount_on_order: {
  //   label: "Total Amount",
  //   code: 2,
  //   placeholder: "0.00",
  //   iconType: "start",
  //   icon: <DollarSign width={20} height={17} color="#50B47F" />,
  // },
  pounds: {
    code: 3,
    placeholder: "e.g. 100",
    label: "Pounds (lb)",
    icon: "lb",
    iconType: "end",
  },
  kilograms: {
    label: "Kilograms (kg)",
    code: 4,
    placeholder: "e.g. 100",
    icon: "kg",
    iconType: "end",
  },
};

export const AppliesToWhichItems = [
  {
    label: "None",
    code: -1,
  },
  {
    label: "First Purchase",
    key: "primary",
    code: 0,
  },
  {
    label: "Second Purchase",
    key: "secondary",
    code: 1,
  },
  {
    label: "Third Purchase",
    key: "tertiary",
    code: 2,
  },
];

export const PurchaseRequirementsRelation = [
  {
    label: "At least one of the following: First, Second, or Third",
    code: 0,
    logic: "OR",
  },
  {
    label: "All of the following: First, Second, and Third",
    code: 1,
    logic: "AND",
  },
  {
    label: "Both: First and (Second or Third)",
    code: 2,
    logic: "AND OR",
  },
  {
    label: "Any of the following: First, Second, and Third",
    code: 3,
    logic: "OR",
  },
];

export const noOfRequirementOptions = {
  primary: {
    label: 1,
    key: "primary",
  },
  secondary: {
    label: 2,
    key: "secondary",
  },
  tertiary: {
    label: 3,
    key: "tertiary",
  },
};

const initialPurchaseRequirementState = (brand) => ({
  type: "item",
  value: null,
  data: [],
  brand,
  search: null,
  expanded: false,
  error: null,
});

const initialOfferState = {
  unit: null,
  free_quantity_type: "kg",
  type: "discount_on_item",
  value: null,
  appliesToWhichItems: -1,
  purchaseRequirementsRelation: 0,
  data: [],
};

const PurchaseCriteria = () => {
  const ref = useRef(null);
  const previousToggle = useRef(true);
  const {
    editStep,
    handleSetValues,
    setActiveStep,
    formData: { requirements, brand, campaignDuration },
    isEditable,
    selectedCampaign,
  } = useCampaignContext();

  const [selected, setSelected] = useState({
    noOfRequirement: requirements?.tertiary?.value ? 3 : requirements?.secondary?.value ? 2 : 1,
    offer: requirements?.offer ?? initialOfferState,
    primary: requirements?.primary ?? initialPurchaseRequirementState(brand),
    secondary: requirements?.secondary ?? initialPurchaseRequirementState(brand),
    tertiary: requirements?.tertiary ?? initialPurchaseRequirementState(brand),
  });

  const [disabled, setDisabled] = useState(isEditable ? true : false);

  // If secondary & tertiary purchase are not present offer & primary type matches basic offer type
  const [isBasicOffer, setIsBasicOffer] = useState(true);

  const handleSetValue = (value, label, item) => {
    setSelected((state) => {
      return {
        ...state,
        [item]: { ...state[item], [label]: value },
      };
    });
  };

  const handleValidateErrors = () => {
    let isValid = true;
    if (
      selected?.offer?.value?.toString()?.length === 0 ||
      selected?.offer?.value === null ||
      selected?.offer?.value === undefined
    ) {
      setSelected((state) => ({
        ...state,
        offer: { ...state.offer, error: "Offer value is a required field" },
      }));
      isValid = false;
      handleScrollTo(ref);
    }

    if (
      selected?.primary?.value?.toString()?.length === 0 ||
      selected?.primary?.value === null ||
      selected?.primary?.value === undefined
    ) {
      setSelected((state) => ({
        ...state,
        primary: {
          ...state.primary,
          error: "Purchase requirement value is a required field",
        },
      }));
      isValid = false;
      handleScrollTo(ref);
    }

    if (
      selected?.noOfRequirement >= 2 &&
      (selected?.secondary?.value?.toString()?.length === 0 ||
        selected?.secondary?.value === null ||
        selected?.secondary?.value === undefined)
    ) {
      setSelected((state) => ({
        ...state,
        secondary: {
          ...state.secondary,
          error: "Purchase requirement value is a required field",
        },
      }));
      isValid = false;
      handleScrollTo(ref);
    }

    if (
      selected?.noOfRequirement === 3 &&
      (selected?.tertiary?.value?.toString()?.length === 0 ||
        selected?.tertiary?.value === null ||
        selected?.tertiary?.value === undefined)
    ) {
      setSelected((state) => ({
        ...state,
        tertiary: {
          ...state.tertiary,
          error: "Purchase requirement value is a required field",
        },
      }));
      isValid = false;
      handleScrollTo(ref);
    }

    return isValid;
  };

  const handleOnSubmit = () => {
    if (handleValidateErrors()) {
      if (selectedCampaign !== null || editStep) {
        setActiveStep(5);
      } else {
        setActiveStep((prev) => prev + 1);
      }

      const currOption = AppliesToWhichItems.find((x) => x?.code === selected?.offer?.appliesToWhichItems);
      if (currOption?.code >= 0) handleSetValues("brand", selected?.[currOption?.key]?.brand);

      handleSetValues("requirements", selected);
    }
  };

  const handleToggle = () => {
    setIsBasicOffer((toggle) => {
      if (!toggle && previousToggle.current) {
        // set offer type and primary purchase to basic types when switching to `basic offers`
        setSelected((state) => ({
          noOfRequirement: 1,
          offer: requirements?.offer ?? initialOfferState,
          primary: requirements?.primary ?? initialPurchaseRequirementState(brand),
          secondary: initialPurchaseRequirementState(brand),
          tertiary: initialPurchaseRequirementState(brand),
        }));
      } else if (!toggle && !previousToggle.current) {
        // when switching to `basic offers` and initial toggle was `complex offer`
        setSelected((state) => ({
          noOfRequirement: 1,
          offer: initialOfferState,
          primary: initialPurchaseRequirementState(brand),
          secondary: initialPurchaseRequirementState(brand),
          tertiary: initialPurchaseRequirementState(brand),
        }));
      } else if (toggle && !previousToggle.current) {
        // when switching to `complex offers` and initial toggle was `complex offer`
        setSelected({
          noOfRequirement: requirements?.tertiary?.value ? 3 : requirements?.secondary?.value ? 2 : 1,
          offer: requirements?.offer ?? initialOfferState,
          primary: requirements?.primary ?? initialPurchaseRequirementState(brand),
          secondary: requirements?.secondary ?? initialPurchaseRequirementState(brand),
          tertiary: requirements?.tertiary ?? initialPurchaseRequirementState(brand),
        });
      } else if (toggle && previousToggle.current) {
        // when switching to `complex offers` and initial toggle was `basic offer`
        setSelected((state) => ({
          noOfRequirement: 1,
          offer: initialOfferState,
          primary: initialPurchaseRequirementState(brand),
          secondary: initialPurchaseRequirementState(brand),
          tertiary: initialPurchaseRequirementState(brand),
        }));
      }
      return !toggle;
    });
  };

  const handleScrollTo = (ref) => {
    ref?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      marginTop: 100,
    });
  };

  useEffect(() => {
    if (isEditable && isObjectsAreEqual(selected, requirements)) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [selected, requirements]);

  useEffect(() => {
    if (
      requirements?.secondary?.value ||
      requirements?.tertiary?.value ||
      (requirements?.offer?.type && requirements?.offer?.type !== "discount_on_item") ||
      (requirements?.primary?.type && requirements?.primary?.type !== "item")
    ) {
      setIsBasicOffer(false);
      previousToggle.current = false;
    }
  }, [requirements]);

  // useEffect(() => {
  //   if (isBasicOffer) {
  //     handleSetValue("discount_on_item", "type", "offer");
  //     handleSetValue("item", "type", "primary");
  //   }
  // }, [isBasicOffer]);

  return (
    <CriteriaContext.Provider
      value={{
        handleSetValue,
        selected,
        setSelected,
        isBasicOffer,
      }}
    >
      <div data-aos="fade-left" className="d-flex flex-column" ref={ref}>
        <Typography variant="h5" className="mb-4">
          What will be the <span className="fw-semibold">purchase requirements </span> for availing this offer?
        </Typography>
        <Accordion expanded={true}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <div className="w-100">
              <div className="d-flex justify-content-between align-items-start">
                <div>
                  <div
                    style={{
                      width: "fit-content",
                      position: "relative",
                    }}
                  >
                    <Typography>{isBasicOffer ? "Basic Offer" : "Complex Offer"}</Typography>
                    <Tooltip
                      title="The offer comprises a discounted amount applied to the selected products but also a minimum purchase
                    quantity requirement, ensuring specific quantity must be met in order to take advantage of the
                    promotion"
                      placement="right"
                    >
                      <IconButton
                        size="small"
                        sx={{
                          position: "absolute",
                          right: "-23px",
                          top: "-6px",
                        }}
                      >
                        {Icons.INFO}
                      </IconButton>
                    </Tooltip>
                  </div>
                  <Typography color="gray.400" variant="subtitle2" maxWidth={800}>
                    The offer comprises a discounted amount applied to the selected products but also a minimum purchase
                    quantity requirement, ensuring specific quantity must be met in order to take advantage of the
                    promotion
                  </Typography>
                </div>
                <div className="d-flex align-items-center gap-3">
                  <Typography variant="subtitle2">Want to create complex offer? </Typography>

                  <FormGroup>
                    <FormControlLabel control={<Switch checked={!isBasicOffer} onChange={handleToggle} />} />
                  </FormGroup>
                </div>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            {selected?.offer?.error ||
            selected?.primary?.error ||
            selected?.secondary?.error ||
            selected?.tertiary?.error ? (
              <FormHelperText error>Please enter the required fields</FormHelperText>
            ) : null}
            {isBasicOffer ? <BasicOffer /> : <ComplexOffer />}
          </AccordionDetails>
          <AddProductModal />
        </Accordion>
        <StepperControls isErrorMode onClickNext={handleOnSubmit} blockNavigation isDisabled={disabled} />
      </div>
    </CriteriaContext.Provider>
  );
};

const CriteriaContext = createContext(null);

export const useCriteriaContext = () => useContext(CriteriaContext);

export default PurchaseCriteria;
