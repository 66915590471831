import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import AuthLayout from "../Layout";
import { cloneElement, useEffect, useState } from "react";
import { createContext } from "react";
import { useContext } from "react";
import { FormStepper, DisabledOverlay } from "components";
import JoinOrCreateEnterprise from "./JoinOrCreateEnterprise";
import BasicDetails from "./BasicDetails";
import { useNavigate } from "react-router";
import { getLocalStorageItem, useInviteCode } from "hooks";
import { LocalStorageKeys } from "constants";
import { useGetUserDetails, usePostLogout } from "queries/auth";
import { Pathnames } from "routes/Pathnames";
import { usePostSwitchEnterprise } from "queries/enterprise";
import useGlobalState from "store";

const steps = ["Basic Details", "Join Enterprise"];

const StepForms = [
  <BasicDetails />,
  // <VerifyEmail />,
  <JoinOrCreateEnterprise />,
];

const RegisterContext = createContext();

export const useRegisterContext = () => useContext(RegisterContext);

const Popup = ({ isOpen, setIsOpen, inviteDetails }) => {
  const { approvedEnterprises } = useGlobalState();

  const { handleSwitchEnterprise, isLoading } = usePostSwitchEnterprise(true);

  const handleContinue = () => {
    const enterprise = approvedEnterprises?.find((x) => x?.id === inviteDetails?.enterprise_id);
    handleSwitchEnterprise(enterprise);
  };

  return (
    <Dialog open={isOpen}>
      <DialogContent>
        <div className="gap-1 d-flex flex-column">
          <Typography color="gray.500">
            You're already a member of the{" "}
            <span className="fw-semibold text-black">{inviteDetails?.enterprise_name}</span> enterprise. Click here to
            switch to this enterprise.
          </Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          fullWidth
          onClick={handleContinue}
          disabled={isLoading}
          endIcon={isLoading && <CircularProgress size={15} />}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Register = () => {
  const navigate = useNavigate();
  const { invite_code, inviteDetails, isFetchingInviteDetails } = useInviteCode();
  const isAuthenticated = getLocalStorageItem(LocalStorageKeys.ACCESS_TOKEN);

  const [step, setStep] = useState(0);
  const [data, setData] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const { data: userDetails, isFetching: isFetchingUserDetails } = useGetUserDetails();
  const { mutate: postLogout, isloading: isLoadingPostLogout } = usePostLogout();

  const handleLogout = () => {
    postLogout(null, {
      onSettled: () => {
        // setIsOpen(false);
        if (inviteDetails?.is_register)
          navigate(`${Pathnames?.LOGIN}?invite_code=${invite_code}`, {
            replace: true,
          });
      },
    });
  };

  useEffect(() => {
    if (!isFetchingInviteDetails && !isFetchingUserDetails && step === 0) {
      if (invite_code && inviteDetails && userDetails) {
        if (inviteDetails?.email === userDetails?.email && step === 0) {
          if (inviteDetails?.invite_status === "Redeemed") {
            setIsOpen(true);
          } else
            navigate(`${Pathnames?.JOIN_OR_CREATE_ENTERPRISE}?invite_code=${invite_code}`, {
              replace: true,
            });
        } else if (inviteDetails?.email !== userDetails?.email) {
          handleLogout();
        }
      } else if (invite_code && inviteDetails) {
        if (inviteDetails?.is_register) {
          navigate(`${Pathnames?.LOGIN}?invite_code=${invite_code}`, {
            replace: true,
          });
        }
      }
      // else if (userDetails) {
      //   console.log("if user without invite");
      //   navigate(Pathnames?.DASHBOARD, {
      //     replace: true,
      //   });
      // }
    }
  }, [invite_code, isAuthenticated, inviteDetails, userDetails, isFetchingInviteDetails, isFetchingUserDetails]);

  return (
    <>
      <RegisterContext.Provider
        value={{
          step,
          setStep,
          data,
          setData,
        }}
      >
        <AuthLayout slider>
          <>
            <DisabledOverlay isLoading={isFetchingUserDetails || isFetchingInviteDetails || isLoadingPostLogout}>
              <Typography variant="h5" align="left" fontWeight={600}>
                Register Account
              </Typography>
              <div className="pb-4">
                <FormStepper divider={23} activeStep={step} setActiveStep={setStep} steps={steps} />
              </div>
              <>{cloneElement(StepForms[step], { inviteDetails })}</>
            </DisabledOverlay>
          </>
        </AuthLayout>
      </RegisterContext.Provider>
      <Popup isOpen={isOpen} setIsOpen={setIsOpen} inviteDetails={inviteDetails} />
    </>
  );
};

export default Register;
