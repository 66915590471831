import { yupResolver } from "@hookform/resolvers/yup";
import { Button, FormHelperText, CircularProgress } from "@mui/material";
import { yupSchema } from "constants";
import React, { Fragment, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import SendIcon from "@mui/icons-material/Send";
import { useGetEnterpriseDetails, usePatchUpdateUserRole, usePostInviteUser } from "queries/enterprise";
import { useToasts } from "react-toast-notifications";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useGetUserDetails } from "queries/auth";
import SaveIcon from "@mui/icons-material/Save";
import { useNavigate, useParams } from "react-router";
import { LoadingWrapper } from "components";
import { Pathnames } from "routes/Pathnames";

const formFields = [
  {
    name: "Email address",
    type: "text",
    placeholder: "Enter email address",
    key: "email",
    required: true,
  },
  {
    name: "First name",
    type: "text",
    placeholder: "Enter first name",
    key: "first_name",
    required: true,
  },
  {
    name: "Last name",
    type: "text",
    placeholder: "Enter last name",
    key: "last_name",
    required: true,
  },
];

const InviteUser = ({ isEditable }) => {
  const params = useParams();
  const navigate = useNavigate();
  const { addToast } = useToasts();

  const {
    reset,
    control,
    setError,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(yupSchema.INVITE_USER),
    defaultValues: {
      role: "Internal User",
    },
  });

  const email = useWatch({ control, name: "email" });
  const first_name = useWatch({ control, name: "first_name" });
  const last_name = useWatch({ control, name: "last_name" });
  const role = useWatch({ control, name: "role" });

  const formData = {
    email,
    first_name,
    last_name,
    role,
  };

  const { mutate: postInviteUser, isLoading: isLoadingInvite } = usePostInviteUser();
  const { mutate: patchUpdateUserRole, isLoading: isLoadingUpdateRole } = usePatchUpdateUserRole();
  const { data: userDetails } = useGetUserDetails();
  const { data: enterpriseDetails, isLoading } = useGetEnterpriseDetails();

  const handleChooseRole = (e) => {
    setValue("role", e?.target?.value);
  };

  const handleUpdateRole = () => {
    patchUpdateUserRole(
      {
        data: {
          role,
        },
        enterprise_id: userDetails?.current_enterprise?.id,
        user_id: params?.user_id,
      },
      {
        onSuccess: () => {
          navigate(Pathnames.LIST_USER);
          addToast("Successfully updated user role!", {
            appearance: "success",
          });
        },
      }
    );
  };

  const handleInvite = () => {
    postInviteUser(
      { ...formData, enterprise_id: userDetails?.current_enterprise?.id },
      {
        onSuccess: () => {
          navigate(Pathnames.LIST_USER);
          addToast("Invitation Sent!", {
            appearance: "success",
          });
        },
        onError: (res) => setError("email", { type: "custom", message: res?.message }, { shouldFocus: true }),
      }
    );
  };

  const handleOnSumit = (formData) => {
    if (isEditable) handleUpdateRole(formData);
    else handleInvite(formData);
  };

  useEffect(() => {
    if (isEditable && enterpriseDetails?.access?.length && params?.user_id) {
      const data = enterpriseDetails?.access?.find((x) => x?.user_id === params?.user_id);
      if (data) {
        reset({
          first_name: data?.first_name ?? " ",
          last_name: data?.last_name ?? " ",
          role: data?.role,
          email: data?.email,
        });
      }
    }
  }, [params?.user_id, enterpriseDetails]);

  return (
    <LoadingWrapper isLoading={isEditable && isLoading}>
      <Container fluid={true}>
        <Form
          className="needs-validation user-add"
          id="invite-user-form"
          noValidate=""
          onSubmit={handleSubmit(handleOnSumit)}
        >
          <Tabs>
            <TabList className="nav nav-tabs tab-coupon">
              <Tab className="nav-link">Info</Tab>
              {/* <Tab className="nav-link">Permission</Tab> */}
            </TabList>
            <TabPanel>
              <Row>
                <Col sm="12">
                  <Card>
                    <CardBody>
                      {/* <h4>Invite User</h4> */}
                      {formFields?.map((field) => (
                        <FormGroup className="row">
                          <Label className="col-xl-3 col-md-4">
                            <span>{field?.required && "*"}</span> {field?.name}
                          </Label>
                          <div className="col-xl-8 col-md-7">
                            <Input
                              disabled={isEditable}
                              value={formData?.[field?.key]}
                              onChange={(e) => setValue(field?.key, e.target.value)}
                              className="form-control"
                              type={field?.type}
                              required=""
                              placeholder={field?.placeholder}
                            />
                            <FormHelperText error={Boolean(errors?.[field?.key])}>
                              {errors?.[field?.key]?.message}
                            </FormHelperText>
                          </div>
                        </FormGroup>
                      ))}
                      <FormGroup className="row">
                        <Label className="col-xl-3 col-md-4">
                          <span>*</span> Role
                        </Label>
                        <div className="col-xl-8 col-md-7">
                          <div className="d-flex align-items-center gap-2">
                            <Label className="d-block flex-shrink-0">
                              <Input
                                checked={role === "Internal User"}
                                value="Internal User"
                                className="radio_animated"
                                type="radio"
                                id="internal-user"
                                name="role"
                                defaultChecked
                                onClick={handleChooseRole}
                              />
                              Internal User
                            </Label>

                            <Label className="d-block flex-shrink-0">
                              <Input
                                checked={role === "External User"}
                                value="External User"
                                className="radio_animated"
                                type="radio"
                                id="external-user"
                                name="role"
                                onClick={handleChooseRole}
                              />
                              External User
                            </Label>
                            <Label className="d-block flex-shrink-0">
                              <Input
                                checked={role === "Admin"}
                                value="Admin"
                                className="radio_animated"
                                type="radio"
                                id="admin"
                                name="role"
                                onClick={handleChooseRole}
                              />
                              Admin
                            </Label>
                          </div>
                          <FormHelperText error={Boolean(errors?.role)}>{errors?.role?.message}</FormHelperText>
                        </div>
                      </FormGroup>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPanel>
            {/* <TabPanel>
              <Row>
                <Col sm="12">
                  <Card style={{ minHeight: 200 }}>
                    <CardBody></CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPanel> */}
          </Tabs>
          <div className="pull-right">
            <Button variant="outlined" className="mx-2" onClick={() => navigate(Pathnames.LIST_USER)}>
              CANCEL
            </Button>
            <Button
              disabled={isLoadingInvite || isLoadingUpdateRole}
              type="submit"
              form="invite-user-form"
              endIcon={isLoadingInvite ? <CircularProgress size={20} /> : isEditable ? <SaveIcon /> : <SendIcon />}
            >
              {isEditable ? "SAVE" : "SEND"}
            </Button>
          </div>
        </Form>
      </Container>
    </LoadingWrapper>
  );
};

export default InviteUser;
