import AddProduct from "pages/products/AddProduct";
import CreateCampaign from "pages/campaign/CreateCampaign";
import ListCampaign from "pages/campaign/ListCampaign";
import ListGroceryChains from "pages/groceryChains/ListGroceryChains";
import CreateGroceryChain from "pages/groceryChains/CreateGroceryChain";
import ListStores from "pages/stores/ListStores";
import CreateStore from "pages/stores/CreateStore";
import ListProducts from "pages/products/ListProducts";
import { Pathnames } from "./Pathnames";
import ListUsers from "pages/users/ListUsers";
import EReceipts from "pages/e-receipts";
import EnterpriseStatus from "components/auth/EnterpriseStatus";
import JoinOrCreateEnterpriseAuth from "components/auth/JoinOrCreateEnterpriseAuth";
import InviteUser from "pages/users/InviteUser";
import Settings from "pages/settings";
import Brands from "pages/brands";
import Dashboard from "pages/Dashboard";
import ListGallery from "pages/campaign/ListGallery";
import CreateGallery from "pages/campaign/ListGallery/CreateGallery";
import AddBrand from "pages/brands/AddBrand";
import Profile from "pages/settings/profile";
import EnterpriseRequests from "components/auth/Admin/EnterpriseRequests";

export const AppRoutes = {
  ENTERPRISE_STATUS: {
    pathname: Pathnames.ENTERPRISE_STATUS,
    component: <EnterpriseStatus />,
    title: "Enterprise Status",
  },
  JOIN_OR_CREATE_ENTERPRISE: {
    pathname: Pathnames.JOIN_OR_CREATE_ENTERPRISE,
    component: <JoinOrCreateEnterpriseAuth />,
    title: "Enterprise Status",
  },
  DASHBOARD: {
    pathname: Pathnames.DASHBOARD,
    component: <Dashboard />,
    title: "Dashboard",
  },
  CREATE_CAMPAIGN: {
    pathname: Pathnames.CREATE_CAMPAIGN,
    component: <CreateCampaign key="is-create" />,
    title: "Create a Campaign",
  },
  LIST_CAMPAIGN: {
    pathname: Pathnames.LIST_CAMPAIGN,
    component: <ListCampaign />,
    title: "Campaigns",
  },
  LIST_GALLERY: {
    pathname: Pathnames.LIST_GALLERY,
    component: <ListGallery />,
    title: "Galleries",
  },
  CREATE_GALLERY: {
    pathname: Pathnames.CREATE_GALLERY,
    component: <CreateGallery key="create-gallery" />,
    title: "Create Gallery",
  },
  EDIT_GALLERY: {
    pathname: Pathnames.EDIT_GALLERY,
    component: <CreateGallery isEditable key="edit-gallery" />,
    title: "Edit Gallery",
  },
  CREATE_GROCERY_CHAIN: {
    pathname: Pathnames.CREATE_GROCERY_CHAIN,
    component: <CreateGroceryChain />,
    title: "Create Grocery Chain",
  },
  LIST_GROCERY_CHAINS: {
    pathname: Pathnames.LIST_GROCERY_CHAINS,
    component: <ListGroceryChains />,
    title: "List Grocery Chains",
  },
  CREATE_STORE: {
    pathname: Pathnames.CREATE_STORE,
    component: <CreateStore />,
    title: "Create Store",
  },
  LIST_STORES: {
    pathname: Pathnames.LIST_STORES,
    component: <ListStores />,
    title: "List Stores",
  },
  ADD_PRODUCT: {
    pathname: Pathnames.ADD_PRODUCT,
    component: <AddProduct key="product-add" />,
    title: "Add Product",
  },
  EDIT_PRODUCT: {
    pathname: Pathnames.EDIT_PRODUCTS,
    component: <AddProduct isEditable key="product-edit" />,
    title: "Edit Product",
  },
  LIST_PRODUCTS: {
    pathname: Pathnames.LIST_PRODUCTS,
    component: <ListProducts />,
    title: "Products",
  },
  INVITE_USER: {
    pathname: Pathnames.INVITE_USER,
    component: <InviteUser key="user-details-add" />,
    title: "Invite User",
  },
  USER_DETAILS: {
    pathname: Pathnames.USER_DETAILS,
    component: <InviteUser isEditable key="user-details-edit" />,
    title: "User Details",
  },
  LIST_USER: {
    pathname: Pathnames.LIST_USER,
    component: <ListUsers />,
    title: "Users",
  },
  ERECEIPTS: {
    pathname: Pathnames.ERECEIPTS,
    component: <EReceipts />,
    title: "E-Receipts",
  },
  CAMPAIGN: {
    pathname: Pathnames.CAMPAIGN,
    component: <CreateCampaign isEditable key="campaign-edit" />,
    title: "CAMPAIGN REVIEW",
  },
  SETTINGS: {
    pathname: Pathnames.SETTINGS,
    component: <Settings />,
    title: "Settings",
  },
  LIST_BRANDS: {
    pathname: Pathnames.LIST_BRANDS,
    component: <Brands />,
    title: "Brands",
  },
  ADD_BRANDS: {
    pathname: Pathnames.ADD_BRANDS,
    component: <AddBrand key="add-brands" />,
    title: "Add Brand",
  },
  EDIT_BRANDS: {
    pathname: Pathnames.EDIT_BRANDS,
    component: <AddBrand isEditable key="edit-brands" />,
    title: "Edit Brand",
  },
  MY_PROFILE: {
    pathname: Pathnames.MY_PROFILE,
    component: <Profile />,
    title: "My Profile",
  },
  ENTERPRISE_REQUESTS: {
    pathname: Pathnames.ENTERPRISE_REQUESTS,
    component: <EnterpriseRequests />,
    title: "My Profile",
  },
};
