import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryKeys } from "constants";
import { ApiConfig } from "constants";
import { AxiosInstance } from "services";

const getAllEnterpriseRequests = () => {
  const method = "GET";
  const url = ApiConfig.ENTERPRISE_REQUESTS;
  return AxiosInstance({ method, url });
};

export const useGetAllEnterpriseRequests = (enabled) => {
  return useQuery([QueryKeys.ENTERPRISE_REQUESTS], () => getAllEnterpriseRequests(), {
    enabled,
  });
};

const patchUpdateEntepriseStatus = ({ data, enterprise_id }) => {
  const method = "PATCH";
  const url = ApiConfig.ENTERPRISE_STATUS.replace(":id", enterprise_id);
  return AxiosInstance({ method, url, data });
};

export const usePatchUpdateEntepriseStatus = () => {
  const queryClient = useQueryClient();
  return useMutation(patchUpdateEntepriseStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.ENTERPRISE_REQUESTS]);
    },
  });
};
