import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { FormHelperText, Typography } from "@mui/material";
import { Pathnames } from "routes/Pathnames";
import logo from "assets/logo/gitsy_color.svg";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { yupSchema } from "constants";
import { usePostLogin } from "queries/auth";
import { LoadingButton } from "components";
import useGlobalState from "store";
import { useInviteCode } from "hooks";
import { useLoginContext } from ".";
import { usePostSwitchEnterprise } from "queries/enterprise";

const LoginForm = () => {
  const navigate = useNavigate();
  const { setActiveStep } = useLoginContext();
  const { setApprovedEnterprises } = useGlobalState();
  const { invite_code, inviteDetails } = useInviteCode();

  const {
    setValue,
    control,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(yupSchema.LOGIN) });

  const { mutateAsync: postLogin, isLoading } = usePostLogin();
  const { handleSwitchEnterprise } = usePostSwitchEnterprise(true);

  const email = useWatch({ control, name: "email" });
  const password = useWatch({ control, name: "password" });

  const handleLogin = (formData) => {
    postLogin(formData)
      .then((res) => {
        const ApprovedEnterprises = res?.user?.enterprises?.filter(
          (obj) => obj.status === "Approved" || obj.status === "Blocked"
        );

        if (invite_code) {
          if (inviteDetails?.invite_status === "Redeemed" && ApprovedEnterprises)
            handleSwitchEnterprise(ApprovedEnterprises?.find((x) => x?.id === inviteDetails?.enterprise_id));
          else navigate(`${Pathnames.JOIN_OR_CREATE_ENTERPRISE}?invite_code=${invite_code}`, { replace: true });
        } else {
          if (ApprovedEnterprises?.length) {
            setApprovedEnterprises(ApprovedEnterprises);
            if (res?.user?.default_enterprise) {
              handleSwitchEnterprise(res?.user?.default_enterprise, res?.user);
            } else if (!res?.user?.default_enterprise) {
              if (ApprovedEnterprises?.length === 1) {
                handleSwitchEnterprise(ApprovedEnterprises?.[0], res?.user);
              } else {
                setActiveStep((curr) => curr + 1);
              }
            }
          } else navigate(Pathnames.ENTERPRISE_STATUS);
        }
      })
      .catch((res) => {
        setError("email", {
          type: "custom",
          message: res?.message || res?.error?.message,
        });
      });
  };

  useEffect(() => {
    if (inviteDetails?.email) setValue("email", inviteDetails?.email);
  }, [inviteDetails]);

  return (
    <div>
      <div className="svg-icon">
        <img alt="" src={logo} className="Img-fluid" />
      </div>
      <Form onSubmit={handleSubmit(handleLogin)} className="form-horizontal auth-form">
        <Typography variant="h5" align="left" fontWeight={600} className="pb-3">
          Login
        </Typography>
        <FormGroup>
          <Input
            disabled={!!inviteDetails?.email}
            value={email}
            onChange={(e) => setValue("email", e.target.value)}
            invalid={errors?.email?.message}
            required=""
            type="email"
            className="form-control"
            placeholder="Username"
          />
        </FormGroup>

        <FormGroup>
          <Input
            value={password}
            onChange={(e) => setValue("password", e.target.value)}
            required=""
            invalid={errors?.password?.message}
            type="password"
            className="form-control"
            placeholder="Password"
          />
          <div style={{ width: "fit-content" }}>
            {errors?.email?.message || errors?.password?.message ? (
              <FormHelperText error>{errors?.email?.message || errors?.password?.message}</FormHelperText>
            ) : null}
          </div>
        </FormGroup>

        <div className="custom-control">
          <LoadingButton isLoading={isLoading}>Login</LoadingButton>
          <div className="forgot-password">
            <Link
              to={Pathnames.FORGOT_PASSWORD}
              style={{
                textDecoration: "none",
                fontWeight: 600,
                color: "black",
              }}
            >
              Forgot Password?
            </Link>
          </div>
        </div>
        <Label className="d-block pt-4">
          Don't have an account?{" "}
          <Link to={Pathnames.REGISTER} style={{ textDecoration: "none", fontWeight: 600 }}>
            REGISTER
          </Link>
        </Label>
      </Form>
    </div>
  );
};

export default LoginForm;
