import { Avatar, Button, DialogActions, DialogContent, Paper, Typography } from "@mui/material";
import { AddBrand, ConnectToTCBAlert, SeeAllDrawer, Table } from "components";
import { useDeleteBrand, useGetABrand, useGetBrands } from "queries/brands";
import React, { useMemo, useState } from "react";
import { Card, CardBody } from "reactstrap";
import { useTableActionContext } from "components/Table";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import EditIcon from "@mui/icons-material/Edit";
import { useGetProducts } from "queries/products";
import { useNavigate } from "react-router";
import { Pathnames } from "routes/Pathnames";
import AddIcon from "@mui/icons-material/Add";
// import BrandIcon from "assets/icons/BrandIcon";
import AssignmentIcon from "@mui/icons-material/Assignment";
import BrandIcon from "@mui/icons-material/CategoryOutlined";

const DeleteBrand = () => {
  const { setIsModalOpen, row } = useTableActionContext();
  const { mutate: postDeleteBrand } = useDeleteBrand();

  const handleDelete = () => {
    postDeleteBrand(row?.internal_id, {
      onSuccess: () => {},
    });
    setIsModalOpen(false);
  };

  return (
    <>
      <DialogContent>
        <div className="gap-1 d-flex mb-2 flex-column">
          <Typography variant="h5" fontWeight={700}>
            Delete Brand
          </Typography>
          <Typography color="gray.500">Are you sure you want to delete this brand?</Typography>
        </div>
        <Paper sx={{ bgcolor: "gray.25" }} elevation={0}>
          <div className="d-flex align-items-center gap-2 p-2">
            <img
              src={row?.image_path}
              alt={row?.name}
              style={{
                height: "70px",
                width: "70px",
                margin: "0px 10px",
                objectFit: "contain",
                objectPosition: "center",
              }}
            />
            <div>
              <Typography fontWeight={500} variant="body1" className="line-clamp-2" maxWidth={300}>
                {row?.name}
              </Typography>
              <Typography fontWeight={400} variant="caption" color="gray.500">
                {row?.description}
              </Typography>
            </div>
          </div>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button color="light-error" fullWidth onClick={handleDelete}>
          Delete
        </Button>
        <Button fullWidth onClick={() => setIsModalOpen(false)}>
          Cancel
        </Button>
      </DialogActions>
    </>
  );
};

const searchKeys = ["name"];

const Brands = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [openSelectProviders, setOpenSelectProviders] = useState(false);
  const [openSelectAuhtorizedPartner, setOpenSelectAuhtorizedPartner] = useState(false);
  const [viewAllProducts, setViewAllProducts] = useState({
    isOpen: false,
    brand: null,
  });

  const { data: brand, isLoading: isLoadingABrand } = useGetABrand(viewAllProducts?.brand?.internal_id);
  const { data: brands, isFetching } = useGetBrands();

  const handleOpenViewAllProducts = (brand) => setViewAllProducts((state) => ({ brand, isOpen: true }));
  const handleCloseViewAllProducts = () => setViewAllProducts((state) => ({ ...state, isOpen: false }));

  const handleOpenAddBrand = () => navigate(Pathnames.ADD_BRANDS);

  const columns = useMemo(
    () => [
      {
        label: "Logo",
        key: "image_path",
        align: "left",
        // width: 80,
        default: true,
        render: (value, row) => (
          // <div className="d-flex justify-content-center">
          <Avatar
            src={value}
            alt={row?.name}
            sx={{ bgcolor: "primary.100", color: "primary.400" }}
            height={40}
            width={80}
          >
            <BrandIcon color="primary" width={24} height={24} />
          </Avatar>
          // </div>
        ),
      },
      {
        label: "Name",
        key: "name",
        align: "left",
        default: true,
        maxWidth: 200,
      },
      // {
      //   label: "Primary Provider",
      //   align: "left",
      //   default: true,
      // },
      // {
      //   label: "Authorized Partners",
      //   align: "left",
      //   default: true,
      // },
      {
        key: "products",
        label: "No. of products",
        align: "left",
        default: true,
        maxWidth: 100,
        render: (value, row) => (
          <Button size="small" variant="text" onClick={() => handleOpenViewAllProducts(row)}>
            {value}
          </Button>
        ),
      },
    ],
    []
  );

  const actions = useMemo(
    () => [
      {
        label: () => "Edit",
        icon: () => <EditIcon />,
        onClick: (row) => navigate(`${Pathnames.EDIT_BRANDS.replace(":brand_id", row?.internal_id)}`),
      },
      // {
      //   label: () => "Assign Providers",
      //   icon: () => <PersonAddIcon />,
      //   onClick: (row) => setOpenSelectProviders(true),
      // },
      // {
      //   label: () => "Assign Authorized Partners",
      //   icon: () => <PersonAddIcon />,
      //   onClick: (row) => setOpenSelectAuhtorizedPartner(true),
      // },
      {
        label: () => "Delete",
        icon: () => <DeleteIcon sx={{ color: "red" }} />,
        component: <DeleteBrand />,
      },
    ],
    []
  );

  return (
    <ConnectToTCBAlert>
      <Card>
        <CardBody>
          <div className="category-table media-table coupon-list-delete">
            <Table
              actions={actions}
              actionsCondition={() => true}
              toolbar={[
                <Button endIcon={<AddIcon />} onClick={handleOpenAddBrand}>
                  ADD BRAND
                </Button>,
              ]}
              searchKeys={searchKeys}
              search={search}
              isLoading={isFetching}
              columns={columns}
              data={brands?.data}
              handleUpdateSearch={(e) => setSearch(e.target.value)}
            />
          </div>
        </CardBody>
      </Card>
      <SeeAllDrawer
        data={brand?.products}
        isOpen={viewAllProducts?.isOpen}
        title={<>{viewAllProducts?.brand?.name}'s Products</>}
        description={
          <>
            All the products are from <b>{viewAllProducts?.brand?.name}</b>.
          </>
        }
        placeholder={"Search a product..."}
        isLoading={isLoadingABrand}
        handleCloseAllProducts={handleCloseViewAllProducts}
        handleRemoveProduct={(item) => {}}
        handleAddProduct={(item) => {}}
        isSelectedCondition={(item) => false}
        uniqueKey={"product_id"}
        displayLabel={"name"}
        displayAvatar={"image"}
        backupDisplayLabel="product_name"
      />
      {/* <SeeAllDrawer
        data={[{ name: "Kabir Batra" }, { name: "Kabir Batra" }, { name: "Kabir Batra" }]}
        isOpen={openSelectProviders}
        title={"Assign Providers"}
        placeholder="Search for providers..."
        description={
          <>
            All the providers are from <b>campaign</b>. Add or remove providers from your brand by clicking on the names
            below
          </>
        }
        isLoading={false}
        handleCloseAllProducts={() => setOpenSelectProviders(false)}
        handleRemoveProduct={() => {}}
        handleAddProduct={() => {}}
        isSelectedCondition={(item) => false}
        handleSubmit={() => {}}
        uniqueKey={"product_id"}
        displayLabel={"name"}
        displayAvatar={"image"}
      />
      <SeeAllDrawer
        data={[{ name: "Kabir Batra" }, { name: "Kabir Batra" }, { name: "Kabir Batra" }]}
        isOpen={openSelectAuhtorizedPartner}
        title={"Assign Authorized Partners"}
        placeholder="Search for Partners..."
        description={
          <>
            All the authorized partners are from <b>campaign</b>. Add or remove authorized partners from your brand by
            clicking on the names below
          </>
        }
        isLoading={false}
        handleCloseAllProducts={() => setOpenSelectAuhtorizedPartner(false)}
        handleRemoveProduct={() => {}}
        handleAddProduct={() => {}}
        handleSubmit={() => {}}
        isSelectedCondition={(item) => false}
        uniqueKey={"product_id"}
        displayLabel={"name"}
        displayAvatar={"image"}
      /> */}
    </ConnectToTCBAlert>
  );
};

export default Brands;
