import { Card, CardBody } from "reactstrap";
import { ConnectToTCBAlert, Table } from "components";
import React, { useMemo } from "react";
import { AppRoutes } from "routes/AppRoutes";
import { Link, useNavigate } from "react-router-dom";
import { Avatar, Button, DialogActions, DialogContent, Paper, Typography } from "@mui/material";
import { useDeleteProduct, useGetProducts } from "queries/products";
import { useState } from "react";
import { useDebounce } from "hooks";
import { Pathnames } from "routes/Pathnames";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useTableActionContext } from "components/Table";
import Products from "@mui/icons-material/ViewInArOutlined";

const columns = [
  {
    label: "Image",
    align: "left",
    key: "image",
    minWidth: 100,
    render: (value, row) => (
      <Avatar src={value} sx={{ bgcolor: "primary.100" }} alt={row?.name} height={40} width={80}>
        <Products color="primary" width={24} height={24} />
      </Avatar>
    ),
  },
  {
    label: "Title",
    align: "left",
    default: true,
    maxWidth: 350,
    render: (_, row) => (
      <>
        <Typography fontWeight={500} variant="body1" className="line-clamp-2" maxWidth={300}>
          {row?.name}
        </Typography>
        <Typography fontWeight={400} variant="caption" color="gray.500">
          {row?.description}
        </Typography>
      </>
    ),
  },
  // { label: "Prefixed Type", align: "left", key: "purchase_prefixed_code_type" },
  // { label: "Prefixed Code", align: "left", key: "purchase_prefixed_code" },
  {
    label: "GTINs (UPC)",
    align: "left",
    key: "upc_gtins",
    minWidth: 150,
  },
  {
    label: "GTINs (EAN)",
    align: "left",
    key: "ean_gtins",
    minWidth: 150,
  },
  {
    label: "Prefixed Codes",
    align: "left",
    key: "purchase_prefixed_code",
    minWidth: 150,
    render: (value) => (typeof value === "object" ? value?.map((x) => `${x?.type}: ${x?.value}`).join(", ") : null),
  },
];

const DeleteProducts = () => {
  const { setIsModalOpen, row } = useTableActionContext();
  const { mutate: deleteProduct } = useDeleteProduct(() => setIsModalOpen(false));

  const handleOnDelete = () => {
    deleteProduct(row?.product_id);
  };

  return (
    <>
      <DialogContent>
        <div className="gap-1 d-flex flex-column mb-2">
          <Typography variant="h5" fontWeight={700}>
            Delete Product
          </Typography>
          <Typography color="gray.500">Are you sure you want to delete this product?</Typography>
        </div>
        <Paper sx={{ bgcolor: "gray.25" }} elevation={0}>
          <div className="d-flex align-items-center gap-2 p-2">
            <img
              src={row?.image}
              alt={row?.name}
              style={{
                height: "70px",
                width: "70px",
                margin: "0px 10px",
                objectFit: "contain",
                objectPosition: "center",
              }}
            />
            <div>
              <Typography fontWeight={500} variant="body1" className="line-clamp-2" maxWidth={300}>
                {row?.name}
              </Typography>
              <Typography fontWeight={400} variant="caption" color="gray.500">
                {row?.description}
              </Typography>
            </div>
          </div>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button color="light-error" fullWidth onClick={handleOnDelete}>
          Delete
        </Button>
        <Button fullWidth onClick={() => setIsModalOpen(false)}>
          Cancel
        </Button>
      </DialogActions>
    </>
  );
};

const ListProducts = ({ isEditable }) => {
  const navigate = useNavigate();
  const [search, setSearch] = useState(null);
  const debouncedSearch = useDebounce(search, 200);

  const actions = useMemo(
    () => [
      {
        label: () => "Edit",
        icon: () => <EditIcon />,
        onClick: (row) => navigate(`${Pathnames.EDIT_PRODUCTS.replace(":product_id", row?.product_id)}`),
      },
      {
        label: () => "Delete",
        icon: () => <DeleteIcon sx={{ color: "red" }} />,
        component: <DeleteProducts />,
      },
    ],
    []
  );

  const { data: products, isLoading } = useGetProducts({
    name: debouncedSearch,
  });

  const handleUpdateSearch = (e) => setSearch(e.target.value);

  const handleOnClickRow = (row) => navigate(`${Pathnames.EDIT_PRODUCTS.replace(":product_id", row?.product_id)}`);

  return (
    <ConnectToTCBAlert>
      <Card>
        <CardBody>
          <div>
            <Table
              isLoading={isLoading}
              actions={actions}
              search={search}
              data={products}
              columns={columns}
              handleUpdateSearch={handleUpdateSearch}
              handleOnClickRow={handleOnClickRow}
              actionsCondition={() => true}
              toolbar={
                <Link to={AppRoutes.ADD_PRODUCT.pathname}>
                  <Button>ADD</Button>
                </Link>
              }
            />
          </div>
        </CardBody>
      </Card>
    </ConnectToTCBAlert>
  );
};

export default ListProducts;
