import { yupResolver } from "@hookform/resolvers/yup";
import { usePatchChangePassword } from "queries/auth";
import { useForm, useWatch } from "react-hook-form";
import { Button, Container, Form, FormGroup, Input } from "reactstrap";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormHelperText,
  IconButton,
  Typography,
} from "@mui/material";
import { yupSchema } from "constants";
import WestIcon from "@mui/icons-material/West";

const ChangePassword = ({ open, handleClose }) => {
  const {
    setValue,
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ resolver: yupResolver(yupSchema.CHANGE_PASSWORD) });

  const { mutate: patchChangePassword, isLoading } = usePatchChangePassword();

  const handleOnSubmit = (formData) => {
    const paylaod = {
      current_password: formData?.current_password,
      new_password: formData?.password,
    };
    patchChangePassword(paylaod, {
      onSuccess: () => handleClose(),
      onError: (res) => {
        setError("password", {
          type: "custom",
          message:
            res?.error?.message === "Incorrect username or password." ? "Incorrect password" : res?.error?.message,
        });
      },
    });
  };

  const current_password = useWatch({ control, name: "current_password" });
  const password = useWatch({ control, name: "password" });
  const confirm_password = useWatch({ control, name: "confirm_password" });

  return (
    <Dialog
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      fullWidth
      maxWidth="sm"
      // slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 400,
        },
      }}
    >
      <DialogTitle>
        <IconButton sx={{ marginLeft: "-10px" }} onClick={handleClose}>
          <WestIcon />
        </IconButton>{" "}
        <Typography variant="h5" fontWeight={700} textAlign={"center"}>
          Change Password
        </Typography>
      </DialogTitle>
      {/* <DialogTitle>
        <Typography variant="h6" align="center" fontWeight={600}>
          Change Password
        </Typography>
      </DialogTitle> */}
      <DialogContent>
        <div className="page-wrapper">
          <Container className="container">
            <Form
              className="form-horizontal auth-form mt-2"
              id="change-password"
              onSubmit={handleSubmit(handleOnSubmit)}
            >
              {/* <Typography color="gray.400" align="center" variant="subtitle2" className="mb-4">
        We`ve send you a code on your email
      </Typography> */}
              <FormGroup>
                <Input
                  value={current_password}
                  onChange={(e) => setValue("current_password", e.target.value)}
                  required=""
                  type="password"
                  className="form-control"
                  placeholder="Current password"
                  autoComplete="password"
                />
              </FormGroup>
              <FormGroup>
                <Input
                  value={password}
                  onChange={(e) => setValue("password", e.target.value)}
                  required=""
                  invalid={errors?.password?.message}
                  type="password"
                  className="form-control"
                  placeholder="New Password"
                  autocomplete="password"
                />
              </FormGroup>
              <FormGroup>
                <Input
                  value={confirm_password}
                  onChange={(e) => setValue("confirm_password", e.target.value)}
                  required=""
                  invalid={errors?.confirm_password?.message}
                  type="password"
                  className="form-control"
                  placeholder="Confirm Password"
                  autocomplete="password"
                />

                <div style={{ width: "fit-content" }}>
                  {errors ? (
                    <FormHelperText error>
                      {errors?.current_password?.message ||
                        errors?.password?.message ||
                        errors?.confirm_password?.message}
                    </FormHelperText>
                  ) : null}
                </div>
              </FormGroup>
              <div className="custom-control">
                <Button color="primary" type="submit" disabled={isLoading} className="w-100 rounded-5">
                  {isLoading ? <CircularProgress color="white" size={20} sx={{ margin: "-5px 13px" }} /> : "DONE"}
                </Button>
              </div>
            </Form>
          </Container>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ChangePassword;
