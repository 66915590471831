import { createTheme, responsiveFontSizes } from "@mui/material/styles";

/**
 * Theme
 */
const commonTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: "#50A1EB",
      contrastText: "#FFF",
      light: "#E9F3FD",
      dark: "#248AE6",
      // 50: "#E9F3FC",
      100: "#E9F3FC",
      200: "#BDDCF7",
      300: "#A7D0F5",
      400: "#7CB9F0",
      500: "#248AE6",
      600: "#1D6EB8",
      700: "#16538A",
      800: "#0E375C",
      900: "#071C2E",
    },
    secondary: {
      main: "#00C5C2",
      contrastText: "#FFF",
      25: "#F5FAFF",
      50: "#E8F3F8",
      100: "#D1E9FF",
      200: "#A1CBE2",
      300: "#73B2D4",
      400: "#4498C5",
      500: "#157EB7",
      600: "#105F89",
      700: "#0B3F5C",
      800: "#05202E",
      900: "#194185",
    },
    gray: {
      main: "#D1D5DB",
      contrastText: "#000",
      light: "#D1D5DB",
      dark: "#9BA5A4",
      25: "#F4F7F7",
      50: "#E6E9E9",
      100: "#F8F8F9",
      200: "#e0e0e0",
      300: "#D1D5DB",
      400: "#757575",
      500: "#6B7280",
      600: "#545454",
      700: "#374151",
      800: "#010807",
      900: "#000303",
    },
    error: {
      main: "#F44336",
      600: "#F44336",
    },
    cyan: {
      main: "#13c9ca",
    },
    black: {
      main: "#333333",
    },
    white: {
      main: "#fff",
      contrastText: "#fff",
    },
    "light-error": {
      main: "#FDF3F2",
      contrastText: "#DC2804",
      dark: "#FDF3F2",
      100: "#FDF3F2",
      500: "#DC2804",
      600: "#ffa726",
    },
  },
  typography: {
    fontFamily: `"Open Sans", sans-serif -webkit-font-smoothing: antialiased -moz-osx-font-smoothing: grayscale !important`,
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: "contained",
        size: "small",
      },
      styleOverrides: {
        root: {
          textTransform: "inherit",
          boxShadow: "none",
          fontWeight: 600,
          padding: "8px 16px",
          "&:hover": {
            boxShadow: "none",
          },
        },
        contained: {
          backgroundColor: "primary",
          letterSpacing: "1px",
          padding: "10px 30px",
        },
        outlined: {
          letterSpacing: "1px",
          border: " primary",
          padding: "10px 30px",
        },
      },
      variants: [
        {
          props: { variant: "text", color: "primary" }, // color/bg not working
          style: ({ theme, color }) => ({
            background: theme.palette.primary[100],
            color: "#000",
            "&:hover": {
              background: theme.palette.primary[200],
            },
            "&:disabled": {
              background: theme.palette.gray[200],
            },
          }),
        },
        {
          props: { variant: "text", color: "secondary" }, // color/bg not working
          style: ({ theme, color }) => ({
            background: theme.palette.secondary[100],
            color: "#000",
            "&:hover": {
              background: theme.palette.secondary[200],
            },
            "&:disabled": {
              background: theme.palette.gray[200],
            },
          }),
        },
        {
          props: { variant: "roundedContained" }, // color/bg not working
          style: {
            borderRadius: "21px",
            background: "#50A1EB",
            // fontWeight: 600,
            border: "1px solid #50A1EB",
            color: "white",
            "&:hover": {
              background: "#248AE6",
            },
          },
        },
        {
          props: { variant: "roundedOutlined" }, // color/bg not working
          style: {
            borderRadius: "21px",
            // background: "#50A1EB",
            border: "1px solid #667085",
            "&:hover": {
              // color: "white",
              background: "#E9F3FD",
              border: "1px solid #50A1EB",
            },
          },
        },
        {
          props: { variant: "circularOutlined" }, // color/bg not working
          style: {
            borderRadius: "50%",
            minWidth: "48px",
            height: "48px",
            padding: "0px",
            // background: "#50A1EB",
            border: "1px solid #667085",
            "&:hover": {
              // color: "white",
              background: "#E9F3FD",
              border: "1px solid #50A1EB",
            },
          },
        },
        {
          props: { variant: "circularContained" }, // color/bg not working
          style: {
            borderRadius: "50%",
            color: "white",
            minWidth: "48px",
            height: "48px",
            padding: "0px",
            background: "#50A1EB",
            border: "1px solid #50A1EB",
            "&:hover": {
              background: "#248AE6",
            },
          },
        },
      ],
    },

    MuiTypography: {
      styleOverrides: {
        root: {
          textTransform: "initial",
          color: "black",
        },
        caption: {
          color: "gray",
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        root: {
          padding: "0px!important",
          zIndex: "999999 !important",
        },
        popper: {
          zIndex: "999999 !important",
        },
        popup: {
          zIndex: "999999 !important",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          // padding: "0px!important",
          borderRadius: 8,
          textarea: {
            padding: "0px!important",
            border: "none!important",
          },
        },
        input: {
          padding: "10px 14px !important",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          border: "none!important",
          outline: "none!important",
        },
      },
    },
    MuiStanddardInput: {
      defaultProps: {
        disableUnderline: "false",
      },
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: "false",
      },
      styleOverrides: {
        root: ({ theme }) => ({
          padding: "0px!important",
          borderRadius: 8,
          background: `${theme.palette.gray[100]}!important`,
          "& .MuiFilledInput-input": {
            padding: "12px 18px!important",
          },
        }),
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: "#CCCCCC",
          padding: "initial",
          ml: "initial",

          // "&.Mui-checked": {
          //   color: "white",
          // },
        },
        // checked: {
        //   color: "yellow",
        // },
      },
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          opacity: 1,
          borderWidth: "1px",
        },
      },
    },
    MuiCircularProgress: {
      defaultProps: {
        size: 25,
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "12px",
          minWidth: "420px",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "14px 24px",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
    },
    MuiAvatar: {
      defaultProps: {
        variant: "rounded",
      },
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.primary.main,
          color: "white!important",
          objectFit: "contain",
        }),
      },
    },
    MuiSelect: {
      defaultProps: {
        sx: { minWidth: 120 },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: "10px",
          border: "2px solid white",
          "&.Mui-selected": {
            border: `2px solid ${theme.palette.primary.main}`,
          },
        }),

        // checked: {
        //   color: "yellow",
        // },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          // width: "100%!important",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          // width: "100%!important",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          width: "100%!important",
        },
      },
    },
    //   MuiFormLabel: {
    //     styleOverrides: {
    //       root: {
    //         '&.Mui-focused': {
    //           color: '#3699FF',
    //         },
    //       },
    //       asterisk: {
    //         color: 'red',
    //       },
    //     },
    //   },
    //   MuiInputBase: {
    //     defaultProps: {
    //       color: 'secondary',
    //     },
    //     styleOverrides: {
    //       root: {
    //         '&.MuiOutlinedInput-root': {
    //           backgroundColor: '#fff',
    //           borderRadius: '8px',
    //           minHeight: '44px',
    //         },
    //         input: {
    //           lineHeight: '24px',
    //         },
    //       },
    //     },
    //   },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#313131",
          fontSize: "14px",
        },
        asterisk: {
          color: "red",
        },
      },
    },
    //   },
    MuiTable: {
      styleOverrides: {
        root: {
          minWidth: "300px!important",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: `${theme.palette.gray[100]}!important`,
        }),
      },
    },
    MuiTableFooter: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: `${theme.palette.gray[100]}!important`,
        }),
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: `${theme.palette.gray[100]}!important`,
          width: "100%!important",
          p: {
            margin: "auto!important",
          },
        }),
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "12px 10px",
        },
        head: {
          fontWeight: "600",
        },
        body: {
          fontWeight: "400",
        },
      },
    },

    MuiTextField: {
      defaultProps: {
        autoComplete: "off",
        InputProps: {
          inputProps: { min: 0 },
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: "0px",
        },
      },
    },
    //   MuiFab: {
    //     styleOverrides: {
    //       root: {
    //         backgroundColor: 'white',
    //         boxShadow: 'none',
    //       },
    //     },
    //   },
    //   MuiSelect: {
    //     styleOverrides: {
    //       root: {
    //         borderRadius: '32px',
    //         border: '1px solid #F2F2F2',
    //       },
    //       select: {
    //         backgroundColor: '#F2F2F2',
    //         borderRadius: '32px',
    //         border: '1px solid #F2F2F2',
    //       },
    //     },
    //   },
    MuiCard: {
      styleOverrides: {
        root: {
          border: "1px solid #D1D5DB",
          boxShadow: "0px 1px 2px 0px #1028240F",
          borderRadius: "8px",
          padding: "0px",
          // maxWidth: "380px",
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: "10px 24px",
          background: "#F3F4F6",
          "& .MuiCardHeader-title": {
            textTransform: "capitalize",
          },
          // maxWidth: "380px",
        },
      },
      defaultProps: {
        titleTypographyProps: {
          variant: "subtitle1",
        },
        subheaderTypographyProps: {
          variant: "subtitle2",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "16px 24px",
          // maxWidth: "380px",
        },
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          zIndex: (theme) => theme.zIndex.modal + 100,
          "& .MuiPaper-root": {
            borderRadius: "8px",
          },
        },
      },
    },

    MuiChip: {
      defaultProps: {},
      styleOverrides: {
        root: {
          // border: `1px solid black`,
        },
        contained: {},
        outlined: {},
      },
      variants: [
        {
          props: { color: "light-error" }, // color/bg not working
          style: ({ theme }) => {
            return {
              border: `1px solid ${theme.palette["light-error"][500]}`,
            };
          },
        },
      ],
    },
  },
});

// Do your common overrides here
/**
 * Typography - body1
 */
// commonTheme.typography.body1.fontSize = "0.8rem";
// commonTheme.typography.body1.color = commonTheme.palette.text.primary;

/**
 * Typography - caption
 */
// commonTheme.typography.caption.color = commonTheme.palette.text.hint;

/**
 *  ** THIS SHOULD BE LAST **
 * Root Theme
 */
const rootTheme = responsiveFontSizes(commonTheme);

export default rootTheme;
