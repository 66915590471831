import React, { useEffect, useRef, useState } from "react";
import "./index.scss";

const Bgleads = () => {
  return <div className="lead"></div>;
};

const LeadBackground = ({ isClipped, children }) => {
  const [leadsCount, setLeadsCount] = useState(7);
  const parentRef = useRef(undefined);
  useEffect(() => {
    setLeadsCount(Math.ceil((parentRef.current.offsetWidth / 12) * 0.5));
  }, []);

  return (
    <div className="lead-bg" ref={parentRef}>
      <div
        className={`leads-wrapper ${
          isClipped ? "lead-bg-second" : "lead-bg-primary"
        }`}
      >
        <div className="leads">
          {Array(leadsCount)
            .fill(1)
            ?.map((_, i) => (
              <Bgleads key={i} />
            ))}
        </div>
      </div>
      <div className="leads-content">{children}</div>
    </div>
  );
};

export default LeadBackground;
