import { Button, Radio } from "@mui/material";
const SelectButton = ({ onClick, selected, title }) => {
  return (
    <>
      <Button
        key={title}
        variant={selected === title ? "roundedContained" : "roundedOutlined"}
        disableRipple
        endIcon={
          <Radio checked={selected === title} size="small" color="white" />
        }
        onClick={onClick}
        sx={{
          fontWeight: 500,
          "& .MuiButton-endIcon": {
            ml: 0.5,
          },
        }}
      >
        {title}
      </Button>
    </>
  );
};

export default SelectButton;
