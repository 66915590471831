import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryKeys, ApiConfig } from "constants";
import { AxiosInstance } from "services";
import { useGetUserDetails } from "./auth";

const getBrands = () => {
  const method = "GET";
  const url = ApiConfig.BRANDS;
  return AxiosInstance({ method, url });
};

export const useGetBrands = () => {
  const { data: userDetails } = useGetUserDetails();
  return useQuery([QueryKeys.BRANDS, userDetails?.current_enterprise?.id], () => getBrands(), {
    enabled: !!userDetails?.current_enterprise?.id,
  });
};

const getABrand = (internal_id) => {
  const method = "GET";
  const url = `${ApiConfig.BRAND}/${internal_id}`;
  return AxiosInstance({ method, url });
};

export const useGetABrand = (internal_id) => {
  const { data: userDetails } = useGetUserDetails();
  return useQuery([QueryKeys.BRANDS, userDetails?.current_enterprise?.id, internal_id], () => getABrand(internal_id), {
    enabled: !!internal_id,
  });
};

const postAddBrand = (data) => {
  const method = "POST";
  const url = ApiConfig.BRAND;
  return AxiosInstance({ method, url, data });
};

export const usePostAddBrand = () => {
  const queryClient = useQueryClient();
  // const brands = queryClient.getQueriesData([QueryKeys.BRANDS])?.[0]?.[1];

  return useMutation(postAddBrand, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.BRANDS]);
    },
  });
};

const deleteBrand = (internal_id) => {
  const method = "DELETE";
  const url = `${ApiConfig.BRAND}/${internal_id}`;
  return AxiosInstance({ method, url });
};

export const useDeleteBrand = () => {
  const queryClient = useQueryClient();
  // const brands = queryClient.getQueriesData([QueryKeys.BRANDS])?.[0]?.[1];

  return useMutation(deleteBrand, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.BRANDS]);
    },
  });
};
const patchUpdateBrand = ({ internal_id, data }) => {
  const method = "PATCH";
  const url = `${ApiConfig.BRAND}/${internal_id}`;
  return AxiosInstance({ method, url, data });
};

export const usePatchUpdateBrand = () => {
  const queryClient = useQueryClient();
  // const brands = queryClient.getQueriesData([QueryKeys.BRANDS])?.[0]?.[1];

  return useMutation(patchUpdateBrand, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.BRANDS]);
    },
  });
};
