import { Today } from "@mui/icons-material";
import { create } from "zustand";
import { today, getLocalTimeZone, startOfMonth } from "@internationalized/date";

const DummyCampaigns = [
  {
    brand: {
      internal_id: "f3ec5770",
      name: "P&G",
      provider: "",
    },
    title: null,
    description: null,
    campaignDuration: {
      startDate: "This weekend",
      duration: {
        select: "2 weeks",
      },
    },
    redemptionDuration: {
      startDate: "This weekend",
      duration: {
        select: "2 weeks",
      },
    },
    moreInfo: {
      rollingExpiration: "Yes",
      rollingExpirationDuration: {
        select: "1 month",
      },
      estimatedCirculation: "10,000",
      product_image: "https://gitsy.s3.ap-south-1.amazonaws.com/product_image/1688974611264.Layer%201.png",
    },
    requirements: {
      noOfRequirement: 1,
      offer: {
        type: "discount_on_item",
        value: "2.50",
      },
      primary: {
        title: "Primary",
        type: "discount_on_item",
        value: "5.00",
        data: [
          {
            product_image: "https://gitsy.s3.ap-south-1.amazonaws.com/product_image/1688974611264.Layer%201.png",
            product_searchable_name: "tomato ketchup",
            product_name: "Tomato Ketchup",
            brand_name: "Radiansys",
            purchase_prefixed_code: "1234",
            purchase_prefixed_code_type: "DPT",
            product_id: "01H4ZBPRRCQ2C0G2K3K999T6YC",
            brand_searchable_name: "radiansys",
          },
        ],
        search: null,
        expanded: true,
      },
      secondary: {
        title: "Secondary",
        type: "item",
        value: "10",
        data: [],
        search: null,
        expanded: false,
      },
      tertiary: {
        title: "Tertiary",
        type: "item",
        value: null,
        data: [],
        search: null,
        expanded: false,
      },
    },
  },
];

const useGlobalState = create((set) => ({
  approvedEnterprises: null,
  dashboardFilter: {
    start_date: startOfMonth(today(getLocalTimeZone())),
    end_date: today(getLocalTimeZone()),
  },
  dashboardRef: null,
  tcbSecrets: null,
  setTcbSecrets: (tcbSecrets) => set((globalState) => ({ ...globalState, tcbSecrets })),
  setDashboardRef: (dashboardRef) => set((globalState) => ({ ...globalState, dashboardRef })),
  setDashboardFilter: (dashboardFilter) =>
    set((globalState) => {
      return { ...globalState, dashboardFilter };
    }),
  setApprovedEnterprises: (data) => set((globalState) => ({ ...globalState, approvedEnterprises: data })),
  campaigns: DummyCampaigns,
  addCampaign: (data) =>
    set((globalState) => {
      const state = { ...globalState };
      state.campaigns = [...state.campaigns, data];
      return state;
    }),
}));
export default useGlobalState;
