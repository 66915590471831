import { useRef } from "react";
import { useButton } from "@react-aria/button";
import Button from "@mui/material/Button";

export function CalendarButton(props) {
    let ref = useRef();
    let { buttonProps } = useButton(props, ref);

    return (
        <Button
            {...buttonProps}
            ref={ref}
            variant="ghost"
            color="primary"
            disabled={props.isDisabled}
            sx={{
                color: "#000",
                p: "10px",
                minWidth: "20px",
                height: "30px",
                my: "2px",
                gap: "12px",
                fontSize: "14px",
            }}
        >
            {props.children}
        </Button>
    );
}
