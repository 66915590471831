import jwt_decode from "jwt-decode";
import get from "lodash/get";

export const appendQueryParams = (data) => {
  let url = "?";
  if (data)
    Object.keys(data).forEach((key) => {
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(data[key])) {
        url += `${key}=${typeof data[key] === "string" ? data[key]?.replaceAll("&", "%26") : data[key]}&`;
      }
    });
  return url;
};

export function decodeJWT(token) {
  try {
    const decoded = jwt_decode(token);
    return decoded;
  } catch (error) {
    console.error("Error decoding JWT token:", error);
    return null;
  }
}

export const filteredDataOnSearch = (data, searchTerm, searchKeys) => {
  if (searchTerm && searchTerm?.length && searchKeys) {
    return data?.filter((item) => {
      const lowerCaseQuery = searchTerm?.toLowerCase();
      for (const key of searchKeys) {
        const value = get(item, key)?.toString()?.toLowerCase();
        if (value?.includes(lowerCaseQuery)) return true;
      }
      return false;
    });
  }
  return data ?? [];
};

export const areAllAsterisks = (str) => {
  return /^[\*]+$/.test(str);
};

export const getInitials = (name) => {
  if (name) {
    const names = name?.split(" ");
    return `${names?.[0]?.[0]?.toUpperCase() ?? ""}${names?.[1]?.[0]?.toUpperCase() ?? ""}`;
  }
  return "--";
};
