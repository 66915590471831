import React from "react";
import "./index.scss";
import { Dialog, DialogContent, Typography } from "@mui/material";

const DownloadingGraphic = ({ open }) => {
  return (
    <Dialog
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => {}}
      closeAfterTransition
      maxWidth="xs"
      fullWidth
      // slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 400,
        },
      }}
    >
      <DialogContent
      // sx={{
      //   background: "#A7D0F5!important",
      // }}
      >
        <div className="d-flex flex-column gap-3 align-items-center">
          <div class="folder">
            <div class="folder_tab"></div>
            <div class="content">
              <div class="arrow">
                <div class="arrow_tab"></div>
              </div>
              <div class="bottom_bar"></div>
            </div>
          </div>
          <Typography fontWeight={600}>Please wait, while we are downloading your file...</Typography>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DownloadingGraphic;
