import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useCampaignContext } from "../..";
import { useEffect, useState } from "react";
import { generateCampaignText } from "utils/generateCampaignText";
import { isObjectsAreEqual } from "utils";

const EditBasicDetails = ({
  isOpen,
  handleClose,
  handleResetBasicDetailsValidation,
}) => {
  const { formData, handleSetValues } = useCampaignContext();
  const [form, setForm] = useState({
    title: "",
    description: "",
  });

  const [disabled, setDisabled] = useState(false);

  const handleOnSave = () => {
    handleResetBasicDetailsValidation();
    handleSetValues("title", form?.title);
    handleSetValues("description", form?.description);
    handleClose();
  };

  const handleUseSystemGeneratedText = () => {
    const { title, description } = generateCampaignText(formData);
    setForm({
      title,
      description,
    });
  };

  useEffect(() => {
    if (isOpen)
      setForm({
        title: formData?.title,
        description: formData?.description,
      });
  }, [formData?.title, formData?.description, isOpen]);

  useEffect(() => {
    const data = { title: formData?.title, description: formData?.description };
    if (isObjectsAreEqual(data, form)) setDisabled(true);
    else setDisabled(false);
  }, [formData?.title, formData?.description, form]);

  return (
    <Dialog
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      fullWidth
      slotProps={{
        backdrop: {
          timeout: 400,
        },
      }}
    >
      <DialogTitle>
        <Typography className="pb-3" variant="h6" fontWeight={600}>
          Edit Basic Details
        </Typography>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <form className="d-flex flex-column gap-2 mt-2">
          <InputLabel>Title</InputLabel>
          <TextField
            fullWidth
            placeholder="Enter title"
            value={form?.title}
            onChange={(e) =>
              setForm((curr) => ({ ...curr, title: e.target.value }))
            }
          />
          <InputLabel>Description</InputLabel>
          <TextField
            fullWidth
            value={form?.description}
            placeholder="Enter description"
            multiline
            rows={3}
            onChange={(e) =>
              setForm((curr) => ({ ...curr, description: e.target.value }))
            }
          />
        </form>
      </DialogContent>
      <DialogActions>
        <div className="d-flex w-100 align-items-center justify-content-between">
          <div>
            <Button variant="text" onClick={handleUseSystemGeneratedText}>
              Use system generated
            </Button>
          </div>
          <div className="d-flex gap-2">
            <Button
              color="secondary"
              type="submit"
              form-id={form}
              disabled={
                form?.title?.length === 0 ||
                form?.description?.length === 0 ||
                disabled
              }
              onClick={handleOnSave}
            >
              Save
            </Button>
            <Button color="gray" onClick={handleClose}>
              Close
            </Button>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default EditBasicDetails;
