import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { FormProvider, useWatch } from "react-hook-form";

import { AddProductPrimaryDetailsForm, AddProductSecondaryForm } from "./FormComponents";
import useFormHanlders from "./useFormHanlders";
import { useEffect, useMemo, useState } from "react";
import { AddBrand, ConnectToTCBAlert } from "components";
import { useGetProductDetails, useGetProducts } from "queries/products";
import { useNavigate, useParams } from "react-router";
import { Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { Pathnames } from "routes/Pathnames";
import BlockNavigationModal from "pages/campaign/CreateCampaign/BlockNavigationModal";

const AddProduct = ({ isEditable }) => {
  const params = useParams();
  const navigate = useNavigate();
  const { handleOnSubmit, formMethods, handleSubmit, fieldMethods } = useFormHanlders(isEditable);
  const [isAddBrandOpen, setIsAddBrandOpen] = useState(false);

  const {
    reset,
    control,
    setValue,
    formState: { dirtyFields, isSubmitted },
  } = formMethods;

  const handleSelectBrand = (item) => setValue("brand", item);
  const brand = useWatch({ control, name: "brand" });
  const image = useWatch({ control, name: "image" });

  const isDirty = !!Object.keys(dirtyFields)?.length || !!image; //don't wrap this in memo;

  const { data: product_details, isLoading: isProductDetailsLoading } = useGetProductDetails(params?.product_id);

  useEffect(() => {
    if (product_details) {
      reset({
        brand: {
          internal_id: product_details?.brand_id,
          name: product_details?.brand,
        },
        name: product_details?.name,
        description: product_details?.description,
        upc_gtins: product_details?.upc_gtins,
        ean_gtins: product_details?.ean_gtins,
        purchase_prefixed_code: product_details?.purchase_prefixed_code ?? [
          {
            type: "PLU",
            value: "",
          },
        ],
        image: product_details?.image,
      });
    }
  }, [product_details]);

  return (
    <ConnectToTCBAlert>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <Container fluid={true}>
            <Row className="product-adding">
              <Col xl="6">
                <Card>
                  <CardBody>
                    <AddProductPrimaryDetailsForm
                      isProductDetailsLoading={isProductDetailsLoading}
                      setIsAddBrandOpen={setIsAddBrandOpen}
                      fieldMethods={fieldMethods}
                      isEditable={isEditable}
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col xl="6">
                <Card>
                  <CardBody>
                    <AddProductSecondaryForm />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <div className="d-flex gap-2 my-2 align-items-center">
              <Button variant="outlined" onClick={() => navigate(Pathnames.LIST_PRODUCTS)}>
                CANCEL
              </Button>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={
                  isEditable &&
                  !Object.keys(dirtyFields)?.length &&
                  product_details?.brand_id === brand?.internal_id &&
                  product_details?.image === image
                }
                endIcon={isEditable && <SaveIcon />}
              >
                {isEditable ? "SAVE" : "Add Product"}
              </Button>
            </div>
          </Container>
        </form>
        <BlockNavigationModal
          title={isEditable ? "editing a product" : "creating a product"}
          condition={!isEditable && isDirty && !isSubmitted}
        />
        <AddBrand
          isOpen={isAddBrandOpen}
          handleClose={() => setIsAddBrandOpen(false)}
          handleSelectBrand={handleSelectBrand}
        />
      </FormProvider>
    </ConnectToTCBAlert>
  );
};

export default AddProduct;
