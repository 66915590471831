import React, { Fragment, createContext, useContext, useState } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import {
  CircularProgress,
  FormHelperText,
  Button as MuiButton,
  Typography,
} from "@mui/material";
import AuthLayout from "./Layout";
import logo from "assets/logo/gitsy_color.svg";
import { usePostForgotPassword, usePostResetPassword } from "queries/auth";
import { useForm, useWatch } from "react-hook-form";
import { yupSchema } from "constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router";
import { Pathnames } from "routes/Pathnames";
import { Link } from "react-router-dom";

const EnterYourEmail = () => {
  const { setActiveStep, setData } = useForgotPassword();
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(yupSchema.FORGOT_PASSWORD),
  });

  const email = useWatch({ control, name: "email" });

  const { mutate: postForgotPassword, isLoading } = usePostForgotPassword();

  const handleOnSubmit = (formData) => {
    postForgotPassword(formData, {
      onSuccess: () => {
        setActiveStep((curr) => curr + 1);
        setData((curr) => ({ ...curr, email: formData?.email }));
      },
    });
  };
  return (
    <Form
      className="form-horizontal auth-form"
      onSubmit={handleSubmit(handleOnSubmit)}
      style={{ height: "220px" }}
    >
      <Typography variant="h5" align="center" fontWeight={600}>
        Forgot your password
      </Typography>
      <Typography
        color="gray.400"
        align="center"
        variant="subtitle2"
        className="mb-4"
      >
        We`ll send you a code to verify your account
      </Typography>
      <FormGroup>
        <Input
          value={email}
          onChange={(e) => setValue("email", e.target.value)}
          required=""
          name="login[email]"
          type="email"
          className="form-control"
          placeholder="Enter your email address"
          id="exampleInputEmail1"
        />
      </FormGroup>
      <div className="custom-control">
        <Button
          color="primary"
          type="submit"
          className="w-100"
          disabled={!email?.length}
        >
          {isLoading ? (
            <CircularProgress
              color="white"
              size={20}
              sx={{ margin: "-5px 13px" }}
            />
          ) : (
            "Reset Password"
          )}
        </Button>
      </div>
    </Form>
  );
};

const ResetPassword = () => {
  const { setActiveStep, data } = useForgotPassword();

  const {
    setValue,
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ resolver: yupResolver(yupSchema.RESET_PASSWORD) });

  const navigate = useNavigate();
  const { mutate: postResetPassword } = usePostResetPassword();

  const handleOnSubmit = (formData) => {
    const paylaod = {
      email: data?.email,
      password: formData?.password,
      code: formData?.verify_code,
    };
    postResetPassword(paylaod, {
      onSuccess: () => navigate(Pathnames.LOGIN),
      onError: (res) => {
        setError("password", { type: "custom", message: res?.error?.message });
      },
    });
  };

  const verify_code = useWatch({ control, name: "verify_code" });
  const password = useWatch({ control, name: "password" });
  const confirm_password = useWatch({ control, name: "confirm_password" });

  return (
    <Form
      className="form-horizontal auth-form"
      onSubmit={handleSubmit(handleOnSubmit)}
    >
      <Typography variant="h5" align="center" fontWeight={600}>
        Reset Password
      </Typography>
      <Typography
        color="gray.400"
        align="center"
        variant="subtitle2"
        className="mb-4"
      >
        We`ve send you a code on your email
      </Typography>
      <FormGroup>
        <Input
          value={verify_code}
          onChange={(e) => setValue("verify_code", e.target.value)}
          required=""
          type="text"
          name="code"
          className="form-control"
          placeholder="Enter verification code"
        />
      </FormGroup>
      <FormGroup>
        <Input
          value={password}
          onChange={(e) => setValue("password", e.target.value)}
          required=""
          invalid={errors?.password?.message}
          type="password"
          className="form-control"
          placeholder="Password"
          id="verify-code"
          autoComplete="off"
        />
      </FormGroup>
      <FormGroup>
        <Input
          value={confirm_password}
          onChange={(e) => setValue("confirm_password", e.target.value)}
          required=""
          invalid={errors?.confirm_password?.message}
          type="password"
          className="form-control"
          placeholder="Confirm Password"
        />

        <div style={{ width: "fit-content" }}>
          {errors ? (
            <FormHelperText error>
              {errors?.confirm_password?.message ||
                errors?.password?.message ||
                errors?.verify_code?.message}
            </FormHelperText>
          ) : null}
        </div>
      </FormGroup>
      <div className="custom-control">
        <Button color="primary" type="submit" className="w-100">
          RESET PASSWORD
        </Button>
      </div>
    </Form>
  );
};

const steps = [<EnterYourEmail />, <ResetPassword />];

const ForgotPaswwordContext = createContext(null);

const ForgotPassword = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [data, setData] = useState({});
  //   const [timer, setTimer] = useState(60);
  //   const handleResend = () => {
  //     const interval = setInterval(
  //       () =>
  //         setTimer((curr) => {
  //           if (curr === 0) {
  //             clearTimeout(interval);
  //             return 60;
  //           }
  //           return curr - 1;
  //         }),
  //       1000
  //     );
  //   };
  return (
    <ForgotPaswwordContext.Provider
      value={{
        setActiveStep,
        setData,
        data,
      }}
    >
      <AuthLayout>
        <div className="svg-icon">
          <img alt="" src={logo} className="Img-fluid" />
        </div>
        {steps[activeStep]}
        <Label className="d-block pt-3">
          Already have an account?{" "}
          <Link
            to={Pathnames.LOGIN}
            style={{ textDecoration: "none", fontWeight: 600 }}
          >
            LOGIN
          </Link>
        </Label>
      </AuthLayout>
    </ForgotPaswwordContext.Provider>
  );
};

export default ForgotPassword;

export const useForgotPassword = () => useContext(ForgotPaswwordContext);
