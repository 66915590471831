import * as yup from "yup";

export const validateImageFileType = (value) => {
  const isFile = (value) => value instanceof File;

  const isValidFileType = (file) => ["image/jpg", "image/jpeg", "image/png"].includes(file.type);

  const isValidURL = (url) => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  if (isFile(value)) {
    return isValidFileType(value);
  } else if (typeof value === "string") {
    return isValidURL(value);
  }
  return false;
};

const REGISTER = yup.object().shape({
  first_name: yup.string().required("Firstname is a required field"),
  last_name: yup.string().required("Lastname is a required field"),
  email: yup.string().required("Email is a required field"),
  password: yup.string().required("Password is a required field"),
  confirm_password: yup
    .string()
    .required("Confirm Password is a required field")
    .oneOf([yup.ref("password"), null], "Passwords do not match"),
});

const LOGIN = yup.object().shape({
  email: yup.string().required("Email is a required field"),
  password: yup.string().required("Password is a required field"),
});

const FORGOT_PASSWORD = yup.object().shape({
  email: yup.string().required("Email is a required field"),
});

const RESET_PASSWORD = yup.object().shape({
  verify_code: yup.string().required("Verfication Code is a required field"),
  password: yup.string().required("Password is a required field"),
  confirm_password: yup
    .string()
    .required("Confirm Password is a required field")
    .oneOf([yup.ref("password"), null], "Passwords do not match"),
});

const CHANGE_PASSWORD = yup.object().shape({
  current_password: yup.string().required("Current password is a required field"),
  password: yup.string().required("Password is a required field"),
  confirm_password: yup
    .string()
    .required("Confirm Password is a required field")
    .oneOf([yup.ref("password"), null], "Passwords do not match"),
});

const ADD_BRAND = yup.object().shape({
  name: yup.string().nullable().required("Name is a required field"),
  image_path: yup
    .mixed()
    .nullable()
    .required("Image is a required field")
    .test("fileType", "Unsupported file format. Please upload a JPG, JPEG, or PNG image.", validateImageFileType),
  // provider: yup.string(),
  // authorized_partners: yup.array(),
});

const ADD_DETAILS = yup.object().shape({
  title: yup
    .string()
    .nullable()
    .required("Title is a required field")
    .min(5, "Title must be greater than 5 characters"),
  description: yup
    .string()
    .nullable()
    .required("Description is a required field")
    .min(10, "Description must be greater than 10 characters"),
  // product_image: yup.mixed().required("Image is a required field"),
});

const ADD_PRODUCT = yup
  .object()
  .shape({
    brand: yup.object().nullable().required("Brand is a required field"),
    name: yup.string().required("Name is a required field").min(5, "Name must be greater than 5 characters"),
    description: yup.string().nullable(),
    upc_gtins: yup
      .string()
      .nullable()
      .transform((o, c) => (o === "" ? null : c))
      .min(12, "UPC GTIN must be exactly 12 digits")
      .max(12, "UPC GTIN must be exactly 12 digits"),
    ean_gtins: yup
      .string()
      .nullable()
      .transform((o, c) => (o === "" ? null : c))
      .min(13, "UPC GTIN must be exactly 13 digits")
      .max(13, "UPC GTIN must be exactly 13 digits"),
    purchase_prefixed_code: yup.array().of(
      yup.object().shape({
        type: yup.string(),
        value: yup.string(),
      })
    ),
    image: yup
      .mixed()
      .required("Product image is a required field")
      .test("fileType", "Unsupported file format. Please upload a JPG, JPEG, or PNG image.", validateImageFileType),
  })
  .test(
    "at-least-one-field",
    'At least one field is required among GTIN and purchase prefixed code".',
    function (value) {
      const { upc_gtins, ean_gtins, purchase_prefixed_code } = value;
      if (!upc_gtins && !ean_gtins && !purchase_prefixed_code?.some((x) => x?.value)) {
        return this.createError({
          message: "At least one field is required among Purchase GTIN or Prefixed codes",
          path: "purchase_prefixed_code", // You can choose any of the fields as the error path
        });
      }
      return true;
    }
  );

const INVITE_USER = yup.object().shape({
  email: yup.string().email().nullable().required("email is a required field"),
  first_name: yup.string().nullable().required("first Name is a required field"),
  last_name: yup.string().nullable().required("last Name is a required field"),
  role: yup.string().nullable().required("role is a required field"),
});

const MY_PROFILE = yup.object().shape({
  // email: yup.string().nullable().required("Email is a required field"),
  first_name: yup.string().nullable().required("First Name is a required field"),
  last_name: yup.string().nullable().required("Last Name is a required field"),
  image_path: yup
    .mixed()
    .nullable()
    // .required("Image is a required field")
    .test("fileType", "Unsupported file format. Please upload a JPG, JPEG, or PNG image.", validateImageFileType),
});

const ENTERPRISE_SETTINGS = yup.object().shape({
  // email_domain: yup.string().nullable(),
  access_key: yup.string().nullable().required("Access key is a required field"),
  secret_key: yup.string().nullable().required("Secret access key is a required field"),
  alias_name: yup.string().nullable().required("Display name is a required field"),
  terms_and_conditions: yup.string().nullable().required("Terms and conditions is a required field"),
  allow_multiple_brands: yup.boolean(),
  image_path: yup
    .mixed()
    .nullable()
    .test("fileType", "Unsupported file format. Please upload a JPG, JPEG, or PNG image.", validateImageFileType),
});

const ADD_GALLERY = yup.object().shape({
  title: yup
    .string()
    .nullable()
    .required("Title is a required field")
    .min(5, "Title must be greater than 5 characters"),
  description: yup.string().nullable(),
  image: yup
    .mixed()
    .required("Image is a required field")
    .test("fileType", "Unsupported file format. Please upload a JPG, JPEG, or PNG image.", validateImageFileType),
  campaigns: yup
    .array()
    .min(1, "Select atleast one or more campaigns")
    .required("Please select atleast one or more campaigns"),
});

export default {
  ADD_GALLERY,
  ADD_BRAND,
  ADD_DETAILS,
  ADD_PRODUCT,
  LOGIN,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  REGISTER,
  INVITE_USER,
  ENTERPRISE_SETTINGS,
  MY_PROFILE,
  CHANGE_PASSWORD,
};
