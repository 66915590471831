import { CircularProgress } from "@mui/material";
import React from "react";

const DisabledOverlay = ({ children, isLoading }) => {
  return (
    <div style={{ position: "relative" }}>
      {isLoading ? (
        <div
          style={{
            position: "absolute",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            top: 0,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            zIndex: 10,
            height: "100%",
          }}
        >
          <CircularProgress size={20} sx={{ zIndex: 11 }} />
        </div>
      ) : null}
      {children}
    </div>
  );
};

export default DisabledOverlay;
