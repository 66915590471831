function getBorderRadius(isRoundedLeft, isRoundedRight) {
  if (isRoundedLeft) {
    return isRoundedRight ? "4px" : "4px 0px 0px 4px";
  } else {
    return isRoundedRight ? "0px 4px 4px 0px" : "0px";
  }
}

function getBgColor(isSelectionStart, isSelectionEnd, isSelected, isInvalid) {
  if ((isSelectionStart || isSelectionEnd) && isSelected) {
    return "#3574F0";
  } else if (isSelected) {
    return isInvalid ? "#C4C4C4" : "#EDF3FF";
  } else {
    return "#FFF";
  }
}

function getTextColor(isSelectionStart, isSelectionEnd, isSelected) {
  if ((isSelectionStart || isSelectionEnd) && isSelected) {
    return "#FFF";
  } else {
    return "#494B57";
  }
}

function getHoverBgColor(isSelected, isInvalid, isDisabled) {
  if (isSelected) {
    return isInvalid ? "#E55765" : "#3574F0";
  } else if (!isSelected && !isDisabled) {
    return "#FFF";
  } else {
    return "inherit";
  }
}

function getHoverTextColor(isSelected, isInvalid, isDisabled, isSelectionStart, isSelectionEnd) {
  if (!isSelected && !isDisabled && !(isSelectionStart || isSelectionEnd)) {
    return isInvalid ? "#FFF" : "#588CF3";
  } else {
    return "#FFF";
  }
}

export function getButtonStyles(
  isRoundedLeft,
  isRoundedRight,
  isSelected,
  isInvalid,
  isDisabled,
  isSelectionStart,
  isSelectionEnd
) {
  const borderRadius = getBorderRadius(isRoundedLeft, isRoundedRight);

  const bgColor = getBgColor(isSelectionStart, isSelectionEnd, isSelected, isInvalid);

  const textColor = getTextColor(isSelectionStart, isSelectionEnd, isSelected);

  const hoverBgColor = getHoverBgColor(isSelected, isInvalid, isDisabled);

  const hoverTextColor = getHoverTextColor(isSelected, isInvalid, isDisabled, isSelectionStart, isSelectionEnd);

  return {
    borderRadius: borderRadius,
    // color: isDisabled && !isInvalid ? "#494B57" : "#3574F0",
    // ":focus-visible": {
    //     zIndex: "2",
    //     ring: "4",
    //     ringOffset: "2",
    // },
    backgroundColor: bgColor,
    color: textColor,

    fontWeight: 400,
    ":hover": {
      backgroundColor: hoverBgColor,
      color: hoverTextColor,
    },
  };
}
