import axios from "axios";
import { LocalStorageKeys } from "constants";
import { getLocalStorageItem } from "hooks";
import { postRefreshToken, handleTokens } from "queries/auth";
import { Pathnames } from "routes/Pathnames";

const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    "content-type": "application/json",
  },
});

const handleLogoutRedirectUser = () => {
  window.location.href = Pathnames.LOGIN;
  localStorage.clear();
};

// AxiosInstance.interceptors.request.use(async (config) => {
//   const authToken = await getLocalStorageItem(LocalStorageKeys.ACCESS_TOKEN);
//   if (authToken) {
//     config.headers = {
//       ...config.headers,
//       Authorization: `Bearer ${authToken}`,
//     };
//   }
//   return config;
// });

// AxiosInstance.interceptors.response.use(
//   (response) => response.data,
//   async (error) => {
//     const originalRequest = error.config;
//     const refresh_token = await getLocalStorageItem(
//       LocalStorageKeys.REFRESH_TOKEN
//     );
//     if (
//       error?.response?.data?.code === "UNAUTHORIZED" &&
//       refresh_token &&
//       !originalRequest._retry
//     ) {
//       originalRequest._retry = true;
//       try {
//         const response = await postRefreshToken({ refresh_token });
//         if (response) {
//           handleTokens(response);
//           return await AxiosInstance(originalRequest);
//         }
//       } catch (error) {
//         console.log(error);
//         handleLogoutRedirectUser();
//       }
//     } else if (error?.response?.data?.code === "UNAUTHORIZED") {
//       handleLogoutRedirectUser();
//     }

//     return Promise.reject(error.response.data);
//   }
// );

AxiosInstance.interceptors.request.use(async (config) => {
  const authToken = await getLocalStorageItem(LocalStorageKeys.ACCESS_TOKEN);
  if (authToken) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${authToken}`,
    };
  }
  return config;
});

AxiosInstance.interceptors.response.use(
  (response) => response.data,
  async (error) => {
    const originalRequest = error.config;
    const refresh_token = await getLocalStorageItem(
      LocalStorageKeys.REFRESH_TOKEN
    );
    if (
      error?.response?.data?.code === "UNAUTHORIZED" &&
      refresh_token &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      try {
        const response = await postRefreshToken({ refresh_token });
        if (response) {
          handleTokens(response);
          return await AxiosInstance(originalRequest);
        }
      } catch (error) {
        console.log(error);
        handleLogoutRedirectUser();
      }
    } else if (error?.response?.data?.code === "UNAUTHORIZED") {
      handleLogoutRedirectUser();
    }

    return Promise.reject(error.response.data);
  }
);

export default AxiosInstance;
