import React, { useState } from "react";
import "./index.scss";
import { Dummy } from "constants";
import CouponCard from "./CouponCard";
import logo from "assets/logo/gitsy_white.svg";
import CouponInfo from "./CouponInfo";
import { IconButton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useCampaignContext } from "pages/campaign/CreateCampaign";
import { QRCodeDisplay } from "components";

const AppPreview = () => {
  const {
    isEditable,
    formData: { isSettled, link, title, description },
  } = useCampaignContext();

  const [step, setStep] = useState(0);
  const handleCloseCouponInfo = () => setStep(0);
  const handleOpenCouponInfo = () => setStep(1);
  const handleNextStep = () => setStep((curr) => curr + 1);
  const handlePrevStep = () => setStep((curr) => curr - 1);

  return (
    <div className="wrapper">
      <div className="app-preview" data-no-print>
        <Typography variant="h6" align="center" fontWeight={500} width={400}>
          {step === 0
            ? "Here is an example of how your offer will appear on Gitsy App."
            : "This is how your offer info screen will appear on Gitsy App."}
        </Typography>
        <div className="preview-wrapper">
          <div className="logo">
            <img className="blur-up lazyloaded" src={logo} alt="" />
          </div>
          <CouponCard coupon={{}} handleOpenCouponInfo={handleOpenCouponInfo} />
          <div className="iphone-position">
            <div className="iphone">{Dummy.iphone}</div>
            <div className="overlay-wrapper">
              <div className={`bottom-sheet ${step === 1 && "bottom-sheet-open"}`}>
                <CouponInfo handleCloseCouponInfo={handleCloseCouponInfo} />
              </div>
              {step === 1 && <div className="overlay" onClick={handleCloseCouponInfo}></div>}
            </div>
          </div>
          <div className="preview-nav">
            <IconButton disabled={step <= 0} onClick={handlePrevStep}>
              <ArrowBackIcon />
            </IconButton>
            <IconButton disabled={step >= 1} onClick={handleNextStep}>
              <ArrowForwardIcon />
            </IconButton>
          </div>
        </div>
      </div>
      {isEditable && !isSettled ? <QRCodeDisplay title={title} description={description} link={link} /> : null}
    </div>
  );
};

export default AppPreview;
