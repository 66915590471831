import { useEffect, useState } from "react";
import { Typography, TextField } from "@mui/material";
import { DatePicker, SelectButton } from "components";
import StepperControls from "../StepperControls";
import { useCampaignContext } from "..";
import { DateOptions, DurationOptions } from "constants/options";
import { isObjectsAreEqual } from "utils";
import { NumericFormat } from "react-number-format";

const Duration = () => {
  const {
    isEditable,
    handleSetValues,
    formData: { campaignDuration },
  } = useCampaignContext();
  const [selected, setSelected] = useState(campaignDuration);
  const [disabled, setDisabled] = useState(isEditable ? true : false);

  const handleOnSubmit = () => {
    handleSetValues("campaignDuration", selected);
  };

  useEffect(() => {
    if (isEditable && isObjectsAreEqual(selected, campaignDuration)) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [selected, campaignDuration]);

  return (
    <div data-aos="fade-left" className="gap-4 d-flex flex-column">
      <Typography variant="h5">
        What's the <span className="fw-semibold">start date</span> of your campaign?*
      </Typography>
      <div className="d-flex gap-2 flex-wrap align-items-center">
        {DateOptions?.map((item, i) => (
          <SelectButton
            key={i}
            selected={selected?.startDate}
            title={item}
            onClick={() =>
              setSelected((state) => ({
                ...state,
                startDate: item,
              }))
            }
          />
        ))}
        <Typography className="mx-4 text-secondary" variant="body1">
          or
        </Typography>
        <DatePicker
          disableTyping
          value={typeof selected?.startDate === "string" ? null : selected?.startDate}
          // {...register("end_date")}
          minDate={new Date()}
          onChange={(val) => {
            setSelected((state) => ({ ...state, startDate: val }));
          }}
          // error={Boolean(errors?.end_date?.message)}
          // helperText={errors?.end_date?.message}
        />
      </div>
      <div>
        <Typography variant="h5">
          <span className="fw-semibold">How long</span> will your campaign run?*
        </Typography>
        <Typography color="gray.400" variant="subtitle2">
          This duration is applicable from the start date of this campaign
        </Typography>
      </div>
      <div className="d-flex gap-2 flex-wrap align-items-center">
        {DurationOptions?.map((item) => (
          <SelectButton
            key={item}
            selected={selected?.duration?.select}
            title={item}
            onClick={() =>
              setSelected((state) => ({
                ...state,
                duration: { select: item, input: "" },
                endDate: null,
              }))
            }
          />
        ))}
        <div className="d-flex gap-2 align-items-center">
          <NumericFormat
            value={selected?.duration?.input ?? undefined}
            onValueChange={(x) => {
              setSelected((state) => ({
                ...state,
                duration: { input: x?.value },
                endDate: null,
              }));
            }}
            placeholder="e.g. 100"
            decimalScale={0}
            fixedDecimalScale
            customInput={TextField}
            allowNegative={false}
            InputProps={{ inputProps: { min: 0 } }}
            sx={{ width: 100 }}
          />
          {/* <TextField
            InputProps={{
              inputProps: { min: 0 },
            }}
            placeholder="e.g. 100"
            sx={{ width: 100 }}
            type="number"
            value={selected?.duration?.input ?? undefined}
            onWheel={(e) => e.target.blur()}
            onChange={(e) => {
              console.log("e.target.value >= 0", e.target.value >= 0);
              if (e.target.value >= 0) {
                setSelected((state) => ({
                  ...state,
                  duration: { input: e.target.value?.replace("-", "") },
                  endDate: null,
                }));
              }
            }}
          /> */}
          <Typography variant="body2" color="gray">
            Days
          </Typography>
        </div>

        <Typography className="mx-4 text-secondary" variant="body1">
          or
        </Typography>
        <DatePicker
          disableTyping
          value={typeof selected?.endDate === "string" ? null : selected?.endDate}
          // {...register("end_date")}
          minDate={new Date()}
          onChange={(val) => {
            setSelected((state) => ({ ...state, endDate: val, duration: { input: "" } }));
          }}
          // error={Boolean(errors?.end_date?.message)}
          // helperText={errors?.end_date?.message}
        />
      </div>
      <StepperControls
        onClickNext={handleOnSubmit}
        isDisabled={!(selected?.duration || selected?.endDate) || !selected?.startDate || disabled}
      />
    </div>
  );
};

export default Duration;
