import { AlertTitle, Alert, Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import { Pathnames } from "routes/Pathnames";

import Graphic from "assets/images/connect-to-tcb.png";
import { useGetEnterpriseDetails } from "queries/enterprise";
import LoadingWrapper from "../loading-wrapper";

const ConnectToTCBAlert = ({ children, isLoading, isSettingsPage }) => {
  const navigate = useNavigate();

  const { data: enterpriseDetails, isLoading: isloadingEnterprise } = useGetEnterpriseDetails();

  const isTcbNotConnected = !!!enterpriseDetails?.email_domain;

  const isBlocked = enterpriseDetails?.status === "Blocked";

  if (isBlocked) {
    return (
      <LoadingWrapper isLoading={isloadingEnterprise || isLoading}>
        <div>
          <div className="d-flex align-items-center flex-column w-100 gap-5">
            <Alert severity="warning" sx={{ zIndex: 3, width: "100%" }}>
              <AlertTitle color="#663C00">
                Access to this enterprise system has been
                <strong> blocked by the administrator</strong>.
              </AlertTitle>
              Please contact your administrator for further assistance.
            </Alert>
            <img src={Graphic} height={300} />
          </div>
        </div>
      </LoadingWrapper>
    );
  }

  if (isTcbNotConnected && !isSettingsPage) {
    return (
      <LoadingWrapper isLoading={isloadingEnterprise || isLoading}>
        <div>
          <div className="d-flex align-items-center flex-column w-100 gap-5">
            <Alert
              severity="warning"
              action={
                <Button color="secondary" onClick={() => navigate(Pathnames.SETTINGS)}>
                  Connect
                </Button>
              }
              sx={{ zIndex: 3, width: "100%" }}
            >
              <AlertTitle color="#663C00">
                Connect to <strong>The Coupon Bureau's</strong> account
              </AlertTitle>
              Kindly link your account to access this feature
            </Alert>
            <img src={Graphic} height={300} />
          </div>
        </div>
      </LoadingWrapper>
    );
  }

  return (
    <LoadingWrapper isLoading={isloadingEnterprise || isLoading}>
      <div>{children}</div>
    </LoadingWrapper>
  );
};

export default ConnectToTCBAlert;
