import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Button, Chip } from "@mui/material";
import { Pathnames } from "routes/Pathnames";
import { useGetEnterpriseDetails, useGetInvites } from "queries/enterprise";
import { ConnectToTCBAlert, Table } from "components";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteUser from "./DeleteUser";
import BlockUser from "./BlockUser";
import { useGetUserDetails } from "queries/auth";
import AddIcon from "@mui/icons-material/Add";
import ListInvites from "./ListInvites";

const columns = [
  { label: "Name", align: "left", key: "user_name" },
  { label: "Email", align: "left", key: "email" },
  {
    label: "Role",
    align: "left",
    key: "role",
    render: (value) => <Chip label={value} />,
  },
];

const actions = [
  {
    label: (row) => (row?.is_blocked ? "Unblock" : "Block"),
    icon: (row) => (row?.is_blocked ? <LockOpenIcon /> : <LockIcon />),
    component: <BlockUser />,
  },
  {
    label: () => "Delete",
    icon: () => <DeleteIcon sx={{ color: "red" }} />,
    component: <DeleteUser />,
  },
];

const ListUsers = () => {
  const navigate = useNavigate();
  const { data: enterpriseDetails, isLoading } = useGetEnterpriseDetails();
  const { data: userDetails } = useGetUserDetails();

  const handleOnClickRow = (row) => {
    if (row?.user_id !== userDetails?.id) navigate(`${Pathnames.USER_DETAILS.replace(":user_id", row?.user_id)}`);
  };

  return (
    <ConnectToTCBAlert>
      <Fragment>
        <Container fluid={true}>
          <Tabs>
            <div className="d-flex gap-4 mb-2 justify-content-between w-100">
              <TabList className="nav nav-tabs tab-coupo w-100">
                <Tab className="nav-link">Users</Tab>
                <Tab className="nav-link">Invites</Tab>
              </TabList>
              <div className="d-flex gap-2 justify-content-end flex-shrink-0">
                <Button endIcon={<AddIcon />} onClick={() => navigate(Pathnames.INVITE_USER)}>
                  INVITE USER
                </Button>
              </div>
            </div>
            <TabPanel>
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="12">
                      <div className="tab-pane fade show active">
                        <div className="category-table media-table coupon-list-delete">
                          <Table
                            columns={columns}
                            isLoading={isLoading}
                            actions={actions}
                            actionsCondition={(row) => {
                              return row?.user_id !== userDetails?.id;
                            }}
                            // selectionKey={"user_id"}
                            data={enterpriseDetails?.access}
                            handleOnClickRow={handleOnClickRow}
                            // activeRow={userDetails?.id}
                            disabledRowCondition={(row) => row?.user_id === userDetails?.id}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </TabPanel>
            <TabPanel>
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="12">
                      <div className="tab-pane fade show active">
                        <div className="category-table media-table coupon-list-delete">
                          <ListInvites />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </TabPanel>
          </Tabs>
        </Container>
      </Fragment>
    </ConnectToTCBAlert>
  );
};

export default ListUsers;
