import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryKeys, ApiConfig } from "constants";
import { AxiosInstance } from "services";
import { useGetUserDetails } from "./auth";
import { useToasts } from "react-toast-notifications";
import { appendQueryParams } from "utils";

const postCreateCampaign = (data) => {
  const method = "POST";
  const url = ApiConfig.CAMPAIGN;
  return AxiosInstance({ method, url, data });
};

export const usePostCreateCampaign = (base_gs1) => {
  // const { formData, campaignDetails } = useCampaignContext();
  const QueryClient = useQueryClient();
  const { data: userDetails } = useGetUserDetails();
  return useMutation({
    mutationFn: postCreateCampaign,
    mutationKey: QueryKeys.MUTATE_CAMPAIGN,
    onSuccess: (res) => {
      QueryClient.invalidateQueries([QueryKeys.LIST_CAMPAIGNS, userDetails?.current_enterprise?.id]);
      if (base_gs1)
        QueryClient.removeQueries([QueryKeys.CAMPAIGN_DETAILS, userDetails?.current_enterprise?.id, base_gs1]);
      QueryClient.invalidateQueries([QueryKeys.LIST_GALLERY, userDetails?.current_enterprise?.id]);
      QueryClient.invalidateQueries([QueryKeys.GALLERY_DETAILS, userDetails?.current_enterprise?.id]);
    },
  });
};

export const getCampaignsList = (data) => {
  const method = "GET";
  const url = ApiConfig.CAMPAIGNS + appendQueryParams(data);
  return AxiosInstance({ method, url });
};

export const useGetCampaignsList = (data) => {
  const { data: userDetails } = useGetUserDetails();
  return useQuery(
    [
      QueryKeys.LIST_CAMPAIGNS,
      userDetails?.current_enterprise?.id,
      data?.limit,
      data?.lastEvaluatedKey,
      data?.status,
      data?.brand_id,
      data?.start_date,
      data?.end_date,
    ],
    () => getCampaignsList(data),
    {
      enabled: !!userDetails?.current_enterprise?.id,
    }
  );
};

const getInviteDetails = (invite_code) => {
  const method = "GET";
  const url = ApiConfig.INVITE_DETAILS.replace(":code", invite_code);
  return AxiosInstance({ method, url });
};

export const useGetInviteDetails = (invite_code) => {
  return useQuery([QueryKeys.INVITE_CODE, invite_code], () => getInviteDetails(invite_code), {
    enabled: !!invite_code,
  });
};

const getCampaignDetails = (base_gs1) => {
  const method = "GET";
  const url = ApiConfig.CAMPAIGN_DETAILS.replace(":base_gs1", base_gs1);
  return AxiosInstance({ method, url });
};

export const useGetCampaignDetails = (base_gs1) => {
  const { data: userDetails } = useGetUserDetails();
  return useQuery(
    [QueryKeys.CAMPAIGN_DETAILS, userDetails?.current_enterprise?.id, base_gs1],
    () => getCampaignDetails(base_gs1),
    {
      enabled: !!base_gs1,
    }
  );
};

const patchToggleLockCampaign = (base_gs1) => {
  const method = "PATCH";
  const url = ApiConfig.CAMPAIGN_LOCK.replace(":base_gs1", base_gs1);
  return AxiosInstance({ method, url });
};

export const usePatchToggleLockCampaign = () => {
  const { addToast } = useToasts();
  const QueryClient = useQueryClient();
  const { data: userDetails } = useGetUserDetails();
  return useMutation(patchToggleLockCampaign, {
    onSuccess: () => {
      QueryClient.invalidateQueries([QueryKeys.LIST_CAMPAIGNS, userDetails?.current_enterprise?.id]);
      addToast("Successfully updated campaign", {
        appearance: "success",
      });
    },
  });
};

export const getProviders = () => {
  const method = "GET";
  const url = ApiConfig.PROVIDERS;
  return AxiosInstance({ method, url });
};

export const useGetProviders = () => {
  const { data: userDetails } = useGetUserDetails();
  return useQuery([QueryKeys.PROVIDERS, userDetails?.current_enterprise?.id], () => getProviders(), {
    enabled: !!userDetails?.current_enterprise?.id,
  });
};

const patchProvidersToggle = (data) => {
  const method = "PATCH";
  const url = ApiConfig.ASSIGN_PROVIDERS;
  return AxiosInstance({ method, url, data });
};

export const usePatchProvidersToggle = (base_gs1) => {
  const { addToast } = useToasts();
  const QueryClient = useQueryClient();
  const { data: userDetails } = useGetUserDetails();
  return useMutation({
    mutationFn: patchProvidersToggle,
    mutationKey: QueryKeys.MUTATE_PROVIDERS,
    onSuccess: () => {
      QueryClient.invalidateQueries([QueryKeys.LIST_CAMPAIGNS, userDetails?.current_enterprise?.id]);
      QueryClient.removeQueries([QueryKeys.CAMPAIGN_DETAILS, userDetails?.current_enterprise?.id, base_gs1]);
      addToast("Successfully assigned providers", {
        appearance: "success",
      });
    },
  });
};

export const getRetailers = () => {
  const method = "GET";
  const url = ApiConfig.RETAILERS;
  return AxiosInstance({ method, url });
};

export const useGetRetailers = () => {
  const { data: userDetails } = useGetUserDetails();
  return useQuery([QueryKeys.RETAILERS, userDetails?.current_enterprise?.id], () => getRetailers(), {
    enabled: !!userDetails?.current_enterprise?.id,
  });
};

const patchRetailersToggle = (data) => {
  const method = "PATCH";
  const url = ApiConfig.ASSIGN_RETAILERS;
  return AxiosInstance({ method, url, data });
};

export const usePatchRetailersToggle = (base_gs1) => {
  const { addToast } = useToasts();
  const QueryClient = useQueryClient();
  const { data: userDetails } = useGetUserDetails();
  return useMutation({
    mutationFn: patchRetailersToggle,
    mutationKey: QueryKeys.MUTATE_RETAILERS,
    onSuccess: () => {
      QueryClient.invalidateQueries([QueryKeys.LIST_CAMPAIGNS, userDetails?.current_enterprise?.id]);
      QueryClient.removeQueries([QueryKeys.CAMPAIGN_DETAILS, userDetails?.current_enterprise?.id, base_gs1]);
      addToast("Successfully assigned retailers", {
        appearance: "success",
      });
    },
  });
};
