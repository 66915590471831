// [( Save Value Code, Purchase Code)]: [reponses]
// $offer = Save Value, $requirememt = PPR, (&group) = Product/Group of Products

import {
  PurchaseTypes,
  OfferTypes,
  PurchaseRequirementsRelation,
} from "pages/campaign/CreateCampaign/Steps/PurchaseCriteria";
import { calDuration, calEndDate, calStartDate } from "pages/campaign/CreateCampaign/CreatePayload";
import { format, parseISO } from "date-fns";
import { enGB } from "date-fns/locale";

export const PurchaseRequirementValue = (requirement) => {
  if (requirement?.value !== null && requirement?.value !== undefined && requirement?.value !== "") {
    let purchaseRequirement = PurchaseTypes[requirement?.type];
    let value = parseFloat(requirement?.value).toFixed(2);
    let prefix = purchaseRequirement?.iconType === "start" ? "$" : "";
    let suffix = "";

    // If purchase type is `free quantity`, then set suffix using icon
    if (purchaseRequirement?.iconType === "end")
      suffix = purchaseRequirement?.icon + (parseFloat(value) > 1 ? "s" : "");

    // If purchase type is `Number of Items` (which means `units`), then set value as an integer otherwise send decimal value upto 2 points
    if (purchaseRequirement?.code === 0) value = parseInt(requirement?.value);
    return `${prefix}${value} ${suffix}`;
  }
  return null;
};

export const OfferValue = (formData) => {
  let value = parseFloat(formData?.requirements?.offer?.value).toFixed(2);
  let offer = OfferTypes[formData?.requirements?.offer?.type];
  let prefix = offer?.iconType === "start" ? "$" : "";
  let suffix = offer?.iconType === "end" ? offer?.icon : "";

  // If offer type is `free quantity` and sub-unit is `unit`, then set value as an integer otherwise send decimal value upto 2 points
  if (offer?.code === 2 && formData?.requirements?.offer?.free_quantity_type === "unit")
    value = parseInt(formData?.requirements?.offer?.value);

  // If offer type is `free quantity`, then set suffix using free-qunatity-type
  if (offer?.code === 2) suffix = formData?.requirements?.offer?.free_quantity_type;

  return `${prefix}${value} ${suffix}`;
};

const OfferText = (formData) => {
  const prefixOptions = ["Get", "Save"];
  const offerCode = OfferTypes[formData?.requirements?.offer?.type]?.code;

  // If offer type is free quantity, then use prefixeOption as `Get`
  const prefix = prefixOptions[offerCode !== 2 ? Math.floor(Math.random() * prefixOptions.length) : 0];

  const offerValue = OfferValue(formData);

  // If offer type is not free qunantity and prefix is equal to `Get`, then use `off` else use `free`
  const suffix = offerCode === 2 ? "free" : prefix === "Get" ? "off" : "";
  return `${prefix} ${offerValue} ${suffix}`;
};

const GeneratePurchaseRequirement = (requirement, isOffer) => {
  if (requirement?.value !== null && requirement?.value !== undefined && requirement?.value !== "") {
    const value = PurchaseRequirementValue(requirement);
    const products = requirement?.data?.map((product) => product?.name ?? product?.product_name).join(" OR ");

    if (isOffer) return `${products?.length ? `(${products})` : ""}`;
    return `${value} ${products?.length ? `(${products})` : ""}`;
  }
  return "";
};

const GenerateRequirements = (formData) => {
  const prefixOptions = ["on OFFER_PRODUCTS minimum shopping of"];
  const prefix = prefixOptions[Math.floor(Math.random() * prefixOptions.length)];

  const offerProducts = GeneratePurchaseRequirement(formData?.requirements?.offer, true);

  const primary = GeneratePurchaseRequirement(formData?.requirements?.primary);
  const secondary = GeneratePurchaseRequirement(formData?.requirements?.secondary);
  const tertiary = GeneratePurchaseRequirement(formData?.requirements?.tertiary);

  let relation1 = PurchaseRequirementsRelation[formData?.requirements?.offer?.purchaseRequirementsRelation].logic;
  let relation2 = PurchaseRequirementsRelation[formData?.requirements?.offer?.purchaseRequirementsRelation].logic;

  if (relation1 === "AND OR") relation1 = "AND";
  if (relation2 === "AND OR") relation2 = "OR";

  return `${prefix?.replace("OFFER_PRODUCTS", offerProducts)} ${primary} ${
    secondary?.length ? relation1 : ""
  } ${secondary} ${tertiary?.length ? relation2 : ""} ${tertiary}`;
};

const GetEndDateInString = (duration) => {
  const prefixOptions = ["at your nearest store till"];
  const prefix = prefixOptions[Math.floor(Math.random() * prefixOptions.length)];

  let endDate = null;
  if (!duration?.endDate) {
    const redemption_start_time = calStartDate(duration?.startDate);
    const redemption_duration = calDuration(duration?.duration);
    const redemption_end_time = calEndDate(redemption_start_time, redemption_duration);

    const date = parseISO(redemption_end_time);
    endDate = format(date, "do MMMM yyyy");
  } else {
    endDate = format(duration?.endDate, "do MMMM yyyy");
  }

  return `${prefix} ${endDate}`;
};

export const generateCampaignText = (formData) => {
  const title = OfferText(formData)?.replace(/\s+/g, " ");
  const description = (
    title +
    " " +
    GenerateRequirements(formData) +
    GetEndDateInString(formData?.redemptionDuration)
  )?.replace(/\s+/g, " ");

  return {
    title,
    description,
  };
};
