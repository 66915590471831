import { CircularProgress } from "@material-ui/core";
import { Box } from "@mui/material";
import { useGetCampaignsList } from "queries/campaign";
import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Table } from "reactstrap";
import { Pathnames } from "routes/Pathnames";

const LatestCampaigns = () => {
  const { data: campaigns } = useGetCampaignsList();
  return (
    <Card>
      <CardHeader>
        <h5>Latest Campaigns</h5>
      </CardHeader>
      <CardBody>
        <div className="user-status table-responsive latest-order-table">
          <Table borderless>
            <thead>
              <tr>
                <th scope="col">Title</th>
                <th scope="col">Brand</th>
                <th scope="col">Start Date</th>
                <th scope="col">End Date</th>
              </tr>
            </thead>
            <tbody>
              {campaigns?.baseGs1s &&
                campaigns?.baseGs1s?.slice(0, 5)?.map((campaign) => (
                  <tr key={campaign?.base_gs1}>
                    <td>{campaign?.campaign_metadata?.title}</td>
                    <td className="digits">{campaign?.brand_name}</td>
                    <td className="font-danger">{campaign?.campaign_start_time}</td>
                    <td className="digits">{campaign?.campaign_end_time}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <Link to={Pathnames.LIST_CAMPAIGN} className="btn btn-primary">
            View All Campaigns
          </Link>
        </div>
      </CardBody>
    </Card>
  );
};

export default LatestCampaigns;
