const ApiConfig = {
  PRODUCT: "/product",
  PRODUCTS: "/products",
  PRODUCT_DETAILS: "/product/:product_id",
  IMAGE: "/image",
  CAMPAIGN: "/campaign",
  CAMPAIGNS: "/campaigns",
  SIGN_IN: "/signin",
  SIGN_UP: "/signup",
  VERIFY_EMAIL: "/verify-email",
  LOGOUT: "/logout",
  FORGOT_PASSWORD: "/forget-password",
  RESET_PASSWORD: "/reset-password",
  ME: "/me",
  REFRESH_TOKEN: "/refresh-token",
  BRANDS: "/brands",
  BRAND: "/brand",
  ENTERPRISE: "/enterprise",
  ENTERPRISE_REQUESTS: "/enterprise/requests",
  ACCEPT_INVITE: "/enterprise/accept-invite",
  DECLINE_INVITE: "/enterprise/decline-invite",
  REVOKE_INVITE: "/enterprise/revoke-invite",
  INVITE_USER: "/enterprise/:id/send-invite",
  INVITE_DETAILS: "/get-invite/:code",
  INVITES: "/enterprise/:id/invites",
  SWITCH_ENTERPRISE: "/enterprise/switch",
  DEFAULT_ENTERPRISE: "/enterprise/default",
  ENTERPRISE_SETTINGS: "/enterprise/:id/settings",
  ENTERPRISE_DETAILS: "/enterprise/:id",
  ENTERPRISE_STATUS: "/enterprise/:id/status",
  DELETE_USER: "/enterprise/:id/user/:user_id",
  BLOCK_USER: "/enterprise/:id/user/:user_id/block",
  UPDATE_USER_ROLE: "/enterprise/:id/user/:user_id/update-role",
  CAMPAIGN_GALLERY: "/campaign-gallery",
  ADD_MOFS_TO_GALLERY: "/campaign-gallery/add-mof/:gallery_id",
  GALLERY_DETAILS: "/campaign-gallery/gallery",
  SHARE_GALLERY: "/campaign-gallery/share/:gallery_id",
  GALLERY_CAMPAIGNS: "/campaign-gallery/get-mof/:gallery_id",
  CAMPAIGN_DETAILS: "/campaign/:base_gs1",
  CAMPAIGN_LOCK: "/campaign/:base_gs1/toggle-lock",
  DASHBOARD_ANALYTICS: "/dashboard/:enterprise_id",
  PROVIDERS: "/providers",
  ASSIGN_PROVIDERS: "/providers/assign",
  RETAILERS: "/retailers",
  ASSIGN_RETAILERS: "/retailers/assign",
  TCB_SECRETS: "/enterprise/:id/tcb-secrets",
  PROFILE: "/profile",
  CHANGE_PASSWORD: "/change-password",
};

export default ApiConfig;
