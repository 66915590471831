import React from "react";
import { Home } from "react-feather";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { Pathnames } from "routes/Pathnames";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Button, TextField } from "@mui/material";
import useGlobalState from "store";
import DropdownWrapper from "components/atoms/DropdownWrapper";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { handleExportAsCSV, handleExportAsExcel } from "services";
import ExcelIcon from "assets/icons/Excel";
import CsvIcon from "assets/icons/CsvIcon";
import { useGetAnalytics } from "queries/dashboard";
import { format } from "date-fns";
import { searchKeys } from "constants";
import { handleExportAsPDF, parseDashboardDataForCsv, parseDashboardDataForExcel } from "services/exportAsFile";
import PDFIcon from "assets/icons/PDFIcon";
import { DateRangePicker } from "components";
import { today, getLocalTimeZone } from "@internationalized/date";
import { useGetEnterpriseDetails } from "queries/enterprise";

const downloadOptions = [
  {
    icon: <PDFIcon width={24} height={24} />,
    label: "PDF Document (.pdf)",
    key: "pdf_download",
  },
  {
    icon: <ExcelIcon width={24} height={24} />,
    label: "Excel file (.xlsx)",
    key: "excel_download",
  },
  {
    icon: <CsvIcon width={22} height={22} />,
    label: "CSV file (.csv)",
    key: "csv_download",
  },
];

const ExportDasbboardData = () => {
  const { dashboardFilter, dashboardRef } = useGlobalState();
  const { data, isLoading } = useGetAnalytics(dashboardFilter);

  const handleOnClick = (option) => {
    const title = `${format(dashboardFilter?.start_date?.toDate(), "do MMM yyyy")} - ${format(
      dashboardFilter?.end_date?.toDate(),
      "do MMM yyyy"
    )}`;
    if (option?.key === "csv_download")
      handleExportAsCSV(parseDashboardDataForCsv(data?.this_month?.records, searchKeys.dashboardCsv), title);
    else if (option?.key === "excel_download")
      handleExportAsExcel(parseDashboardDataForExcel(data?.this_month?.records, searchKeys.dashboardCsv), title);
    else if (option?.key === "pdf_download") handleExportAsPDF(dashboardRef, true, title);
  };

  return (
    <DropdownWrapper
      handleOnClick={handleOnClick}
      disabled={isLoading}
      options={downloadOptions}
      selected={false}
      selectedKey={"key"}
    >
      <Button disabled={isLoading} color="secondary" endIcon={<FileDownloadIcon />}>
        EXPORT
      </Button>
    </DropdownWrapper>
  );
};

const Breadcrumb = ({ title, parent }) => {
  const { dashboardFilter, setDashboardFilter } = useGlobalState();

  const { data: enterpriseDetails } = useGetEnterpriseDetails();

  const isOpen = !!enterpriseDetails?.email_domain;
  return (
    <Container fluid={true}>
      <div className="page-header">
        <Row>
          <Col lg="6">
            <div className="page-header-left">
              <h3 className="gap-2">
                {title}
                <small>
                  {title === "Dashboard" && isOpen ? (
                    <div className="d-flex align-items-center gap-2">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateRangePicker
                          defaultValue={{ start: dashboardFilter?.start_date, end: dashboardFilter?.end_date }}
                          handleSetValue={setDashboardFilter}
                          showOptions={false}
                          // minValue={today(getLocalTimeZone()).subtract({ days: 30 })}
                          maxValue={today(getLocalTimeZone())}
                        />
                        {/* <DatePicker
                          views={["month", "year"]}
                          value={dashboardFilter}
                          onChange={(value) => {
                            setDashboardFilter(value);
                          }}
                          maxDate={new Date()}
                          renderInput={(params) => (
                            <TextField {...params} autoComplete="off" placeholder="Created by" />
                          )}
                        /> */}
                      </LocalizationProvider>
                      <ExportDasbboardData />
                    </div>
                  ) : (
                    "Gitsy for Retail"
                  )}
                </small>
              </h3>
            </div>
          </Col>
          <Col lg="6">
            <ol className="breadcrumb pull-right">
              <li className="breadcrumb-item">
                <Link to={Pathnames.DASHBOARD}>
                  <Home />
                </Link>
              </li>
              {parent && <li className="breadcrumb-item">{parent}</li>}
              <li className="breadcrumb-item active">{title}</li>
            </ol>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Breadcrumb;
