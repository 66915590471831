import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryKeys } from "constants";
import { ApiConfig } from "constants";
import { AxiosInstance } from "services";
import { appendQueryParams } from "utils";
import { useGetUserDetails } from "./auth";
import { useParams } from "react-router";
import { useToasts } from "react-toast-notifications";

const postAddProduct = (data) => {
  const method = "POST";
  const url = ApiConfig.PRODUCT;
  return AxiosInstance({ method, url, data });
};

export const usePostAddProduct = (onSuccess) => {
  const QueryClient = useQueryClient();
  return useMutation(postAddProduct, {
    onSuccess: () => {
      QueryClient.invalidateQueries([QueryKeys.PRODUCTS]);
      onSuccess && onSuccess();
    },
  });
};

const getProducts = (data) => {
  const method = "GET";
  const url = ApiConfig.PRODUCTS + appendQueryParams(data);
  return AxiosInstance({ method, url });
};

export const useGetProducts = (data) => {
  const { data: userDetails } = useGetUserDetails();
  return useQuery([QueryKeys.PRODUCTS, userDetails?.current_enterprise?.id, data], () => getProducts(data), {
    enabled: Boolean(userDetails?.current_enterprise?.id),
  });
};

const getProductDetails = (product_id) => {
  const method = "GET";
  const url = ApiConfig.PRODUCT_DETAILS.replace(":product_id", product_id);
  return AxiosInstance({ method, url });
};

export const useGetProductDetails = (product_id) => {
  const { data: userDetails } = useGetUserDetails();
  return useQuery(
    [QueryKeys.PRODUCT_DETAILS, userDetails?.current_enterprise?.id, product_id],
    () => getProductDetails(product_id),
    {
      enabled: Boolean(userDetails?.current_enterprise?.id && product_id),
    }
  );
};

const patchProduct = (payload) => {
  const method = "PATCH";
  const url = ApiConfig.PRODUCT_DETAILS.replace(":product_id", payload?.id);
  return AxiosInstance({ method, url, data: payload?.data });
};

export const usePatchProduct = (onSuccess) => {
  const params = useParams();
  const { data: userDetails } = useGetUserDetails();
  const QueryClient = useQueryClient();
  return useMutation(patchProduct, {
    onSuccess: (res) => {
      QueryClient.invalidateQueries([QueryKeys.PRODUCTS]);
      QueryClient.invalidateQueries([QueryKeys.CAMPAIGN_DETAILS]);
      QueryClient.invalidateQueries([QueryKeys.BRANDS]);
      QueryClient.setQueryData(
        [QueryKeys.PRODUCT_DETAILS, userDetails?.current_enterprise?.id, params?.product_id],
        res?.Attributes
      );
      onSuccess && onSuccess();
    },
  });
};

const deleteProduct = (product_id) => {
  const method = "DELETE";
  const url = ApiConfig.PRODUCT_DETAILS.replace(":product_id", product_id);
  return AxiosInstance({ method, url });
};

export const useDeleteProduct = (onSuccess) => {
  const { addToast } = useToasts();
  const queryClient = useQueryClient();
  const { data: userDetails } = useGetUserDetails();

  return useMutation(deleteProduct, {
    onSuccess: () => {
      onSuccess && onSuccess();
      addToast("Successfully deleted product", {
        appearance: "success",
      });
      queryClient.invalidateQueries([QueryKeys.PRODUCTS, userDetails?.current_enterprise?.id]);
    },
  });
};
