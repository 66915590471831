import { Button, IconButton, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { fileUpload } from "queries/file";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import BackupIcon from "@mui/icons-material/Backup";

const MyUploader = ({ setValue, trigger, value, id, error }) => {
  const ref = useRef(null);
  const handleRemove = (e) => {
    e.stopPropagation();
    setValue(id, undefined);
    if (trigger) trigger(id);
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setValue(id, fileUploaded);
    if (trigger) trigger(id);
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const file = event.dataTransfer.files[0];
    setValue(id, file);
    if (trigger) trigger();
  };

  return (
    <div className="dropzone-bg">
      <div
        className={`dropzone ${error && "dropzone-error"}`}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={() => ref?.current && ref?.current?.click()}
      >
        {value ? (
          <>
            <div style={{ position: "relative" }}>
              <img
                src={value && typeof value === "string" ? value : URL.createObjectURL(value)}
                className="product-image"
                alt="input-img"
              />

              <IconButton onClick={handleRemove} className="position-absolute" sx={{ top: -20, right: -20 }}>
                <HighlightOffIcon />
              </IconButton>
            </div>
          </>
        ) : (
          <>
            <input
              ref={ref}
              hidden
              accept="image/jpeg, image/png, image/gif, image/bmp, image/webp, image/tiff"
              type="file"
              onChange={handleChange}
            />
            <div className="mb-3">
              <BackupIcon fontSize="large" />
              <div className="my-2">
                <span className="fw-bold">Choose a file</span> or drag it here.
                <br />
                <span className="text-secondary" style={{ fontSize: "12px" }}>
                  (supports JPEG, PNG files)
                </span>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MyUploader;
