import { useQuery } from "@tanstack/react-query";
import { QueryKeys, ApiConfig } from "constants";
import { AxiosInstance } from "services";
import { useGetUserDetails } from "./auth";
import { appendQueryParams } from "utils";

const getAnalytics = ({ enterprise_id, start_date, end_date }) => {
  const method = "GET";
  const url =
    ApiConfig.DASHBOARD_ANALYTICS.replace(":enterprise_id", enterprise_id) +
    appendQueryParams({ start_date, end_date });
  return AxiosInstance({ method, url });
};

export const useGetAnalytics = ({ start_date, end_date }) => {
  const { data: userDetails } = useGetUserDetails();
  return useQuery(
    [QueryKeys.DASHBOARD_ANALYTICS, userDetails?.current_enterprise?.id, start_date, end_date],
    () => getAnalytics({ enterprise_id: userDetails?.current_enterprise?.id, end_date, start_date }),
    {
      enabled: !!userDetails?.current_enterprise?.id,
    }
  );
};
