import { unparse } from "papaparse";
import * as XLSX from "xlsx";
import get from "lodash/get";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const handleExportAsCSV = (transformedData, title) => {
  const csvBlob = new Blob([transformedData], { type: "text/csv;charset=utf-8;" });
  const csvUrl = URL.createObjectURL(csvBlob);
  const link = document.createElement("a");
  link.href = csvUrl;
  link.setAttribute("download", `${title}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const handleExportAsExcel = (transformedData, title) => {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(transformedData);

  XLSX.utils.book_append_sheet(wb, ws, title);
  XLSX.writeFile(wb, `${title}.xlsx`);
};

export const handleExportAsPDF = (ref, orientation, title) => {
  if (!ref.current) return;

  html2canvas(ref.current, { scrollY: -window.scrollY }).then((canvas) => {
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF({
      orientation: orientation ? "landscape" : "portrait", // Adjusted based on true for landscape
      unit: "mm",
      format: "A4",
    });

    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    // Define padding top in mm
    const paddingTop = 10; // Adjust this value to increase or decrease padding

    // Scale the canvas image to fit within the PDF page dimensions, accounting for padding
    let imgWidth = canvas.width / 3.78;
    let imgHeight = canvas.height / 3.78;
    const ratio = Math.min(pdfWidth / imgWidth, (pdfHeight - paddingTop) / imgHeight);
    imgWidth *= ratio;
    imgHeight *= ratio;
    const x = (pdfWidth - imgWidth) / 2;
    // Adjust y position to include padding at the top
    const y = paddingTop;

    pdf.addImage(imgData, "PNG", x, y, imgWidth, imgHeight);
    pdf.save(`${title}.pdf`);
  });
};

export const parseCampaignDataForCsv = (data, keys) => {
  return unparse([
    Object.keys(keys),
    ...data?.map((row) =>
      Object.values(keys).map((value) => {
        if (typeof value === "object") {
          let data = [];
          value.forEach((x) => {
            const temp = get(row, x) ?? [];
            if (temp?.length) data = [...data, ...temp];
          });
          return data?.join(", ");
        }
        if (value === "email_domain") return (get(row, value) ?? "")?.split(".")?.[0];
        if (
          [
            "primary_purchase_prefixed_code",
            "second_purchase_prefixed_code",
            "third_purchase_prefixed_code",
            "primary_purchase_eans",
            "second_purchase_eans",
            "third_purchase_eans",
            "primary_purchase_gtins",
            "second_purchase_gtins",
            "third_purchase_gtins",
          ].includes(value)
        ) {
          return get(row, value)?.join(", ");
        }
        return get(row, value);
      })
    ),
  ]);
};

export const parseCampaignsDataForExcel = (data, keys) =>
  data.map((row) => {
    const resp = {};
    Object.keys(keys).forEach((key) => {
      const value = keys[key];
      if (typeof value === "object") {
        let data = [];
        value.forEach((x) => {
          const temp = get(row, x) ?? [];
          if (temp?.length) data = [...data, ...temp];
        });
        resp[key] = data?.join(", ");
      } else if (value === "email_domain") {
        resp[key] = (get(row, value) ?? "")?.split(".")?.[0];
      } else if (
        [
          "primary_purchase_prefixed_code",
          "second_purchase_prefixed_code",
          "third_purchase_prefixed_code",
          "primary_purchase_eans",
          "second_purchase_eans",
          "third_purchase_eans",
          "primary_purchase_gtins",
          "second_purchase_gtins",
          "third_purchase_gtins",
        ].includes(value)
      ) {
        resp[key] = get(row, value)?.join(", ");
      } else {
        resp[key] = get(row, value);
      }
      // resp[key] = get(item, keys[key]);
    });
    return resp;
  });

export const parseDashboardDataForCsv = (data, keys) => {
  return unparse([
    Object.keys(keys),
    ...data?.map((item) => {
      return Object.values(keys)?.map((x) => item[x]);
    }),
  ]);
};

export const parseDashboardDataForExcel = (data, keys) => {
  return data?.map((row) => {
    const res = {};
    Object.keys(keys)?.forEach((key) => {
      res[key] = row[keys[key]];
    });
    return res;
  });
};
