import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

const ConfirmationModal = ({ open, handleClose, handleProceed, title }) => {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs">
      <DialogTitle sx={{ minWidth: "500px" }}>
        <Typography variant="h6" fontWeight={500} color="gray.600">
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent></DialogContent>
      <DialogActions>
        <Button color="light-error" onClick={handleProceed}>
          Yes
        </Button>
        <Button onClick={handleClose}>No</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
