import React, { Fragment } from "react";
import Slider from "react-slick";
import logo from "assets/logo/gitsy_color.svg";
import "assets/scss/slick.scss";
import "assets/scss/slick-theme.scss";
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  arrows: false,
  autoplay: true,
};

const Slides = [
  {
    title: "Welcome to Gitsy 1",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
  },
  {
    title: "Welcome to Gitsy 2",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
  },
  {
    title: "Welcome to Gitsy 3",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
  },
];

const AuthLayout = ({ children, slider }) => {
  return (
    <Fragment>
      <div className="page-wrapper authentication-bg">
        <div className="authentication-box">
          <Container className="container">
            {slider ? (
              <Row>
                <Col className="col-md-5 p-0 card-left">
                  <Card className="bg-primary">
                    <div className="svg-icon">
                      <img alt="" src={logo} className="Img-fluid" />
                    </div>
                    <Slider {...settings}>
                      {Slides.map((slide) => (
                        <div key={slide?.title}>
                          <div>
                            <h3>{slide?.title}</h3>
                            <p>{slide?.description}</p>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </Card>
                </Col>
                <Col className="col-md-7 p-0 card-right">
                  <Card className="tab2-card">
                    <CardBody>{children} </CardBody>
                  </Card>
                </Col>
              </Row>
            ) : (
              <Col className="col-md-12 p-0 card-right">
                <Card className="tab2-card">
                  <CardBody>{children}</CardBody>
                </Card>
              </Col>
            )}
          </Container>
        </div>
      </div>
    </Fragment>
  );
};

export default AuthLayout;
