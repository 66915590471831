import { useFormContext, useWatch } from "react-hook-form";
import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { FormGroup, Label } from "reactstrap";
import { MyDropzone, ORDivider } from "components";
import { useGetBrands } from "queries/brands";
import AddIcon from "@mui/icons-material/Add";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useEffect } from "react";

const purchasePrefixedCode = {
  PLU: "Price Look Up",
  CLS: "Class ID",
  DPT: "Department ID",
  C_D: "Class and Dept.",
};

const SelectBrandDropdown = ({ setIsAddBrandOpen, isEditable }) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  const { data: brands, isLoading } = useGetBrands();
  const brand = useWatch({ control, name: "brand" });

  const handleOnChange = (e) => {
    if (e.target.value === "add-brand") {
      setIsAddBrandOpen(true);
    } else {
      setValue(
        "brand",
        brands?.data?.find((x) => x?.internal_id === e.target.value)
      );
    }
  };

  useEffect(() => {
    if (!isEditable && brands?.data?.[0] && !brand) {
      setValue("brand", brands?.data?.[0]);
    }
  }, [brands, brand, isEditable]);

  return (
    <FormControl sx={{ minWidth: "180px" }}>
      <Label className="col-form-label pt-0">
        <span style={{ color: "red" }}>*</span> Select Brand
      </Label>
      {!isLoading ? (
        <Select error={Boolean(errors?.brand)} value={brand?.internal_id ?? null} onChange={handleOnChange}>
          <MenuItem value={"add-brand"}>
            <ListItemIcon>
              <AddIcon color="primary" />
            </ListItemIcon>
            <ListItemText> Add Brand</ListItemText>
          </MenuItem>
          {brands?.data?.map((brand) => (
            <MenuItem value={brand?.internal_id} key={brand?.internal_id}>
              {brand?.name}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <div className="d-flex align-items-center justify-content-center">
          <CircularProgress />
        </div>
      )}
      <FormHelperText error={!!errors?.brand}>{errors?.brand?.message}</FormHelperText>
    </FormControl>
  );
};

const ProductPurchasePrefixedCodes = ({ fieldMethods }) => {
  const {
    watch,
    control,
    setValue,
    register,
    formState: { errors },
  } = useFormContext();

  const { fields, append, remove } = fieldMethods;

  const purchase_prefixed_code = useWatch({
    control,
    name: "purchase_prefixed_code",
  });

  const handleAddMorePrefixedCodes = () => {
    const nextOption = Object.entries(purchasePrefixedCode)?.filter(
      (x) => !purchase_prefixed_code?.some((y) => y?.type === x[0])
    )[0][0];
    append({ type: nextOption, value: "" });
  };

  const handleRemovePrefixedCode = (index) => {
    remove(index);
  };

  return (
    <>
      <Label className="col-form-label pt-0">Purchase Prefixed Codes</Label>
      {fields?.map((field, index) => {
        const prefixedOptions = Object.entries(purchasePrefixedCode)?.filter(
          (x) => x[0] === field?.type || !fields?.some((y) => y?.type === x[0])
        );
        const type = watch(`purchase_prefixed_code.${index}.type`);
        return (
          <div className="d-flex gap-2 align-items-start mb-2" key={field.id}>
            <FormControl sx={{ minWidth: "180px" }}>
              <Select
                value={type}
                id="grouped-select"
                error={Boolean(errors?.purchase_prefixed_code_type)}
                onChange={(e) => setValue(`purchase_prefixed_code.${index}.type`, e.target.value)}
              >
                {prefixedOptions?.map((option, i) => (
                  <MenuItem value={option[0]} key={i}>
                    {option[1]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div className="w-100">
              <TextField
                {...register(`purchase_prefixed_code.${index}.value`)}
                type={"text"}
                autoComplete="off"
                placeholder="Enter prefixed value"
                fullWidth
                error={!!errors?.purchase_prefixed_code?.[index]?.value?.message}
                helperText={errors?.purchase_prefixed_code?.[index]?.value?.message}
              />
            </div>
            {fields?.length !== 1 ? (
              <IconButton onClick={() => handleRemovePrefixedCode(index)}>
                <DeleteForeverIcon />
              </IconButton>
            ) : null}
          </div>
        );
      })}
      <Button
        variant="text"
        disabled={fields?.length === 4}
        onClick={handleAddMorePrefixedCodes}
        startIcon={<ControlPointIcon />}
      >
        Add More
      </Button>
    </>
  );
};

export const AddProductPrimaryDetailsForm = ({ setIsAddBrandOpen, fieldMethods, isEditable }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="digital-add needs-validation">
      <div className="mb-3">
        <Typography fontWeight={600}>Basic Details</Typography>
        <Divider />
      </div>
      <div className="d-flex gap-2">
        <SelectBrandDropdown setIsAddBrandOpen={setIsAddBrandOpen} isEditable={isEditable} />
        <FormGroup className="w-100">
          <Label className="col-form-label pt-0">
            <span>*</span> Name
          </Label>
          <TextField
            {...register("name")}
            type={"text"}
            autoComplete="off"
            fullWidth
            error={!!errors?.name}
            helperText={errors?.name?.message}
          />
        </FormGroup>
      </div>
      <FormGroup>
        <Label className="col-form-label pt-0">Description</Label>
        <TextField
          {...register("description")}
          type={"text"}
          autoComplete="off"
          fullWidth
          multiline={true}
          rows={2}
          error={!!errors?.description}
          helperText={errors?.description?.message}
        />
      </FormGroup>
      <div className="mb-3">
        <Typography fontWeight={600}>Purchase Details</Typography>
        <Divider />
        <FormHelperText error={!!errors?.purchase_prefixed_code}>
          {errors?.purchase_prefixed_code?.message}
        </FormHelperText>
      </div>
      <FormGroup>
        <Label className="col-form-label pt-0">Purchase GTIN (UPC)</Label>
        <TextField
          {...register("upc_gtins")}
          type={"number"}
          autoComplete="off"
          fullWidth
          error={!!errors?.upc_gtins}
          helperText={errors?.upc_gtins?.message}
          onWheel={(e) => e.target.blur()}
          InputProps={{ inputProps: { min: 0 } }}
        />
      </FormGroup>
      <FormGroup>
        <Label className="col-form-label pt-0">Purchase GTIN (EAN)</Label>
        <TextField
          {...register("ean_gtins")}
          type={"number"}
          autoComplete="off"
          fullWidth
          error={!!errors?.ean_gtins}
          helperText={errors?.ean_gtins?.message}
          onWheel={(e) => e.target.blur()}
          InputProps={{ inputProps: { min: 0 } }}
        />
      </FormGroup>
      <ProductPurchasePrefixedCodes fieldMethods={fieldMethods} />
    </div>
  );
};

export const AddProductSecondaryForm = () => {
  const {
    setValue,
    trigger,
    control,
    formState: { errors },
  } = useFormContext();

  const value = useWatch({ control, name: "image" });

  return (
    <div className="digital-add needs-validation">
      <FormGroup>
        <Label className="col-form-label pt-0">
          <span>*</span> Upload Product's image
        </Label>
        <MyDropzone setValue={setValue} trigger={trigger} value={value} id={"image"} />
        <FormHelperText error={Boolean(errors?.image)}>{errors?.image?.message}</FormHelperText>
      </FormGroup>
    </div>
  );
};
