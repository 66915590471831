import React from "react";
import { useTableActionContext } from "components/Table";
import { useGetUserDetails } from "queries/auth";
import { Alert, AlertTitle, Button, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useDeleteUserFromEnterprise } from "queries/enterprise";

const DeleteUser = () => {
  const { setIsModalOpen, row: user } = useTableActionContext();
  const { mutate: deleteUserFromEnterprise } = useDeleteUserFromEnterprise();
  const { data: userDetails } = useGetUserDetails();

  const handleSubmit = () => {
    deleteUserFromEnterprise({
      id: userDetails?.current_enterprise?.id,
      user_id: user?.user_id,
    });
    setIsModalOpen(false);
  };

  return (
    <>
      <DialogTitle>Delete User</DialogTitle>
      <DialogContent sx={{ width: "400px" }}>
        <Alert severity="warning">Are you sure you want to delete this user from the enterprise?</Alert>
      </DialogContent>
      <DialogActions>
        <Button color="light-error" onClick={handleSubmit}>
          Yes
        </Button>
        <Button onClick={() => setIsModalOpen(false)}>No</Button>
      </DialogActions>
    </>
  );
};

export default DeleteUser;
