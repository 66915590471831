import {
  Button,
  Dialog,
  TextField,
  Typography,
  InputLabel,
  DialogTitle,
  DialogContent,
  Divider,
  DialogActions,
  Avatar,
  Badge,
  useTheme,
  FormControl,
  Autocomplete,
  InputAdornment,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import { yupResolver } from "@hookform/resolvers/yup";
import yupSchema from "constants/yupSchema";
import { useForm, useWatch } from "react-hook-form";
import { usePostAddBrand } from "queries/brands";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useEffect, useRef } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { uploadToS3Bucket } from "services";
import { useGetUserDetails } from "queries/auth";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";

export const UploadImage = ({ src, errors, handleUpload }) => {
  const ref = useRef(null);
  const theme = useTheme();

  return (
    <>
      <Badge
        onClick={() => ref?.current && ref?.current?.click()}
        overlap="circular"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        badgeContent={
          src && (
            <Avatar
              variant="circular"
              sx={{
                width: 26,
                height: 26,
                bgcolor: theme.palette.secondary.main,
              }}
            >
              <EditIcon sx={{ fontSize: "16px" }} />
            </Avatar>
          )
        }
      >
        <Avatar
          variant="circular"
          src={src && typeof src !== "string" ? URL.createObjectURL(src) : src}
          aria-label="upload picture"
          sx={{
            height: 100,
            width: 100,
            bgcolor: theme.palette.gray[300],
            cursor: "pointer",
            border: errors?.image_path && `2px solid ${theme.palette.error.main}`,
          }}
          component="label"
        >
          {!src && <CloudUploadIcon sx={{ height: 30, width: 30 }} />}
        </Avatar>
      </Badge>
      <input ref={ref} hidden accept="image/*" type="file" onChange={handleUpload} />
    </>
  );
};

const AddBrand = ({ brand, isOpen, handleClose, handleSelectBrand }) => {
  const {
    reset,
    trigger,
    control,
    setValue,
    setError,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(yupSchema.ADD_BRAND),
  });

  const image = useWatch({ control, name: "image_path" });
  const provider = useWatch({ control, name: "provider" });
  const { mutate: postAddBrand } = usePostAddBrand();
  const { data: userDetails } = useGetUserDetails();

  const handleUploadImage = (event) => {
    const fileUploaded = event.target.files[0];
    setValue("image_path", fileUploaded);
    trigger("image_path");
  };

  const handleOnSubmit = (formData) => {
    const payload = {
      file: formData?.image_path,
      image_type: "brand_image",
    };
    uploadToS3Bucket(payload, userDetails?.current_enterprise?.id).then((imageUrl) => {
      postAddBrand(
        {
          name: formData?.name,
          image_path: imageUrl,
        },
        {
          onSuccess: () => {
            handleClose();
            reset({
              name: null,
              provider: null,
              image_path: null,
            });
          },
          onError: (res) => setError("name", { type: "custom", message: res?.message }, { shouldFocus: true }),
        }
      );
    });
  };

  const handleOnSelectProvider = (_, value) => {
    setValue("provider", value);
  };

  useEffect(() => {
    if (!isOpen) reset();
    if (brand) reset(brand);
  }, [isOpen, brand]);

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)} id="add-brand-form">
      <Dialog
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        onClose={handleClose}
        closeAfterTransition
        fullWidth
        // slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 400,
          },
        }}
      >
        <DialogTitle>
          <Typography className="pb-3" variant="h6" fontWeight={600}>
            Add Brand
          </Typography>
          <Divider />
        </DialogTitle>
        <DialogContent>
          <div className="d-flex gap-4">
            <div className="w-25">
              <UploadImage src={image} handleUpload={handleUploadImage} errors={errors} />
              <InputLabel align="center" className="my-2">
                Logo
              </InputLabel>
            </div>
            <div className="d-flex flex-column gap-2 w-100">
              <InputLabel>Name</InputLabel>
              <TextField
                fullWidth
                {...register("name")}
                placeholder="Enter a brand name"
                error={Boolean(errors?.name?.message)}
                helperText={errors?.name?.message}
              />
              {/* <InputLabel>Select primary provider</InputLabel>
              <Autocomplete
                value={provider}
                options={["Kabir Batra", "Joice Joy", "Purnima Srivastava"]}
                onChange={handleOnSelectProvider}
                clearIcon={<DeleteIcon />}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    placeholder={!provider?.length ? "Search for providers..." : ""}
                    autoComplete="off"
                  />
                )}
                renderOption={(props, option, { selected }) => (
                  <MenuItem {...props}>
                    <ListItemIcon>
                      <Avatar variant="circular" sx={{ height: 34, width: 34, margin: "0 14px 0 0" }}></Avatar>
                    </ListItemIcon>
                    <ListItemText>{option}</ListItemText>
                  </MenuItem>
                )}
              /> */}
            </div>
          </div>
          {/* {errors?.image && (
              <FormHelperText error>{errors?.image?.message}</FormHelperText>
            )} */}
        </DialogContent>
        <DialogActions>
          <Button form="add-brand-form" type="submit">
            Save
          </Button>
          <Button color="gray" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default AddBrand;
