import { CalendarDate } from "@internationalized/date";
import { Box, Button } from "@mui/material";
import { getRangeForOption } from "./CalendarSideBar.config";
import { useState } from "react";

export function CalendarSideBar({ state }) {
  const [selected, setSelected] = useState(null);
  const options = [
    "Today",
    "Yesterday",
    "This week",
    "Last week",
    "This month",
    "Last month",
    // "This year",
    // "Last year",
  ];

  function selectRange(option) {
    const range = getRangeForOption(option);
    state.setAnchorDate(null);
    state.setValue(range);
    state.setFocusedDate(new CalendarDate(range.end.year, range.end.month, range.end.day));
  }

  return (
    <Box display={"flex"} flexDirection={"column"} alignItems="start" padding="8px" gap="4px">
      {options.map((option) => (
        <Button
          variant="text"
          color={
            selected
              ? selected === option
                ? "primary"
                : "black"
              : option === options?.[4] && state.value
              ? "primary"
              : "black"
          }
          fullWidth
          sx={{
            fontSize: "14px",
            color: "#000",
            ":hover": {
              backgroundColor: "white",
              color: "#000",
            },
          }}
          key={option}
          onClick={() => {
            setSelected(option);
            selectRange(option);
          }}
        >
          {option}
        </Button>
      ))}
    </Box>
  );
}
