import { yupResolver } from "@hookform/resolvers/yup";
import {
  TextField,
  Button,
  FormHelperText,
  InputAdornment,
  IconButton,
  DialogContent,
  Typography,
  DialogTitle,
  Dialog,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { ConnectToTCBAlert, LoadingWrapper, MuiLoadingButton, MyDropzone } from "components";
import { yupSchema } from "constants";
import { useGetUserDetails } from "queries/auth";
import { useGetEnterpriseDetails, usePatchEnterpriseSettings, usePostTcbSecrets } from "queries/enterprise";
import React, { Fragment, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import SaveIcon from "@mui/icons-material/Save";
import { Card, Form, FormGroup, Label, CardBody } from "reactstrap";
import { uploadToS3Bucket } from "services";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import WestIcon from "@mui/icons-material/West";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import "./index.scss";
import { areAllAsterisks } from "utils/helpers/parser";
import useGlobalState from "store";

const dummyPlaceholder = "*****************************";

const SecretsConfirmationModal = ({ open, handleClose, handleSubmit, enterpriseId }) => {
  const [password, setPassword] = useState({
    value: "",
    error: "",
    show: false,
  });

  const handleError = (res) => {
    setPassword((state) => ({ ...state, error: res?.message }));
  };

  const handleOnSubmit = () => {
    const payload = {
      data: {
        password: password?.value,
      },
      enterprise_id: enterpriseId,
    };
    handleSubmit(payload, handleError);
  };

  const handleTogglePassword = () => {
    setPassword((state) => ({ ...state, show: !state?.show }));
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <IconButton sx={{ marginLeft: "-10px" }} onClick={handleClose}>
          <WestIcon />
        </IconButton>{" "}
      </DialogTitle>
      <DialogContent>
        <div className="gap-3 d-flex flex-column align-items-center" style={{ width: "400px" }}>
          <div className="auth-icon">
            <VpnKeyOutlinedIcon
              color="secondary"
              sx={{
                width: 28,
                height: 28,
              }}
            />
          </div>
          <Typography variant="h6" fontWeight={700} textAlign={"center"}>
            Please enter your account password <br /> to access your keys.
          </Typography>
          <TextField
            fullWidth
            value={password?.value}
            placeholder="Enter your account password"
            onChange={(e) => setPassword((state) => ({ ...state, value: e.target.value }))}
            error={!!password?.error?.length}
            helperText={password?.error}
            type={password?.show ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleTogglePassword} edge="end">
                    {!password?.show ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button color="secondary" fullWidth onClick={handleOnSubmit}>
            Show
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const Settings = () => {
  const { addToast } = useToasts();
  const { tcbSecrets, setTcbSecrets } = useGlobalState();
  const {
    trigger,
    reset,
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      image_path: "",
      allow_multiple_brands: false,
    },
    resolver: yupResolver(yupSchema.ENTERPRISE_SETTINGS),
  });

  const image = useWatch({ control, name: "image_path" });
  const allow_multiple_brands = useWatch({ control, name: "allow_multiple_brands" });

  const [showSecrets, setShowSecrets] = useState(false);
  const [openSecretsConfirmation, setOpenSecretsConfirmation] = useState(false);

  const { mutate: patchEnterpriseSettings, isLoading } = usePatchEnterpriseSettings();
  const { mutate: postTcbSecrets, isLoading: isLoadingTcbSecrets } = usePostTcbSecrets();
  const { data: userDetails } = useGetUserDetails();
  const { data: enterpriseDetails, isLoading: isloadingEnterprise } = useGetEnterpriseDetails();

  const doesSecretExists = !!enterpriseDetails?.alias_name;

  const handleOpenConfirmation = () => setOpenSecretsConfirmation(true);
  const handleCloseConfirmation = () => setOpenSecretsConfirmation(false);

  const handleToggleSecrets = () => {
    if (!tcbSecrets) handleOpenConfirmation();
    else
      setShowSecrets((state) => {
        if (!state) {
          setValue("access_key", tcbSecrets?.access_key);
          setValue("secret_key", tcbSecrets?.secret_key);
        } else {
          setValue("access_key", dummyPlaceholder);
          setValue("secret_key", dummyPlaceholder);
        }
        return !state;
      });
  };

  const handleSubmitSecretConfimation = (payload, handleError) => {
    postTcbSecrets(payload, {
      onSuccess: (res) => {
        setTcbSecrets(res);
        handleCloseConfirmation();
        handleToggleSecrets();
      },
      onError: handleError,
    });
  };

  const handleOnSubmit = (formData) => {
    /** remove tcb secrets placeholders */
    if (areAllAsterisks(formData?.access_key)) delete formData.access_key;
    if (areAllAsterisks(formData?.secret_key)) delete formData.secret_key;

    if (typeof formData?.image_path !== "string" && !!formData?.image_path) {
      const payload = {
        file: formData?.image_path,
        image_type: "enterprise_image",
      };

      if (formData?.access_key)
        uploadToS3Bucket(payload, userDetails?.current_enterprise?.id).then((image_path) => {
          patchEnterpriseSettings(
            {
              payload: {
                ...formData,
                image_path,
              },
              id: userDetails?.current_enterprise?.id,
            },
            {
              onSuccess: () => {
                reset();
                addToast("Successfully updated enterprise settings", {
                  appearance: "success",
                });
              },
            }
          );
        });
    }
    if (typeof formData?.image_path === "string") {
      patchEnterpriseSettings(
        {
          payload: formData,
          id: userDetails?.current_enterprise?.id,
        },
        {
          onSuccess: () => {
            reset();
            addToast("Successfully updated enterprise settings", {
              appearance: "success",
            });
          },
        }
      );
    }
  };

  useEffect(() => {
    if (!!enterpriseDetails?.alias_name) {
      reset({
        alias_name: enterpriseDetails?.alias_name,
        access_key: dummyPlaceholder,
        secret_key: dummyPlaceholder,
        // email_domain: enterpriseDetails?.email_domain,
        image_path: enterpriseDetails?.image_path,
        terms_and_conditions: enterpriseDetails?.terms_and_conditions,
        allow_multiple_brands: !!enterpriseDetails?.allow_multiple_brands,
      });
    }
  }, [enterpriseDetails]);

  // note: temp fix, need to be resolved
  useEffect(() => {
    if (!!tcbSecrets) {
      handleCloseConfirmation();
      handleToggleSecrets();
    }
  }, [tcbSecrets]);

  return (
    <ConnectToTCBAlert isSettingsPage>
      <form onSubmit={handleSubmit(handleOnSubmit)} autoComplete="off">
        <Card>
          <CardBody>
            <Fragment>
              <Form className="needs-validation user-add" noValidate="">
                <h4>Enterprise Settings</h4>
                <LoadingWrapper isLoading={isloadingEnterprise}>
                  <FormGroup className="row">
                    <Label className="col-xl-3 col-md-4">
                      <span>*</span> Allow multiple brands in campaigns
                    </Label>
                    <div className="col-xl-8 col-md-7">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={allow_multiple_brands}
                            onChange={() => setValue("allow_multiple_brands", !allow_multiple_brands)}
                          />
                        }
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-xl-3 col-md-4">
                      <span>*</span> Display Name
                    </Label>
                    <div className="col-xl-8 col-md-7">
                      <TextField
                        fullWidth
                        autoComplete="false"
                        {...register("alias_name")}
                        placeholder="Enter display name"
                        error={Boolean(errors?.alias_name?.message)}
                        helperText={errors?.alias_name?.message}
                      />
                    </div>
                  </FormGroup>
                  {/* <FormGroup className="row">
                  <Label className="col-xl-3 col-md-4">
                    <span>*</span> Email Domain
                  </Label>
                  <div className="col-xl-8 col-md-7">
                    <TextField
                      fullWidth
                      autoComplete="false"
                      {...register("email_domain")}
                      placeholder="Enter email domain"
                      error={Boolean(errors?.email_domain?.message)}
                      helperText={errors?.email_domain?.message}
                      disabled={!!enterpriseDetails?.email_domain}
                    />
                  </div>
                </FormGroup> */}
                  {userDetails?.current_enterprise?.id !== "01HHSADNZQNQNKFMCDFESA18CF" && (
                    <>
                      <FormGroup className="row">
                        <Label className="col-xl-3 col-md-4">
                          <span>*</span> Access Key
                        </Label>
                        <div className="col-xl-8 col-md-7">
                          <TextField
                            fullWidth
                            {...register("access_key")}
                            placeholder="Enter access key"
                            error={Boolean(errors?.access_key?.message)}
                            helperText={errors?.access_key?.message}
                            disabled={doesSecretExists}
                            InputProps={{
                              endAdornment: (
                                <>
                                  {doesSecretExists ? (
                                    <InputAdornment position="end">
                                      <IconButton onClick={handleToggleSecrets} edge="end">
                                        {!showSecrets ? <VisibilityOff /> : <Visibility />}
                                      </IconButton>
                                    </InputAdornment>
                                  ) : null}
                                </>
                              ),
                            }}
                          />
                        </div>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-xl-3 col-md-4">
                          <span>*</span> Secret Access Key
                        </Label>
                        <div className="col-xl-8 col-md-7">
                          <TextField
                            fullWidth
                            {...register("secret_key")}
                            placeholder="Enter secret access key"
                            error={Boolean(errors?.secret_key?.message)}
                            helperText={errors?.secret_key?.message}
                            disabled={doesSecretExists}
                            InputProps={{
                              endAdornment: (
                                <>
                                  {doesSecretExists ? (
                                    <InputAdornment position="end">
                                      <IconButton onClick={handleToggleSecrets} edge="end">
                                        {!showSecrets ? <VisibilityOff /> : <Visibility />}
                                      </IconButton>
                                    </InputAdornment>
                                  ) : null}
                                </>
                              ),
                            }}
                          />
                        </div>
                      </FormGroup>
                    </>
                  )}

                  <FormGroup className="row">
                    <Label className="col-xl-3 col-md-4">
                      <span>*</span> Terms and conditions
                    </Label>
                    <div className="col-xl-8 col-md-7">
                      <TextField
                        fullWidth
                        {...register("terms_and_conditions")}
                        multiline
                        rows={5}
                        sx={{
                          "& .MuiInputBase-input": {
                            padding: "0!important",
                          },
                        }}
                        error={Boolean(errors?.terms_and_conditions?.message)}
                        helperText={errors?.terms_and_conditions?.message}
                        placeholder="Describe your terms and conditions or provide an external link"
                      />
                    </div>
                  </FormGroup>

                  <FormGroup className="row">
                    <Label className="col-xl-3 col-md-4">Logo</Label>
                    <div className="col-xl-8 col-md-7">
                      <MyDropzone setValue={setValue} value={image} trigger={trigger} id={"image_path"} />
                      <FormHelperText error={Boolean(errors?.image_path)}>{errors?.image_path?.message}</FormHelperText>
                    </div>
                  </FormGroup>
                </LoadingWrapper>
              </Form>
              <div className="pull-right">
                <MuiLoadingButton isLoading={isLoading} form-id="add-gallery" type="submit" endIcon={<SaveIcon />}>
                  SAVE
                </MuiLoadingButton>
              </div>
            </Fragment>
          </CardBody>
        </Card>
        <SecretsConfirmationModal
          open={openSecretsConfirmation}
          handleClose={handleCloseConfirmation}
          handleSubmit={handleSubmitSecretConfimation}
          enterpriseId={enterpriseDetails?.id}
        />
      </form>
    </ConnectToTCBAlert>
  );
};

export default Settings;
