import { Avatar, CircularProgress, Divider, FormHelperText, Paper, Typography } from "@mui/material";
import { usePostAddNewEnterprise, usePostJoinEnterprise, usePostSwitchEnterprise } from "queries/enterprise";
import { useEffect, useState } from "react";
import { Button, Form, Input, Label } from "reactstrap";
import { useRegisterContext } from "./Register";
import { Pathnames } from "routes/Pathnames";
import { useNavigate } from "react-router";
import { LoadingButton } from "components";
import { useToasts } from "react-toast-notifications";
import { getInitials } from "utils/helpers/parser";

const JoinOrCreateEnterprise = ({ inviteDetails }) => {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const { data } = useRegisterContext();
  const [form, setForm] = useState({
    invite_code: null,
    enterprise_name: null,
  });

  const { mutate: postAddNewEnterprise, error, isLoading: isLoadingCreateEnt } = usePostAddNewEnterprise();
  const { mutate: postJoinEnterprise, isLoading: isLoadingJoinEnt } = usePostJoinEnterprise();
  const { handleSwitchEnterprise } = usePostSwitchEnterprise(true);

  const handleAddNewEnterprise = () =>
    postAddNewEnterprise(
      {
        name: form?.enterprise_name,
        email: data?.email,
        user_id: data?.user_id,
      },
      {
        onSuccess: () => {
          navigate(Pathnames.ENTERPRISE_STATUS);
        },
      }
    );

  const handleJoinEnterprise = () => {
    postJoinEnterprise(
      { code: form?.invite_code },
      {
        onSuccess: () => {
          handleSwitchEnterprise({
            name: inviteDetails?.enterprise_name,
            role: inviteDetails?.role,
            id: inviteDetails?.enterprise_id,
            email: inviteDetails?.invited_by?.email,
            status: inviteDetails?.status ?? "Approved",
          });
          // navigate(Pathnames.DASHBOARD);
          addToast("Successfully joined enterprise", {
            appearance: "success",
          });
        },
      }
    );
  };

  useEffect(() => {
    if (inviteDetails?.id) setForm((curr) => ({ ...curr, invite_code: inviteDetails?.id }));
  }, [inviteDetails]);

  return (
    <>
      {inviteDetails ? (
        <>
          <Paper sx={{ bgcolor: "gray.25" }} elevation={0} className="p-3 mb-3">
            <div className="d-flex flex-column align-items-center gap-1">
              <Avatar
                src={inviteDetails?.image_path}
                className="rounded-circle blur-up"
                variant="circular"
                sx={{ height: 60, width: 60 }}
                // alt={initials}
              >
                {!inviteDetails?.image_path ? getInitials(inviteDetails?.enterprise_name) : null}
              </Avatar>
              <Typography fontWeight={600} variant="h6" className="line-clamp-2" maxWidth={300}>
                {inviteDetails?.enterprise_name}
              </Typography>
              <div className="mt-4 w-100">
                <div className="d-flex gap-4 border-solid border-1 border-black">
                  <Typography fontWeight={600} variant="body2" className="w-25">
                    Role:
                  </Typography>
                  <Typography fontWeight={600} variant="body2" color="gray.500">
                    {inviteDetails?.role}
                  </Typography>
                </div>
                <div className="d-flex gap-4">
                  <Typography fontWeight={600} variant="body2" className="w-25">
                    Name:
                  </Typography>
                  <Typography fontWeight={600} variant="body2" color="gray.500">
                    {inviteDetails?.first_name} {inviteDetails?.last_name}
                  </Typography>
                </div>
                <div className="d-flex gap-4">
                  <Typography fontWeight={600} variant="body2" className="w-25">
                    Email:
                  </Typography>
                  <Typography fontWeight={600} variant="body2" color="gray.500">
                    {inviteDetails?.email}
                  </Typography>
                </div>
                <div className="d-flex gap-4 flex-shrink-0">
                  <Typography fontWeight={600} variant="body2" className="w-25">
                    Invited by:
                  </Typography>
                  <Typography fontWeight={600} variant="body2" color="gray.500">
                    {inviteDetails?.invited_by?.first_name} {inviteDetails?.invited_by?.last_name}
                    <br />({inviteDetails?.invited_by?.email})
                  </Typography>
                </div>
              </div>
            </div>
          </Paper>
          <LoadingButton
            fullWidth
            isLoading={isLoadingJoinEnt}
            disabled={!form?.invite_code?.length}
            onClick={handleJoinEnterprise}
          >
            Accept Invite
          </LoadingButton>
          <FormHelperText error={error}>{error?.join}</FormHelperText>
        </>
      ) : (
        <div className="form-horizontal auth-form" style={{ height: "380px" }}>
          <Typography variant="h6" align="left" fontWeight={500} className="pb-1">
            Join enterprise
          </Typography>
          <Label className="col-form-label">
            Enter the invite code received in your email <span>*</span>
          </Label>
          <div className="d-flex align-items-center gap-2">
            <Input
              value={form?.invite_code}
              onChange={(e) => setForm((curr) => ({ ...curr, invite_code: e.target.value }))}
              required=""
              type="text"
              className="form-control"
              placeholder="Enter invite code"
            />
            <LoadingButton
              isLoading={isLoadingJoinEnt}
              disabled={!form?.invite_code?.length}
              onClick={handleJoinEnterprise}
            >
              JOIN
            </LoadingButton>
          </div>
          <div style={{ display: "flex", alignItems: "center", margin: "20px 0" }}>
            <Divider style={{ flex: 1 }} />
            <Typography variant="body1" style={{ margin: "0 16px" }}>
              OR
            </Typography>
            <Divider style={{ flex: 1 }} />
          </div>
          <Typography variant="h6" align="left" fontWeight={500} className="pb-1">
            Create enterprise
          </Typography>

          <Label className="d-block pb-2 fw-medium">
            Enterprise name <span>*</span>
          </Label>
          <div className="d-flex align-items-center gap-2">
            <Input
              value={form?.enterprise_name}
              onChange={(e) => setForm((curr) => ({ ...curr, enterprise_name: e.target.value }))}
              required=""
              type="text"
              className="form-control"
              placeholder="Enter enterprise name"
            />
            <LoadingButton
              isLoading={isLoadingCreateEnt}
              disabled={isLoadingCreateEnt || !form?.enterprise_name?.length}
              onClick={handleAddNewEnterprise}
            >
              NEXT
            </LoadingButton>
            <FormHelperText error={error}>{error?.message}</FormHelperText>
          </div>
        </div>
      )}
    </>
  );
};

export default JoinOrCreateEnterprise;
