import {
  Avatar,
  Checkbox,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useGetUserDetails } from "queries/auth";
import React, { useState } from "react";
import useGlobalState from "store";
import EastIcon from "@mui/icons-material/East";
import { usePatchDefaultEnterprise, usePostSwitchEnterprise } from "queries/enterprise";
import { LoadingButton } from "components";
import { Link } from "react-router-dom";
import { Pathnames } from "routes/Pathnames";
import BusinessIcon from "@mui/icons-material/Business";

const ChooseEnterprise = () => {
  const { approvedEnterprises } = useGlobalState();
  const { data: userDetails } = useGetUserDetails();
  const { handleSwitchEnterprise, isLoading } = usePostSwitchEnterprise(true);

  const [selected, setSelected] = useState(userDetails?.default_enterprise);
  const [isDefault, setIsDefault] = useState(false);

  const { mutate: patchDefaultEnterprise } = usePatchDefaultEnterprise();

  const handleNext = () => {
    if (isDefault) patchDefaultEnterprise(selected);
    handleSwitchEnterprise(selected);
  };

  return (
    <>
      <Typography variant="h6" align="left" fontWeight={500}>
        Select enterprise
      </Typography>
      <Typography color="gray.400" variant="subtitle2" align="left">
        Enterprises for {userDetails?.email}
      </Typography>
      <List>
        {approvedEnterprises?.map((enterprise) => (
          <ListItem key={enterprise?.id} onClick={() => setSelected(enterprise)} disablePadding>
            <ListItemButton selected={selected?.id === enterprise?.id} className="mb-2">
              <ListItemIcon>
                <Avatar alt={enterprise?.name} src={undefined} variant="square">
                  <BusinessIcon />
                </Avatar>
              </ListItemIcon>
              <ListItemText
                id="switch-list-label-bluetooth"
                primary={enterprise?.name}
                secondaryTypographyProps={{ variant: "subtitle2" }}
                secondary={enterprise?.role}
              />
              <EastIcon />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox size="small" checked={isDefault} onChange={(e) => setIsDefault(e.target.checked)} />}
          label={
            <Typography color="gray.400" variant="body2">
              Use this enterprise as the default option.
            </Typography>
          }
        />
      </FormGroup>
      <LoadingButton fullWidth isLoading={isLoading} onClick={handleNext}>
        Next
      </LoadingButton>
      <Typography color="gray.400" variant="body2">
        Can’t find your enterprise? <Link to={Pathnames.ENTERPRISE_STATUS}>View enterprises requests</Link>
      </Typography>
    </>
  );
};

export default ChooseEnterprise;
