import React, { useEffect, useMemo, useState } from "react";
import { Typography, TextField, Box, FormGroup, InputAdornment, FormHelperText } from "@mui/material";
import { SelectButton } from "components";
import StepperControls from "../StepperControls";
import { useCampaignContext } from "..";
import { AvailOptions, CirculationOptions, DurationOptions, QunatityOptions } from "constants/options";
import { Col, Row } from "reactstrap";
import { MyDropzone } from "components";
import SelectImage from "components/atoms/Cards/SelectImage";
import { isObjectsAreEqual } from "utils";
import { validateImageFileType } from "constants/yupSchema";
import { NumericFormat } from "react-number-format";

const MoreInfo = () => {
  const {
    isEditable,
    handleSetValues,
    formData: { moreInfo, requirements },
  } = useCampaignContext();
  const [error, setError] = useState(false);
  const [selected, setSelected] = useState(moreInfo);
  const [disabled, setDisabled] = useState(isEditable ? true : false);

  const ImageOptions = useMemo(() => {
    const response = [];
    Object.values(requirements).forEach((requirement) =>
      requirement?.data?.forEach((product) => response.push(product?.image))
    );
    return response;
  }, [requirements]);

  const handleUploadImage = (key, value) => setSelected((state) => ({ ...state, [key]: value }));

  const handleOnSubmit = () => {
    if (!selected?.product_image && ImageOptions?.length)
      handleSetValues("moreInfo", {
        ...selected,
        product_image: ImageOptions[0],
      });
    else handleSetValues("moreInfo", selected);
  };

  useEffect(() => {
    if (isEditable && isObjectsAreEqual(selected, moreInfo)) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [selected, moreInfo, isEditable]);

  useEffect(() => {
    if (!validateImageFileType(selected?.product_image)) {
      setError(true);
      setDisabled(true);
    } else {
      setError(false);
      setDisabled(false);
    }
  }, [selected?.product_image]);

  return (
    <div data-aos="fade-left" className="gap-4 d-flex flex-column">
      <Typography variant="h5">
        Does the campaign offer have a<span className="fw-semibold"> rolling expiration</span> period?
      </Typography>
      <Box className="d-flex gap-2">
        <SelectButton
          title="Yes"
          selected={selected?.rollingExpiration}
          onClick={() => setSelected((state) => ({ ...state, rollingExpiration: "Yes" }))}
        />
        <SelectButton
          title="No"
          selected={selected?.rollingExpiration}
          onClick={() =>
            setSelected((state) => ({
              ...state,
              rollingExpiration: "No",
              rollingExpirationDuration: null,
            }))
          }
        />
      </Box>
      {selected?.rollingExpiration === "Yes" ? (
        <div className="d-flex flex-column gap-2">
          <Typography variant="h5">
            How many days after <span className="fw-semibold">deposit</span> will the campaign offer be available?
          </Typography>
          <div className="d-flex gap-2 flex-wrap align-items-center">
            {DurationOptions?.map((item) => (
              <div key={item}>
                <SelectButton
                  selected={selected?.rollingExpirationDuration?.select ?? null}
                  title={item}
                  onClick={() =>
                    setSelected((state) => ({
                      ...state,
                      rollingExpirationDuration: { select: item, input: "" },
                    }))
                  }
                />
              </div>
            ))}
            <Typography className="mx-4 text-secondary" variant="body1">
              or
            </Typography>
            <div className="d-flex align-items-center gap-2">
              <NumericFormat
                value={selected?.rollingExpirationDuration?.input ?? null}
                onValueChange={(x) => {
                  setSelected((state) => ({
                    ...state,
                    rollingExpirationDuration: { input: x?.value },
                  }));
                }}
                placeholder="e.g. 100"
                decimalScale={0}
                fixedDecimalScale
                customInput={TextField}
                allowNegative={false}
                InputProps={{ inputProps: { min: 0 } }}
                sx={{ width: 100 }}
              />
              <Typography variant="body2" color="gray">
                Days
              </Typography>
            </div>

            {/* <TextField
              type="number"
              placeholder="Enter Days"
              value={selected?.rollingExpirationDuration?.input ?? null}
              onWheel={(e) => e.target.blur()}
              onChange={(e) => {
                if (e.target.value >= 0) {
                  setSelected((state) => ({
                    ...state,
                    rollingExpirationDuration: { input: e.target.value },
                  }));
                }
              }}
            /> */}
          </div>
        </div>
      ) : null}
      <Typography variant="h5">
        What is the <span className="fw-semibold">estimated circulation</span> number for your campaign?
      </Typography>
      <div className="d-flex gap-2 flex-wrap align-items-center">
        {CirculationOptions?.map((item) => (
          <SelectButton
            selected={selected?.estimatedCirculation?.select ?? null}
            title={item}
            onClick={() =>
              setSelected((state) => ({
                ...state,
                estimatedCirculation: { select: item, input: "" },
              }))
            }
          />
        ))}
        <Typography className="mx-4 text-secondary" variant="body1">
          or
        </Typography>
        <NumericFormat
          value={selected?.estimatedCirculation?.input ?? null}
          onValueChange={(x) => {
            setSelected((state) => ({
              ...state,
              estimatedCirculation: { input: x?.value },
            }));
          }}
          placeholder="Enter Circulation"
          decimalScale={0}
          fixedDecimalScale
          customInput={TextField}
          allowNegative={false}
          InputProps={{ inputProps: { min: 0 } }}
          // sx={{ width: 100 }}
        />
        {/* <TextField
          type="number"
          value={selected?.estimatedCirculation?.input ?? null}
          placeholder="Enter Circulation"
          onWheel={(e) => e.target.blur()}
          onChange={(e) => {
            if (e.target.value >= 0) {
              setSelected((state) => ({
                ...state,
                estimatedCirculation: { input: e.target.value },
              }));
            }
          }}
        /> */}
      </div>
      <Typography variant="h5">
        What is the <span className="fw-semibold">maximum quantity of items</span> that this offer applies to? (e.g. up
        to 5 egg cartons)
      </Typography>
      <div className="d-flex gap-2 flex-wrap align-items-center">
        {QunatityOptions?.map((item) => (
          <SelectButton
            selected={selected?.max_quantity_to_group?.select ?? null}
            title={item}
            onClick={() =>
              setSelected((state) => ({
                ...state,
                max_quantity_to_group: { select: item, input: "" },
              }))
            }
          />
        ))}
        <Typography className="mx-4 text-secondary" variant="body1">
          or
        </Typography>
        <NumericFormat
          value={selected?.max_quantity_to_group?.input ?? null}
          onValueChange={(x) => {
            setSelected((state) => ({
              ...state,
              max_quantity_to_group: { input: x?.value },
            }));
          }}
          placeholder="Enter Quantity"
          decimalScale={0}
          fixedDecimalScale
          customInput={TextField}
          allowNegative={false}
          InputProps={{ inputProps: { min: 0 } }}
          // sx={{ width: 100 }}
        />
        {/* <TextField
          type="number"
          value={selected?.max_quantity_to_group?.input ?? null}
          placeholder="Enter Quantity"
          onWheel={(e) => e.target.blur()}
          onChange={(e) => {
            if (e.target.value >= 0) {
              setSelected((state) => ({
                ...state,
                max_quantity_to_group: { input: parseInt(e.target.value) },
              }));
            }
          }}
        /> */}
      </div>
      <Typography variant="h5">
        What is the
        <span className="fw-semibold"> maximum number of coupons</span> a user can{" "}
        <span className="fw-semibold">clip from this campaign </span>?
      </Typography>
      <div className="d-flex gap-2 flex-wrap align-items-center">
        {AvailOptions?.map((item) => (
          <SelectButton
            selected={selected?.max_limit_to_avail_offer?.select ?? null}
            title={item}
            onClick={() =>
              setSelected((state) => ({
                ...state,
                max_limit_to_avail_offer: { select: item, input: "" },
              }))
            }
          />
        ))}
        <Typography className="mx-4 text-secondary" variant="body1">
          or
        </Typography>
        <NumericFormat
          value={selected?.max_limit_to_avail_offer?.input ?? null}
          onValueChange={(x) => {
            setSelected((state) => ({
              ...state,
              max_limit_to_avail_offer: { input: x?.value },
            }));
          }}
          placeholder="Enter Limit"
          decimalScale={0}
          fixedDecimalScale
          customInput={TextField}
          allowNegative={false}
          InputProps={{ inputProps: { min: 0 } }}
          // sx={{ width: 100 }}
        />
        {/* <TextField
          type="number"
          value={selected?.max_limit_to_avail_offer?.input ?? null}
          InputProps={{
            endAdornment: <InputAdornment position="end">times</InputAdornment>,
          }}
          placeholder="Enter Limit"
          onWheel={(e) => e.target.blur()}
          onChange={(e) => {
            if (e.target.value >= 0) {
              setSelected((state) => ({
                ...state,
                max_limit_to_avail_offer: { input: parseInt(e.target.value) },
              }));
            }
          }}
        /> */}
      </div>
      <Typography variant="h5">
        Is this coupon specific
        <span className="fw-semibold"> for your store?</span>
      </Typography>
      <Box className="d-flex gap-2">
        <SelectButton
          title="Yes"
          selected={selected?.store_coupon}
          onClick={() => setSelected((state) => ({ ...state, store_coupon: "Yes" }))}
        />
        <SelectButton
          title="No"
          selected={selected?.store_coupon}
          onClick={() =>
            setSelected((state) => ({
              ...state,
              store_coupon: "No",
            }))
          }
        />
      </Box>
      <Row className="product-adding">
        <Col xl="8">
          <Typography variant="h5">
            What are the <span className="fw-semibold">terms and conditions</span>?
          </Typography>
          <Box className="d-flex gap-2 mt-3">
            <TextField
              fullWidth
              value={selected?.terms}
              onChange={(e) => {
                setSelected((state) => ({
                  ...state,
                  terms: e?.target?.value,
                }));
              }}
              multiline
              rows={5}
              sx={{
                "& .MuiInputBase-input": {
                  padding: "0!important",
                },
              }}
              placeholder="Describe your terms and conditions or provide an external link"
            />
          </Box>
        </Col>
      </Row>
      <Typography variant="h5">
        What will be the main image{" "}
        <span featured className="fw-semibold">
          on the coupon?
        </span>
      </Typography>
      <Row className="product-adding">
        <Col xl="8">
          <div className="digital-add needs-validation">
            <FormGroup>
              <MyDropzone
                setValue={handleUploadImage}
                trigger={() => {}}
                value={selected?.product_image}
                id="product_image"
              />
              {error ? (
                <FormHelperText error>Unsupported file format. Please upload a JPG, JPEG, or PNG image.</FormHelperText>
              ) : null}
              <div className="d-flex gap-2 my-2">
                {ImageOptions?.map((item) => (
                  <SelectImage
                    id="product_image"
                    isS3
                    setValue={handleUploadImage}
                    isSelected={selected?.product_image === item}
                    src={item}
                  />
                ))}
              </div>
            </FormGroup>
          </div>
        </Col>
      </Row>

      <StepperControls onClickNext={handleOnSubmit} isDisabled={!selected?.estimatedCirculation || disabled} />
    </div>
  );
};

export default MoreInfo;
