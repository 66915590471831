import { Button, IconButton, Typography } from "@mui/material";
import { LoadingWrapper, ORDivider, PreviousCampaignCard } from "components";
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useCampaignContext } from ".";
import { useGetCampaignsList, useGetProviders, useGetRetailers } from "queries/campaign";
import { handleCampaignDataForEdit } from "./CreatePayload";
import { useNavigate } from "react-router";
import { Pathnames } from "routes/Pathnames";
import WestIcon from "@mui/icons-material/West";
import { Dummy } from "constants";

const ChooseCampaign = ({ setSelectedCampaign, selectedCampaign, seiIsStepperOpen, handleDefaultAssignments }) => {
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  const [swiper, setSwiper] = useState(null);
  const { setFormData, InitialState, setActiveStep } = useCampaignContext();

  const { data: campaigns, isFetching } = useGetCampaignsList();
  const { data: allProviders } = useGetProviders();
  const { data: allRetailers } = useGetRetailers();

  const handleOnSelect = (index, campaign) => {
    setSelectedCampaign(index);
    handleCampaignDataForEdit(campaign, setFormData, true, allProviders, allRetailers);
    setActiveStep(5);
    seiIsStepperOpen(true);
  };

  const handleOnCreateCampaign = () => {
    setFormData((state) => ({ ...InitialState, providers: state?.providers, retailer: state?.retailers }));
    handleDefaultAssignments();
    setSelectedCampaign(null);
    seiIsStepperOpen(true);
  };

  // const handleNextButton = () => {
  //   if (selectedCampaign !== null) {
  //   } else {
  //     setActiveStep(0);
  //   }
  //   seiIsStepperOpen(true);
  // };

  useEffect(() => {
    if (swiper)
      swiper.on("slideChange", (e) => {
        setProgress(e.progress);
      });
  }, [swiper]);

  useEffect(() => {
    if (!isFetching && !campaigns?.baseGs1s?.length) {
      seiIsStepperOpen(true);
    }
  }, [isFetching, campaigns]);

  useEffect(() => {
    setSelectedCampaign(null);
  }, []);

  return (
    <div data-aos="fade-left" className="gap-4 d-flex flex-column">
      <Typography variant="h6">
        <IconButton onClick={() => navigate(Pathnames.LIST_CAMPAIGN)}>
          <WestIcon />
        </IconButton>{" "}
        Choose from previous campaigns
      </Typography>

      <LoadingWrapper isLoading={isFetching} style={{ height: 200 }}>
        {campaigns?.baseGs1s && campaigns?.baseGs1s?.length !== 0 && (
          <>
            <div className="position-relative" style={{ width: "100%" }}>
              <IconButton
                disabled={progress === 0}
                onClick={() => swiper.slidePrev()}
                style={{ zIndex: 10, position: "absolute", top: 75 }}
              >
                <ArrowBackIosNewIcon />
              </IconButton>
              <IconButton
                disabled={progress === 1}
                onClick={() => swiper.slideNext()}
                style={{ zIndex: 10, position: "absolute", top: 75, right: 0 }}
              >
                <ArrowForwardIosIcon />
              </IconButton>
              <Swiper className="mt-4 mx-4" slidesPerView={"auto"} onSwiper={(s) => setSwiper(s)}>
                {campaigns?.baseGs1s?.map((campaign, i) => (
                  <SwiperSlide
                    className="w-auto"
                    key={i}
                    onClick={() => {
                      handleOnSelect(i, campaign);
                    }}
                  >
                    <PreviousCampaignCard isSelected={selectedCampaign === i} campaign={campaign} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>

            <ORDivider />
            <div className="d-flex justify-content-center">
              <Button variant="roundedContained" onClick={handleOnCreateCampaign}>
                CREATE A NEW CAMPAIGN
              </Button>
            </div>
          </>
        )}
      </LoadingWrapper>
    </div>
  );
};

export default ChooseCampaign;
