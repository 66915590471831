import WestIcon from "@mui/icons-material/West";

import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Label } from "reactstrap";
import { CampaignCard, LoadingWrapper, NoResultsFoundWrapper } from "components";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useMemo, useRef, useState } from "react";
import { filteredDataOnSearch } from "utils";
import { searchKeys } from "constants";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getCampaignsList, useGetCampaignsList } from "queries/campaign";
import { QueryKeys } from "constants";
import { useGetUserDetails } from "queries/auth";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useIntersection } from "@mantine/hooks";
import { Filters } from "../ListCampaign";
import TuneIcon from "@mui/icons-material/Tune";

const EditCampaignsDrawer = ({ open, selected, handleOnSelect, handleClose }) => {
  const lastRef = useRef(null);
  const [search, setSearch] = useState("");
  const [openFilters, setOpenFilters] = useState(false);
  const [params, setParams] = useState({
    limit: 25,
    lastEvaluatedKey: null,
    status: "Active",
    brand_id: null,
    start_date: null,
    end_date: null,
  });
  const { data: userDetails } = useGetUserDetails();

  const { ref, entry } = useIntersection({
    root: lastRef.current,
    threshold: 1,
  });

  const {
    data: campaigns,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
    dataUpdatedAt,
  } = useInfiniteQuery({
    queryKey: [
      QueryKeys.LIST_CAMPAIGNS,
      userDetails?.current_enterprise?.id,
      "GALLERY",
      params?.limit,
      params?.lastEvaluatedKey,
      params?.status,
      params?.brand_id,
      params?.start_date,
      params?.end_date,
    ],
    queryFn: async ({ pageParam = null }) => {
      const response = await getCampaignsList({
        lastEvaluatedKey: pageParam,
        limit: params?.limit,
        status: params?.status,
        brand_id: params?.brand_id,
        start_date: params?.start_date,
        end_date: params?.end_date,
      });
      return response;
    },
    getNextPageParam: (lastPage) => {
      if (lastPage?.LastEvaluatedKey) {
        const lastEvaluatedKey = encodeURIComponent(JSON.stringify(lastPage?.LastEvaluatedKey));
        // setParams((state) => ({
        //   ...state,
        //   lastEvaluatedKey,
        // }));
        return lastEvaluatedKey;
      }

      return undefined;
    },
    enabled: !!userDetails?.current_enterprise?.id,
  });

  const filteredCampaigns = useMemo(() => {
    const data = campaigns?.pages
      ? campaigns?.pages
          ?.flatMap((x) => x?.baseGs1s)
          ?.filter((x) => !selected?.some((y) => y?.base_gs1 === x?.base_gs1))
      : [];
    return filteredDataOnSearch(data, search, searchKeys.campaigns);
  }, [campaigns, search, selected]);

  const handleUpdateSearch = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    if (entry?.isIntersecting) {
      fetchNextPage();
    }
  }, [entry, fetchNextPage]);

  return (
    <Drawer anchor="right" open={open} onClose={handleClose}>
      <div className="pt-0 position-relative" style={{ width: "600px" }}>
        <div className="position-fixed bg-white z-1">
          <div className="d-flex px-3 align-items-center justify-content-between">
            <IconButton onClick={handleClose} sx={{ marginLeft: "-10px" }}>
              <WestIcon />
            </IconButton>
          </div>
          <div className="d-flex px-3 pb-2 flex-wrap">
            <div className="d-flex justify-content-between align-items-center">
              <Label className="col-form-label">
                Add campaigns you want to include <span className="fw-semibold">in this gallery</span>
                <span className="text-danger">*</span>{" "}
              </Label>
              {/* <Typography variant="body2">{selected?.length} selected</Typography> */}
            </div>
            <TextField
              fullWidth
              value={search}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {!!search?.length ? (
                      <IconButton onClick={() => setSearch("")}>
                        <CloseIcon />
                      </IconButton>
                    ) : null}
                    <IconButton onClick={() => setOpenFilters(true)}>
                      <TuneIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={handleUpdateSearch}
              variant="filled"
              placeholder="Search for campaigns..."
              autoComplete="off"
            />
          </div>
        </div>
      </div>
      <LoadingWrapper isLoading={isLoading}>
        <div className="d-flex flex-column gap-2 px-3 overflow-auto" style={{ width: 600, marginTop: 130 }}>
          {/* <NoResultsFoundWrapper isEmpty={!filteredCampaigns?.length}> */}
          {filteredCampaigns?.map((campaign, i) => {
            return (
              <>
                {i === filteredCampaigns?.length - 1 && <div ref={ref}></div>}
                <CampaignCard
                  isPreview
                  campaign={campaign}
                  key={campaign?.base_gs1}
                  handleOnSelect={() => handleOnSelect(campaign, false, null, false)}
                  isSelected={false}
                />
              </>
            );
          })}

          {filteredCampaigns?.length === 0 && (
            <Typography color="gray.500" align="center">
              No records.
            </Typography>
          )}

          {isFetchingNextPage ? (
            <Box sx={{ height: "100px" }} className="d-flex my-2 w-100 justify-content-center align-items-center">
              <CircularProgress size={20} />
            </Box>
          ) : null}
        </div>
      </LoadingWrapper>
      <Filters open={openFilters} setOpen={setOpenFilters} pagination={params} setPagination={setParams} />
    </Drawer>
  );
};

export default EditCampaignsDrawer;
