import { FormGroup, FormHelperText, TextField } from "@mui/material";
import { MyDropzone } from "components";
import { useFormContext, useWatch } from "react-hook-form";
import { Label } from "reactstrap";

const BasicDetails = ({ setDisabled }) => {
  const {
    trigger,
    register,
    setValue,
    resetField,
    formState: { errors },
    control,
  } = useFormContext();
  const image = useWatch({ control, name: "image" });

  const handleSetValue = (id, value) => {
    setValue(id, value);
    setDisabled(false); // isDirty state doesn't change when using setValue method
  };

  return (
    <div className="d-flex flex-column gap-3">
      <FormGroup>
        <Label className="col-form-label">
          Title<span className="text-danger">*</span>
        </Label>
        <TextField
          {...register("title")}
          type={"text"}
          autoComplete="off"
          fullWidth
          error={!!errors?.title}
          helperText={errors?.title?.message}
        />
      </FormGroup>
      <FormGroup>
        <Label className="col-form-label">Description</Label>
        <TextField
          {...register("description")}
          type={"text"}
          autoComplete="off"
          fullWidth
          multiline={true}
          rows={2}
          error={!!errors?.description}
          helperText={errors?.description?.message}
        />
      </FormGroup>
      <FormGroup>
        <Label className="col-form-label">
          Upload gallery's image<span className="text-danger">*</span>
        </Label>
        <MyDropzone setValue={handleSetValue} trigger={trigger} value={image} id="image" />
        <FormHelperText error={Boolean(errors?.image)}>{errors?.image?.message}</FormHelperText>
      </FormGroup>
    </div>
  );
};

export default BasicDetails;
