import { Table } from "components";
import { useGetUserDetails } from "queries/auth";
import { useGetInvites, usePostInviteUser, usePostRevokeInvite } from "queries/enterprise";
import React, { useCallback, useMemo, useState } from "react";
import ReplayIcon from "@mui/icons-material/Replay";
import { Chip, Typography } from "@mui/material";
import { useToasts } from "react-toast-notifications";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { searchKeys } from "constants";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
const columns = [
  { label: "Name", align: "left", key: "", render: (_, row) => `${row?.first_name} ${row?.last_name}` },
  { label: "Email", align: "left", key: "email" },
  // {
  //   label: "Invite Code",
  //   align: "left",
  //   key: "id",
  //   render: (_, row) => <Typography color="primary.500"></Typography>,
  // },
  {
    label: "Status",
    align: "left",
    key: "invite_status",
    render: (value) => (
      <Chip
        // variant="outlined"
        color={
          value === "Expired"
            ? "warning"
            : value === "Declined" || value === "Revoked"
            ? "error"
            : value === "Accepted"
            ? "success"
            : "default"
        }
        label={value}
      />
    ),
  },
];

const ListInvites = () => {
  const { addToast } = useToasts();
  const [search, setSearch] = useState("");

  const { data: userDetails } = useGetUserDetails();
  const { data: invites, isLoading: isLoadingInvites } = useGetInvites();
  const { mutate: postInviteUser } = usePostInviteUser();
  const { mutate: postRevokeInvite } = usePostRevokeInvite();

  const handleInvite = useCallback((row) => {
    postInviteUser(
      {
        first_name: row?.first_name,
        last_name: row?.last_name,
        email: row?.email,
        role: row?.role,
        enterprise_id: userDetails?.current_enterprise?.id,
      },
      {
        onSuccess: () => {
          addToast("Invitation sent!", {
            appearance: "success",
          });
        },
        onError: (res) =>
          addToast(`Error: ${res?.message}`, {
            appearance: "error",
          }),
      }
    );
  }, []);

  const handleRevokeInvite = useCallback((row) => {
    postRevokeInvite(
      {
        code: row?.id,
      },
      {
        onSuccess: () => {
          addToast("Invitation revoked!", {
            appearance: "success",
          });
        },
        onError: (res) =>
          addToast(`Error: ${res?.message}`, {
            appearance: "error",
          }),
      }
    );
  }, []);

  const actions = useMemo(() => {
    const isExpired = (row) =>
      row?.invite_status === "Expired" || row?.invite_status === "Declined" || row?.invite_status === "Revoked";
    const options = [
      {
        label: (row) => (isExpired(row) ? "Resend Invite" : "Revoke Invite"),
        icon: (row) => (isExpired(row) ? <ReplayIcon /> : <RemoveCircleIcon color="error" />),
        onClick: (row) => (isExpired(row) ? handleInvite(row) : handleRevokeInvite(row)),
      },
      {
        label: () => "Copy Invite",
        icon: () => <ContentCopyIcon />,
        onClick: (row) => {
          navigator.clipboard.writeText(`${window.location.origin}/register?invite_code=${row?.id}`);
          addToast(`Invitation code copied successfully.`, {
            appearance: "info",
          });
        },
      },
    ];
    return options;
  }, [handleInvite, handleRevokeInvite]);
  return (
    <Table
      columns={columns}
      isLoading={isLoadingInvites}
      actions={actions}
      searchKeys={searchKeys.invites}
      search={search}
      handleUpdateSearch={(e) => setSearch(e.target.value)}
      actionsCondition={(row) =>
        row?.invite_status === "Expired" ||
        row?.invite_status === "Declined" ||
        row?.invite_status === "Pending" ||
        row?.invite_status === "Revoked"
      }
      data={invites?.records}
      handleOnClickRow={() => {}}
    />
  );
};

export default ListInvites;
