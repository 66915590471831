import { IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import LeadBackground from "./LeadBackground";
import "./index.scss";
import { useCampaignContext } from "pages/campaign/CreateCampaign";
import { calCampaignDuration } from "pages/campaign/CreateCampaign/CreatePayload";
import { OfferTypes } from "../../PurchaseCriteria";
import { format } from "date-fns";

const Icons = {
  CHECK_MARK: (color) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <rect width="24" height="24" fill="#fff" rx="12"></rect>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
        d="M4.861 12l4.76 4.76 9.518-9.52"
      ></path>
    </svg>
  ),
  INFO: (
    <svg width={12} height={12} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.84.96H2.16a1.2 1.2 0 0 0-1.2 1.2v7.68a1.2 1.2 0 0 0 1.2 1.2h7.68a1.2 1.2 0 0 0 1.2-1.2V2.16a1.2 1.2 0 0 0-1.2-1.2ZM6.48 9.029c0 .055-.046.091-.115.091h-.73c-.07 0-.115-.036-.115-.091V4.89c0-.055.046-.091.115-.091h.73c.07 0 .115.036.115.091V9.03Zm0-5.292c0 .062-.043.103-.11.103h-.742c-.065 0-.108-.04-.108-.103v-.754c0-.062.043-.103.108-.103h.742c.067 0 .11.04.11.103v.754Z"
        fill="#fff"
      />
    </svg>
  ),
  TRASH: (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32">
      <circle cx="16" cy="16" r="15.5" fill="#fff" stroke="#20B75B"></circle>
      <path
        fill="#3D3D3D"
        fillRule="evenodd"
        d="M19.2 10.2h2.4a.8.8 0 01.8.8v.8H9.6V11a.8.8 0 01.8-.8h2.4v-2A1.2 1.2 0 0114 7h4a1.2 1.2 0 011.2 1.2v2zm-4.8-1.6v1.6h3.2V8.6h-3.2zM10.4 13.4h11.2v8.4a1.2 1.2 0 01-1.2 1.2h-8.8a1.2 1.2 0 01-1.2-1.2v-8.4zm3.2 2.4H12v4.8h1.6v-4.8zm3.2 0h-1.6v4.8h1.6v-4.8zm1.6 0H20v4.8h-1.6v-4.8z"
        clipRule="evenodd"
      ></path>
    </svg>
  ),
  OPTIONS: (
    <svg width="21" height="7" viewBox="0 0 21 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.379883 3.49977C0.379883 5.01812 1.62152 6.25977 3.13988 6.25977C4.65824 6.25977 5.89988 5.01812 5.89988 3.49977C5.89988 1.98141 4.65824 0.739765 3.13988 0.739766C1.62152 0.739766 0.379883 1.98141 0.379883 3.49977ZM7.73988 3.49977C7.73988 5.01812 8.98152 6.25977 10.4999 6.25977C12.0182 6.25977 13.2599 5.01812 13.2599 3.49977C13.2599 1.98141 12.0182 0.739765 10.4999 0.739765C8.98152 0.739765 7.73988 1.98141 7.73988 3.49977ZM15.0999 3.49976C15.0999 5.01812 16.3415 6.25976 17.8599 6.25976C19.3782 6.25976 20.6199 5.01812 20.6199 3.49976C20.6199 1.98141 19.3782 0.739765 17.8599 0.739765C16.3415 0.739765 15.0999 1.98141 15.0999 3.49976Z"
        fill="#A9A9A9"
      />
    </svg>
  ),
  ADD_REMOVE_COUPON: (color) => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="15.5" fill="white" />
      <path
        d="M9.91887 10.626L9.84816 10.6967L9.91887 10.7674L15.1515 16L9.91887 21.2326L9.84816 21.3033L9.91887 21.374L10.626 22.0811L10.6967 22.1518L10.7674 22.0811L16 16.8485L21.2326 22.0811L21.3033 22.1518L21.374 22.0811L22.0811 21.374L22.1518 21.3033L22.0811 21.2326L16.8485 16L22.0811 10.7674L22.1518 10.6967L22.0811 10.626L21.374 9.91888L21.3033 9.84817L21.2326 9.91888L16 15.1515L10.7674 9.91888L10.6967 9.84817L10.626 9.91888L9.91887 10.626Z"
        fill={color}
      />
    </svg>
  ),
};

const CouponCard = ({ handleOpenCouponInfo }) => {
  const [selected, setSelected] = useState(false);

  const handleToggle = (e) => {
    e.stopPropagation();
    setSelected((curr) => !curr);
  };

  const {
    formData: { brand, title, campaignDuration, moreInfo, requirements },
  } = useCampaignContext();

  const { _, end_time } = calCampaignDuration(campaignDuration);

  const code = OfferTypes?.[requirements.offer.type]?.code;

  return (
    <div className={`coupon ${selected && "selected"} `} onClick={handleOpenCouponInfo}>
      <div className="coupon-layout">
        <div className="d-flex align-items-center justify-content-center gap-2">
          <img
            src={
              moreInfo?.product_image &&
              (typeof moreInfo?.product_image === "string"
                ? moreInfo?.product_image
                : URL.createObjectURL(moreInfo?.product_image))
            }
            alt={brand?.name}
            className="object-contain"
            height={60}
            width={60}
          />
          <div>
            {true ? (
              <div className="info-btn">
                <IconButton size="small">{Icons.OPTIONS}</IconButton>
              </div>
            ) : null}
            <Typography
              color="error"
              fontFamily={"Ubuntu"}
              variant="subtitle2"
              fontWeight={600}
              className="line-clamp-2"
              sx={{
                lineHeight: "140%",
              }}
            >
              {[0, 1, 6].includes(code) ? `$${requirements.offer.value} off` : title}
            </Typography>
            <Typography fontSize={6}>Expires {format(new Date(end_time), "MM-dd-yyyy")}</Typography>
          </div>
        </div>
      </div>
      <LeadBackground isClipped={selected}>
        <div className="lead-layout">
          <div className="d-flex justify-content-between align-items-start">
            <p className="line-clamp brand-name">{brand?.name}</p>

            <IconButton className="transition" size="small" onClick={handleToggle}>
              {selected ? Icons.CHECK_MARK("#20b75b") : Icons.CHECK_MARK("#969696")}
            </IconButton>
          </div>
        </div>
      </LeadBackground>
    </div>
  );
};

export default CouponCard;
