import { Box, CircularProgress } from "@mui/material";

const LoadingWrapper = ({ children, isLoading, style }) => {
  return (
    <>
      {isLoading ? (
        <Box
          className="d-flex justify-content-center align-items-center"
          sx={
            style
              ? {
                  ...{
                    width: "100%",
                    height: "200px",
                    margin: "auto",
                  },
                  ...style,
                }
              : {
                  width: "100%",
                  height: "200px",
                  margin: "auto",
                }
          }
        >
          <CircularProgress size={25} />
        </Box>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default LoadingWrapper;
