import React from "react";
import { Card, CardBody, Media } from "reactstrap";
import CountUp from "react-countup";

const CountWidget = ({ title, amount, index = 0, isDollar, icon, color }) => {
  return (
    <Card className="o-hidden widget-cards">
      <CardBody className={`bg-${color} p-4`} style={{ background: color }}>
        <Media className="static-top-widget row">
          <div className="icons-widgets col-3">
            <div className="align-self-center text-center">{icon}</div>
          </div>
          <Media body className="col-9">
            <span className="m-0 line-clamp-1 break-all text-white">{title}</span>
            <h2 className="mb-0">
              {isDollar && "$"} <CountUp className="counter" end={amount} />
            </h2>
          </Media>
        </Media>
      </CardBody>
    </Card>
  );
};

export default CountWidget;
