import App from "app";
import { Fragment } from "react";
import { Route, Routes } from "react-router-dom";

import { AppRoutes } from "./AppRoutes";

const LayoutRoutes = () => {
  return (
    <Fragment>
      <Routes>
        <Route element={<App />}>
          {Object.values(AppRoutes).map((route) => (
            <Route
              key={route.pathname}
              path={`${process.env.PUBLIC_URL}${route.pathname}`}
              element={route.component}
            />
          ))}
        </Route>
      </Routes>
    </Fragment>
  );
};

export default LayoutRoutes;
