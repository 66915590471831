import {
    startOfWeek,
    startOfMonth,
    startOfYear,
    today,
    getLocalTimeZone,
    endOfWeek,
    endOfMonth,
    endOfYear,
} from "@internationalized/date";

export function getRangeForOption(option) {
    let date = today(getLocalTimeZone());
    const locale = navigator.language;

    switch (option) {
        case "Today":
            return { start: date, end: date };

        case "Yesterday":
            return {
                start: date.subtract({ days: 1 }),
                end: date.subtract({ days: 1 }),
            };

        case "This week":
            return {
                start: startOfWeek(date, locale),
                end: date,
            };

        case "Last week":
            const lastWeek = date.subtract({ weeks: 1 });

            return {
                start: startOfWeek(lastWeek, locale),
                end: endOfWeek(lastWeek, locale),
            };

        case "This month":
            return { start: startOfMonth(date), end: date };

        case "Last month":
            const lastMonth = date.subtract({ months: 1 });

            return {
                start: startOfMonth(lastMonth),
                end: endOfMonth(lastMonth),
            };

        case "This year":
            return { start: startOfYear(date), end: date };

        case "Last year":
            const lastYear = date.subtract({ years: 1 });

            return { start: startOfYear(lastYear), end: endOfYear(lastYear) };

        default:
            //@ts-ignore
            return { start: null, end: null };
    }
}
