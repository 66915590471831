import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.scss";
import Routers from "./routes";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ThemeProvider } from "@mui/material";
import rootTheme from "styles/PrimaryTheme";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useEffect } from "react";
import AOS from "aos";

import { ToastProvider } from "react-toast-notifications";

const Root = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        // cacheTime: 1000 * 60 * 7,
        // enabled: true,
        retry: 1,
        staleTime: Infinity,
      },
    },
  });

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <React.StrictMode>
      <ToastProvider
        autoDismiss
        autoDismissTimeout={4000}
        placement="top-right"
      >
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={rootTheme}>
            {/* <BrowserRouter basename={"/"}> */}
            <PerfectScrollbar>
              <Routers />
            </PerfectScrollbar>
            {/* </BrowserRouter> */}
          </ThemeProvider>
        </QueryClientProvider>
      </ToastProvider>
    </React.StrictMode>
  );
};

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
