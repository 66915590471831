const QueryKeys = {
  PRODUCTS: "PRODUCTS",
  PRODUCT_DETAILS: "PRODUCT_DETAILS",
  BRANDS: "BRANDS",
  ME: "ME",
  INVITE_CODE: "INVITE_CODE",
  LIST_ENTEPRISES: "LIST_ENTEPRISES",
  ENTERPRISE_DETAILS: "ENTERPRISE_DETAILS",
  LIST_CAMPAIGNS: "LIST_CAMPAIGNS",
  CAMPAIGN_DETAILS: "CAMPAIGN_DETAILS",
  LIST_GALLERY: "LIST_GALLERY",
  GALLERY_DETAILS: "GALLERY_DETAILS",
  CAMPAIGN_PRODUCTS: "CAMPAIGN_PRODUCTS",
  DASHBOARD_ANALYTICS: "DASHBOARD_ANALYTICS",
  PROVIDERS: "PROVIDERS",
  RETAILERS: "RETAILERS",
  MUTATE_CAMPAIGN: "MUTATE_CAMPAIGN",
  MUTATE_PROVIDERS: "MUTATE_PROVIDERS",
  MUTATE_RETAILERS: "MUTATE_RETAILERS",
  ENTERPRISE: "ENTERPRISE",
  ENTERPRISE_REQUESTS: "ENTERPRISE_REQUESTS",
  GALLERY_CAMPAIGNS: "GALLERY_CAMPAIGNS",
};

export default QueryKeys;
