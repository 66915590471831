import React from "react";
import { usePatchProduct, usePostAddProduct } from "queries/products";
import { usePostFileUpload } from "queries/file";
import { useNavigate, useParams } from "react-router";
import { Pathnames } from "routes/Pathnames";

import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { yupSchema } from "constants";
import { useToasts } from "react-toast-notifications";
import { uploadToS3Bucket } from "services";
import { useGetUserDetails } from "queries/auth";
import { useGetEnterpriseDetails } from "queries/enterprise";

const useFormHanlders = (isEditable, handleOnSuccess, initialState) => {
  const formMethods = useForm({
    resolver: yupResolver(yupSchema.ADD_PRODUCT),
    defaultValues: {
      name: "",
      description: "",
      upc_gtins: "",
      ean_gtins: "",
      brand: initialState?.brand,
      purchase_prefixed_code: [
        {
          type: "PLU",
          value: "",
        },
      ],
      image: "",
    },
  });

  const { control, handleSubmit } = formMethods;

  const fieldMethods = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "purchase_prefixed_code", // unique name for your Field Array
  });

  const params = useParams();
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const { mutateAsync: postAddProduct, isLoading: isLoadingAdd } = usePostAddProduct();
  const { mutateAsync: patchProduct, isLoading: isLoadingEdit } = usePatchProduct();
  const { data: userDetails } = useGetUserDetails();
  const { data: enterpriseDetails } = useGetEnterpriseDetails();

  const handlePayload = (formData) => {
    const purchase_prefixed_code =
      formData?.purchase_prefixed_code?.filter((x) => x?.value)?.length === 0
        ? null
        : formData?.purchase_prefixed_code?.filter((x) => x?.value);

    let payload2 = {
      ...formData,
      purchase_prefixed_code,
      email_domain: enterpriseDetails?.email_domain,
      brand_id: formData?.brand?.internal_id,
      brand: formData?.brand?.name,
    };

    return payload2;
  };

  const handleOnSuccessAddProduct = () => {
    if (handleOnSuccess) handleOnSuccess();
    else {
      navigate(Pathnames.LIST_PRODUCTS);
      addToast("Successfully added product", {
        appearance: "success",
      });
    }
  };

  const handleOnSuccessUpdateProduct = () => {
    navigate(Pathnames.LIST_PRODUCTS);
    addToast("Successfully updated product details", {
      appearance: "success",
    });
  };

  const handleAddProduct = (formData) => {
    const payload = {
      image_type: "product_image",
      file: formData?.image,
    };

    uploadToS3Bucket(payload, userDetails?.current_enterprise?.id).then((productUrl) => {
      formData.image = productUrl;
      const payload2 = handlePayload(formData);

      postAddProduct(payload2)
        .then(handleOnSuccessAddProduct)
        .catch((err) => console.error(err));
    });
  };

  const handleUpdateProduct = (formData) => {
    if (typeof formData?.image === "string") {
      const payload2 = handlePayload(formData);
      patchProduct({ data: payload2, id: params?.product_id })
        .then(handleOnSuccessUpdateProduct)
        .catch((err) => console.error(err));
    } else {
      const payload = {
        image_type: "product_image",
        file: formData?.image,
      };
      uploadToS3Bucket(payload, userDetails?.current_enterprise?.id).then((productUrl) => {
        formData.image = productUrl;
        const payload2 = handlePayload(formData);

        patchProduct({ data: payload2, id: params?.product_id })
          .then(handleOnSuccessUpdateProduct)
          .catch((err) => console.error(err));
      });
    }
  };

  const handleOnSubmit = async (formData) => {
    if (!isEditable) handleAddProduct(formData);
    else handleUpdateProduct(formData);
  };

  return {
    isLoading: isLoadingAdd || isLoadingEdit,
    handleOnSubmit,
    formMethods,
    handleSubmit,
    fieldMethods,
  };
};

export default useFormHanlders;
