import { Button, Typography } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router";
import { AppRoutes } from "routes/AppRoutes";
import { useCampaignContext } from "..";
import { QRCode } from "react-qrcode-logo";
import { QRCodeDisplay } from "components";

const Congratulations = () => {
  const {
    formData: { base_gs1, link, title, description },
  } = useCampaignContext();
  return (
    <div className="d-flex justify-content-center flex-column align-items-center my-5" data-aos="fade-down">
      <Typography variant="h4" align="center" fontWeight={600} width={500}>
        CONGRATULATIONS
      </Typography>
      {/* <h3 className="w-50 text-center">
        Your campaign details have been successfully submitted for approval. Our
        team will review them thoroughly and provide you with updates soon.
      </h3> */}
      <Typography variant="h6" align="center" fontWeight={400} width={500}>
        on successfully creating a campaign!
      </Typography>
      <QRCodeDisplay title={title} description={description} link={link} />
    </div>
  );
};

export default Congratulations;
