import React from "react";

import "./SelectImage.scss";

const SelectImage = ({ setValue, isSelected, src, isS3, id }) => {
  const handleStaticImage = (src) => {
    const img = new Image();
    img.src = src; // Replace 'image.jpg' with the URL of your image

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      context.drawImage(img, 0, 0);

      // Convert canvas to Blob
      canvas.toBlob((blob) => {
        // Use the Blob as needed
        // e.g., upload to a server, save to file, etc.
        setValue("image", blob);
      });
    };
  };
  return (
    <div
      className={`select-image ${isSelected && "selected-image"}`}
      onClick={() => (isS3 ? setValue(id, src) : handleStaticImage(src))}
    >
      <img src={src} alt="" />
    </div>
  );
};

export default SelectImage;
