import { ClickAwayListener, ListItemText, ListItemIcon, MenuItem, MenuList, Paper, Popper, Grow } from "@mui/material";
import React, { useState } from "react";

const DropdownWrapper = ({
  options,
  selectedKey,
  selected,
  handleOnClick,
  children,
  style,
  render,
  disabled,
  placementGrow,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (item) => {
    if (handleOnClick) handleOnClick(item);
    handleClose();
  };

  return (
    <>
      <div
        onClick={handleOpen}
        ref={anchorEl}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        style={style}
      >
        {children}
      </div>
      <Popper
        open={disabled ? false : !!open}
        anchorEl={anchorEl}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        sx={{ zIndex: 100 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placementGrow
                ? placementGrow
                : placement === "bottom-start"
                ? "left top"
                : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  //   onKeyDown={handleListKeyDown}
                >
                  {options?.map((item, i) =>
                    render ? (
                      render(item, i, selected?.[selectedKey] === item?.[selectedKey], handleClick)
                    ) : (
                      <MenuItem
                        selected={selected?.[selectedKey] === item?.[selectedKey]}
                        onClick={() => handleClick(item)}
                        sx={{ width: "100%!important" }}
                      >
                        {item?.icon && <ListItemIcon>{item?.icon}</ListItemIcon>}
                        <ListItemText>{item?.label}</ListItemText>
                      </MenuItem>
                    )
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default DropdownWrapper;
