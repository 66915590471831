import { InputAdornment, TextField } from "@mui/material";
import React from "react";
import { useCriteriaContext } from ".";
import { NumericFormat } from "react-number-format";

const CustomTextField = ({ type, value, item, error, decimalNotAllowed }) => {
  const { handleSetValue } = useCriteriaContext();

  const InputProps = {
    inputProps: { min: 1 },
    startAdornment: type?.iconType === "start" && <InputAdornment>{type?.icon}</InputAdornment>,
    endAdornment: type?.iconType === "end" && <InputAdornment>{type?.icon}</InputAdornment>,
  };

  const sx =
    type?.iconType === "start"
      ? {
          "& .MuiInputBase-input": {
            pl: "8px !important",
          },
        }
      : null;

  if (decimalNotAllowed)
    return (
      <NumericFormat
        value={value}
        onValueChange={(x) => {
          handleSetValue(x?.value, "value", item);
          handleSetValue(null, "error", item);
        }}
        isAllowed={(values) => values?.value?.length <= 5}
        placeholder={type?.placeholder}
        decimalScale={0}
        fixedDecimalScale
        customInput={TextField}
        allowNegative={false}
        InputProps={InputProps}
        helperText={error}
        error={error}
        sx={sx}
      />
    );

  return (
    <>
      {type?.iconType && type?.iconType === "start" ? (
        <NumericFormat
          value={value}
          onValueChange={(x) => {
            handleSetValue(x?.value, "value", item);
            handleSetValue(null, "error", item);
          }}
          isAllowed={(values) => values?.value?.length <= 5}
          placeholder={type?.placeholder}
          decimalScale={2}
          fixedDecimalScale
          allowNegative={false}
          customInput={TextField}
          InputProps={InputProps}
          helperText={error}
          error={error}
          sx={sx}
        />
      ) : (
        <TextField
          value={value}
          onChange={(e) => {
            if (!e.target.value?.includes("-")) {
              handleSetValue(e.target.value, "value", item);
              handleSetValue(null, "error", item);
            }
          }}
          placeholder={type?.placeholder}
          helperText={error}
          error={error}
          InputProps={InputProps}
          sx={sx}
        />
      )}
    </>
  );
};

export default CustomTextField;
