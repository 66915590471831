import { Card, CardBody } from "reactstrap";
import { SEOHelmet, Table } from "components";
import React from "react";
import { Avatar, Button } from "@mui/material";
import { AppRoutes } from "routes/AppRoutes";
import { Link } from "react-router-dom";

const columns = [
  { label: "Avatar", align: "left" },
  { label: "Name", align: "left" },
  { label: "Stores", align: "left" },
  { label: "Users", align: "left" },
  { label: "Actions", align: "center" },
];

const data = [
  [<Avatar>G</Avatar>, "GC16", 10, 1],
  [<Avatar>G</Avatar>, "GC15", 9, 2],
  [<Avatar>G</Avatar>, "GC14", 8, 3],
];

const ListGroceryChains = () => {
  return (
    <>
      <Card>
        <CardBody>
          <div>
            <Table
              columns={columns}
              data={data}
              toolbar={
                <Link to={AppRoutes.CREATE_GROCERY_CHAIN.pathname}>
                  <Button>CREATE</Button>
                </Link>
              }
            />
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default ListGroceryChains;
