import { getYear } from "date-fns";
import React from "react";
import { Col, Container, Row } from "reactstrap";

const Footer = () => {
  return (
    <div data-no-print>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md="6" className="footer-copyright">
              <p className="mb-0">Copyright {getYear(new Date())} © Gitsy All rights reserved.</p>
            </Col>
            <Col md="6"></Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
};

export default Footer;
