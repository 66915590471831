import React, { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
//images import
import man from "assets/images/dashboard/man.png";
import { useGetUserDetails, usePostLogout } from "queries/auth";
import { Pathnames } from "routes/Pathnames";
import { Avatar } from "@mui/material";

const UserMenu = () => {
  const navigate = useNavigate();
  const { mutate: postLogout } = usePostLogout();
  const { data: userDetails } = useGetUserDetails();
  const handleLogout = () => {
    postLogout(null, {
      onSuccess: () => navigate(Pathnames.LOGIN),
    });
  };
  return (
    <Fragment>
      <li className="onhover-dropdown">
        <div className="media align-items-center">
          {/* <img
            className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded"
            src={man}
            alt="header-user"
          /> */}
          <Avatar
            src={userDetails?.image_path}
            alt={`${userDetails?.first_name + userDetails?.last_name}`}
            sx={{ bgcolor: "primary.100", color: "primary.400", height: "50px", width: "50px" }}
            height={120}
            width={120}
            variant="circular"
          />
          <div className="dotted-animation">
            <span className="animate-circle"></span>
            <span className="main-circle"></span>
          </div>
        </div>
        <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
          <li>
            <Link to={Pathnames.MY_PROFILE}>
              <i data-feather="user"></i>Edit Profile
            </Link>
          </li>
          {/* <li>
            <i data-feather="mail"></i>Inbox
          </li> */}
          <li>
            <i data-feather="lock"></i>Lock Screen
          </li>
          {/* <li>
            <i data-feather="settings"></i>Settings
          </li> */}
          <li onClick={handleLogout}>
            <i data-feather="log-out"></i>Logout
          </li>
        </ul>
      </li>
    </Fragment>
  );
};

export default UserMenu;
