import * as React from "react";
const ExcelIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 512 512" {...props}>
    <path
      fill="#23A566"
      d="M419.393 512.726C311.313 513 203.625 513 95.469 513c-8.1-1.882-14.608-5.361-19.302-12.015-4.067-5.767-6.581-11.866-6.502-19.013.079-7.164.005-14.332.005-21.498 0-141.496.006-282.992-.035-424.487-.002-6.585.31-13.02 3.813-18.827C78.26 9.176 84.409 2.92 94.606 1.275 164.021 1 233.042 1 302.531 1c.626.25.784.5 1.06 1.176.813 5.056.725 9.719.724 14.38-.003 29.488-.032 58.976.01 88.465.024 16.868 6.654 26.645 22.596 33.194 7.537 7.363 16.02 12.954 23.823 19.367 18.73 15.392 37.613 30.602 56.455 45.859 11.613 9.404 23.272 18.751 34.815 28.243.655.539 1.238 1.096 1.786 2.238.529 2.879.52 5.379.52 7.876.018 79.42-.05 158.84.078 238.26.02 11.797-4.694 20.733-13.61 28.033-3.308 2.709-7.233 3.18-11.395 4.635z"
    />
    <path
      fill="#FCFEFD"
      d="M347.3 412.029c-2.504 4.412-6.198 5.853-10.661 5.853-52.996-.002-105.99.023-158.986-.026-7.967-.007-11.961-4.04-11.97-12.084a57403.8 57403.8 0 0 1 .004-137.987c.01-8.089 3.917-11.846 12.1-11.851 52.828-.032 105.657-.033 158.485 0 7.97.006 12.177 3.947 12.192 11.902.09 46.162.052 92.325-.004 138.487-.003 1.789-.634 3.577-1.16 5.706z"
    />
    <path
      fill="#8CD0B0"
      d="M302.756 2.176c42.436 41.77 84.587 83.845 126.71 125.949 4.21 4.209 8.25 8.588 12.366 13.215-1.359 2.774-3.741 2.9-6.094 2.911-32.652.152-65.305.117-97.958.047-3.667-.007-6.643-2.01-10.383-3.198-19.234-8.069-25.357-17.235-25.358-37.724-.003-31.648-.009-63.295.017-94.943.002-1.981.265-3.962.7-6.257z"
    />
    <path
      fill="#1C8653"
      d="M327.18 141.087c16.285 1.41 32.787.397 49.266.534 21.486.179 42.975.055 64.923.053.493 30.145.526 60.296.474 90.914-6.007-1.743-10.13-6.494-14.882-10.282-18.804-14.987-37.413-30.218-56.07-45.39a6662.453 6662.453 0 0 1-43.71-35.83z"
    />
    <path
      fill="#29A76A"
      d="M186.691 279.14c.49-3.126 2.61-2.092 4.023-2.098 17.3-.073 34.602.026 51.902-.115 3.055-.025 4.074.806 3.961 3.9-.217 5.98-.161 11.977-.02 17.963.065 2.698-.67 3.854-3.615 3.836-17.632-.107-35.267-.092-52.9-.014-2.637.012-3.47-.97-3.42-3.521.127-6.485.046-12.975.069-19.95zM282.007 276.995c14.138 0 27.778.084 41.416-.066 3.001-.033 4.106.711 3.983 3.86-.234 5.978-.161 11.975-.026 17.96.06 2.659-.604 3.892-3.578 3.875-17.63-.105-35.263-.09-52.894-.01-2.597.011-3.514-.883-3.461-3.473.129-6.318.163-12.644-.012-18.96-.078-2.828 1.176-3.33 3.596-3.225 3.486.152 6.984.039 10.976.039z"
    />
    <path
      fill="#23A566"
      d="M212 323.58c10.497 0 20.496.094 30.49-.054 2.99-.045 4.145.794 4.045 3.933-.207 6.491-.134 12.995-.03 19.491.036 2.332-.713 3.214-3.14 3.204-17.828-.077-35.656-.095-53.484.014-2.83.017-3.271-1.22-3.232-3.598.104-6.33.225-12.67-.041-18.99-.149-3.522 1.363-4.126 4.4-4.051 6.827.168 13.661.051 20.992.05zM304 323.58c6.665 0 12.833.124 18.993-.05 3.04-.087 4.547.53 4.4 4.05-.267 6.32-.146 12.661-.042 18.991.04 2.38-.405 3.614-3.233 3.597-17.828-.109-35.656-.091-53.483-.014-2.43.01-3.176-.874-3.14-3.205.103-6.496.172-13-.028-19.491-.097-3.125 1.056-3.973 4.044-3.932 10.661.144 21.326.055 32.49.054z"
    />
    <path
      fill="#28A769"
      d="M186.664 386.972c.406-5.409-2.13-11.743 1.116-14.872 2.993-2.885 9.217-.779 14.011-.818 13.496-.109 26.993.055 40.488-.103 3.29-.04 4.388 1.006 4.275 4.271-.208 5.991-.138 11.996-.028 17.992.047 2.552-.84 3.491-3.467 3.478a5286.897 5286.897 0 0 0-52.984.002c-2.628.014-3.61-.892-3.434-3.46.137-1.99.025-3.997.023-6.49zM297 371.244c8.997 0 17.496.096 25.99-.05 3.037-.052 4.587.521 4.42 4.076-.28 5.985-.196 11.995-.026 17.988.082 2.868-.951 3.689-3.754 3.672-17.493-.106-34.987-.11-52.48.002-2.91.018-3.747-1.044-3.685-3.78.135-5.995.17-12-.012-17.991-.094-3.085 1.042-4.03 4.057-3.971 8.327.162 16.66.055 25.49.054z"
    />
  </svg>
);
export default ExcelIcon;
