import { useMemo, useState } from "react";
import { useGetProviders, useGetRetailers } from "queries/campaign";

const useProviderRetailerAssignments = (providers, retailers, handleSetValues) => {
  const [openSideDrawer, setOpenSideDrawer] = useState({
    search: "",
    key: null,
    isOpen: false,
    title: null,
  });

  const { data: allProviders, isLoading: isLoadingProviders } = useGetProviders();
  const { data: allRetailers, isLoading: isLoadingRetailers } = useGetRetailers();

  const { data, isLoading } = useMemo(() => {
    if (openSideDrawer?.key === "providers")
      return {
        data: allProviders?.data,
        isLoading: isLoadingProviders,
      };
    else
      return {
        data: allRetailers?.data,
        isLoading: isLoadingRetailers,
      };
  }, [allProviders, allRetailers, openSideDrawer, isLoadingProviders, isLoadingRetailers]);

  const handleOpen = (type) => {
    setOpenSideDrawer({
      search: "",
      key: type,
      isOpen: true,
      title: `Assign ${type}`,
    });
  };

  const handleClose = () =>
    setOpenSideDrawer({
      search: "",
      key: null,
      isOpen: false,
      title: "",
    });

  const handleAdd = (item) => {
    if (openSideDrawer?.key === "providers") handleSetValues(openSideDrawer?.key, [...providers, item]);
    else handleSetValues(openSideDrawer?.key, [...retailers, item]);
  };

  const handleRemove = (item) => {
    if (openSideDrawer?.key === "providers")
      handleSetValues(
        openSideDrawer?.key,
        providers.filter((x) => x?.email_domain !== item?.email_domain && x !== item?.email_domain)
      );
    else
      handleSetValues(
        openSideDrawer?.key,
        retailers.filter((x) => x?.email_domain !== item?.email_domain && x !== item?.email_domain)
      );
  };

  const isSelected = (item) => {
    if (openSideDrawer?.key === "providers") {
      return providers?.some((x) => x?.email_domain === item?.email_domain || x === item?.email_domain);
    } else if (openSideDrawer?.key === "retailers")
      return retailers?.some((x) => x?.email_domain === item?.email_domain || x === item?.email_domain);
  };

  return {
    data,
    isLoading,
    handleRemove,
    handleAdd,
    handleClose,
    handleOpen,
    openSideDrawer,
    isSelected,
  };
};

export default useProviderRetailerAssignments;
