import React from "react";
import "./PreviousCampaignCard.scss";
import dummy from "constants/dummy";
import { Radio, Typography } from "@mui/material";

const PreviousCampaignCard = ({ isSelected, campaign }) => {
  return (
    <div className="previous-campaign-card">
      <section>
        <img src={campaign?.campaign_metadata?.desktop_image_url} alt={campaign?.campaign_metadata?.title} />
        <div>
          <Typography fontWeight={500} variant="body1" maxWidth={100} className="line-clamp-2">
            {campaign?.campaign_metadata?.title}
          </Typography>
          <Typography fontWeight={400} variant="caption" color="gray.500">
            {campaign?.brand_name}
          </Typography>
        </div>
      </section>
      <>{dummy.beads}</>
      <action>{/* <Radio size="small" checked={isSelected} color="white" /> */}</action>
    </div>
  );
};

export default PreviousCampaignCard;
