import React from "react";
import CountWidget from "./CountWidget";
import { Col } from "reactstrap";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import RedeemIcon from "@mui/icons-material/Redeem";
import InstallMobileIcon from "@mui/icons-material/InstallMobile";

const PrimaryWidgetsData = [
  {
    title: "Coupon Clips",
    key: "total_clips",
    icon: <LocalOfferIcon sx={{ color: "#0659b2" }} />,
    color: "#0659b2",
  },
  {
    title: "Coupon Redemptions",
    key: "total_redemptions",
    icon: <CurrencyExchangeIcon sx={{ color: "#50a1eb" }} />,
    color: "#50a1eb",
  },
  {
    title: "Total Users",
    key: "total_clips_by_users_count",
    icon: <PeopleAltOutlinedIcon sx={{ color: "#9ad1f9" }} />,
    color: "#9ad1f9",
  },
  // {
  //   title: "Total Installs",
  //   icon: <InstallMobileIcon className="font-warning" />,
  //   color: "warning",
  // },
];

const PrimaryWidgets = ({ data }) => {
  return (
    <>
      {PrimaryWidgetsData?.map((widget, i) => (
        <Col xl="4 xl-50" md="4" className="mb-4" key={i}>
          <CountWidget title={widget?.title} amount={data?.[widget?.key]} icon={widget?.icon} color={widget?.color} />
        </Col>
      ))}
    </>
  );
};

export default PrimaryWidgets;
