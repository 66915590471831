import { useRef } from "react";
import { useDateRangePickerState } from "react-stately";
import { useDateRangePicker } from "react-aria";

import { RangeCalendar } from "./RangeCalendar";

import React, { useState } from "react";
import { ClickAwayListener, Grow, Paper, Popper, TextField, Box, InputAdornment } from "@mui/material";
// import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
// import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { useDateFormatter } from "react-aria";
import { getLocalTimeZone } from "@internationalized/date";

const DateRangePicker = (props) => {
  let ref = useRef(null);
  const [anchorEl, setAnchorEl] = useState(false);
  const handleOpen = (e) => setAnchorEl(e.currentTarget);
  const handleClose = (e) => setAnchorEl(null);

  let state = useDateRangePickerState({
    ...props,
    shouldCloseOnSelect: false,
    // minValue={today(getLocalTimeZone()).subtract({ days: 30 })}
  });

  let { calendarProps } = useDateRangePicker(props, state, ref);

  const handleCancel = () => {
    state.setValue(props.defaultValue);
    handleClose();
  };

  const handleApply = () => {
    props.handleSetValue({ start_date: state.value.start, end_date: state.value.end });
    handleClose();
  };

  let dateFormatter = useDateFormatter({
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  return (
    <>
      <TextField
        placeholder="Start Date - End Date"
        sx={{ width: "320px" }}
        onClick={handleOpen}
        // autoFocusItem={!!anchorEl}
        InputProps={{
          endAdornment: (
            <InputAdornment className="px-2" position="end">
              <DateRangeIcon color="gray" />
            </InputAdornment>
          ),
        }}
        value={
          props.defaultValue?.start && props.defaultValue?.end
            ? `${dateFormatter.format(props.defaultValue?.start?.toDate())} - ${dateFormatter.format(
                props.defaultValue?.end?.toDate()
              )}`
            : ""
        }
      />
      <Popper
        open={!!anchorEl}
        anchorEl={anchorEl}
        role={undefined}
        placement="bottom-start"
        transition
        sx={{ zIndex: 10000 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleCancel}>
                <Box position="relative" display="inline-flex" flexDirection="column" aria-label="Select Date Range">
                  <RangeCalendar
                    {...calendarProps}
                    handleCancel={handleCancel}
                    handleApply={handleApply}
                    calendarState={state}
                    showOptions={props?.showOptions}
                  />
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default DateRangePicker;
