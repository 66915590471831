import { Alert, Avatar, Button, Card, CardContent, CardHeader, Chip, Typography, useTheme } from "@mui/material";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import { useCampaignContext } from "../..";
import { useGetUserDetails } from "queries/auth";

const SummaryCard = ({ ref, error, step, handleOpenBasicDetails, handleOpenAllProducts }) => {
  const theme = useTheme();
  const { data: userDetails } = useGetUserDetails();
  const {
    isEditable,
    setActiveStep,
    setEditStep,
    formData,
    formData: { isSettled, requirements },
  } = useCampaignContext();

  const handleEdit = () => {
    if (step?.step !== -1) {
      setActiveStep(step?.step);
      setEditStep(true);
    } else handleOpenBasicDetails();
  };

  return (
    <Card
      sx={{
        border:
          error?.active &&
          `1px solid ${theme.palette[error?.type][error?.type === "error" ? "main" : "error"]}!important`,
      }}
      ref={ref}
    >
      <CardHeader
        // avatar={}
        action={
          userDetails?.current_enterprise?.id !== "01HHSADNZQNQNKFMCDFESA18CF" && !isSettled ? (
            <Button variant="text" color="black" startIcon={<EditIcon />} className="px-3 py-1" onClick={handleEdit}>
              Edit
            </Button>
          ) : null
        }
        title={step?.title}
        subheader={step?.subtitle}
      />
      <CardContent className="d-flex flex-column gap-2">
        {Object.entries(step?.data)?.map((item, i) => {
          const brand =
            item[1]?.key === "offer" ? formData?.brand : requirements?.[item[1]?.key]?.brand ?? formData?.brand;
          if (
            (typeof item[1] !== "object" && item[1] !== "" && item[1] !== null && item[1] !== undefined) ||
            item[1]?.data?.length > 0
          )
            return (
              <div className="d-flex gap-4" key={i}>
                <div className="w-25">
                  <Typography variant="subtitle2" fontWeight={500} color="gray.400">
                    {item[0]}
                  </Typography>
                </div>
                {" : "}
                <div className="w-75">
                  {typeof item[1] === "object" ? (
                    <>
                      <div className="d-flex flex-wrap gap-2 align-items-center mb-2">
                        {item[1]?.data
                          ?.sort((a, b) => {
                            if (a.brand_id !== brand?.internal_id && b.brand_id === brand?.internal_id) {
                              return -1; // 'a' has the brand?.internal_id and 'b' does not, so 'a' comes first
                            } else if (b.brand_id !== brand?.internal_id && a.brand_id === brand?.internal_id) {
                              return 1; // 'b' has the brand?.internal_id and 'a' does not, so 'b' comes first
                            } else {
                              // If neither or both have the brand?.internal_id, sort by another criteria (e.g., brand_id or product_name)
                              return a.brand_id.localeCompare(b.brand_id); // Example: sorting alphabetically by brand_id
                            }
                          })
                          ?.slice(0, 4)
                          ?.map((item) => (
                            <Chip
                              key={item?.product_id}
                              label={item?.name ?? item?.product_name}
                              color={item?.brand_id !== brand?.internal_id ? "light-error" : "default"}
                              avatar={
                                <Avatar variant="circular" src={item?.image} alt={item?.name ?? item?.product_name} />
                              }
                            />
                          ))}
                        {item[1]?.data?.length > 4 ? `+${item[1]?.data?.length - 4}` : null}
                      </div>
                      <Button
                        // className="py-1"
                        size="small"
                        variant="text"
                        onClick={() => handleOpenAllProducts(item[1], item[0])}
                      >
                        See all
                      </Button>
                    </>
                  ) : (
                    <Typography variant="subtitle2" fontWeight={500}>
                      {item[1]}
                    </Typography>
                  )}
                </div>
              </div>
            );
          return null;
        })}
        {step?.Image ? (
          <div className="d-flex justify-content-center">
            <img
              src={step && typeof step?.Image === "string" ? step?.Image : URL.createObjectURL(step?.Image)}
              alt=""
              height={200}
              width={400}
              style={{ objectFit: "contain", objectPosition: "center" }}
            />
          </div>
        ) : null}
      </CardContent>
      {error?.active && <Alert severity={error?.type}>{error?.text}</Alert>}
    </Card>
  );
};

export default SummaryCard;
