import React, { Fragment, useEffect, useMemo, useRef } from "react";

import { ResponsiveBar } from "@nivo/bar";

// image impoer
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import LatestCampaigns from "./LatestCampaigns";
import LastestGroceryChains from "./LatestGroceryChains";
import LatestStores from "./LatestStores";
import TransactionStatus from "./TransactionStatus";

import PrimaryWidgets from "./PrimaryWidgets";
import SecondaryWidgets from "./SecondaryWidgets";
import { useGetAnalytics } from "queries/dashboard";
import { Skeleton } from "@mui/material";
import useGlobalState from "store";
import { format } from "date-fns";
import { ConnectToTCBAlert } from "components";

const Dashboard = () => {
  const ref = useRef();
  const { dashboardFilter, setDashboardRef } = useGlobalState();
  const { data, isLoading } = useGetAnalytics(dashboardFilter);

  useEffect(() => {
    setDashboardRef(ref);
  }, []);

  const gitsyVsExternal = useMemo(() => {
    return data?.last_6_months?.records?.map((x) => {
      const obj = { ...x };
      obj["External Clips"] = obj.external_clips;
      obj["Internal Clips"] = obj.internal_clips;
      obj["Clips"] = obj.clips;
      obj["Redemptions"] = obj.redemptions;
      delete obj.external_clips;
      return obj;
    });
  }, [data]);

  return (
    <ConnectToTCBAlert>
      <div ref={ref}>
        <Container fluid={true}>
          <Row>
            {isLoading ? (
              <div>
                <div className="d-flex gap-4 mb-3">
                  <Skeleton variant="rectangular" sx={{ width: "33%", height: "100px", borderRadius: "20px" }} />
                  <Skeleton variant="rectangular" sx={{ width: "33%", height: "100px", borderRadius: "20px" }} />
                  <Skeleton variant="rectangular" sx={{ width: "33%", height: "100px", borderRadius: "20px" }} />
                  {/* <Skeleton variant="rectangular" sx={{ width: "25%", height: "100px", borderRadius: "20px" }} /> */}
                </div>
                <div className="d-flex gap-4 mb-3">
                  <Skeleton variant="rectangular" sx={{ width: "33%", height: "300px", borderRadius: "20px" }} />
                  <Skeleton variant="rectangular" sx={{ width: "33%", height: "300px", borderRadius: "20px" }} />
                  <Skeleton variant="rectangular" sx={{ width: "33%", height: "300px", borderRadius: "20px" }} />
                  {/* <Skeleton variant="rectangular" sx={{ width: "25%", height: "300px", borderRadius: "20px" }} /> */}
                </div>
              </div>
            ) : (
              <>
                <PrimaryWidgets data={data?.this_month} />
                <SecondaryWidgets data={data?.this_month} />
                <Col xl="6 xl-100" className="mb-4">
                  <Card>
                    <CardBody>
                      <h5>Coupon Clips v/s Redemptions</h5>
                      <div className="market-chart">
                        <ResponsiveBar
                          data={gitsyVsExternal}
                          keys={["Clips", "Redemptions"]}
                          indexBy="label"
                          margin={{ top: 50, right: 0, bottom: 25, left: 40 }}
                          padding={0.3}
                          groupMode="grouped"
                          valueScale={{ type: "linear" }}
                          indexScale={{ type: "band", round: true }}
                          colors={{ scheme: "nivo" }}
                          // defs={[
                          //   {
                          //     id: "dots",
                          //     type: "patternDots",
                          //     background: "inherit",
                          //     color: "#38bcb2",
                          //     size: 4,
                          //     padding: 1,
                          //     stagger: true,
                          //   },
                          //   {
                          //     id: "lines",
                          //     type: "patternLines",
                          //     background: "inherit",
                          //     color: "#eed312",
                          //     rotation: -45,
                          //     lineWidth: 6,
                          //     spacing: 10,
                          //   },
                          // ]}
                          // fill={[
                          //   {
                          //     match: {
                          //       id: "redemptions",
                          //     },
                          //     id: "dots",
                          //   },
                          //   {
                          //     match: {
                          //       id: "sandwich",
                          //     },
                          //     id: "lines",
                          //   },
                          // ]}
                          borderColor={{
                            from: "color",
                            modifiers: [["darker", 1.6]],
                          }}
                          axisTop={null}
                          axisRight={null}
                          axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: "label",
                            legendPosition: "middle",
                            legendOffset: 32,
                            truncateTickAt: 0,
                          }}
                          axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: "",
                            legendPosition: "middle",
                            legendOffset: -40,
                            truncateTickAt: 0,
                          }}
                          // labelSkipWidth={12}
                          // labelSkipHeight={12}
                          // labelTextColor={{
                          //   from: "color",
                          //   modifiers: [["darker", 1.6]],
                          // }}
                          legends={[
                            {
                              dataFrom: "keys",
                              anchor: "top-right",
                              direction: "column",
                              justify: false,
                              translateX: 0,
                              translateY: -50,
                              itemWidth: 100,
                              itemHeight: 20,
                              itemsSpacing: 2,
                              symbolSize: 20,
                              itemDirection: "left-to-right",
                            },
                          ]}
                          role="application"
                          ariaLabel="Nivo bar chart demo"
                          barAriaLabel={(e) => e.id + ": " + e.formattedValue + " in label: " + e.indexValue}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl="6 xl-100" className="mb-4">
                  <Card>
                    <CardBody>
                      <h5>Gitsy v/s External Clips</h5>
                      <div className="market-chart">
                        <ResponsiveBar
                          data={gitsyVsExternal}
                          keys={["Internal Clips", "External Clips"]}
                          indexBy="label"
                          margin={{ top: 50, right: 0, bottom: 25, left: 40 }}
                          padding={0.3}
                          groupMode="grouped"
                          valueScale={{ type: "linear" }}
                          indexScale={{ type: "band", round: true }}
                          colors={{ scheme: "set2" }}
                          // colorBy="indexValue"
                          borderColor={{
                            from: "color",
                            modifiers: [["darker", 1.6]],
                          }}
                          axisTop={null}
                          axisRight={null}
                          axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: "label",
                            legendPosition: "middle",
                            legendOffset: 32,
                            truncateTickAt: 0,
                          }}
                          axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: "",
                            legendPosition: "middle",
                            legendOffset: -40,
                            truncateTickAt: 0,
                          }}
                          // labelSkipWidth={12}
                          // labelSkipHeight={12}
                          // labelTextColor={{
                          //   from: "color",
                          //   modifiers: [["darker", 1.6]],
                          // }}
                          legends={[
                            {
                              // dataFrom: ["clips", "external_clips"],
                              anchor: "top-right",
                              direction: "column",
                              justify: false,
                              translateX: 0,
                              translateY: -50,
                              itemWidth: 100,
                              itemHeight: 20,
                              itemsSpacing: 2,
                              symbolSize: 20,
                              itemDirection: "left-to-right",
                            },
                          ]}
                          role="application"
                          ariaLabel="Nivo bar chart demo"
                          barAriaLabel={(e) => e.id + ": " + e.formattedValue + " in label: " + e.indexValue}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col xl="12 xl-100" className="mb-4">
                  <LatestCampaigns />
                </Col>
              </>
            )}

            {/* <Col sm="12" className="mb-4">
            <TransactionStatus />
          </Col> */}

            {/* <Col sm="12">
            <Card>
              <CardHeader>
                <h5>Buy / Sell</h5>
              </CardHeader>
              <CardBody className="sell-graph">
                <Line
                  data={buyData}
                  options={buyOption}
                  width={700}
                  height={350}
                />
              </CardBody>
            </Card>
          </Col> */}

            {/* <Col xl="6 xl-100" className="mb-4">
            <LastestGroceryChains />
          </Col>
          <Col xl="6 xl-100" className="mb-4">
            <LatestStores />
          </Col> */}
          </Row>
        </Container>
      </div>
    </ConnectToTCBAlert>
  );
};

export default Dashboard;
