import { Autocomplete, TextField, InputAdornment } from "@mui/material";
import { SEOHelmet } from "components";
import SearchIcon from "@mui/icons-material/Search";

import React, { Fragment } from "react";
// import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import {
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  CardBody,
  Row,
} from "reactstrap";

const CreateStore = () => {
  return (
    <>
      <Card>
        <CardBody>
          <Fragment>
            <Form className="needs-validation user-add" noValidate="">
              <h4>Store Details</h4>
              <FormGroup className="row">
                <Label className="col-xl-3 col-md-4">
                  <span>*</span> Name
                </Label>
                <div className="col-xl-8 col-md-7">
                  <Input
                    className="form-control"
                    id="validationCustom0"
                    type="text"
                    required=""
                    placeholder="Enter name"
                  />
                </div>
              </FormGroup>
              <FormGroup className="row">
                <Label className="col-xl-3 col-md-4">
                  <span>*</span> Email
                </Label>
                <div className="col-xl-8 col-md-7">
                  <Input
                    className="form-control"
                    id="validationCustom2"
                    type="text"
                    required=""
                    placeholder="Enter email address"
                  />
                </div>
              </FormGroup>
            </Form>

            <div className="pull-right">
              <Button type="button" color="primary">
                Create
              </Button>
            </div>
          </Fragment>
        </CardBody>
      </Card>
    </>
  );
};

export default CreateStore;
