import React, { useMemo } from "react";
import man from "assets/images/dashboard/man.png";
import { Avatar, Skeleton } from "@mui/material";
import { useGetEnterpriseDetails } from "queries/enterprise";

const UserPanel = () => {
  const { data: enterpriseDetails, isLoading } = useGetEnterpriseDetails();

  const initials = useMemo(() => {
    if (enterpriseDetails?.alias_name || enterpriseDetails?.name) {
      const names = enterpriseDetails?.alias_name
        ? enterpriseDetails?.alias_name?.split(" ")
        : enterpriseDetails?.name?.split(" ");
      return `${names?.[0]?.[0]?.toUpperCase() ?? ""}${names?.[1]?.[0]?.toUpperCase() ?? ""}`;
    }
    return "--";
  }, [enterpriseDetails]);

  return (
    <div>
      <div className="sidebar-user text-center">
        {/* <img className="img-60 rounded-circle lazyloaded blur-up" src={man} alt="#" /> */}
        {isLoading ? (
          <>
            <div className="d-flex justify-content-center w-100">
              <Skeleton variant="circular">
                <Avatar className="rounded-circle blur-up" variant="circular" sx={{ height: 60, width: 60 }} />
              </Skeleton>
            </div>
            <h6 className="mt-3 f-14 text-center d-flex flex-column align-items-center justify-content-center">
              <Skeleton width={140} height={12} />
              <Skeleton width={100} height={12} />
            </h6>
          </>
        ) : (
          <>
            <div className="d-flex justify-content-center w-100">
              <Avatar
                src={enterpriseDetails?.image_path}
                className="rounded-circle blur-up"
                variant="circular"
                sx={{ height: 60, width: 60 }}
                // alt={initials}
              >
                {!enterpriseDetails?.image_path ? initials : null}
              </Avatar>
            </div>
            <h6 className="mt-3 f-14">{enterpriseDetails?.alias_name ?? enterpriseDetails?.name}</h6>
          </>
        )}
        {/* <h6 className="mt-3 f-14">
          {userDetails?.first_name} {userDetails?.last_name}
        </h6>
        <p>
          {userDetails?.current_enterprise?.name} -{" "}
          {userDetails?.current_enterprise?.role}
        </p> */}
      </div>
    </div>
  );
};

export default UserPanel;
