import { Card, CardBody } from "reactstrap";
import { SEOHelmet, Table } from "components";
import React from "react";
import { AppRoutes } from "routes/AppRoutes";
import { Link } from "react-router-dom";
import { Avatar, Button } from "@mui/material";

const columns = [
  { label: "Avatar", align: "left" },
  { label: "Name", align: "left" },
  { label: "ID", align: "left" },
  { label: "Actions", align: "center" },
];

const data = [
  [<Avatar>S</Avatar>, "Store10", 10],
  [<Avatar>S</Avatar>, "Store9", 9],
  [<Avatar>S</Avatar>, "Store8", 8],
];

const filters = [
  {
    label: "Grocery Chain",
    options: ["All", "GC10", "GC9", "GC8"],
  },
];

const ListStores = () => {
  return (
    <>
      <Card>
        <CardBody>
          <div>
            <Table
              data={data}
              columns={columns}
              filters={filters}
              toolbar={
                <Link to={AppRoutes.CREATE_STORE.pathname}>
                  <Button>CREATE</Button>
                </Link>
              }
            />
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default ListStores;
