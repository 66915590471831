import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import { AddBrand } from "components";
import { AddProductPrimaryDetailsForm, AddProductSecondaryForm } from "pages/products/AddProduct/FormComponents";
import useFormHanlders from "pages/products/AddProduct/useFormHanlders";
import { useState } from "react";
import { FormProvider } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import { useCampaignContext } from "../..";

const AddProductModal = () => {
  const { addToast } = useToasts();
  const {
    formData: { brand },
  } = useCampaignContext();

  const initialState = {
    brand,
  };

  const [isAddProductOpen, setIsAddProductOpen] = useState(false);
  const [isAddBrandOpen, setIsAddBrandOpen] = useState(false);

  const handleOnSuccess = () => {
    setIsAddProductOpen(false);
    addToast("Successfully added product", {
      appearance: "success",
    });
  };

  const { isLoading, handleOnSubmit, formMethods, fieldMethods } = useFormHanlders(
    false,
    handleOnSuccess,
    initialState
  );

  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = formMethods;

  const handleOnClose = () => {
    setIsAddProductOpen(false);
  };

  return (
    <>
      <div className="d-flex gap-2 align-items-center mx-3">
        <Typography>Unable to find your product?</Typography>
        <Button variant="contained" color="secondary" onClick={() => setIsAddProductOpen(true)}>
          Add Product
        </Button>
      </div>
      <form onSubmit={handleSubmit(handleOnSubmit)} id="add-product-form">
        <Dialog
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={isAddProductOpen}
          onClose={handleOnClose}
          closeAfterTransition
          fullWidth
          // slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 400,
            },
          }}
        >
          <DialogTitle>
            <Typography className="pb-3" variant="h6" fontWeight={600}>
              Add Product
            </Typography>
            <Divider />
          </DialogTitle>
          <DialogContent>
            <FormProvider {...formMethods}>
              <div className="d-flex gap-3 flex-column">
                <AddProductPrimaryDetailsForm setIsAddBrandOpen={setIsAddBrandOpen} fieldMethods={fieldMethods} />
                <AddProductSecondaryForm />
              </div>
            </FormProvider>
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              color="primary"
              form="add-product-form"
              endIcon={isLoading ? <CircularProgress color="white" size={20} /> : null}
              disabled={isLoading}
              variant="contained"
            >
              Add Product
            </Button>
            <Button variant="outlined" onClick={handleOnClose}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </form>
      <AddBrand
        isOpen={isAddBrandOpen}
        handleClose={() => setIsAddBrandOpen(false)}
        handleSelectBrand={(item) => setValue("brand", item)}
      />
    </>
  );
};

export default AddProductModal;
