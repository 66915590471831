import { Table, ConnectToTCBAlert, SeeAllDrawer, DateRangePicker } from "components";
import React, { useCallback, useMemo } from "react";
import { Card, CardBody } from "reactstrap";
import {
  Button,
  TextField,
  DialogContent,
  Typography,
  DialogActions,
  Dialog,
  DialogTitle,
  IconButton,
  Autocomplete,
  CircularProgress,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Avatar,
  Divider,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import LockIcon from "@mui/icons-material/Lock";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CloseIcon from "@mui/icons-material/Close";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

import { useState } from "react";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { useTableActionContext } from "components/Table";
import {
  useGetCampaignsList,
  usePatchProvidersToggle,
  usePatchRetailersToggle,
  usePatchToggleLockCampaign,
} from "queries/campaign";
import { useNavigate } from "react-router";
import { Pathnames } from "routes/Pathnames";
import { useToasts } from "react-toast-notifications";
import { searchKeys } from "constants";
import WestIcon from "@mui/icons-material/West";
import { useGetCampaignGalleryList, usePatchAddCampaignsToGallery } from "queries/gallery";
import SearchIcon from "@mui/icons-material/Search";
import { useGetBrands } from "queries/brands";
import DropdownWrapper from "components/atoms/DropdownWrapper";
import { isEqual, difference, union } from "lodash";
import ExcelIcon from "assets/icons/Excel";
import { handleExportAsCSV, handleExportAsExcel } from "services";
import CsvIcon from "assets/icons/CsvIcon";
import CampaignLogo from "@mui/icons-material/CampaignOutlined";
import { useQueryClient } from "@tanstack/react-query";
import { QueryKeys } from "constants";
import { useGetUserDetails } from "queries/auth";
import { useProviderRetailerAssignments } from "hooks";
import { parseCampaignDataForCsv, parseCampaignsDataForExcel } from "services/exportAsFile";
import { format } from "date-fns";

const AddToGalleryModal = ({ open, selectedCampaigns, handleClose, setSelectedCampaigns }) => {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const [selectedGallery, setSelectedGallery] = useState(null);
  const { data: galleries, isLoading } = useGetCampaignGalleryList(open);
  const { mutate: patchAddCampaignsToGallery, isLoading: isLoadingAddCampaigns } = usePatchAddCampaignsToGallery(
    selectedGallery?.id
  );

  const handleNavigateToCreateGallery = () =>
    navigate(Pathnames.CREATE_GALLERY, {
      state: {
        selectedCampaigns: selectedCampaigns,
      },
    });

  const handleNavigateAddToExisting = () => {
    const payload = {
      gallery_id: selectedGallery?.id,
      body: {
        base_gs1s: selectedCampaigns?.map((x) => ({
          base_gs1: x?.base_gs1,
        })),
      },
    };

    patchAddCampaignsToGallery(payload, {
      onSuccess: () => {
        addToast("Successfully added campaigns to gallery", {
          appearance: "success",
        });
        handleClose();
        setSelectedGallery(null);
        setSelectedCampaigns([]);
      },
    });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <IconButton sx={{ marginLeft: "-10px", position: "absolute" }} onClick={handleClose}>
          <WestIcon />
        </IconButton>{" "}
        <Typography variant="h6" fontWeight={700} textAlign={"center"} sx={{ padding: "2px 0px" }}>
          Add to Gallery
        </Typography>
      </DialogTitle>
      <DialogContent>
        <div className="gap-3 d-flex flex-column" style={{ width: "400px" }}>
          <Button onClick={handleNavigateToCreateGallery} color="secondary">
            Create a new Gallery
          </Button>
          <Typography variant="body1" fontWeight={500} textAlign={"center"}>
            Or
          </Typography>
          <Autocomplete
            value={selectedGallery}
            onChange={(_, value) => setSelectedGallery(value)}
            loading={isLoading}
            options={galleries?.galleries ?? []}
            getOptionLabel={(option) => option?.name}
            fullWidth
            // popupIcon={<SearchIcon />}
            InputProps={{
              endAdornment: <React.Fragment>{<SearchIcon />}</React.Fragment>,
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                // InputProps={{
                //   endAdornment: (
                //     <InputAdornment position="end" className="p-2">
                //       <SearchIcon />
                //     </InputAdornment>
                //   ),
                // }}
                variant="filled"
                placeholder="Select an existing gallery..."
                autoComplete="off"
              />
            )}
          />
          <Button disabled={!selectedGallery || isLoadingAddCampaigns} onClick={handleNavigateAddToExisting}>
            {"Add to existing gallery"}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const DeleteCampaign = () => {
  const { setIsModalOpen, row } = useTableActionContext();
  return (
    <>
      <DialogContent>
        <div className="gap-1 d-flex mb-2 flex-column">
          <Typography variant="h5" fontWeight={700}>
            Delete Campaign
          </Typography>
          <Typography color="gray.500">Are you sure you want to delete this campaign?</Typography>
        </div>
        <Paper sx={{ bgcolor: "gray.25" }} elevation={0}>
          <div className="d-flex align-items-center gap-2 p-2">
            <img
              src={row?.campaign_metadata?.desktop_image_url}
              alt={row?.campaign_metadata?.title}
              style={{
                height: "70px",
                width: "70px",
                margin: "0px 10px",
                objectFit: "contain",
                objectPosition: "center",
              }}
            />
            <div>
              <Typography fontWeight={500} variant="body1" className="line-clamp-2" maxWidth={300}>
                {row?.campaign_metadata?.title}
              </Typography>
              <Typography fontWeight={400} variant="caption" color="gray.500">
                {row?.campaign_metadata?.description}
              </Typography>
            </div>
          </div>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button color="light-error" fullWidth onClick={() => {}}>
          Delete
        </Button>
        <Button fullWidth onClick={() => setIsModalOpen(false)}>
          Cancel
        </Button>
      </DialogActions>
    </>
  );
};

const LockCampaign = () => {
  const { addToast } = useToasts();
  const { setIsModalOpen, row } = useTableActionContext();
  const { mutate: patchToggleLockCampaign } = usePatchToggleLockCampaign();

  const handleToggleLock = () => {
    setIsModalOpen(false);
    patchToggleLockCampaign(row?.base_gs1);
  };

  return (
    <>
      <DialogContent>
        <div className="gap-1 d-flex flex-column">
          <Typography variant="h5" fontWeight={700}>
            {!!row?.status?.locked ? "Inactivate" : "Activate"} Campaign
          </Typography>
          <Typography color="gray.500">
            Are you sure you want to {!!row?.status?.locked ? "Inactivate" : "Activate"} this campaign?
          </Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="light-error"
          startIcon={!!row?.status?.locked ? <LockOpenIcon /> : <LockIcon />}
          fullWidth
          onClick={handleToggleLock}
        >
          {!!row?.status?.locked ? "Inactivate" : "Activate"}
        </Button>
        <Button fullWidth onClick={() => setIsModalOpen(false)}>
          Cancel
        </Button>
      </DialogActions>
    </>
  );
};

const downloadOptions = [
  // {
  //   icon: <PDFIcon width={24} height={24} />,
  //   label: "PDF Document (.pdf)",
  //   key: "pdf_download",
  // },
  {
    icon: <ExcelIcon width={24} height={24} />,
    label: "Excel file (.xlsx)",
    key: "excel_download",
  },
  // {
  //   icon: <ExcelIcon width={24} height={24} />,
  //   label: "Export As Excel (Individual GTINs)",
  //   key: "excel_download_new",
  // },
  {
    icon: <CsvIcon width={22} height={22} />,
    label: "CSV file (.csv)",
    key: "csv_download",
  },
  // {
  //   icon: <CsvIcon width={22} height={22} />,
  //   label: "Export as CSV (Individual GTINs)",
  //   key: "csv_download_new",
  // },
];

const ExportOptions = ({ campaigns }) => {
  const handleOnClick = (option) => {
    if (option?.key === "csv_download")
      handleExportAsCSV(parseCampaignDataForCsv(campaigns, searchKeys.campaignsCsv), "campaigns");
    else if (option?.key === "excel_download")
      handleExportAsExcel(parseCampaignsDataForExcel(campaigns, searchKeys.campaignsCsv), "campaigns");
  };

  return (
    <DropdownWrapper
      handleOnClick={handleOnClick}
      disabled={!campaigns?.length}
      options={downloadOptions}
      selected={false}
      selectedKey={"key"}
    >
      <Button disabled={!campaigns?.length} variant="text" sx={{ minWidth: "20px!important", padding: "10px 10px" }}>
        <FileDownloadIcon />
      </Button>
    </DropdownWrapper>
  );
};

const columns = [
  {
    label: "Image",
    key: "campaign_metadata.desktop_image_url",
    align: "left",
    default: true,
    width: 120,
    render: (value, row) => (
      <Avatar
        src={value}
        alt={row?.name}
        sx={{ bgcolor: "primary.100", color: "primary.400", height: "70px", width: "70px" }}
        height={70}
        width={70}
      >
        <CampaignLogo color="primary" sx={{ height: "28px", width: "28px" }} />
      </Avatar>
    ),
  },
  {
    label: "Title",
    key: "campaign_metadata.description",
    align: "left",
    default: true,
    maxWidth: 350,
    render: (_, row) => (
      <>
        <Typography fontWeight={500} variant="body1" className="line-clamp-2" maxWidth={300}>
          {row?.campaign_metadata?.title}
        </Typography>
        <Typography fontWeight={400} variant="caption" color="gray.500">
          {row?.campaign_metadata?.description}
        </Typography>
      </>
    ),
  },
  {
    label: "Brand",
    key: "brand_name",
    align: "left",
    default: true,
  },
  {
    label: "Campaign Dates",
    key: "campaign_start_time",
    align: "left",
    width: 180,
    sort: true,
    render: (_, row) => (
      <div className="d-flex flex-column gap-2">
        <Typography fontWeight={500} variant="caption" color="gray.600" textAlign={"left"}>
          <FiberManualRecordIcon fontSize="10px" color="success" />{" "}
          <b>{format(new Date(row?.campaign_start_time), "do MMM yyyy")}</b>
        </Typography>
        <Typography fontWeight={400} variant="caption" color="gray.600" textAlign={"left"}>
          <FiberManualRecordIcon fontSize="10px" color="error" />{" "}
          <b>{format(new Date(row?.campaign_end_time), "do MMM yyyy")}</b>
        </Typography>
      </div>
    ),
  },
  {
    label: "Redemption Dates",
    key: "redemption_start_time",
    align: "left",
    width: 190,
    render: (_, row) => (
      <div className="d-flex flex-column gap-2">
        <Typography fontWeight={500} variant="caption" color="gray.600" textAlign={"left"}>
          <FiberManualRecordIcon fontSize="10px" color="success" />{" "}
          <b>{format(new Date(row?.redemption_start_time), "do MMM yyyy")}</b>
        </Typography>
        <Typography fontWeight={400} variant="caption" color="gray.600" textAlign={"left"}>
          <FiberManualRecordIcon fontSize="10px" color="error" />{" "}
          <b>{format(new Date(row?.redemption_end_time), "do MMM yyyy")}</b>
        </Typography>
      </div>
    ),
  },
];

const campaignStatusOptions = [
  { name: "Active", id: "Active" },
  { name: "Inactive", id: "Inactive" },
  { name: "Expired", id: "Expired" },
  { name: "Settled", id: "Settled" },
  { name: "All", id: "All" },
];

export const Filters = ({ open, setOpen, setPagination, pagination }) => {
  const [selected, setSelected] = useState({
    brand_id: pagination?.brand_id,
    status: pagination?.status,
    start_date: pagination?.start_date,
    end_date: pagination?.end_date,
  });

  const { data: brands } = useGetBrands();

  const options = useMemo(
    () => [
      {
        label: "Status",
        options: campaignStatusOptions,
        key: "status",
        default: "Active",
      },
      {
        label: "Brand",
        options: [
          { name: "All", id: "All" },
          ...(brands?.data
            ? brands?.data?.map((x) => ({
                name: x?.name,
                id: x?.internal_id,
              }))
            : []),
        ],
        key: "brand_id",
        default: "All",
      },
      {
        type: "date",
        label: "Created On",
        key: "date",
      },
    ],
    [brands]
  );

  const handleOnApply = () => {
    setPagination((state) => ({ ...state, ...selected }));
    setOpen(false);
  };

  const handleClear = () => {
    setSelected({
      status: "Active",
      brand_id: null,
      start_date: null,
      end_date: null,
    });
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      closeAfterTransition
      maxWidth="lg"
      slotProps={{
        backdrop: {
          timeout: 400,
        },
      }}
    >
      <DialogTitle>
        <div className="d-flex justify-content-between gap-2 w-100 align-items-center">
          <Typography fontWeight={600} variant="h6">
            Filters
          </Typography>
          <IconButton sx={{ transform: `translateX(10px)` }} onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <div className="d-flex gap-2 my-2 flex-wrap">
          {options?.map((item) => {
            if (item?.type === "date")
              return (
                <DateRangePicker
                  showOptions
                  key={item?.key}
                  defaultValue={{ start: selected?.start_date, end: selected?.end_date }}
                  handleSetValue={(values) => setSelected((state) => ({ ...state, ...values }))}
                  // showOptions
                  // minValue={today(getLocalTimeZone()).subtract({ days: 30 })}
                  // maxValue={today(getLocalTimeZone())}
                />
              );
            return (
              <FormControl sx={{ maxWidth: "150px" }} key={item?.key}>
                <InputLabel htmlFor="grouped-select">{item?.label}</InputLabel>
                <Select
                  // defaultValue={item?.default}
                  id="grouped-select"
                  label={item?.label}
                  value={selected?.[item?.key] ?? "All"}
                  onChange={(e) =>
                    setSelected((state) => ({
                      ...state,
                      [item?.key]: e.target.value === "All" ? null : e.target.value,
                    }))
                  }
                >
                  {item?.options?.map((option, i) => (
                    <MenuItem value={option?.id} key={i}>
                      {option?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          })}
        </div>
      </DialogContent>
      <DialogActions>
        <div className="d-flex justify-content-between align-items-center w-100">
          <Button size="small" color="light-error" onClick={handleClear}>
            Clear All
          </Button>
          <div>
            <Button size="small" onClick={handleOnApply}>
              Apply
            </Button>
            <Button
              color="black"
              variant="text"
              onClick={() => {
                setSelected({});
                setOpen(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
};

const ListCampaign = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    limit: 25,
    previousKey: [],
    nextKey: null,
    brand_id: null,
    status: "Active",
    start_date: null,
    end_date: null,
  });

  const [openAddToGallery, setAddToGallery] = useState({
    open: false,
    data: null,
  });

  const [drawerData, setDrawerData] = useState({
    data: [],
    base_gs1: null,
  });

  const [selectedCampaigns, setSelectedCampaigns] = useState({});

  const handleSelect = (type, updatedData) => {
    setDrawerData((state) => ({
      ...state,
      data: updatedData,
    }));
  };

  const {
    data,
    openSideDrawer,
    isSelected,
    handleRemove,
    handleAdd,
    handleClose,
    handleOpen,
    isLoading: isLoadingDrawer,
  } = useProviderRetailerAssignments(drawerData?.data, drawerData?.data, handleSelect);

  const { data: campaigns, isLoading } = useGetCampaignsList({
    limit: pagination?.limit,
    lastEvaluatedKey: pagination?.nextKey,
    status: pagination?.status,
    brand_id: pagination?.brand_id,
    start_date: pagination?.start_date,
    end_date: pagination?.end_date,
  });

  const { data: userDetails } = useGetUserDetails();

  const { mutate: patchAssignProviders } = usePatchProvidersToggle(drawerData?.base_gs1);
  const { mutate: patchAssignRetailers } = usePatchRetailersToggle(drawerData?.base_gs1);

  const handleOnClickRow = (row) => {
    navigate(Pathnames.CAMPAIGN.replace(":id", row?.base_gs1));
  };

  const handleOpenAddToGallery = useCallback(() => {
    setAddToGallery((state) => ({
      ...state,
      open: true,
      data: Object.values(selectedCampaigns).flatMap((x) => x),
    }));
  }, [selectedCampaigns]);

  const handleCloseAddToGalleryModal = () => {
    setAddToGallery((state) => ({
      ...state,
      open: false,
    }));

    setTimeout(
      () =>
        setAddToGallery((state) => ({
          ...state,
          data: null,
        })),
      10
    );
  };

  const handleOpenProvidersAssignment = (row) => {
    setDrawerData({
      data: row?.providers,
      base_gs1: row?.base_gs1,
      previous_data: row?.providers,
    });
    handleOpen("providers");
  };

  const handleOpenRetailersAssignment = (row) => {
    setDrawerData({
      data: row?.allowed_retailers,
      base_gs1: row?.base_gs1,
      previous_data: row?.allowed_retailers,
    });
    handleOpen("retailers");
  };

  const handleGetUpdatedAssignments = (previousData, updateData) => {
    if (!isEqual(previousData, updateData)) {
      const addedElements = difference(updateData, previousData);
      const removedElements = difference(previousData, updateData);

      // sending only changed items for toggle
      return union(addedElements, removedElements);
    }
    return [];
  };

  const handleAssignments = () => {
    const updated_email_domains = drawerData?.data?.map((x) => x?.email_domain || x);
    const previous_email_domains = drawerData?.previous_data?.map((x) => x?.email_domain || x);

    const email_domains = handleGetUpdatedAssignments(previous_email_domains, updated_email_domains);

    if (email_domains?.length) {
      if (openSideDrawer?.key === "providers") {
        patchAssignProviders(
          {
            base_gs1: drawerData?.base_gs1,
            email_domains,
          },
          {
            onSuccess: () => handleCloseAssignment(),
          }
        );
      } else {
        patchAssignRetailers(
          {
            base_gs1: drawerData?.base_gs1,
            email_domains,
          },
          {
            onSuccess: () => handleCloseAssignment(),
          }
        );
      }
    } else {
      handleCloseAssignment();
    }
  };

  const handleCloseAssignment = (email_domains) => {
    handleClose();
    setDrawerData({
      base_gs1: null,
      data: [],
    });

    // queryClient.getQueryData([QueryKeys.])
  };

  const Toolbar = useMemo(() => {
    if (userDetails?.current_enterprise?.id === "01HHSADNZQNQNKFMCDFESA18CF")
      return [
        <ExportOptions campaigns={Object.values(selectedCampaigns).flatMap((x) => x)} key="export-options" />,
        <Button
          key="add-to-gallery-options"
          color="secondary"
          disabled={isLoading || !Object.values(selectedCampaigns).flatMap((x) => x)?.length}
          onClick={handleOpenAddToGallery}
        >
          Add To Gallery
        </Button>,
      ];
    return [
      <ExportOptions campaigns={Object.values(selectedCampaigns).flatMap((x) => x)} key="export-options" />,
      <Button
        key="add-to-gallery-options"
        color="secondary"
        disabled={isLoading || !Object.values(selectedCampaigns).flatMap((x) => x)?.length}
        onClick={handleOpenAddToGallery}
      >
        Add To Gallery
      </Button>,
      <Button key="create-campaign-options" onClick={() => navigate(Pathnames.CREATE_CAMPAIGN)}>
        Create Campaign
      </Button>,
    ];
  }, [userDetails, selectedCampaigns, isLoading, handleOpenAddToGallery, navigate]);

  const actions = useMemo(
    () => [
      {
        enabled: (row) => row?.settled === 1,
        label: (row) => (!!row?.status?.locked ? "Inactivate" : "Activate"),
        icon: (row) => (!!row?.status?.locked ? <LockOpenIcon /> : <LockIcon />),
        component: <LockCampaign />,
      },
      {
        label: () => "Edit",
        icon: () => <EditIcon />,
        onClick: (row) => navigate(`${Pathnames.CAMPAIGN.replace(":id", row?.base_gs1)}`),
      },
      {
        label: () => "Assign providers",
        icon: () => <PersonAddIcon />,
        onClick: handleOpenProvidersAssignment,
      },
      // {
      //   label: () => "Assign retailers",
      //   icon: () => <PersonAddIcon />,
      //   onClick: handleOpenRetailersAssignment,
      // },
      // {
      //   label: () => "Delete",
      //   icon: () => <DeleteIcon sx={{ color: "red" }} />,
      //   component: <DeleteCampaign />,
      // },
    ],
    []
  );

  return (
    <ConnectToTCBAlert>
      <Card>
        <CardBody>
          <div className="category-table media-table coupon-list-delete">
            <Table
              Filters={Filters}
              defaultSortBy={{
                type: "asc",
                column: "campaign_start_time",
              }}
              actions={actions}
              toolbar={Toolbar}
              selectedRows={selectedCampaigns}
              setSelectedRows={setSelectedCampaigns}
              searchKeys={searchKeys.campaigns}
              search={search}
              isLoading={isLoading}
              selection
              selectionKey={"base_gs1"}
              columns={columns}
              data={campaigns?.baseGs1s ?? []}
              handleOnClickRow={handleOnClickRow}
              handleUpdateSearch={(e) => setSearch(e.target.value)}
              setPagination={setPagination}
              pagination={pagination}
              lastEvaluatedKey={campaigns?.LastEvaluatedKey}
              actionsCondition={(row) => userDetails?.current_enterprise?.id !== "01HHSADNZQNQNKFMCDFESA18CF"}
            />
            <AddToGalleryModal
              open={openAddToGallery?.open}
              selectedCampaigns={openAddToGallery?.data}
              handleClose={handleCloseAddToGalleryModal}
              setSelectedCampaigns={setSelectedCampaigns}
            />
            <SeeAllDrawer
              data={data}
              isOpen={openSideDrawer?.isOpen}
              title={openSideDrawer?.title}
              description={<>Add or remove {openSideDrawer?.key} from your campaign by clicking on the names below</>}
              placeholder={`Search for ${openSideDrawer?.key}...`}
              isLoading={isLoadingDrawer}
              handleCloseAllProducts={handleCloseAssignment}
              handleRemoveProduct={handleRemove}
              handleAddProduct={handleAdd}
              isSelectedCondition={isSelected}
              uniqueKey={"email_domain"}
              displayLabel={"name"}
              handleSubmit={handleAssignments}
            />
          </div>
        </CardBody>
      </Card>
    </ConnectToTCBAlert>
  );
};

export default ListCampaign;
