import {
  Box,
  Divider,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  stepConnectorClasses,
  styled,
} from "@mui/material";
import "assets/scss/form-stepper.scss";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 18,
    left: "calc(-50% + 18px)",
    right: "calc(50% + 18px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      // backgroundImage: "blue",
      backgroundColor: "#50A1EB",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      // backgroundImage: "blue",
      backgroundColor: "#50A1EB",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#EDEDED",
    borderRadius: 1,
  },
}));

const ActiveStep = styled("div")(({ active }) => ({
  border: active ? "2px solid #50A1EB" : "2px solid #EDEDED",
  color: active ? "#50A1EB" : "white",
  height: "36px",
  width: "36px",
  backgroundColor: active ? "transparent" : "#50A1EB",
  boxShadow: active ? "0px 0px 0px 4px #E1E1FE" : "initial",
  transition: "all 0.7s",
  cursor: !active ? "pointer" : "initial",
}));

const NextSteps = styled("div")({
  border: "2px solid  #EDEDED",
  color: "#B0B0B0",
  height: "36px",
  width: "36px",
  backgroundColor: "transparent",
});

const QontoStepIcon = (props) => {
  const { active, completed, className, icon } = props;

  return (
    <div ownerstate={{ active }} className={className} id="i">
      {/* {active && <></>} */}
      {completed || active ? (
        <ActiveStep
          active={active}
          className="rounded-circle d-flex justify-content-center align-items-center fs-6"
        >
          {icon}
        </ActiveStep>
      ) : (
        <NextSteps className="rounded-circle d-flex justify-content-center align-items-center fs-6 ">
          {icon}
        </NextSteps>
      )}
    </div>
  );
};

const FormStepper = ({ activeStep, setActiveStep, steps, divider }) => {
  return (
    <Box sx={{ position: "relative", paddingTop: "20px" }}>
      {/* have to handle for 4k screens */}
      <Divider
        sx={{
          width: `calc(${divider ? divider : 9.5}% - 12px)`,
          top: 38,
          position: "absolute",
          background: "#50A1EB",
          height: "3px",
          opacity: 1,
        }}
      />
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((label, idx) => (
          <Step
            key={label}
            onClick={() => idx <= activeStep && setActiveStep(idx)}
          >
            <StepLabel StepIconComponent={QontoStepIcon}>{label} </StepLabel>
          </Step>
        ))}
      </Stepper>
      <Divider
        sx={{
          width: `calc(${divider ? divider : 9.5}% - 12px)`,
          top: 38,
          position: "absolute",
          background: activeStep >= steps.length - 1 ? "#50A1EB" : "#B0B0B0",
          height: "3px",
          opacity: activeStep >= steps.length - 1 ? 1 : 0.25,
          right: 0,
        }}
      />
    </Box>
  );
};

export default FormStepper;
