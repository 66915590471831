import WestIcon from "@mui/icons-material/West";
import { useEffect, useMemo, useState } from "react";
import { Avatar, Button, Chip, Drawer, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { LoadingWrapper } from "components";

const SeeAllDrawer = ({
  errorItems,
  data,
  isOpen,
  title,
  description,
  isLoading,
  placeholder,
  handleCloseAllProducts,
  handleRemoveProduct,
  handleAddProduct,
  isSelectedCondition,
  uniqueKey,
  displayLabel,
  backupDisplayLabel,
  displayAvatar,
  handleSubmit,
  submitLabel,
  submitDisabled,
}) => {
  const [search, setSearch] = useState("");

  const searchResults = useMemo(() => {
    if (data?.length && !!search?.length)
      return data?.filter(
        (x) =>
          x?.[displayLabel]?.toLowerCase()?.includes(search?.toLowerCase()) ||
          x?.[backupDisplayLabel]?.toLowerCase()?.includes(search?.toLowerCase())
      );
    return data;
  }, [backupDisplayLabel, data, displayLabel, search]);

  useEffect(() => {
    if (!isOpen) {
      setSearch("");
    }
  }, [isOpen]);

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleCloseAllProducts}>
      <div className="d-flex flex-column overflow-hidden" style={{ height: "100vh", width: "400px" }}>
        <div className="bg-white">
          <div
            className="d-flex align-items-center justify-content-between border-solid"
            style={{ padding: "10px 20px 0px 5px" }}
          >
            <IconButton onClick={handleCloseAllProducts}>
              <WestIcon />
            </IconButton>

            <Typography fontWeight={600}>{title}</Typography>
          </div>
          <div className="p-3">
            <Typography variant="subtitle2" color="gray.400" className="pb-2">
              {description}
            </Typography>
            <TextField
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder={placeholder}
              fullWidth
              variant="filled"
              InputProps={{
                endAdornment: (
                  <InputAdornment className="px-2" position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        <div className="gap-2 px-3 overflow-scroll" style={{ flexGrow: 1 }}>
          <LoadingWrapper isLoading={isLoading}>
            {searchResults?.length === 0 && (
              <Typography align="center" color="gray.400">
                {!!search?.length ? `No results were found for "${search}"` : "No results found"}
              </Typography>
            )}

            {errorItems?.length
              ? errorItems?.map((item) => {
                  const name = item?.[displayLabel] ?? item?.[backupDisplayLabel];
                  const initials = name
                    ? `${name?.split(" ")?.[0]?.[0]?.toUpperCase() ?? ""}${
                        name?.split(" ")?.[1]?.[0]?.toUpperCase() ?? ""
                      }`
                    : "--";
                  return (
                    <Chip
                      onClick={null}
                      key={item?.[uniqueKey]}
                      color={"light-error"}
                      label={name}
                      sx={{ m: 0.5 }}
                      avatar={
                        <Avatar variant="circular" src={item?.[displayAvatar]}>
                          {initials}
                        </Avatar>
                      }
                      onDelete={() => handleRemoveProduct(item)}
                    />
                  );
                })
              : null}
            {searchResults?.map((item) => {
              const isSelected = isSelectedCondition(item);
              const name = item?.[displayLabel] ?? item?.[backupDisplayLabel];
              const initials = name
                ? `${name?.split(" ")?.[0]?.[0]?.toUpperCase() ?? ""}${name?.split(" ")?.[1]?.[0]?.toUpperCase() ?? ""}`
                : "--";

              return (
                <Chip
                  onClick={!isSelected ? () => handleAddProduct(item) : null}
                  key={item?.[uniqueKey]}
                  color={isSelected ? "primary" : "default"}
                  label={name}
                  sx={{ m: 0.5 }}
                  avatar={
                    <Avatar variant="circular" src={item?.[displayAvatar]}>
                      {initials}
                    </Avatar>
                  }
                  onDelete={isSelected ? () => handleRemoveProduct(item) : null}
                />
              );
            })}
          </LoadingWrapper>
        </div>
        {handleSubmit ? (
          <div className="d-flex gap-2 p-3">
            <Button size="small" onClick={handleSubmit} disabled={submitDisabled}>
              {submitLabel ?? "Save"}
            </Button>
            <Button size="small" color="black" variant="text" onClick={handleCloseAllProducts}>
              Cancel
            </Button>
          </div>
        ) : null}
      </div>
    </Drawer>
  );
};

export default SeeAllDrawer;
