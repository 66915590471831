export function ValidateEmail(email) {
  if (/^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/.test(email)) {
    return true;
  }
  // alert('You have entered an invalid email address!');
  return false;
}

export const isObjectsAreEqual = (a, b) => {
  const replacer = (key, value) => {
    if (typeof value === "number") return `${value}`;
    return value;
  };

  return JSON.stringify(a, replacer) === JSON.stringify(b, replacer);
};
