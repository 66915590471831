import { Button, IconButton, Typography } from "@mui/material";
import { useCampaignContext } from "pages/campaign/CreateCampaign";
import { calCampaignDuration } from "pages/campaign/CreateCampaign/CreatePayload";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import AddIcon from "@mui/icons-material/Add";
import { format } from "date-fns";

const CouponInfo = ({ handleCloseCouponInfo }) => {
  const {
    formData: { brand, title, description, moreInfo, campaignDuration },
  } = useCampaignContext();

  const { _, end_time } = calCampaignDuration(campaignDuration);
  return (
    <div className="coupon-info">
      <div className="controls">
        <IconButton>
          <KeyboardBackspaceIcon color="white" />
        </IconButton>
        <div>
          <Typography
            color="white.main"
            variant="subtitle1"
            fontFamily={"DM Sans"}
            fontWeight={600}
            align="center"
            className="line-clamp-1"
          >
            {brand?.name}
          </Typography>
          <Typography color="white.main" fontSize={10} fontFamily={"Ubuntu"} align="center">
            Expires {format(new Date(end_time), "MM-dd-yyyy")}
          </Typography>
        </div>
        <IconButton className="transition" size="small" sx={{ background: "#fff" }}>
          <AddIcon color="primary" fontSize="10" />
        </IconButton>
      </div>
      <div className="coupon-details">
        <img
          src={
            moreInfo?.product_image &&
            (typeof moreInfo?.product_image === "string"
              ? moreInfo?.product_image
              : URL.createObjectURL(moreInfo?.product_image))
          }
          alt={brand?.brand_name}
        />
        <div>
          <Typography fontWeight={600} fontFamily="Ubuntu" variant="subtitle1" className="line-clamp-2">
            {title}
          </Typography>
          <Typography fontSize={10} fontFamily="Ubuntu">
            {description}
          </Typography>
        </div>
      </div>

      <div className="qr-code-layout">
        <Typography fontWeight={500} variant="body" width={180} fontFamily="Ubuntu">
          Terms & conditions:
        </Typography>
        <Typography fontSize={10} fontFamily="Ubuntu" color="primary.main" sx={{ textDecoration: "underline" }}>
          https://thecouponbureau.org/terms
        </Typography>
      </div>
    </div>
  );
};

export default CouponInfo;
