import React from "react";
import { Divider, Typography } from "@mui/material";
const ORDivider = () => {
  return (
    <div className="d-flex align-items-center">
      <Divider className="flex-1" />
      <Typography className="mx-4 text-secondary" variant="body1">
        or
      </Typography>
      <Divider className="flex-1" />
    </div>
  );
};

export default ORDivider;
