import React, { useEffect, useState } from "react";
import { useCampaignContext } from "../..";
import EditIcon from "@mui/icons-material/Edit";
import { Avatar, Button, Card, CardContent, CardHeader, Chip, Grid, Typography } from "@mui/material";
import { SeeAllDrawer } from "components";
import { useProviderRetailerAssignments } from "hooks";
import { useGetUserDetails } from "queries/auth";

const AssignmentCard = ({ handleOpen, data, type, title, isPandG }) => {
  return (
    <Card>
      <CardHeader
        action={
          isPandG ? null : (
            <Button
              variant="text"
              color="black"
              startIcon={<EditIcon />}
              className="px-3 py-1"
              onClick={() => handleOpen(type)}
            >
              Edit
            </Button>
          )
        }
        title={title}
        // subheader={""}
      />
      <CardContent className="d-flex flex-column gap-2">
        <div className="d-flex gap-4">
          <div className="w-100">
            {data?.length ? (
              <div className="d-flex flex-wrap gap-2 align-items-center">
                {data?.slice(0, 10)?.map((x, i) => {
                  const initials = x?.name
                    ? `${x?.name?.split(" ")?.[0]?.[0]?.toUpperCase() ?? ""}${
                        x?.name?.split(" ")?.[1]?.[0]?.toUpperCase() ?? ""
                      }`
                    : "--";
                  return (
                    <Chip
                      key={i}
                      label={x?.name}
                      avatar={<Avatar variant="circular" src={initials} alt={initials} />}
                    />
                  );
                })}
                {data?.length > 10 ? `+${data.length - 10}` : null}
              </div>
            ) : (
              <Typography variant="subtitle2" fontWeight={500} color="gray.400" align="center">
                No {type} assigned
              </Typography>
            )}
          </div>
        </div>
      </CardContent>
      {/* {error?.active && <Alert severity={error?.type}>{error?.text}</Alert>} */}
    </Card>
  );
};

const Assignments = () => {
  const {
    handleSetValues,
    formData: { providers, retailers },
  } = useCampaignContext();

  const { data: userDetails } = useGetUserDetails();

  const { openSideDrawer, isSelected, handleRemove, handleAdd, handleClose, handleOpen, data, isLoading } =
    useProviderRetailerAssignments(providers, retailers, handleSetValues);

  return (
    <>
      <Grid item xs={12} sm={12} lg={12}>
        <AssignmentCard
          isPandG={userDetails?.current_enterprise?.id === "01HHSADNZQNQNKFMCDFESA18CF"}
          handleOpen={handleOpen}
          data={providers}
          type="providers"
          title="Providers"
        />
      </Grid>
      <Grid item xs={12} sm={12} lg={12}>
        <AssignmentCard
          isPandG={userDetails?.current_enterprise?.id === "01HHSADNZQNQNKFMCDFESA18CF"}
          handleOpen={handleOpen}
          data={retailers}
          type="retailers"
          title="Retailers"
        />
      </Grid>
      <SeeAllDrawer
        data={data}
        isOpen={openSideDrawer?.isOpen}
        title={openSideDrawer?.title}
        description={<>Add or remove {openSideDrawer?.key} from your campaign by clicking on the names below</>}
        placeholder={`Search for ${openSideDrawer?.key}...`}
        isLoading={isLoading}
        handleCloseAllProducts={handleClose}
        handleRemoveProduct={handleRemove}
        handleAddProduct={handleAdd}
        isSelectedCondition={isSelected}
        uniqueKey={"email_domain"}
        displayLabel={"name"}
      />
    </>
  );
};

export default Assignments;
