const campaigns = [
  "email_domain",
  "campaign_metadata.description",
  "brand_name",
  "primary_purchase_requirements",
  "second_purchase_requirements",
  "third_purchase_requirements",
  "campaign_metadata.title",
];

const campaignsCsv = {
  // title: "campaign_metadata.title",
  CPG: "email_domain",
  description: "campaign_metadata.description",
  Brand: "brand_name",
  "Dollar Amount": "campaign_metadata.dollar_amount",
  // brand_id: "brand_id",
  // total_circulation: "total_circulation",
  // rolling_expiration: "rolling_expiration",
  // store_coupon: "store_coupon",
  // deposited: "deposited",
  // create_date: "create_date",
  "Campaign Start Date": "campaign_start_time",
  "Campaign End Date": "campaign_end_time",
  "Redemption Start Date": "redemption_start_time",
  "Redemption End Date": "redemption_end_time",
  "Primary Purchase Products Gtins (UPC)": "primary_purchase_gtins",
  "Second Purchase Products Gtins (UPC)": "second_purchase_gtins",
  "Third Purchase Products Gtins (UPC)": "third_purchase_gtins",
  "Primary Purchase Products Gtins (EAN)": "primary_purchase_eans",
  "Second Purchase Products Gtins (EAN)": "second_purchase_eans",
  "Third Purchase Products Gtins (EAN)": "third_purchase_eans",
  "Primary Purchase Products Prefixed Code": "primary_purchase_prefixed_code",
  "Second Purchase Products Prefixed Code": "second_purchase_prefixed_code",
  "Third Purchase Products Prefixed Code": "third_purchase_prefixed_code",
};

const dashboardCsv = {
  Date: "date",
  Clips: "clips",
  "Unique users (clips)": "clips_by_users",
  Redemptions: "redemptions",
  "Unique users (redemptions)": "redemptions_by_users",
};

const invites = ["email", "first_name", "last_name"];

export default { campaigns, campaignsCsv, dashboardCsv, invites };
