import React, { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Breadcrumb, Datatable, Table } from "components";

import { Card, CardBody, CardHeader, Container } from "reactstrap";
import { Avatar, Button } from "@mui/material";
import { Pathnames } from "routes/Pathnames";

const columns = [
  { label: "DATETIME", align: "left" },
  { label: "RECEIPT ID", align: "left" },
  { label: "PREVIEW", align: "left" },
  { label: "Actions", align: "center" },
];

const data = [
  ["23rd May, 2023", "1", null],
  ["22nd May, 2023", "2", null],
  ["21st May, 2023", "3", null],
];

const filters = [
  {
    label: "Grocery Chain",
    options: ["All", "GC10", "GC9", "GC8"],
  },
  {
    label: "Store",
    options: ["All", "Store1", "Store2", "Store3"],
  },
];

const EReceipts = () => {
  return (
    <Fragment>
      <Container fluid={true}>
        <Card>
          <CardBody>
            <div className="category-table media-table coupon-list-delete">
              <Table
                selection
                columns={columns}
                actions={[]}
                filters={filters}
                data={data}
              />
            </div>
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
};

export default EReceipts;
