import {
  Avatar,
  Button,
  ClickAwayListener,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Grow,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { ConnectToTCBAlert, Table, ConfirmationModal, SeeAllDrawer, QRCodeDisplay } from "components";
import React, { useEffect, useMemo, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Graphic from "assets/images/no-data.png";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router";
import { Pathnames } from "routes/Pathnames";
import {
  useDeleteGallery,
  useGetCampaignGalleryList,
  useGetGalleryDetails,
  usePatchShareGalleries,
} from "queries/gallery";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import WestIcon from "@mui/icons-material/West";
import DeleteIcon from "@mui/icons-material/Delete";
import { useToasts } from "react-toast-notifications";
import BoltIcon from "@mui/icons-material/Bolt";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import AddIcon from "@mui/icons-material/Add";
import { useGetAllEnterprises } from "queries/enterprise";
import ShareIcon from "@mui/icons-material/Share";
import { useGetUserDetails } from "queries/auth";
import GalleryPreview from "./GalleryPreview";

const ActionColumn = ({ row, isShared, isActive, handleOpenQRCode, handleOpenActionPopper }) => (
  <div className="d-flex align-items-center justify-content-end">
    <IconButton onClick={() => handleOpenQRCode(row)} size="small" color={isActive ? "white" : "gray.300"}>
      <QrCodeScannerIcon />
    </IconButton>
    {!isShared ? (
      <IconButton
        size="small"
        onClick={(e) => handleOpenActionPopper(e, row)}
        id="composition-button"
        aria-controls={"composition-menu"}
        aria-expanded={"true"}
        aria-haspopup="true"
        color={isActive ? "white" : "gray.300"}
      >
        <BoltIcon />
      </IconButton>
    ) : null}
    <IconButton size="small" sx={{ p: 0 }} color={"gray.300"}>
      <KeyboardArrowRightIcon />
    </IconButton>
  </div>
);

const GalleryQRCodeModal = ({ open, gallery, handleClose }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <IconButton sx={{ marginLeft: "-10px" }} onClick={handleClose}>
          <WestIcon />
        </IconButton>{" "}
        <Typography variant="h5" fontWeight={700} textAlign={"center"}>
          {gallery?.name}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <div className="gap-1 d-flex flex-column">
          <QRCodeDisplay
            link={gallery?.link}
            title={gallery?.name}
            description={""}
            label={
              <>
                Explore this gallery on Gitsy app by scanning this <br /> QR Code or{" "}
                <a target="_blank" href={gallery?.link}>
                  click here
                </a>
              </>
            }
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

const ActionsPopper = ({ open, data, handleClose, handleOpenShareDrawer }) => {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const { mutate: deletGallery } = useDeleteGallery();

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleDeleteGallery = () => {
    deletGallery(data?.id, {
      onSuccess: () => {
        addToast("Successfully deleted gallery", {
          appearance: "success",
        });
        handleCloseDeleteModal();
      },
    });
  };

  const actions = useMemo(
    () => [
      {
        label: "Edit",
        icon: <EditIcon />,
        onClick: () =>
          navigate(Pathnames.EDIT_GALLERY.replace(":gallery_id", data?.id), {
            state: {
              data,
            },
          }),
      },
      {
        label: "Share",
        icon: <ShareIcon />,
        onClick: () => handleOpenShareDrawer(data),
      },
      {
        label: "Delete",
        icon: <DeleteIcon sx={{ color: "#F97066" }} />,
        onClick: () => handleOpenDeleteModal(),
      },
    ],
    [data]
  );

  return (
    <>
      <Popper
        open={!!open}
        anchorEl={open}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        sx={{ zIndex: 100 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  //   onKeyDown={handleListKeyDown}
                >
                  {actions?.map((item) => (
                    <MenuItem sx={{ width: "100%" }} onClick={item?.onClick} key={item?.label}>
                      {item?.icon && <ListItemIcon>{item?.icon}</ListItemIcon>}
                      <ListItemText>{item?.label}</ListItemText>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <ConfirmationModal
        title={
          <span>
            Are you sure you want to delete <br />"<b className="text-black">{data?.name}"</b>?
          </span>
        }
        open={openDeleteModal}
        handleClose={handleCloseDeleteModal}
        handleProceed={handleDeleteGallery}
      />
    </>
  );
};

const Galleries = ({ galleries, isShared }) => {
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [activeRow, setAcitveRow] = useState(null);
  const [search, setSearch] = useState("");
  const [openActions, setOpenActions] = useState({
    anchor: null,
    data: null,
  });
  const [openQRCode, setOpenQRCode] = useState({ open: false, data: null });
  const [selectedEnterprise, setSelectedEnterprise] = useState([]);
  const [openShareGallery, setOpenSharaGallery] = useState({
    isOpen: false,
    data: null,
  });

  const { data: userDetails } = useGetUserDetails();
  const { data: allEnterprises, isLoading: isLoadingAllEnterprises } = useGetAllEnterprises(openShareGallery?.isOpen);
  const { mutate: patchShareGallery } = usePatchShareGalleries(openShareGallery?.isOpen);

  const handleOpenQRCode = (row) => {
    setOpenQRCode({
      open: true,
      data: row,
    });
  };

  const handleCloseQRCode = () => {
    setOpenQRCode((state) => ({
      ...state,
      open: false,
    }));
  };

  const handleOpenActionPopper = (e, row) => {
    setOpenActions({ anchor: e.currentTarget, data: row });
  };
  const handleCloseActionPopper = () => {
    setOpenActions({ anchor: null, data: null });
  };

  const handleOpenShareDrawer = (data) => {
    setOpenSharaGallery({
      isOpen: true,
      data,
    });
  };

  const handleCloseShareDrawer = () => {
    setOpenSharaGallery({
      isOpen: false,
      data: null,
    });
  };

  const handleSelectEnterprise = (item) => {
    setSelectedEnterprise((state) => [...state, item?.id]);
  };

  const handleUnselectEnterprise = (item) => {
    setSelectedEnterprise((state) => {
      return state?.filter((x) => x !== item?.id);
    });
  };

  const handleSubmit = () => {
    patchShareGallery(
      {
        data: {
          enterprises: selectedEnterprise,
        },
        gallery_id: openShareGallery?.data?.id,
      },
      {
        onSuccess: () => {
          addToast("Successfully shared gallery", {
            appearance: "success",
          });
          handleCloseShareDrawer();
        },
      }
    );
  };

  const columns = useMemo(
    () => [
      {
        label: "Image",
        key: "image_path",
        align: "left",
        default: true,
        width: 50,
        render: (value) => <Avatar src={value} alt={value} />,
      },
      {
        label: "Title",
        key: "name",
        align: "left",
        default: true,
        render: (value, _, isActive) => (
          <Typography fontWeight={600} color={isActive && "white.main"}>
            {value}
          </Typography>
        ),
      },
      {
        label: "",
        key: "",
        align: "left",
        default: true,
        render: (_, row, isActive) => (
          <ActionColumn
            isShared={isShared}
            row={row}
            isActive={isActive}
            handleOpenQRCode={handleOpenQRCode}
            handleOpenActionPopper={handleOpenActionPopper}
          />
        ),
      },
    ],
    [isShared]
  );

  // const filteredCampaigns = useMemo(
  //   () => filteredDataOnSearch(galleryDetails?.base_gs1s, campaignSearch, searchKeys.campaigns),
  //   [campaignSearch, galleryDetails]
  // );
  // const filteredCampaigns = useMemo(
  //   () => filteredDataOnSearch(activeRow?.base_gs1s, campaignSearch, searchKeys.campaigns),
  //   [campaignSearch, activeRow]
  // );

  const filteredEnterprise = useMemo(
    () => allEnterprises?.filter((x) => x?.id !== userDetails?.current_enterprise?.id),
    [userDetails, allEnterprises]
  );

  useEffect(() => {
    if (galleries?.length) setAcitveRow(galleries?.[0]);
  }, [galleries]);

  useEffect(() => {
    if (openActions?.data?.shared_enterprise) {
      setSelectedEnterprise(openActions?.data?.shared_enterprise ?? []);
    }
  }, [openActions]);

  return (
    <>
      {!galleries?.length ? (
        <div className="d-flex align-items-center text-center flex-column w-100">
          <img src={Graphic} height={350} alt="graphic-2" />
          <Typography className="mb-3">You do not have any galleries yet. Click here to add one</Typography>
          <Button onClick={() => navigate(Pathnames.CREATE_GALLERY)}>Create Gallery</Button>
        </div>
      ) : (
        <>
          <Grid container spacing={1.5} alignItems="stretch">
            <Grid item xs={4}>
              <Table
                searchPlaceholder={"Search for galleries..."}
                // borderRadius={"8px 0px 0px 8px"}
                selectionKey={"id"}
                hideHeader
                searchFullWidth
                handleOnClickRow={(row) => setAcitveRow(row)}
                search={search}
                searchKeys={["name", "description"]}
                activeRow={activeRow?.id}
                columns={columns}
                data={galleries}
                handleUpdateSearch={(e) => setSearch(e.target.value)}
              />
              <GalleryQRCodeModal open={openQRCode?.open} gallery={openQRCode?.data} handleClose={handleCloseQRCode} />
              <ActionsPopper
                open={openActions?.anchor}
                data={openActions?.data}
                handleClose={handleCloseActionPopper}
                handleOpenShareDrawer={handleOpenShareDrawer}
              />
            </Grid>

            <GalleryPreview activeRow={activeRow} />
          </Grid>
          <SeeAllDrawer
            data={filteredEnterprise}
            isOpen={openShareGallery?.isOpen}
            title={"Share Galleries"}
            description={"Select the enterprise to share galleries with."}
            placeholder={`Search for enterprises...`}
            isLoading={isLoadingAllEnterprises}
            handleCloseAllProducts={handleCloseShareDrawer}
            handleRemoveProduct={handleUnselectEnterprise}
            handleAddProduct={handleSelectEnterprise}
            isSelectedCondition={(item) => selectedEnterprise?.some((x) => x === item?.id)}
            uniqueKey={"id"}
            displayLabel={"alias_name"}
            backupDisplayLabel={"name"}
            displayAvatar={"image_path"}
            handleSubmit={handleSubmit}
            submitLabel="Share"
            submitDisabled={!selectedEnterprise?.length}
          />
        </>
      )}
    </>
  );
};

const ListGallery = () => {
  const navigate = useNavigate();

  const { data, isLoading } = useGetCampaignGalleryList(true);

  return (
    <ConnectToTCBAlert isLoading={isLoading}>
      <div>
        <Container fluid={true}>
          <Tabs>
            <div className="d-flex gap-4 mb-2 justify-content-between w-100">
              <TabList className="nav nav-tabs tab-coupo w-100">
                <Tab className="nav-link">My Galleries</Tab>
                <Tab className="nav-link">Shared Galleries</Tab>
              </TabList>
              <div className="d-flex gap-2 justify-content-end flex-shrink-0">
                <Button endIcon={<AddIcon />} onClick={() => navigate(Pathnames.CREATE_GALLERY)}>
                  CREATE GALLERY
                </Button>
              </div>
            </div>
            <TabPanel>
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="12">
                      <Galleries galleries={data?.galleries} />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </TabPanel>
            <TabPanel>
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="12">
                      <Galleries galleries={data?.shared_galleries} isShared />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </TabPanel>
          </Tabs>
        </Container>
      </div>
    </ConnectToTCBAlert>
  );
};

// #50a1eb
export default ListGallery;
