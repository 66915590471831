import { Box, Button, TextField, Typography, Autocomplete, Chip } from "@mui/material";
import { AddBrand, LoadingWrapper, ORDivider, SelectButton } from "components";
import { useEffect, useMemo, useState } from "react";
import StepperControls from "../StepperControls";
import { useCampaignContext } from "..";
import AddIcon from "@mui/icons-material/Add";
import { useGetBrands, usePostAddBrand } from "queries/brands";
import { useGetUserDetails } from "queries/auth";
import { isObjectsAreEqual } from "utils";
import SearchIcon from "@mui/icons-material/Search";

const SelectBrand = () => {
  const [selectedBrand, setSelectedBrand] = useState(null);
  const {
    isEditable,
    formData: { brand },
    handleSetValues,
  } = useCampaignContext();
  const [isAddBrandOpen, setIsAddBrandOpen] = useState(false);

  const { data: brands, isLoading } = useGetBrands(isEditable);
  const { data: userDetails } = useGetUserDetails();

  const isDisabled = useMemo(
    () => isEditable && isObjectsAreEqual(selectedBrand, brand),
    [selectedBrand, brand, isEditable]
  );

  const handleSelectBrand = (item) => {
    setSelectedBrand((state) => (item?.name === state?.name ? null : item));
  };

  const handleOnSubmit = () => {
    handleSetValues("brand", selectedBrand);
  };

  useEffect(() => {
    if (brand) setSelectedBrand(brand);
  }, [brand]);

  return (
    <div data-aos="fade-left" className="gap-4 d-flex flex-column">
      <Typography variant="h5">
        <span sx={{ textTransform: "capitalize" }}>Hello {userDetails?.first_name}</span>
        ,👋
        <br />
        For which <span className="fw-semibold">brand</span> you want to create a campaign ?*
      </Typography>
      <LoadingWrapper isLoading={isLoading} style={{ height: 150 }}>
        <Autocomplete
          sx={{
            width: "40%",
          }}
          value={selectedBrand}
          onChange={(e, value) => handleSetValues("brand", value)}
          // filterOptions={filterOptions}
          disablePortal
          loading={isLoading}
          options={brands?.data}
          getOptionLabel={(option) => option?.name}
          fullWidth
          renderInput={(params) => (
            <TextField {...params} variant="filled" placeholder="Search a brand" autoComplete="off" />
          )}
        />

        <Box className="d-flex gap-2 flex-wrap">
          <Button
            variant="roundedContained"
            fontWeight={600}
            style={{ letterSpacing: "1px" }}
            endIcon={<AddIcon />}
            onClick={() => setIsAddBrandOpen(true)}
          >
            ADD BRAND
          </Button>
          {brands?.data?.map((item) => (
            <div key={item?.internal_id}>
              <SelectButton selected={selectedBrand?.name} title={item?.name} onClick={() => handleSelectBrand(item)} />
            </div>
          ))}
        </Box>

        <AddBrand
          isOpen={isAddBrandOpen}
          handleClose={() => setIsAddBrandOpen(false)}
          handleSelectedBrand={(item) => handleSetValues("brand", item)}
        />
      </LoadingWrapper>
      <StepperControls onClickNext={handleOnSubmit} isDisabled={!selectedBrand || isLoading || isDisabled} />
    </div>
  );
};

export default SelectBrand;
