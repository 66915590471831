import { useState, useMemo, createContext, useContext } from "react";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
import { Checkbox, DateRangePicker } from "components";
import {
  Box,
  Button,
  Dialog,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  CircularProgress,
  ListItemIcon,
  ListItemText,
  ClickAwayListener,
  MenuList,
  Popper,
  Grow,
  FormControl,
  Select,
  TableBody,
} from "@mui/material";
import TableSortLabel from "@mui/material/TableSortLabel";
import MenuItem from "@mui/material/MenuItem";
import BoltIcon from "@mui/icons-material/Bolt";
import get from "lodash/get";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { filteredDataOnSearch } from "utils";
import * as React from "react";
import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import TuneIcon from "@mui/icons-material/Tune";
import CloseIcon from "@mui/icons-material/Close";
import { visuallyHidden } from "@mui/utils";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { useGetUserDetails } from "queries/auth";

const ActionsContext = createContext(null);

const styles = {
  height: "calc(100vh - 350px)",
  marginTop: "15vh",
};

const ActionsPopper = ({ actions, action, setAction, handleOnClick }) => {
  const open = Boolean(action?.anchor);

  const handleClose = () => {
    setAction((state) => ({
      ...state,
      anchor: null,
    }));

    // setTimeout(
    //   () =>
    //     setAction((state) => ({
    //       ...state,
    //       data: null,
    //     })),
    //   300
    // );
  };

  return (
    <>
      <Popper
        open={!!open}
        anchorEl={action?.anchor}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  //   onKeyDown={handleListKeyDown}
                >
                  {actions?.map((item, i) => {
                    if (item?.enabled && !item?.enabled(action?.data)) return null;
                    return (
                      <MenuItem
                        key={i}
                        sx={{ width: "100%" }}
                        onClick={() => {
                          item?.onClick ? item?.onClick(action?.data) : handleOnClick(i);
                          handleClose();
                        }}
                      >
                        {item?.icon && <ListItemIcon>{item?.icon(action?.data)}</ListItemIcon>}
                        <ListItemText>{item?.label(action?.data)}</ListItemText>
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

const DataStatusWrapper = ({ children, isLoading, length, search }) => {
  if (isLoading)
    return (
      <Box
        sx={{ height: "200px" }}
        className="d-flex top-0 position-absolute w-100 justify-content-center align-items-center m-auto"
      >
        <CircularProgress />
      </Box>
    );
  if (length === 0)
    return (
      <Box
        sx={{ height: "200px" }}
        className="d-flex w-100 top-0 position-absolute flex-column justify-content-center align-items-center"
      >
        <DescriptionOutlinedIcon fontSize="large" color="gray" />
        <Typography fontWeight={500} color="gray.600" textAlign={"center"}>
          {search ? (
            <>
              No results were found for
              <br /> <b className="text-black"> "{search}"</b>
            </>
          ) : (
            "No results found"
          )}
        </Typography>
      </Box>
    );

  return children;
};

const PaginationControls = ({ pagination, setPagination, setSelectedRows, lastEvaluatedKey }) => {
  const handleOnPerpage = (e) => {
    setPagination((state) => ({ ...state, nextKey: null, previousKey: [], limit: e.target.value }));
    setSelectedRows({});
  };
  return (
    <Box className="w-100 d-flex p-2 justify-content-between align-items-center" sx={{ bgcolor: "gray.100" }}>
      <div className="d-flex gap-2 align-items-center">
        <Typography fontWeight={500} color="gray.400" variant="body2">
          Per page :
        </Typography>
        <FormControl sx={{ minWidth: 120 }}>
          <Select
            value={pagination?.limit}
            onChange={handleOnPerpage}
            id="grouped-select"
            sx={{ "&.MuiSelect-select": { padding: "0px!important" } }}
          >
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={75}>75</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="d-flex gap-1">
        <Button
          color="black"
          size="small"
          variant="text"
          disabled={pagination?.previousKey?.length === 0}
          startIcon={<KeyboardArrowLeft sx={{ height: 20, width: 20 }} />}
          onClick={() =>
            setPagination((state) => ({
              ...state,
              nextKey: state?.previousKey?.at(-1),
              previousKey: state?.previousKey?.slice(0, -1),
            }))
          }
        >
          Prev
        </Button>
        <Button
          color="black"
          size="small"
          variant="text"
          endIcon={<KeyboardArrowRight sx={{ height: 20, width: 20 }} />}
          disabled={!lastEvaluatedKey}
          onClick={() =>
            setPagination((state) => ({
              ...state,
              previousKey: [...state?.previousKey, state?.nextKey],
              nextKey: encodeURIComponent(JSON.stringify(lastEvaluatedKey)),
            }))
          }
        >
          Next
        </Button>
      </div>
    </Box>
  );
};

export default function CustomTable({
  height,
  defaultSortBy,
  searchPlaceholder,
  hideHeader,
  defaultColumns = [],
  isDesc,
  isLoading,
  search,
  hideSearch,
  searchFullWidth = false,
  activeRow,
  selection,
  selectionKey,
  selectedRows = [],
  setSelectedRows = () => [],
  data = [],
  searchKeys,
  columns = [],
  actions = [],
  actionsCondition = () => {},
  disabledRowCondition = () => {},
  toolbar,
  Filters,
  handleUpdateSearch,
  handleOnClickRow = () => {},
  setPagination,
  pagination,
  lastEvaluatedKey,
}) {
  const [sortBy, setSortBy] = useState(defaultSortBy);
  const [action, setAction] = useState({ anchor: null, data: null });
  const [visibleColumns, setVisibleCoulumns] = useState(defaultColumns);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const handleOnClick = (i) => setIsModalOpen(i);
  const handleCloseModal = () => setIsModalOpen(false);

  const { data: userDetails } = useGetUserDetails();

  const handleOpenActionPopper = (event, row) => {
    event.stopPropagation();
    setAction({ anchor: event.currentTarget, data: row });
  };

  const rows = useMemo(() => {
    const temp = filteredDataOnSearch(data, search, searchKeys) || [];
    // console.log("temo", temp);
    if (sortBy?.column && sortBy?.type === "asc") {
      temp?.sort((a, b) => new Date(a.campaign_start_time) - new Date(b.campaign_start_time));
    } else if (sortBy?.column && sortBy?.type === "desc") {
      temp?.sort((a, b) => new Date(b.campaign_start_time) - new Date(a.campaign_start_time));
    }
    return temp;
  }, [data, search, searchKeys, sortBy]);

  const handleOnSelectColumns = (index) => {
    const key = columns?.[index]?.key;
    setVisibleCoulumns((state) => {
      if (state?.includes(key)) return [...state]?.filter((col) => col !== key);
      else return [...state, key];
    });
  };

  const columnOptions = useMemo(
    () =>
      columns?.map((col) => ({
        label: col?.label,
        icon: <Checkbox checked={visibleColumns?.includes(col?.key)} />,
      })),
    [columns, visibleColumns]
  );

  const renderColumns = useMemo(() => {
    if (visibleColumns?.length) return columns?.filter((col) => visibleColumns?.includes(col?.key));
    return columns;
  }, [columns, visibleColumns]);

  const isAllSelected = useMemo(
    () =>
      rows?.length &&
      rows?.every((x) => selectedRows?.[pagination?.nextKey]?.some((y) => x?.[selectionKey] === y?.[selectionKey])),
    [rows, selectedRows, selectionKey]
  );

  const handleSelectRow = (e, row) => {
    e.stopPropagation();
    // setSelectedRows((state) => {
    //   if (state?.some((x) => x?.[selectionKey] === row?.[selectionKey])) {
    //     return state.filter((x) => x?.[selectionKey] !== row?.[selectionKey]);
    //   } else {
    //     return [...state, row];
    //   }
    // });
    setSelectedRows((state) => {
      const currentStack = pagination?.nextKey;
      if (
        state?.[currentStack]?.length &&
        state?.[currentStack]?.some((x) => x?.[selectionKey] === row?.[selectionKey])
      ) {
        return {
          ...state,
          [currentStack]: state?.[currentStack]?.filter((x) => x?.[selectionKey] !== row?.[selectionKey]),
        };
      } else {
        return {
          ...state,
          [currentStack]: [...(state?.[currentStack] ?? []), row],
        };
      }
    });
  };

  const handleSelectAllRows = () => {
    // setSelectedRows((state) => {
    //   if (isAllSelected) {
    //     return [];
    //   } else if (!!state?.length) {
    //     return [];
    //   } else {
    //     return [...(state ?? []), ...(rows ?? [])];
    //   }
    // });
    setSelectedRows((state) => {
      const currentStack = pagination?.nextKey;
      if (isAllSelected) {
        return {
          ...state,
          [currentStack]: [],
        };
      } else if (!!state?.[currentStack]?.length) {
        return {
          ...state,
          [currentStack]: [],
        };
      } else {
        return {
          ...state,
          [currentStack]: rows,
        };
      }
    });
  };

  const handleRequestSort = (event, property) => {
    setSortBy((state) => {
      const isAsc = state?.column === property && state?.type === "asc";
      return {
        type: isAsc ? "desc" : "asc",
        column: property,
      };
    });
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  return (
    <>
      {/* <div className="d-flex gap-2 my-2">
        {filters?.map((item) => {
          if (item?.type === "date")
            return <DatePicker value={null} disableTyping maxDate={new Date()} onChange={(val) => {}} />;
          return (
            <FormControl>
              <InputLabel htmlFor="grouped-select">{item?.label}</InputLabel>
              <Select defaultValue="All" id="grouped-select" label={item?.label}>
                {item?.options?.map((option, i) => (
                  <MenuItem value={option} key={i}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        })}
      </div> */}
      {!hideSearch && (
        <div className="mb-3 d-flex justify-content-between gap-2">
          <TextField
            fullWidth
            value={search}
            InputProps={{
              startAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: Filters && (
                <InputAdornment position="end">
                  <IconButton onClick={() => setIsFilterModalOpen(true)}>
                    <TuneIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={handleUpdateSearch}
            variant="filled"
            placeholder={searchPlaceholder ?? "Search..."}
            autoComplete="off"
          />
          {toolbar ? <div className="d-flex gap-2 align-items-center flex-shrink-0">{toolbar}</div> : null}
        </div>
      )}

      <Paper
        elevation={1}
        sx={{
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            width: "100%",
            overflowY: "auto",
            height: height ? height : styles.height,
          }}
        >
          <Table sx={{ minWidth: 650, position: "relative" }} aria-label="simple table" key={pagination?.nextKey}>
            <TableHead sx={{ zIndex: 5, position: "sticky", top: 0 }}>
              {!hideHeader && (
                <TableRow>
                  {selection && (
                    <TableCell padding="checkbox">
                      <IconButton onClick={handleSelectAllRows}>
                        {isAllSelected ? (
                          <Checkbox checked />
                        ) : !!selectedRows?.[pagination?.nextKey]?.length ? (
                          <IndeterminateCheckBoxIcon sx={{ height: 17, width: 17 }} color="primary" />
                        ) : (
                          <Checkbox checked={false} />
                        )}
                      </IconButton>
                    </TableCell>
                  )}
                  {renderColumns?.map((item) => (
                    <TableCell
                      key={item.key}
                      align={item.align}
                      sx={{
                        maxWidth: item?.maxWidth,
                        minWidth: item?.minWidth,
                        width: item?.width,
                      }}
                      sortDirection={sortBy?.column === item.key ? sortBy?.type : false}
                    >
                      {item?.sort ? (
                        <TableSortLabel
                          active={sortBy?.column === item.key}
                          direction={sortBy?.column === item.key ? sortBy?.type : "asc"}
                          onClick={createSortHandler(item.key)}
                        >
                          {sortBy?.column === item.key ? (
                            <Box component="span" sx={visuallyHidden}>
                              {sortBy?.type === "desc" ? "sorted descending" : "sorted ascending"}
                            </Box>
                          ) : null}
                          {item?.label}
                        </TableSortLabel>
                      ) : (
                        item?.label
                      )}
                    </TableCell>
                  ))}
                  {!!actions?.length ? <TableCell align="right"></TableCell> : null}
                  {/* <Dropdown
                  options={columnOptions}
                  handleOnClick={handleOnSelectColumns}
                  style={{ top: 6, right: 0, position: "absolute" }}
                >
                  <IconButton size="small">
                    <MoreVertIcon />
                  </IconButton>
                </Dropdown> */}
                </TableRow>
              )}
            </TableHead>
            <TableBody sx={{ position: "relative" }}>
              <DataStatusWrapper search={search} length={rows?.length} isLoading={isLoading}>
                {(isDesc ? [...rows].reverse() : [...rows]).map((row, i) => {
                  const isActive = !!activeRow && activeRow === row?.[selectionKey];
                  const isDisabled = disabledRowCondition(row);

                  const style = {
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: !isDisabled && "pointer",
                    // color: isActive && "#fff!important",
                    ...(isActive && {
                      backgroundColor: (theme) => isActive && `${theme.palette.primary[400]}!important`,
                    }),
                    ...(isDisabled && {
                      backgroundColor: (theme) => isDisabled && `${theme.palette.gray[50]}!important`,
                    }),
                    // backgroundColor: (theme) => isDisabled && `${theme.palette.gray[50]}!important`,
                  };

                  const isChecked = selectedRows?.[pagination?.nextKey]?.some(
                    (x) => x?.[selectionKey] === row?.[selectionKey]
                  );

                  const rowkey = selectionKey ? `${row?.[selectionKey]}-${isChecked}` : i;

                  return (
                    <TableRow hover={!isDisabled} key={rowkey} onClick={() => handleOnClickRow(row)} sx={style}>
                      {selection && (
                        <TableCell align={"center"} key={`${rowkey}-${isChecked}-selection-cell`}>
                          <IconButton onClick={(e) => handleSelectRow(e, row)}>
                            <Checkbox checked={isChecked} />
                          </IconButton>
                        </TableCell>
                      )}
                      {renderColumns?.map((cell) => {
                        const value = get(row, cell?.key);
                        const style = {
                          maxWidth: cell?.maxWidth,
                          minWidth: cell?.minWidth,
                          width: `${cell?.width}!important`,
                          color: isActive && "white!important",
                        };

                        const cellKey = `${rowkey}-${cell?.key}-${value}`;

                        return (
                          <TableCell align={cell?.align} component="th" scope="row" key={cellKey} sx={style}>
                            {!cell?.render ? (
                              <span className={"line-clamp"}>{value && value?.length ? value : "---"}</span>
                            ) : (
                              cell?.render(value, row, isActive)
                            )}
                          </TableCell>
                        );
                      })}
                      <TableCell align="right">
                        {!!actions?.length && actionsCondition(row) ? (
                          <IconButton
                            onClick={(e) => handleOpenActionPopper(e, row)}
                            ref={action?.anchor}
                            id="composition-button"
                            aria-controls={!!action?.anchor ? "composition-menu" : undefined}
                            aria-expanded={!!action?.anchor ? "true" : undefined}
                            aria-haspopup="true"
                          >
                            <BoltIcon />
                          </IconButton>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </DataStatusWrapper>
            </TableBody>
          </Table>
        </Box>
        {pagination ? (
          <PaginationControls
            lastEvaluatedKey={lastEvaluatedKey}
            setPagination={setPagination}
            pagination={pagination}
            setSelectedRows={setSelectedRows}
          />
        ) : null}
      </Paper>
      {!!actions?.length ? (
        <>
          <ActionsPopper actions={actions} setAction={setAction} action={action} handleOnClick={handleOnClick} />
          <Dialog
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={isModalOpen !== false}
            onClose={handleCloseModal}
            closeAfterTransition
            maxWidth="xs"
            // slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 400,
              },
            }}
          >
            <ActionsContext.Provider value={{ setIsModalOpen, row: action?.data }}>
              {actions?.[isModalOpen]?.component}
            </ActionsContext.Provider>
          </Dialog>
        </>
      ) : null}
      {Filters ? (
        <Filters
          open={isFilterModalOpen}
          setOpen={setIsFilterModalOpen}
          setPagination={setPagination}
          pagination={pagination}
        />
      ) : null}
    </>
  );
}

export const useTableActionContext = () => useContext(ActionsContext);
