import {
  Alert,
  AlertTitle,
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import Lottie from "react-lottie";
import Loader from "assets/data/gitsy_loading";
import { useNavigate } from "react-router";
import { Pathnames } from "routes/Pathnames";
import { useState } from "react";
import ReplayIcon from "@mui/icons-material/Replay";
import LogoutIcon from "@mui/icons-material/Logout";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: Loader,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const CampaignProgress = ({ open, setOpen, progress, setActiveStep, isEditable, handleSubmit }) => {
  console.log("progress", progress);
  const navigate = useNavigate();
  const [retry, setRetry] = useState(0);

  const handleOnTryAgain = () => {
    handleSubmit();
    setRetry((count) => count + 1);
  };

  const isPaused = progress?.some((x) => x?.isError);

  useEffect(() => {
    const isFinished = progress?.every((x) => (x?.isSuccess || x?.isError) && !x?.isLoading);
    // const isOpen = progress?.some((x) => x?.isLoading);

    // if (isOpen) setOpen(true);
    if (isFinished) {
      setTimeout(() => {
        setOpen(false);

        if (!isEditable) setActiveStep((curr) => curr + 1);
        // else navigate(Pathnames.LIST_CAMPAIGN);
      }, 1000);
    }
  }, [isEditable, navigate, progress, setActiveStep]);

  return (
    <Dialog
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => {}}
      closeAfterTransition
      maxWidth="sm"
      fullWidth
      // slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 400,
        },
      }}
    >
      <DialogContent>
        <div className="d-flex flex-column gap-5 align-items-center">
          <Lottie options={defaultOptions} height={100} width={100} isStopped={isPaused} />

          <div className="d-flex flex-column gap-2 align-items-center">
            {progress?.map((x) => (
              <Alert
                key={x?.label}
                sx={{ width: 500 }}
                iconMapping={{
                  info: x?.isLoading && <CircularProgress size={24} />,
                }}
                severity={x?.isSuccess ? "success" : x?.isError ? "warning" : "info"}
              >
                <Typography variant="body1">{x?.label}</Typography>
              </Alert>
            ))}
          </div>

          {progress?.some((x) => x?.isError) ? (
            <div className="d-flex flex-column gap-2 align-items-center">
              <Typography variant="body1" color="error">
                Something went wrong, please try again {retry >= 2 ? "after some time" : ""}
              </Typography>
              <div className="d-flex w-100 justify-content-center gap-2 align-items-center">
                <Button endIcon={<ReplayIcon />} disabled={retry >= 2} color="secondary" onClick={handleOnTryAgain}>
                  Try again
                </Button>
                <Button color="black" variant="text" endIcon={<LogoutIcon />} onClick={() => setOpen(false)}>
                  Cancel
                </Button>
              </div>
            </div>
          ) : (
            <div className="d-flex w-100 justify-content-center align-items-center">
              <Button color="black" variant="text" endIcon={<LogoutIcon />} onClick={() => setOpen(false)}>
                Dismiss in background
              </Button>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CampaignProgress;
