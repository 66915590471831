import { useCallback, useEffect, useState } from "react";
import { unstable_useBlocker as useBlocker } from "react-router-dom";

const useBlockNavigation = (isDirty) => {
  const [openPrompt, setOpenPrompt] = useState(false);

  // Allow the submission navigation to the same route to go through
  const shouldBlock = useCallback(
    ({ currentLocation, nextLocation }) =>
      isDirty && currentLocation.pathname !== nextLocation.pathname,
    [isDirty]
  );

  const blocker = useBlocker(shouldBlock);

  const handleOnNavigate = () => {
    blocker.proceed();
    setOpenPrompt(false);
  };

  // Clean the input after a successful submission
  const handleOnClose = () => {
    setOpenPrompt(false);
  };

  // Reset the blocker if the user cleans the form
  useEffect(() => {
    if (blocker.state === "blocked") {
      //   blocker.reset();
      setOpenPrompt(true);
    }
  }, [blocker]);
  return { openPrompt, handleOnClose, handleOnNavigate };
};

export default useBlockNavigation;
