import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QueryKeys } from "constants";
import { ApiConfig } from "constants";
import { AxiosInstance } from "services";

const patchProfile = (data) => {
  const method = "PATCH";
  const url = ApiConfig.PROFILE;
  return AxiosInstance({ method, url, data });
};

export const usePatchProfile = () => {
  const queryClient = useQueryClient();
  return useMutation(patchProfile, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.ME]);
    },
  });
};

