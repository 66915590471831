import { FormControl, Typography } from "@mui/material";
import CustomTextField from "./CustomTextField";
import SelectProducts from "./SelectProducts";
import { OfferTypes, PurchaseTypes, useCriteriaContext } from ".";

const BasicOffer = () => {
  const { selected } = useCriteriaContext();
  const currState = selected?.primary;
  const currType = PurchaseTypes?.item;
  const currOffer = OfferTypes?.discount_on_item;

  return (
    <div className="d-flex gap-3 my-3 flex-column">
      <div>
        <Typography variant="subtitle1">How much is the offer?</Typography>
        <Typography color="gray.400" variant="subtitle2">
          The maximum offer amount
        </Typography>
      </div>
      <FormControl sx={{ width: "20%" }}>
        <CustomTextField
          type={currOffer}
          item={"offer"}
          value={selected?.offer?.value}
          error={selected?.offer?.error}
        />
      </FormControl>
      <div>
        <Typography variant="subtitle1">Minimum Purchase Requirement</Typography>
        <Typography color="gray.400" variant="subtitle2">
          The minimum quantity of products a consumer must buy to qualify for this offer.
        </Typography>
      </div>

      <FormControl sx={{ width: "20%" }}>
        <CustomTextField
          type={currType}
          decimalNotAllowed
          item="primary"
          value={currState?.value}
          error={currState?.error}
        />
      </FormControl>
      <SelectProducts item={"primary"} isBasicOffer />
    </div>
  );
};

export default BasicOffer;
