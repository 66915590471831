import { CircularProgress } from "@mui/material";
import React from "react";
import { Button } from "reactstrap";

const LoadingButton = ({
  fullWidth,
  disabled,
  isLoading,
  children,
  onClick,
  type,
  color,
}) => {
  return (
    <Button
      style={fullWidth && { width: "100%" }}
      color={color ? color : "primary"}
      type={type}
      disabled={isLoading || disabled}
      onClick={onClick}
      block
    >
      {isLoading ? (
        <CircularProgress
          color="white"
          size={20}
          sx={{ margin: "-5px 13px" }}
        />
      ) : (
        children
      )}
    </Button>
  );
};

export default LoadingButton;
