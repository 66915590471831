import { CampaignCard, LoadingWrapper } from "components";
import {
  Box,
  Button,
  Paper,
  Typography,
  CircularProgress,
  Grid,
  TextField,
  InputAdornment,
  Tooltip,
  Backdrop,
} from "@mui/material";
import React, { useEffect, useMemo } from "react";
import SearchIcon from "@mui/icons-material/Search";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { QueryKeys, searchKeys } from "constants";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useIntersection } from "@mantine/hooks";
import { useRef } from "react";
import { encode, decode } from "js-base64";
import { useGetUserDetails } from "queries/auth";
import { getGalleryCampaigns } from "queries/gallery";
import { filteredDataOnSearch } from "utils";
import ExcelIcon from "assets/icons/Excel";
import CsvIcon from "assets/icons/CsvIcon";
import DropdownWrapper from "components/atoms/DropdownWrapper";
import { handleExportAsCSV, handleExportAsExcel } from "services";
import { parseCampaignDataForCsv, parseCampaignsDataForExcel } from "services/exportAsFile";
import { useState } from "react";
import DownloadingGraphic from "./DownloadingGraphic";

const downloadOptions = [
  // {
  //   icon: <PDFIcon width={24} height={24} />,
  //   label: "Export as PDF",
  //   key: "pdf_download",
  // },
  {
    icon: <ExcelIcon width={24} height={24} />,
    label: "Excel file (.xlsx)",
    key: "excel_download",
  },
  // {
  //   icon: <ExcelIcon width={24} height={24} />,
  //   label: "Export As Excel (Individual GTINs)",
  //   key: "excel_download_new",
  // },
  {
    icon: <CsvIcon width={22} height={22} />,
    label: "CSV file (.csv)",
    key: "csv_download",
  },
  // {
  //   icon: <CsvIcon width={22} height={22} />,
  //   label: "Export as CSV (Individual GTINs)",
  //   key: "csv_download_new",
  // },
];

const ExportOptions = ({ isLoading, title, queryKeys, activeRow, setIsDownloading }) => {
  const queryClient = useQueryClient();

  const handleOnClick = async (option) => {
    setIsDownloading(true);

    const campaigns = await handleFetchAllRecords(setIsDownloading);

    if (option?.key === "csv_download") {
      handleExportAsCSV(parseCampaignDataForCsv(campaigns, searchKeys.campaignsCsv), title);
    } else if (option?.key === "excel_download") {
      handleExportAsExcel(parseCampaignsDataForExcel(campaigns, searchKeys.campaignsCsv), title);
    }

    setIsDownloading(false);
  };

  const handleFetchAllRecords = async (setIsDownloading) => {
    let records = queryClient.getQueryData(queryKeys);
    let lastPage = records?.pages?.[records?.pages?.length - 1];

    while (!!lastPage?.base_gs1s?.length) {
      const lastEvaluatedKey = encode(JSON.stringify({ processed_base_gs1s: lastPage?.processed_base_gs1s }));
      await getGalleryCampaigns(activeRow?.id, lastEvaluatedKey)
        .then((x) => {
          lastPage = x;
          records = {
            pageParams: [...records?.pageParams, lastEvaluatedKey],
            pages: [...records?.pages, x],
          };
        })
        .catch(() => {
          lastPage = {};
          records = {
            pageParams: [...records?.pageParams, lastEvaluatedKey],
            pages: [
              ...records?.pages,
              {
                base_gs1s: [],
              },
            ],
          };
        });
    }

    queryClient.setQueryData(queryKeys, records);
    return records?.pages?.flatMap((x) => x?.base_gs1s);
  };

  return (
    <DropdownWrapper handleOnClick={handleOnClick} options={downloadOptions} selected={false} selectedKey={"key"}>
      <Tooltip title="Export campaigns in this gallery" placement="left">
        <Button variant="text" sx={{ minWidth: "20px!important", padding: "10px 10px" }} disabled={isLoading}>
          <FileDownloadIcon />
        </Button>
      </Tooltip>
    </DropdownWrapper>
  );
};

const GalleryPreview = ({ search, activeRow }) => {
  const lastRef = useRef(null);
  const query = encode(JSON.stringify({ processed_base_gs1s: activeRow?.processed_base_gs1s }));
  const [lastPage, setLastPage] = useState(null);
  const [campaignSearch, setCampaignSearch] = useState("");
  const [isDownloading, setIsDownloading] = useState(false);

  const { ref, entry } = useIntersection({
    root: lastRef.current,
    threshold: 1,
  });

  const { data: userDetails } = useGetUserDetails();
  // const { data: galleryDetails, isFetching } = useGetGalleryDetails(activeRow?.id);

  const {
    data: campaigns,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
  } = useInfiniteQuery({
    initialData: {
      pages: [activeRow],
      pageParams: [query],
    },
    enabled: !!userDetails?.current_enterprise?.id && !!activeRow?.id,
    queryKey: [QueryKeys.GALLERY_CAMPAIGNS, userDetails?.current_enterprise?.id, activeRow?.id],
    queryFn: async ({ pageParam }) => {
      const response = await getGalleryCampaigns(activeRow?.id, pageParam);
      setLastPage(response);
      return response;
    },
    getNextPageParam: (lastPage) => {
      if (lastPage?.processed_base_gs1s?.length && lastPage?.base_gs1s?.length) {
        const lastEvaluatedKey = encode(JSON.stringify({ processed_base_gs1s: lastPage?.processed_base_gs1s }));
        return lastEvaluatedKey;
      }

      return undefined;
    },
  });

  const filteredCampaigns = useMemo(() => {
    const data = campaigns?.pages?.flatMap((x) => x?.base_gs1s);
    return filteredDataOnSearch(data, search, searchKeys.campaigns);
  }, [campaigns?.pages, search]);

  useEffect(() => {
    if (entry?.isIntersecting && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [entry, fetchNextPage, isFetchingNextPage]);

  return (
    <Grid item xs={8}>
      <DownloadingGraphic open={isDownloading} />
      <div className="mb-3 d-flex gap-2 align-items-center">
        <TextField
          fullWidth
          value={campaignSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={(e) => setCampaignSearch(e.target.value)}
          variant="filled"
          placeholder={`Search for campaigns in ${activeRow?.name}`}
          autoComplete="off"
        />

        <ExportOptions
          activeRow={activeRow}
          queryKeys={[QueryKeys.GALLERY_CAMPAIGNS, userDetails?.current_enterprise?.id, activeRow?.id]}
          title={activeRow?.name}
          isLoading={isLoading}
          setIsDownloading={setIsDownloading}
        />
      </div>
      <Paper
        elevation={1}
        sx={{
          width: "100%",
          overflow: "auto",
          height: "calc(100vh - 350px)",
        }}
      >
        <LoadingWrapper isLoading={isLoading}>
          {!filteredCampaigns?.length ? (
            <Box className="d-flex w-100 h-100 flex-column border border-solid justify-content-center align-items-center">
              <DescriptionOutlinedIcon fontSize="large" color="gray" />
              <Typography fontWeight={500} color="gray.600" textAlign={"center"}>
                {!search?.length ? (
                  "No results found"
                ) : (
                  <>
                    No results were found for <br /> <b className="text-black"> "{search}"</b>
                  </>
                )}
              </Typography>
            </Box>
          ) : (
            <div className="position-relative">
              <div
                className="d-flex flex-column gap-2 h-100"
                // style={{
                //   minHeight: "calc(100vh - 405px)",
                // }}
              >
                {filteredCampaigns?.map((campaign, i) => (
                  <>
                    {i === filteredCampaigns?.length - 1 && <div ref={ref}></div>}
                    <CampaignCard campaign={campaign} key={campaign?.base_gs1} isPreview />
                  </>
                ))}
                {isFetchingNextPage ? (
                  <Box sx={{ height: "40px" }} className="d-flex my-2 w-100 justify-content-center align-items-center">
                    <CircularProgress size={20} />
                  </Box>
                ) : null}
              </div>
              {/* <Box
              className="w-100 d-flex p-2 position-sticky bottom-0 z-3 justify-content-end align-items-center"
              sx={{ bgcolor: "gray.100" }}
            >
              <div className="d-flex gap-1 ">
                <Button
                  color="black"
                  size="small"
                  variant="text"
                  // disabled={pagination?.previousKey?.length === 0}
                  startIcon={<KeyboardArrowLeft sx={{ height: 20, width: 20 }} />}
                  // onClick={() =>
                  //   setPagination((state) => ({
                  //     ...state,
                  //     nextKey: state?.previousKey?.at(-1),
                  //     previousKey: state?.previousKey?.slice(0, -1),
                  //   }))
                  // }
                >
                  Prev
                </Button>
                <Button
                  color="black"
                  size="small"
                  variant="text"
                  endIcon={<KeyboardArrowRight sx={{ height: 20, width: 20 }} />}
                  // disabled={!lastEvaluatedKey}
                  // onClick={() =>
                  //   setPagination((state) => ({
                  //     ...state,
                  //     previousKey: [...state?.previousKey, state?.nextKey],
                  //     nextKey: encodeURIComponent(JSON.stringify(lastEvaluatedKey)),
                  //   }))
                  // }
                >
                  Next
                </Button>
              </div>
            </Box> */}
            </div>
          )}
        </LoadingWrapper>
      </Paper>
    </Grid>
  );
};

export default GalleryPreview;
