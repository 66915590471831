import React, { Fragment, useMemo, useState } from "react";
import UserMenu from "./user-menu";
import BusinessIcon from "@mui/icons-material/Business";

import { Dropdown } from "components";
import useGlobalState from "store";
import { Chip, Divider, ListItemText, MenuItem, Typography, IconButton } from "@mui/material";
import { usePostSwitchEnterprise } from "queries/enterprise";
import { useGetUserDetails } from "queries/auth";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router";
import { Pathnames } from "routes/Pathnames";

import SearchHeader from "./searchHeader";
import Notification from "./notification";
import Language from "./language";
import { AlignLeft, Bell, MessageSquare, MoreHorizontal } from "react-feather";
import EnterpriseDropdown from "./enterprise-dropdown";
import { useQueryClient } from "@tanstack/react-query";
import { QueryKeys } from "constants";

const RenderEnterpriseOptions = (item, i, isSelected, handleOnClick) => {
  const navigate = useNavigate();
  if (item?.action)
    return (
      <>
        <MenuItem onClick={() => navigate(Pathnames.JOIN_OR_CREATE_ENTERPRISE)} sx={{ width: "100%" }}>
          <ListItemText>
            <div className="d-flex align-items-center justify-content-between w-100 gap-5">
              <AddIcon
                sx={{
                  width: "24px!important",
                  height: "24px!important",
                  margin: "0px!important",
                }}
                color="primary"
              />

              <Typography>{item?.action}</Typography>
            </div>
          </ListItemText>
        </MenuItem>
        <Divider />
      </>
    );
  else {
    return (
      <MenuItem selected={isSelected} onClick={() => handleOnClick && handleOnClick(item)} sx={{ width: "100%" }}>
        <ListItemText>
          <div className="d-flex align-items-center justify-content-between w-100 gap-5">
            <Typography>{item?.name}</Typography>
            <Chip color="secondary" label={item?.role} />
          </div>
        </ListItemText>
      </MenuItem>
    );
  }
};

const Header = () => {
  const queryClient = useQueryClient();
  const [sidebar, setSidebar] = useState(true);
  const [rightSidebar, setRightSidebar] = useState(true);
  const [navMenus, setNavMenus] = useState(false);
  const toggle = () => {
    // setNavMenus((prevState) => ({
    // 	navMenus: !prevState.navMenus,
    // }));
    setNavMenus(!navMenus);
  };

  // const enterpriseOptions = useMemo(() => {
  //   if (approvedEnterprises) return [{ action: "Add New Enterprise" }, ...approvedEnterprises];
  //   return [];
  // }, [approvedEnterprises]);

  const showRightSidebar = () => {
    if (rightSidebar) {
      setRightSidebar(false);
      document.querySelector(".right-sidebar").classList.add("show");
    } else {
      setRightSidebar(true);
      document.querySelector(".right-sidebar").classList.remove("show");
    }
  };

  // const goFull = () => {
  //   if (
  //     (document.fullScreenElement && document.fullScreenElement !== null) ||
  //     (!document.mozFullScreen && !document.webkitIsFullScreen)
  //   ) {
  //     if (document.documentElement.requestFullScreen) {
  //       document.documentElement.requestFullScreen();
  //     } else if (document.documentElement.mozRequestFullScreen) {
  //       document.documentElement.mozRequestFullScreen();
  //     } else if (document.documentElement.webkitRequestFullScreen) {
  //       document.documentElement.webkitRequestFullScreen(
  //         Element.ALLOW_KEYBOARD_INPUT
  //       );
  //     }
  //   } else {
  //     if (document.cancelFullScreen) {
  //       document.cancelFullScreen();
  //     } else if (document.mozCancelFullScreen) {
  //       document.mozCancelFullScreen();
  //     } else if (document.webkitCancelFullScreen) {
  //       document.webkitCancelFullScreen();
  //     }
  //   }
  // };

  const openCloseSidebar = () => {
    if (sidebar) {
      setSidebar(false);
      document.querySelector(".page-main-header").classList.add("open");
      document.querySelector(".page-sidebar").classList.add("open");
      document.querySelector(".footer").classList.add("open");
    } else {
      setSidebar(true);
      document.querySelector(".page-main-header").classList.remove("open");
      document.querySelector(".page-sidebar").classList.remove("open");
      document.querySelector(".footer").classList.remove("open");
    }
  };

  return (
    <Fragment>
      {/* open */}
      <div className="page-main-header print-hidden">
        <div className="main-header-right row">
          {/* <div className="main-header-left d-lg-none col-auto">
            <div className="logo-wrapper">
              <a href="index.html">
                <img className="blur-up lazyloaded w-100" src={logo} alt="" />
              </a>
            </div>
          </div> */}
          <div className="mobile-sidebar col-auto p-0">
            <div className="media-body text-end">
              <IconButton onClick={openCloseSidebar}>
                <AlignLeft />
              </IconButton>
            </div>
          </div>
          <div className="nav-right col">
            <ul className={"nav-menus " + (navMenus ? "open" : "")}>
              <li>{/* <SearchHeader /> */}</li>
              {/* <li>
                <a onClick={goFull} className="text-dark" href="">
                  <Maximize2 />
                </a>
              </li> */}
              <li className="onhover-dropdown">
                {/* <a className="txt-dark" href="javascript:;">
                  <h6>EN</h6>
                </a>
                <Language /> */}
              </li>

              <li className="onhover-dropdown">
                {/* <Bell />
                <span className="badge rounded-pill badge-primary pull-right notification-badge">3</span>
                <span className="dot"></span>
                <Notification /> */}
              </li>
              <li>
                {/* <a href="javascript:;" onClick={showRightSidebar}>
                  <MessageSquare />
                  <span className="dot"></span>
                </a> */}
              </li>
              <li className="enterprise-badge">
                <EnterpriseDropdown />

                {/* <span className="dot"></span> */}
              </li>
              <UserMenu />
            </ul>
            <div className="d-lg-none mobile-toggle pull-right" onClick={() => toggle()}>
              <MoreHorizontal />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Header;
