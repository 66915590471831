import React, {
  cloneElement,
  createContext,
  useContext,
  useState,
} from "react";

import AuthLayout from "../Layout";

import LoginForm from "./LoginForm";
import ChooseEnterprise from "./ChooseEnterprise";

const LoginContext = createContext();
const steps = [<LoginForm />, <ChooseEnterprise />];

const Login = () => {
  const [activeStep, setActiveStep] = useState(0);
  return (
    <LoginContext.Provider
      value={{
        setActiveStep,
      }}
    >
      <AuthLayout>{steps[activeStep]}</AuthLayout>
    </LoginContext.Provider>
  );
};

export default Login;
export const useLoginContext = () => useContext(LoginContext);
