import AWS from "aws-sdk";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
  signatureVersion: "v4",
});

const uploadToS3Bucket = async (payload, enterpriseId) => {
  const s3 = new AWS.S3();

  if (!payload?.file) {
    return;
  }
  const params = {
    Bucket: `${process.env.REACT_APP_AWS_S3_BUCKET}/${enterpriseId}/${payload?.image_type}`,
    Key: `${Date.now()}.${payload?.file.name}`,
    Body: payload?.file,
  };
  
  const { Location } = await s3.upload(params).promise();
  return Location;
};

export default uploadToS3Bucket;
