import { Button } from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Pathnames } from "routes/Pathnames";
import SaveIcon from "@mui/icons-material/Save";

import { yupResolver } from "@hookform/resolvers/yup";
import { yupSchema } from "constants";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { uploadToS3Bucket } from "services";
import { useGetUserDetails } from "queries/auth";
import { useGetGalleryDetails, usePatchCampaignGallery, usePostAddCampaignGallery } from "queries/gallery";

import SelectCampaigns from "./SelectCampaigns";
import BasicDetails from "./BasicDetails";
import BlockNavigationModal from "../CreateCampaign/BlockNavigationModal";

const CreateGallery = ({ isEditable }) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [disabled, setDisabled] = useState(isEditable ? true : false);

  const formMethods = useForm({
    resolver: yupResolver(yupSchema.ADD_GALLERY),
    defaultValues: {
      image: location?.state?.data?.image_path,
      campaigns: [],
      title: location?.state?.data?.name,
      description: location?.state?.data?.description,
    },
  });

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitted, dirtyFields },
  } = formMethods;

  const image = useWatch({ control, name: "image" });
  const campaigns = useWatch({ control, name: "campaigns" });

  const isDirty = !!Object.keys(dirtyFields)?.length || !!image || !!campaigns?.length; // don't wrap this in memo

  const { mutateAsync: postAddCampaignGallery } = usePostAddCampaignGallery();
  const { mutateAsync: patchCampaignGallery } = usePatchCampaignGallery(params?.gallery_id);
  const { data: userDetails } = useGetUserDetails();
  const { data: galleryDetails, isFetching } = useGetGalleryDetails(params?.gallery_id);

  const handleAddGallery = (formData) => {
    const payload = {
      file: formData?.image,
      image_type: "gallery_image",
    };

    uploadToS3Bucket(payload, userDetails?.current_enterprise?.id).then((imageUrl) => {
      const payload2 = {
        enterprise_id: userDetails?.current_enterprise?.id,
        name: formData?.title,
        description: formData?.description,
        image_path: imageUrl,
        base_gs1s: formData?.campaigns?.map((x, i) => ({
          base_gs1: x?.base_gs1,
          display_order: x?.display_order >= 0 ? i : -1,
        })),
      };
      postAddCampaignGallery(payload2)
        .then(() => {})
        .catch((err) => {
          setError("title", { type: "custom", message: err?.message });
        });
    });
  };

  const handleSaveGalleryDetails = (formData) => {
    if (typeof formData?.image === "string") {
      const payload2 = {
        gallery_id: params?.gallery_id,
        body: {
          enterprise_id: userDetails?.current_enterprise?.id,
          name: formData?.title,
          description: formData?.description,
          image_path: formData?.image,
          base_gs1s: formData?.campaigns?.map((x, i) => ({
            base_gs1: x?.base_gs1,
            display_order: x?.display_order >= 0 ? i : -1,
          })),
        },
      };

      patchCampaignGallery(payload2)
        .then(() => {})
        .catch((err) => {
          setError("title", { type: "custom", message: err?.message });
        });
    } else {
      const payload = {
        file: formData?.image,
        image_type: "gallery_image",
      };

      uploadToS3Bucket(payload, userDetails?.current_enterprise?.id).then((imageUrl) => {
        const payload2 = {
          gallery_id: params?.gallery_id,
          body: {
            enterprise_id: userDetails?.current_enterprise?.id,
            name: formData?.title,
            description: formData?.description,
            image_path: imageUrl,
            base_gs1s: formData?.campaigns?.map((x, i) => ({
              base_gs1: x?.base_gs1,
              display_order: x?.display_order >= 0 ? i : -1,
            })),
          },
        };
        patchCampaignGallery(payload2)
          .then(() => {})
          .catch((err) => {
            setError("title", { type: "custom", message: err?.message });
          });
      });
    }
  };

  const handleSubmitForm = (formData) => {
    if (isEditable) handleSaveGalleryDetails(formData);
    else handleAddGallery(formData);
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(handleSubmitForm)} id="add-gallery">
        <Container fluid={true}>
          <Row>
            <Col xl="5">
              <Card>
                <CardBody>
                  <BasicDetails setDisabled={setDisabled} />
                </CardBody>
              </Card>
            </Col>
            <Col xl="7">
              <Card>
                <CardBody>
                  <SelectCampaigns
                    setDisabled={setDisabled}
                    isLoading={isFetching}
                    isEditable={isEditable}
                    galleryDetails={galleryDetails}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div className="d-flex align-items-center gap-2 my-2">
            <Button
              variant="outlined"
              onClick={() => {
                if (location?.state?.selectedCampaigns) navigate(Pathnames.LIST_CAMPAIGN);
                else navigate(Pathnames.LIST_GALLERY);
              }}
            >
              CANCEL
            </Button>
            <Button
              type="submit"
              color="primary"
              form-id="add-gallery"
              variant="contained"
              disabled={isFetching || (isEditable && disabled && !isDirty)}
              endIcon={isEditable && <SaveIcon />}
            >
              {isEditable ? "SAVE" : "CREATE GALLERY"}
            </Button>
          </div>
        </Container>
      </form>
      <BlockNavigationModal
        title={isEditable ? "editing a gallery" : "creating a gallery"}
        condition={!isEditable && isDirty && !isSubmitted}
      />
    </FormProvider>
  );
};

export default CreateGallery;
