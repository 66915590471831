import React, { useEffect, useState } from "react";
import AuthLayout from "./Layout";
import { Avatar, Divider, FormHelperText, Paper, Typography } from "@mui/material";
import { Input, Label } from "reactstrap";
import { usePostAddNewEnterprise, usePostJoinEnterprise, usePostSwitchEnterprise } from "queries/enterprise";
import { useNavigate } from "react-router";
import { Pathnames } from "routes/Pathnames";
import { useGetUserDetails } from "queries/auth";
import { LoadingButton, LoadingWrapper } from "components";
import { useInviteCode } from "hooks";
import { useToasts } from "react-toast-notifications";
import BusinessIcon from "@mui/icons-material/Business";
import { getInitials } from "utils/helpers/parser";
import useGlobalState from "store";

const InitialErrors = {
  join: null,
  create: null,
};

const JoinOrCreateEnterpriseAuth = () => {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const { inviteDetails, isFetchingInviteDetails } = useInviteCode();
  const { approvedEnterprises } = useGlobalState();

  const [form, setForm] = useState({
    invite_code: null,
    enterprise_name: null,
  });
  const [error, setError] = useState(InitialErrors);

  const { mutate: postAddNewEnterprise, isLoading: isLoadingCreateEnt } = usePostAddNewEnterprise();
  const { mutate: postJoinEnterprise, isLoading: isLoadingJoinEnt } = usePostJoinEnterprise();
  const { data: userDetails } = useGetUserDetails();
  const { handleSwitchEnterprise } = usePostSwitchEnterprise(true);

  const handleAddNewEnterprise = () => {
    setError(InitialErrors);
    postAddNewEnterprise(
      {
        name: form?.enterprise_name,
        email: userDetails?.email,
        user_id: userDetails?.id,
      },
      {
        onSuccess: (res) => {
          navigate(Pathnames.ENTERPRISE_STATUS);
        },
        onError: (res) => setError({ create: res?.message }),
      }
    );
  };

  const handleJoinEnterprise = () => {
    setError(InitialErrors);
    postJoinEnterprise(
      { code: form?.invite_code },
      {
        onSuccess: (res) => {
          handleSwitchEnterprise({
            name: inviteDetails?.enterprise_name,
            role: inviteDetails?.role,
            id: inviteDetails?.enterprise_id,
            email: inviteDetails?.invited_by?.email,
            status: inviteDetails?.status ?? "Approved",
          });
          // navigate(Pathnames.DASHBOARD);
          addToast("Successfully joined enterprise", {
            appearance: "success",
          });
        },
        onError: (res) => setError({ join: res?.message }),
      }
    );
  };

  useEffect(() => {
    if (inviteDetails?.id) setForm((curr) => ({ ...curr, invite_code: inviteDetails?.id }));
  }, [inviteDetails, approvedEnterprises]);

  return (
    <AuthLayout slider>
      <LoadingWrapper isLoading={isFetchingInviteDetails} style={{ height: "360px" }}>
        {inviteDetails ? (
          <>
            <Paper sx={{ bgcolor: "gray.25" }} elevation={0} className="p-3 mb-3">
              <div className="d-flex flex-column align-items-center gap-1">
                <Avatar
                  src={inviteDetails?.image_path}
                  className="rounded-circle blur-up"
                  variant="circular"
                  sx={{ height: 60, width: 60 }}
                  // alt={initials}
                >
                  {!inviteDetails?.image_path ? getInitials(inviteDetails?.enterprise_name) : null}
                </Avatar>
                <Typography fontWeight={600} variant="h6" className="line-clamp-2" maxWidth={300}>
                  {inviteDetails?.enterprise_name}
                </Typography>
                <div className="mt-4 w-100">
                  <div className="d-flex gap-4">
                    <Typography fontWeight={600} variant="body2" className="w-25">
                      Role:
                    </Typography>
                    <Typography fontWeight={600} variant="body2" color="gray.500">
                      {inviteDetails?.role}
                    </Typography>
                  </div>
                  <div className="d-flex gap-4">
                    <Typography fontWeight={600} variant="body2" className="w-25">
                      Name:
                    </Typography>
                    <Typography fontWeight={600} variant="body2" color="gray.500">
                      {inviteDetails?.first_name} {inviteDetails?.last_name}
                    </Typography>
                  </div>
                  <div className="d-flex gap-4">
                    <Typography fontWeight={600} variant="body2" className="w-25">
                      Email:
                    </Typography>
                    <Typography fontWeight={600} variant="body2" color="gray.500">
                      {inviteDetails?.email}
                    </Typography>
                  </div>
                  <div className="d-flex gap-4 flex-shrink-0">
                    <Typography fontWeight={600} variant="body2" className="w-25">
                      Invited by:
                    </Typography>
                    <Typography fontWeight={600} variant="body2" color="gray.500">
                      {inviteDetails?.invited_by?.first_name} {inviteDetails?.invited_by?.last_name}
                      <br />({inviteDetails?.invited_by?.email})
                    </Typography>
                  </div>
                </div>
              </div>
            </Paper>
            <LoadingButton
              fullWidth
              isLoading={isLoadingJoinEnt}
              disabled={!form?.invite_code?.length}
              onClick={handleJoinEnterprise}
            >
              Accept Invite
            </LoadingButton>
            <FormHelperText error={error}>{error?.join}</FormHelperText>
          </>
        ) : (
          <div className="form-horizontal auth-form" style={{ height: "380px" }}>
            <Typography variant="h6" align="left" fontWeight={500} className="pb-1">
              Join enterprise
            </Typography>
            <Label className="col-form-label">
              Enter the invite code received in your email <span>*</span>
            </Label>
            <div className="d-flex align-items-center gap-2">
              <Input
                value={form?.invite_code}
                onChange={(e) => setForm((curr) => ({ ...curr, invite_code: e.target.value }))}
                required=""
                type="text"
                className="form-control"
                placeholder="Enter invite code"
              />
              <LoadingButton
                isLoading={isLoadingJoinEnt}
                disabled={!form?.invite_code?.length}
                onClick={handleJoinEnterprise}
              >
                JOIN
              </LoadingButton>
            </div>
            <div style={{ display: "flex", alignItems: "center", margin: "20px 0" }}>
              <Divider style={{ flex: 1 }} />
              <Typography variant="body1" style={{ margin: "0 16px" }}>
                OR
              </Typography>
              <Divider style={{ flex: 1 }} />
            </div>
            <Typography variant="h6" align="left" fontWeight={500} className="pb-1">
              Create enterprise
            </Typography>

            <Label className="d-block pb-2 fw-medium">
              Enterprise name <span>*</span>
            </Label>
            <div className="d-flex align-items-center gap-2">
              <Input
                value={form?.enterprise_name}
                onChange={(e) => setForm((curr) => ({ ...curr, enterprise_name: e.target.value }))}
                required=""
                type="text"
                className="form-control"
                placeholder="Enter enterprise name"
              />
              <LoadingButton
                isLoading={isLoadingCreateEnt}
                disabled={isLoadingCreateEnt || !form?.enterprise_name?.length}
                onClick={handleAddNewEnterprise}
              >
                NEXT
              </LoadingButton>
              <FormHelperText error={error}>{error?.message}</FormHelperText>
            </div>
          </div>
        )}
      </LoadingWrapper>
    </AuthLayout>
  );
};

export default JoinOrCreateEnterpriseAuth;
