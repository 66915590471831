import { useEffect, useRef } from "react";
import { useRangeCalendarState } from "@react-stately/calendar";
import { useRangeCalendar } from "@react-aria/calendar";
import { createCalendar, getLocalTimeZone } from "@internationalized/date";
import { CalendarGrid } from "./CalendarGrid";
import { Box, Typography, Button, TextField } from "@mui/material";
import { useDateFormatter } from "react-aria";
import { CalendarButton } from "./Button";
import { CalendarSideBar } from "./CalendarSideBar";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

export function RangeCalendar(props) {
  let state = useRangeCalendarState({
    ...props,
    visibleDuration: { months: 2 },
    locale: "en-US",
    createCalendar,
  });

  // minValue={today(getLocalTimeZone()).subtract({ days: 30 })}
  let ref = useRef();
  let { calendarProps, prevButtonProps, nextButtonProps } = useRangeCalendar(props, state, ref);

  let monthDateFormatter = useDateFormatter({
    month: "long",
    year: "numeric",
    timeZone: state.timeZone,
  });

  let dateFormatter = useDateFormatter({
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  useEffect(() => {
    if (state?.value?.start === state?.value?.end) state.setAnchorDate(state?.value?.start);
  }, [state?.value?.start, state?.value?.end]);

  return (
    <Box {...calendarProps} ref={ref}>
      <Box display={"flex"}>
        <Box borderRight="1px solid" borderColor="gray.300">
          <CalendarSideBar state={state} />
        </Box>
        <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
          <Box display="flex" gap={2} padding="16px 8px" justifyContent={"center"}>
            <Box>
              <Box display={"flex"} alignItems="center" padding={"8px 0px"}>
                <CalendarButton {...prevButtonProps}>
                  <KeyboardArrowLeft />
                </CalendarButton>
                <Typography as="h2" size="xs" flex="1" align="center" color="gray.400">
                  {monthDateFormatter.format(state?.visibleRange?.start?.toDate(state.timeZone))}
                </Typography>
              </Box>

              <CalendarGrid state={state} />
            </Box>

            <Box>
              <Box display={"flex"} alignItems="center" padding={"8px 0px"}>
                <Typography as="h2" size="xs" flex="1" align="center" color="gray.400">
                  {monthDateFormatter.format(state?.visibleRange?.start?.add({ months: 1 }).toDate(state.timeZone))}
                </Typography>
                <CalendarButton {...nextButtonProps}>
                  <KeyboardArrowRight />
                </CalendarButton>
              </Box>

              <CalendarGrid state={state} offset={{ months: 1 }} />
            </Box>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent="space-between"
            borderTop="1px solid"
            borderColor="gray.300"
            padding={"8px"}
          >
            <Box display={"flex"} flexDirection={"row"} gap={1} alignItems="center">
              <TextField
                disabled
                sx={{ width: "130px" }}
                aria-label="Selected Start Date"
                value={dateFormatter.format(props.calendarState?.dateRange?.start?.toDate(getLocalTimeZone()))}
              />

              <TextField
                disabled
                sx={{ width: "130px" }}
                aria-label="Selected End Date"
                value={dateFormatter.format(props.calendarState?.dateRange?.end?.toDate(getLocalTimeZone()))}
              />
            </Box>
            <Box display="flex" flexDirection="row" gap={1}>
              <Button onClick={props.handleCancel} color="black" variant="text">
                Cancel
              </Button>
              <Button size={"small"} onClick={props.handleApply}>
                Apply
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
