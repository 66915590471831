import React, { useEffect, useMemo, useRef, useState } from "react";
import { Alert, Button, CircularProgress, Grid, IconButton, Typography } from "@mui/material";
import SummaryCard from "./SummaryCard";
import { campaignSteps, useCampaignContext } from "../..";
import WestIcon from "@mui/icons-material/West";
import { usePatchProvidersToggle, usePatchRetailersToggle, usePostCreateCampaign } from "queries/campaign";
import createPayload from "../../CreatePayload";
import { OfferValue, PurchaseRequirementValue, generateCampaignText } from "utils/generateCampaignText";
import EditBasicDetails from "../PurchaseCriteria/EditBasicDetails";
import { useLocation, useNavigate, useParams } from "react-router";
import { format } from "date-fns";
import { Pathnames } from "routes/Pathnames";
import { useToasts } from "react-toast-notifications";
import AppPreview from "./AppPreview";
import { uploadToS3Bucket } from "services";
import { useGetUserDetails } from "queries/auth";
import { ConfirmationModal, SeeAllDrawer } from "components";
import { isObjectsAreEqual } from "utils";
import { useGetABrand } from "queries/brands";
import Assignments from "./Assignments";
import { isEqual, difference, union } from "lodash";
import { useGetEnterpriseDetails } from "queries/enterprise";
import ProgressAnimation from "./CampaignProgress";
import CampaignProgress from "./CampaignProgress";
import { FastfoodOutlined } from "@mui/icons-material";

const Summary = () => {
  const ref = useRef(null);
  const { addToast } = useToasts();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    errors,
    setErrors,
    formData,
    formData: { requirements, brand, isSettled },
    setFormData,
    setActiveStep,
    handleSetValues,
    isEditable,
    previousState,
    selectedCampaign,
    seiIsStepperOpen,
    campaignDetails,
  } = useCampaignContext();
  const [reverToOriginal, setRevertToOriginal] = useState(false);
  const [isOpenBasicDetials, setIsOpenBasicDetails] = useState(false);
  const [isRevertConfirmationOpen, setIsRevertConfirmationOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [seeAllProducts, setseeAllProducts] = useState({
    search: "",
    key: null,
    isOpen: false,
    title: null,
  });

  const [startAnimation, setStartAnimation] = useState(false);
  const steps = useMemo(() => {
    return [
      {
        key: "brand",
        step: 0,
        title: campaignSteps[0],
        data: {
          Brand: formData?.brand?.name,
        },
      },
      {
        key: "basic_details",
        step: -1,
        title: "Basic Details",
        error: "isBasicDetailRelatedValuesChanged",
        data: {
          Title: formData?.title ?? "--",
          Description: formData?.description ?? "--",
        },
      },
      {
        key: "purchase_requirements",
        step: 1,
        title: campaignSteps[1],
        error: "isBrandChanged",
        data: {
          Offer: OfferValue(formData),
          // ...(formData?.requirements?.offer?.data?.length && {
          //   "Offer Products": {
          //     key: "offer",
          //     data: formData?.requirements?.offer?.data,
          //   },
          // }),
          "First Purchase Requirement": PurchaseRequirementValue(formData?.requirements?.primary),
          "First Purchase Products": {
            key: "primary",
            data: formData?.requirements?.primary?.data,
          },

          "Second Purchase Requirement": PurchaseRequirementValue(formData?.requirements?.secondary),
          "Second Purchase Products": {
            key: "secondary",
            data: formData?.requirements?.secondary?.data,
          },

          "Third Purchase Requirement": PurchaseRequirementValue(formData?.requirements?.tertiary),
          "Third Purchase Products": {
            key: "tertiary",
            data: formData?.requirements?.tertiary?.data,
          },
        },
      },
      {
        key: "campaign_duration",
        step: 2,
        title: campaignSteps[2],
        data: {
          "Start Date":
            formData?.campaignDuration?.startDate && typeof formData?.campaignDuration?.startDate !== "string"
              ? format(formData?.campaignDuration?.startDate, "PPP")
              : formData?.campaignDuration?.startDate,
          [formData?.campaignDuration?.endDate ? "End Date" : "Duration"]:
            formData?.campaignDuration?.duration?.select ||
            (formData?.campaignDuration?.endDate ? format(formData?.campaignDuration?.endDate, "PPP") : false) ||
            `${formData?.campaignDuration?.duration?.input} Days`,
        },
      },
      {
        key: "redemption_duration",
        step: 3,
        title: campaignSteps[3],
        data: {
          "Start Date":
            formData?.campaignDuration?.startDate && typeof formData?.redemptionDuration?.startDate !== "string"
              ? format(formData?.redemptionDuration?.startDate, "PPP")
              : formData?.redemptionDuration?.startDate,
          [formData?.redemptionDuration?.endDate ? "End Date" : "Duration"]:
            formData?.redemptionDuration?.duration?.select ||
            (formData?.redemptionDuration?.endDate ? format(formData?.redemptionDuration?.endDate, "PPP") : false) ||
            `${formData?.redemptionDuration?.duration?.input} Days`,
        },
      },
      {
        key: "more_info",
        step: 4,
        title: campaignSteps[4],
        Image: formData?.moreInfo?.product_image,

        data: {
          "Total circulations":
            formData?.moreInfo?.estimatedCirculation?.select || formData?.moreInfo?.estimatedCirculation?.input,
          "Rolling expiration":
            formData?.moreInfo?.rollingExpiration === "Yes" &&
            (formData?.moreInfo?.rollingExpirationDuration?.select ||
              formData?.moreInfo?.rollingExpirationDuration?.input)
              ? formData?.moreInfo?.rollingExpirationDuration?.select ||
                `${formData?.moreInfo?.rollingExpirationDuration?.input} Days`
              : null,
          "Maximum coupons grouped together":
            formData?.moreInfo?.max_quantity_to_group?.select || formData?.moreInfo?.max_quantity_to_group?.input,
          "Redemption limit (per user)":
            formData?.moreInfo?.max_limit_to_avail_offer?.select || formData?.moreInfo?.max_limit_to_avail_offer?.input,
          "Store coupon": formData?.moreInfo?.store_coupon,
          "Terms and conditions": formData?.moreInfo?.terms,
        },
      },
    ];
  }, [formData]);

  const { data: userDetails } = useGetUserDetails();
  const { data: enterpriseDetails } = useGetEnterpriseDetails();
  const { mutateAsync: postCreateCampaign, isLoading, isSuccess, isError } = usePostCreateCampaign(params?.id);
  const { data: brand_details, isFetching: isLoadingBrandDetails } = useGetABrand(brand?.internal_id);
  const {
    mutateAsync: patchAssignProviders,
    isLoading: isLoadingAssignProviders,
    isSuccess: isSuccessAssignProviders,
    isError: isErrorAssignProvider,
  } = usePatchProvidersToggle(formData?.base_gs1);
  const {
    mutateAsync: patchAssignRetailers,
    isLoading: isLoadingAssignRetailers,
    isSuccess: isSuccessAssignRetailers,
    isError: isErrorAssignRetailers,
  } = usePatchRetailersToggle(formData?.base_gs1);

  const isErrors = Object.values(errors).some((x) => x?.active && x?.type === "error");
  const isDisabled =
    isLoading ||
    isUploading ||
    isLoadingAssignProviders ||
    isLoadingAssignRetailers ||
    (isEditable && !reverToOriginal);

  const errorItems = useMemo(() => {
    const data = requirements?.[seeAllProducts?.key]?.data;
    if (data?.length) return data?.filter((x) => x?.brand_id !== brand_details?.internal_id);
    return [];
  }, [brand_details?.internal_id, requirements, seeAllProducts?.key]);

  const handleOnCloseBasicDetails = () => setIsOpenBasicDetails(false);
  const handleOnOpenBasicDetails = () => setIsOpenBasicDetails(true);

  const handleAssignProviders = async (res) => {
    const previousData = campaignDetails?.providers;
    const updateData = formData?.providers?.map((x) => x?.email_domain);
    if (!isEqual(previousData, updateData)) {
      const addedElements = difference(updateData, previousData);
      const removedElements = difference(previousData, updateData);

      // sending only changed items for toggle
      const email_domains = union(addedElements, removedElements);

      await patchAssignProviders({
        base_gs1: res?.base_gs1,
        email_domains,
      })
        .then(async () => {
          await handleAssignRetailers();
        })
        .catch(() => {});
    }
  };

  const handleAssignRetailers = async (res) => {
    const previousData = campaignDetails?.retailers;
    const updateData = formData?.retailers?.map((x) => x?.email_domain);

    if (!isEqual(previousData, updateData)) {
      const addedElements = difference(updateData, previousData);
      const removedElements = difference(previousData, updateData);

      // sending only changed items for toggle
      const email_domains = union(addedElements, removedElements);

      await patchAssignRetailers({
        base_gs1: res?.base_gs1,
        email_domains,
      });
    }
  };

  const handleCreateCampaign = (payload) => {
    // setStartAnimation(true);
    postCreateCampaign(payload)
      .then(async (res) => {
        // if (isEditable) navigate(Pathnames.LIST_CAMPAIGN);
        // else setActiveStep((curr) => curr + 1);
        if (res?.base_gs1) handleSetValues("base_gs1", res?.base_gs1);
        if (res?.link) handleSetValues("link", res?.link);

        await handleAssignProviders(res);

        addToast(isEditable ? "Successfully updated campaign!" : "Successfully created campaign!", {
          appearance: "success",
        });
      })
      .catch(() => {});
  };

  const handleSubmit = () => {
    if (isErrors) {
      handleScrollTo(ref);
    } else {
      const image = formData?.moreInfo?.product_image;
      if (typeof image === "string") {
        setStartAnimation(true);
        const payload = createPayload({ ...formData, image }, isEditable, enterpriseDetails?.allow_multiple_brands);
        handleCreateCampaign(payload);
      } else if (image !== undefined) {
        const payload1 = {
          image_type: "campaign_image",
          file: image,
        };
        setIsUploading(true);
        setStartAnimation(true);
        uploadToS3Bucket(payload1, userDetails?.current_enterprise?.id).then((imageUrl) => {
          setIsUploading(false);
          const payload = createPayload(
            { ...formData, image: imageUrl },
            isEditable,
            enterpriseDetails?.allow_multiple_brands
          );
          handleCreateCampaign(payload);
        });
      } else {
        setStartAnimation(true);
        const payload = createPayload(
          { ...formData, image: null },
          isEditable,
          enterpriseDetails?.allow_multiple_brands
        );
        handleCreateCampaign(payload);
      }
    }
  };

  const handleOpenAllProducts = ({ data, key }, title) => {
    setseeAllProducts({
      key,
      isOpen: true,
      title,
    });
  };

  const handleCloseAllProducts = () => {
    setseeAllProducts({
      key: null,
      isOpen: false,
      title: null,
    });
  };

  const handleRemoveProduct = (product, key) => {
    if (!isSettled) {
      const filteredProducts = formData?.requirements?.[key]?.data?.filter((x) => x.product_id !== product.product_id);

      const requirements = {
        ...formData?.requirements,
        [key]: { ...formData?.requirements?.[key], data: filteredProducts },
      };

      handleSetValues("requirements", requirements);
    }
  };

  const handleAddProduct = (product, key) => {
    if (!isSettled) {
      const requirements = {
        ...formData?.requirements,
        [key]: {
          ...formData?.requirements?.[key],
          data: [...formData?.requirements?.[key]?.data, product],
        },
      };

      handleSetValues("requirements", requirements);
    }
  };

  const handleScrollTo = (ref) => {
    ref?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      marginTop: 100,
    });
  };

  const handleValidations = () => {
    // if products doesn't match the selected brand
    if (
      formData?.requirements?.offer?.data?.length &&
      formData?.requirements?.offer?.data?.some((x) => formData?.brand?.internal_id !== x?.brand_id)
    ) {
      setErrors((curr) => ({
        ...curr,
        offer: {
          text: `Products do not belong to the selected brand: ${formData?.brand?.name}`,
          active: true,
          type: "error",
        },
      }));
    }

    if (
      formData?.requirements?.primary?.data?.length &&
      formData?.requirements?.primary?.data?.some(
        (x) => (formData?.requirements?.primary?.brand?.internal_id ?? formData?.brand?.internal_id) !== x?.brand_id
      )
    ) {
      setErrors((curr) => ({
        ...curr,
        primary_requirements: {
          text: `Products do not belong to the selected brand: ${formData?.requirements?.primary?.brand?.name}`,
          active: true,
          type: "error",
        },
      }));
    }
    if (
      formData?.requirements?.secondary?.data?.length &&
      formData?.requirements?.secondary?.data?.some(
        (x) => (formData?.requirements?.secondary?.brand?.internal_id ?? formData?.brand?.internal_id) !== x?.brand_id
      )
    ) {
      setErrors((curr) => ({
        ...curr,
        primary_requirements: {
          text: `Products do not belong to the selected brand: ${formData?.requirements?.secondary?.brand?.name}`,
          active: true,
          type: "error",
        },
      }));
    }
    if (
      formData?.requirements?.tertiary?.data?.length &&
      formData?.requirements?.tertiary?.data?.some(
        (x) => (formData?.requirements?.tertiary?.brand?.internal_id ?? formData?.brand?.internal_id) !== x?.brand_id
      )
    ) {
      setErrors((curr) => ({
        ...curr,
        primary_requirements: {
          text: `Products do not belong to the selected brand: ${formData?.requirements?.tertiary?.brand?.name}`,
          active: true,
          type: "error",
        },
      }));
    }
    // if no products are selected
    // else if (
    //   formData?.requirements &&
    //   !formData?.requirements?.primary?.data?.length
    // ) {
    //   setErrors((curr) => ({
    //     ...curr,
    //     purchase_requirements: {
    //       text: `No products are selected under primary purchase requirements`,
    //       active: true,
    //       type: "error",
    //     },
    //   }));
    // }
    else {
      setErrors((curr) => ({
        ...curr,
        purchase_requirements: null,
      }));
    }

    if (!previousState.current) {
      previousState.current = formData?.requirements;

      // if some modifications are made in values related to title and description
    } else if (previousState.current && !isObjectsAreEqual(previousState.current, formData?.requirements)) {
      setErrors((curr) => ({
        ...curr,
        basic_details: {
          active: true,
          text: "It appears that some of the details have been altered. Please consider updating the title and description accordingly.",
          type: "warning",
        },
      }));
    }
  };

  const handleResetBasicDetailsValidation = () => {
    previousState.current = formData?.requirements;
    setErrors((curr) => ({ ...curr, basic_details: null }));
  };

  const handleUseSystemGeneratedText = () => {
    const { title, description } = generateCampaignText(formData);
    handleSetValues("title", title);
    handleSetValues("description", description);
  };

  const handleReverToOriginal = () => {
    setFormData((curr) => {
      return {
        ...curr?.campaignResponse,
        campaignResponse: curr?.campaignResponse,
      };
    });
    setErrors({});
    setIsRevertConfirmationOpen(false);
  };

  const handleBackButton = () => {
    if (!isEditable) {
      // in create mode
      if (selectedCampaign !== null) {
        setActiveStep(0);
        seiIsStepperOpen(false);
      } else if (location?.state?.formData && location?.state?.campaign_pathname) {
        navigate(location?.state?.campaign_pathname);
      } else {
        setActiveStep((curr) => curr - 1);
      }
    } else {
      navigate(Pathnames.LIST_CAMPAIGN);
    }
  };

  useEffect(() => {
    window?.scrollTo({
      top: 0,
      behavior: "instant",
    });

    if (formData && !params?.id && !formData?.title?.length && !formData?.description?.length && !isEditable) {
      handleUseSystemGeneratedText();
    }
  }, []);

  useEffect(() => handleValidations(), [formData?.brand, formData?.requirements]);

  useEffect(() => {
    if (isEditable) {
      const initialState = formData?.campaignResponse;
      const currentState = { ...formData };
      delete currentState?.campaignResponse;

      if (!isObjectsAreEqual(initialState, currentState)) setRevertToOriginal(true);
      else setRevertToOriginal(false);
    }
  }, [formData, isEditable]);

  return (
    <>
      <div data-aos="fade-left">
        {formData?.requirements ? (
          <>
            <div className="d-flex my-3" data-aos="fade-left">
              <div style={{ width: "100%" }} className="d-flex flex-column gap-3" ref={ref}>
                <Typography variant="h5" fontWeight={600} sx={{ marginTop: -2 }}>
                  <IconButton onClick={handleBackButton}>
                    <WestIcon />
                  </IconButton>{" "}
                </Typography>
                {isSettled && isEditable ? (
                  <Alert
                    severity="info"
                    sx={{ zIndex: 3, width: "100%" }}
                    action={
                      <Button
                        size="small"
                        color="secondary"
                        onClick={() =>
                          navigate(Pathnames.CREATE_CAMPAIGN, {
                            state: {
                              formData,
                              campaign_pathname: location?.pathname,
                            },
                          })
                        }
                      >
                        Create
                      </Button>
                    }
                  >
                    This campaign is now settled and can no longer be modified. To duplicate this campaign, click here.
                  </Alert>
                ) : null}
                {steps?.map((step, i) => (
                  <Grid item xs={12} sm={12} lg={12} key={i}>
                    <SummaryCard
                      ref={step?.ref}
                      error={errors?.[step?.key]}
                      step={step}
                      seeAllProducts={seeAllProducts}
                      handleOpenBasicDetails={handleOnOpenBasicDetails}
                      handleOpenAllProducts={handleOpenAllProducts}
                    />
                  </Grid>
                ))}
                <Assignments />
              </div>
              <AppPreview />
            </div>

            {!isSettled && userDetails?.current_enterprise?.id !== "01HHSADNZQNQNKFMCDFESA18CF" ? (
              <div>
                {isEditable ? (
                  <Button
                    onClick={() => setIsRevertConfirmationOpen(true)}
                    variant="outlined"
                    disabled={!reverToOriginal}
                  >
                    REVERT TO ORIGINAL
                  </Button>
                ) : null}{" "}
                <Button
                  onClick={handleSubmit}
                  disabled={isDisabled}
                  endIcon={
                    isLoading || isUploading || isLoadingAssignProviders || isLoadingAssignRetailers ? (
                      <CircularProgress size={20} />
                    ) : null
                  }
                >
                  {isEditable ? "SAVE" : "CREATE CAMPAIGN"}
                </Button>
              </div>
            ) : null}
          </>
        ) : (
          <div className="d-flex justify-content-center align-items-center" style={{ minHeight: 200 }}>
            <CircularProgress />
          </div>
        )}
        <EditBasicDetails
          isOpen={isOpenBasicDetials}
          handleClose={handleOnCloseBasicDetails}
          handleUseSystemGeneratedText={handleUseSystemGeneratedText}
          handleResetBasicDetailsValidation={handleResetBasicDetailsValidation}
        />
        <SeeAllDrawer
          errorItems={errorItems}
          data={brand_details?.products}
          isOpen={seeAllProducts?.isOpen}
          title={seeAllProducts?.title}
          description={
            <>
              All the products are from <b>{brand?.name}</b>.{" "}
              {!isSettled ? "Add or remove products from your campaign by clicking on the products below" : null}
            </>
          }
          placeholder={"Search a product..."}
          isLoading={isLoadingBrandDetails}
          handleCloseAllProducts={handleCloseAllProducts}
          handleRemoveProduct={(item) => handleRemoveProduct(item, seeAllProducts?.key)}
          handleAddProduct={(item) => handleAddProduct(item, seeAllProducts?.key)}
          isSelectedCondition={(item) =>
            requirements?.[seeAllProducts?.key]?.data?.some((prod) => prod?.product_id === item?.product_id)
          }
          uniqueKey={"product_id"}
          displayLabel={"name"}
          backupDisplayLabel={"product_name"}
          displayAvatar={"image"}
          errorItemsKey={"brand_id"}
          errorItemsValue={brand_details?.internal_id}
        />
        <ConfirmationModal
          title="Are you sure you want to revert changes to original?"
          open={isRevertConfirmationOpen}
          handleClose={() => setIsRevertConfirmationOpen(false)}
          handleProceed={handleReverToOriginal}
        />
      </div>
      <CampaignProgress
        open={startAnimation}
        setOpen={setStartAnimation}
        isEditable={isEditable}
        setActiveStep={setActiveStep}
        handleSubmit={handleSubmit}
        progress={[
          { label: "Creating your campaign...", isLoading: isLoading || isUploading, isSuccess, isError },
          {
            label: "Assigning providers...",
            isLoading: isLoadingAssignProviders,
            isSuccess: isSuccessAssignProviders,
            isError: isErrorAssignProvider,
          },
          {
            label: "Assigning retailers...",
            isLoading: isLoadingAssignRetailers,
            isSuccess: isSuccessAssignRetailers,
            isError: isErrorAssignRetailers,
          },
        ]}
      />
    </>
  );
};

export default Summary;
