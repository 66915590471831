import { Box, Divider, Typography } from "@mui/material";
import { ConnectToTCBAlert, FormStepper } from "components";
import { useState, createContext, useContext, useEffect, useRef } from "react";

import BrandSelection from "./Steps/SelectBrand";
import Duration from "./Steps/Duration";
import Redemption from "./Steps/Redemption";
import ChooseCampaign from "./ChooseCampaign";
import MoreInfo from "./Steps/MoreInfo";
import PurchaseCriteria from "./Steps/PurchaseCriteria";
import Congratulations from "./Steps/Congratulations";
import Summary from "./Steps/Summary";
import { useGetCampaignDetails, useGetProviders, useGetRetailers } from "queries/campaign";
import { handleCampaignDataForEdit } from "./CreatePayload";
import { useLocation, useParams } from "react-router";
import BlockNavigationModal from "./BlockNavigationModal";
import { DurationOptions } from "constants/options";
import { useGetEnterpriseDetails } from "queries/enterprise";

const InitialState = {
  brand: null,
  title: null,
  description: null,
  discountAmount: null,
  campaignDuration: {
    startDate: null,
    duration: null,
    endDate: null,
  },
  redemptionDuration: {
    startDate: null,
    duration: null,
    endDate: null,
  },
  moreInfo: {
    rollingExpiration: "No",
    rollingExpirationDuration: null,
    estimatedCirculation: null,
  },
  requirements: null,
  campaignResponse: null,
  providers: [],
  retailers: [],
};

export const campaignSteps = [
  "Brand",
  // "Basic Details",
  "Purchase Criteria",
  "Campaign Duration",
  "Redemption Duration",
  "More Info",
];

const Forms = [
  // <ChooseFromPreviousCampaign />,
  <BrandSelection />, // 0
  // <Details />,
  <PurchaseCriteria />, // 1
  <Duration />, // 2
  <Redemption />, // 3
  <MoreInfo />, // 4
  <Summary />, // 5
  <Congratulations />, // 6
];

const CampaignContext = createContext();

const CreateCampaign = ({ isEditable }) => {
  const previousState = useRef(null);
  const params = useParams();
  const location = useLocation();

  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [activeStep, setActiveStep] = useState(() => (isEditable ? 5 : 0));
  const [editStep, setEditStep] = useState(false);
  const [isStepperOpen, seiIsStepperOpen] = useState(() => (isEditable ? true : false));
  const [formData, setFormData] = useState(InitialState);
  const [errors, setErrors] = useState({});

  const handleSetValues = (key, value) => setFormData((state) => ({ ...state, [key]: value }));

  const handleDefaultAssignments = () => {
    const data = { name: enterpriseDetails?.name, email_domain: enterpriseDetails?.email_domain };
    if (
      !isEditable &&
      enterpriseDetails?.is_provider === "yes"
      // !formData?.providers?.includes(enterpriseDetails?.email_domain) &&
      // providerData
    )
      handleSetValues("providers", [data]);
    if (
      !isEditable &&
      enterpriseDetails?.is_retailer === "yes"
      // !formData?.retailers?.includes(enterpriseDetails?.email_domain) &&
      // retailerData
    )
      handleSetValues("retailers", [data]);
  };

  const { data: campaignDetails } = useGetCampaignDetails(isEditable ? params?.id : null);
  const { data: enterpriseDetails } = useGetEnterpriseDetails();
  const { data: allProviders } = useGetProviders();
  const { data: allRetailers } = useGetRetailers();

  useEffect(() => {
    if (isEditable && campaignDetails && allProviders) {
      handleCampaignDataForEdit(campaignDetails, setFormData, null, allProviders, allRetailers);
      setActiveStep(5);
    }
  }, [isEditable, campaignDetails, allProviders, allRetailers]);

  useEffect(() => {
    if (location?.state?.formData) {
      setFormData({
        ...location?.state?.formData,
        isSettled: false,
        campaignDuration: {
          ...location?.state?.formData?.campaignDuration,
          startDate: new Date(),
          duration: { select: DurationOptions?.[0], input: "" },
        },
        redemptionDuration: {
          ...location?.state?.formData?.redemptionDuration,
          startDate: new Date(),
          duration: { select: DurationOptions?.[0], input: "" },
        },
      });
      seiIsStepperOpen(true);
      setActiveStep(5);
    }
  }, [location]);

  useEffect(() => handleDefaultAssignments(), [enterpriseDetails]);

  // useEffect(() => {
  //   if (!params?.id) {
  //     setSelectedCampaign(null);
  //     setActiveStep(isEditable ? 5 : 0);
  //     setEditStep(false);
  //     seiIsStepperOpen(isEditable ? true : false);
  //     setFormData(InitialState);
  //   }
  // }, [params, isEditable]);

  return (
    <ConnectToTCBAlert>
      <CampaignContext.Provider
        value={{
          errors,
          setErrors,
          isEditable,
          activeStep,
          setActiveStep,
          isStepperOpen,
          seiIsStepperOpen,
          selectedCampaign,
          formData,
          handleSetValues,
          setFormData,
          InitialState,
          editStep,
          setEditStep,
          previousState,
          campaignDetails,
        }}
      >
        <Box className="bg-white rounded-sm" style={{ paddingLeft: "initial" }}>
          {isStepperOpen && activeStep < 5 ? (
            <>
              {editStep ? (
                <div className="px-4 pt-4">
                  <Typography variant="h5" fontWeight={600} className="mb-2">
                    {campaignSteps[activeStep].toUpperCase()}
                  </Typography>
                  <Divider />
                </div>
              ) : (
                <FormStepper activeStep={activeStep} setActiveStep={setActiveStep} steps={campaignSteps} />
              )}
            </>
          ) : null}
          <div className="p-4">
            {isStepperOpen ? (
              <div className={editStep ? null : "my-4"}>{Forms[activeStep]}</div>
            ) : (
              <ChooseCampaign
                selectedCampaign={selectedCampaign}
                setSelectedCampaign={setSelectedCampaign}
                seiIsStepperOpen={seiIsStepperOpen}
                handleDefaultAssignments={handleDefaultAssignments}
              />
            )}
            {isStepperOpen ? null : null}
          </div>
        </Box>
        <BlockNavigationModal
          title={isEditable ? "editing a camapaign" : "creating a campaign"}
          condition={!isEditable && (formData?.brand || selectedCampaign !== null) && isStepperOpen && activeStep < 6}
        />
      </CampaignContext.Provider>
    </ConnectToTCBAlert>
  );
};

export default CreateCampaign;

export const useCampaignContext = () => useContext(CampaignContext);
