import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryKeys, ApiConfig } from "constants";
import { AxiosInstance } from "services";
import { useGetUserDetails } from "./auth";
import { useNavigate } from "react-router";
import { useToasts } from "react-toast-notifications";
import { Pathnames } from "routes/Pathnames";
import { appendQueryParams } from "utils";

const postAddCampaignGallery = (data) => {
  const method = "POST";
  const url = ApiConfig.CAMPAIGN_GALLERY;
  return AxiosInstance({ method, url, data });
};

export const usePostAddCampaignGallery = () => {
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const QueryClient = useQueryClient();
  const { data: userDetails } = useGetUserDetails();

  return useMutation(postAddCampaignGallery, {
    onSuccess: () => {
      navigate(Pathnames.LIST_GALLERY);
      addToast("Successfully created gallery", {
        appearance: "success",
      });
      QueryClient.removeQueries([QueryKeys.LIST_GALLERY, userDetails?.current_enterprise?.id]);
    },
  });
};

const getCampaignGalleryList = (enterprise_id) => {
  const method = "GET";
  const url = `${ApiConfig.CAMPAIGN_GALLERY}/${enterprise_id}`;
  return AxiosInstance({ method, url });
};

export const useGetCampaignGalleryList = (enabled) => {
  const { data: userDetails } = useGetUserDetails();
  return useQuery(
    [QueryKeys.LIST_GALLERY, userDetails?.current_enterprise?.id],
    () => getCampaignGalleryList(userDetails?.current_enterprise?.id),
    {
      enabled: !!userDetails?.current_enterprise?.id && enabled,
    }
  );
};

export const getGalleryCampaigns = (gallery_id, query) => {
  const method = "GET";
  const url =
    ApiConfig.GALLERY_CAMPAIGNS.replace(":gallery_id", gallery_id) +
    appendQueryParams({ next_records: 50, processed_base_gs1s: query });
  return AxiosInstance({ method, url });
};

export const useGetGalleryCampaigns = (gallery_id, query) => {
  const { data: userDetails } = useGetUserDetails();
  return useQuery(
    [QueryKeys.GALLERY_CAMPAIGNS, userDetails?.current_enterprise?.id, gallery_id, query],
    () => getGalleryCampaigns(gallery_id, query),
    {
      enabled: !!userDetails?.current_enterprise?.id && !!gallery_id,
    }
  );
};

const patchAddCampaignsToGallery = (data) => {
  const method = "PATCH";
  const url = ApiConfig.ADD_MOFS_TO_GALLERY.replace(":gallery_id", data?.gallery_id);
  return AxiosInstance({ method, url, data: data?.body });
};

export const usePatchAddCampaignsToGallery = (gallery_id) => {
  const QueryClient = useQueryClient();
  const { data: userDetails } = useGetUserDetails();
  return useMutation(patchAddCampaignsToGallery, {
    onSuccess: () => {
      QueryClient.removeQueries([QueryKeys.LIST_GALLERY, userDetails?.current_enterprise?.id]);
      QueryClient.removeQueries([QueryKeys.CAMPAIGN_GALLERY, userDetails?.current_enterprise?.id, gallery_id]);
    },
  });
};

const deleteGallery = (gallery_id) => {
  const method = "DELETE";
  const url = `${ApiConfig.CAMPAIGN_GALLERY}/${gallery_id}`;
  return AxiosInstance({ method, url });
};

export const useDeleteGallery = () => {
  const QueryClient = useQueryClient();
  const { data: userDetails } = useGetUserDetails();
  return useMutation(deleteGallery, {
    onSuccess: () => QueryClient.invalidateQueries([QueryKeys.LIST_GALLERY, userDetails?.current_enterprise?.id]),
  });
};

const getGalleryDetails = (gallery_id) => {
  const method = "GET";
  const url = `${ApiConfig.GALLERY_DETAILS}/${gallery_id}`;
  return AxiosInstance({ method, url });
};

export const useGetGalleryDetails = (gallery_id) => {
  const { data: userDetails } = useGetUserDetails();
  return useQuery(
    [QueryKeys.CAMPAIGN_GALLERY, userDetails?.current_enterprise?.id, gallery_id],
    () => getGalleryDetails(gallery_id),
    {
      enabled: !!gallery_id,
    }
  );
};

const patchCampaignGallery = (data) => {
  const method = "PATCH";
  const url = `${ApiConfig.CAMPAIGN_GALLERY}/${data?.gallery_id}`;
  return AxiosInstance({ method, url, data: data?.body });
};

export const usePatchCampaignGallery = (gallery_id) => {
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const QueryClient = useQueryClient();
  const { data: userDetails } = useGetUserDetails();

  return useMutation(patchCampaignGallery, {
    onSuccess: () => {
      navigate(Pathnames.LIST_GALLERY);
      addToast("Successfully updated gallery", {
        appearance: "success",
      });
      QueryClient.invalidateQueries([QueryKeys.LIST_GALLERY, userDetails?.current_enterprise?.id]);
      QueryClient.invalidateQueries([QueryKeys.CAMPAIGN_GALLERY, userDetails?.current_enterprise?.id, gallery_id]);
    },
  });
};

const patchShareGalleries = ({ data, gallery_id }) => {
  const method = "PATCH";
  const url = ApiConfig.SHARE_GALLERY.replace(":gallery_id", gallery_id);
  return AxiosInstance({ method, url, data });
};

export const usePatchShareGalleries = () => {
  const QueryClient = useQueryClient();
  const { data: userDetails } = useGetUserDetails();
  return useMutation(patchShareGalleries, {
    onSuccess: () => {
      QueryClient.invalidateQueries([QueryKeys.LIST_GALLERY, userDetails?.current_enterprise?.id]);
    },
  });
};
