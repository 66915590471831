import { TextField, useMediaQuery } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useState } from "react";

const DatePicker = (props) => {
  const isLgScreen = useMediaQuery("(min-width:600px)");
  const [open, setOpen] = useState(false);
  const {
    fullWidth,
    onChange,
    value,
    error,
    helperText,
    required = false,
    inputFormat = "MM/dd/yyyy",
    maxDate,
    disableTyping,
  } = props;
  //
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {isLgScreen ? (
          <DesktopDatePicker
            disableTyping={disableTyping}
            // timeZone="America/Los_Angeles"
            // locale="en-US"
            open={disableTyping ? open : null}
            onOpen={() => disableTyping && setOpen(true)}
            onClose={() => disableTyping && setOpen(false)}
            inputFormat={inputFormat}
            value={value}
            onChange={onChange}
            maxDate={maxDate}
            placeholder="Created by"
            {...props}
            renderInput={(params) => (
              <TextField
                {...params}
                autoComplete="off"
                onClick={() => disableTyping && setOpen(true)}
                // onKeyDown={onKeyDown}
                placeholder="Created by"
                required={required}
                fullWidth={fullWidth}
                error={error}
                helperText={helperText}
                ref={params.inputRef}
              />
            )}
          />
        ) : (
          <MobileDatePicker
            // timeZone="America/Los_Angeles"
            // locale="en-US"
            inputFormat={inputFormat}
            {...props}
            maxDate={maxDate}
            renderInput={(params) => (
              <TextField
                required={required}
                // onKeyDown={onKeyDown}
                {...params}
                error={error}
                helperText={helperText}
                fullWidth={fullWidth}
                ref={params.inputRef}
              />
            )}
          />
        )}
      </LocalizationProvider>
    </>
  );
};

export default DatePicker;
