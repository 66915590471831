import { useCalendarGrid } from "@react-aria/calendar";
import { getWeeksInMonth, endOfMonth } from "@internationalized/date";
import { CalendarCell } from "./CalendarCell";
import { Typography } from "@mui/material";

export function CalendarGrid({ state, offset = {} }) {
  let startDate = state?.visibleRange?.start?.add(offset);
  let endDate = endOfMonth(startDate);
  let { gridProps, headerProps } = useCalendarGrid(
    {
      startDate,
      endDate,
    },
    state
  );
  let weeksInMonth = getWeeksInMonth(state.visibleRange.start, "en-US");

  const weekDaysCustom = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

  return (
    <table {...gridProps} cellSpacing={"1"} cellPadding={"0"}>
      <thead {...headerProps}>
        <tr>
          {weekDaysCustom.map((day, index) => (
            <th key={index} className="text-center">
              <Typography fontWeight={400} fontSize={14} color={"#494B57"}>
                {day}
              </Typography>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {[...new Array(weeksInMonth).keys()].map((weekIndex) => (
          <tr key={weekIndex}>
            {state
              .getDatesInWeek(weekIndex, startDate)
              .map((date, i) =>
                date ? <CalendarCell key={i} state={state} date={date} currentMonth={startDate} /> : <td key={i} />
              )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
