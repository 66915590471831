import { useEffect, useMemo, useState } from "react";
import { Typography, TextField } from "@mui/material";
import { DatePicker, SelectButton } from "components";
import StepperControls from "../StepperControls";
import { useCampaignContext } from "..";
// import "./duration.scss";
import { DateOptions, DurationOptions } from "constants/options";
import { calStartDate } from "../CreatePayload";
import { isObjectsAreEqual } from "utils";
import { NumericFormat } from "react-number-format";

const Redemption = () => {
  const {
    isEditable,
    handleSetValues,
    formData: { redemptionDuration, campaignDuration },
  } = useCampaignContext();
  const [selected, setSelected] = useState(redemptionDuration);
  const [disabled, setDisabled] = useState(isEditable ? true : false);

  const campaignStartDate = useMemo(() => calStartDate(campaignDuration?.startDate), [campaignDuration?.startDate]);

  const RedemptionDateOptions = useMemo(
    () =>
      DateOptions?.filter((date) => {
        const curr = calStartDate(date);
        return curr >= campaignStartDate;
      }),
    [campaignStartDate]
  );

  const handleOnSubmit = () => {
    handleSetValues("redemptionDuration", selected);
  };

  useEffect(() => {
    if (isEditable && isObjectsAreEqual(selected, redemptionDuration)) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [selected, redemptionDuration]);

  return (
    <div data-aos="fade-left" className="gap-4 d-flex flex-column">
      <Typography variant="h5">
        When can users <span className="fw-semibold">start redeeming </span>
        offers?
      </Typography>
      <div className="d-flex gap-2 flex-wrap align-items-center">
        {RedemptionDateOptions?.map((item, i) => (
          <SelectButton
            key={i}
            selected={selected?.startDate}
            title={item}
            onClick={() =>
              setSelected((state) => ({
                ...state,
                startDate: item,
              }))
            }
          />
        ))}

        <Typography className="mx-4 text-secondary" variant="body1">
          or
        </Typography>
        <DatePicker
          disableTyping
          // label="Choose a Day"
          value={typeof selected?.startDate === "string" ? null : selected?.startDate}
          // {...register("end_date")}
          minDate={campaignStartDate}
          onChange={(val) => {
            setSelected((state) => ({ ...state, startDate: val }));
          }}
          // error={Boolean(errors?.end_date?.message)}
          // helperText={errors?.end_date?.message}
        />
      </div>
      <div>
        <Typography variant="h5">
          <span className="fw-semibold">How long </span>can the user redeem an offer?*
        </Typography>
        <Typography color="gray.400" variant="subtitle2">
          This duration is applicable from the start date selected above
        </Typography>
      </div>
      <div className="d-flex gap-2 flex-wrap align-items-center">
        {DurationOptions?.map((item) => (
          <SelectButton
            key={item}
            selected={selected?.duration?.select}
            title={item}
            onClick={() =>
              setSelected((state) => ({
                ...state,
                duration: { select: item, input: "" },
                endDate: null,
              }))
            }
          />
        ))}
        <div className="d-flex align-items-center gap-2">
          <NumericFormat
            value={selected?.duration?.input ?? undefined}
            onValueChange={(x) => {
              setSelected((state) => ({
                ...state,
                duration: { input: x?.value },
                endDate: null,
              }));
            }}
            placeholder="e.g. 100"
            decimalScale={0}
            fixedDecimalScale
            customInput={TextField}
            allowNegative={false}
            InputProps={{ inputProps: { min: 0 } }}
            sx={{ width: 100 }}
          />
          <Typography variant="body2" color="gray">
            Days
          </Typography>
        </div>
        <Typography className="mx-4 text-secondary" variant="body1">
          or
        </Typography>
        <DatePicker
          disableTyping
          // label="Choose a Day"
          value={typeof selected?.endDate === "string" ? null : selected?.endDate}
          // {...register("end_date")}
          minDate={campaignStartDate}
          onChange={(val) => {
            setSelected((state) => ({ ...state, endDate: val, duration: { input: "" } }));
          }}
          // error={Boolean(errors?.end_date?.message)}
          // helperText={errors?.end_date?.message}
        />
      </div>
      <StepperControls
        onClickNext={handleOnSubmit}
        isDisabled={!(selected?.duration || selected?.endDate) || !selected?.startDate || disabled}
      />
    </div>
  );
};

export default Redemption;
