import { useGetInviteDetails } from "queries/campaign";
import { useLocation } from "react-router";

const useInviteCode = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const invite_code = queryParams.get("invite_code");

  const { data: inviteDetails, isFetching: isFetchingInviteDetails } =
    useGetInviteDetails(invite_code);

  return {
    invite_code,
    inviteDetails,
    isFetchingInviteDetails,
  };
};

export default useInviteCode;
