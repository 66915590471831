import {
  Alert,
  Autocomplete,
  Avatar,
  Button,
  Chip,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingWrapper } from "components";
import React, { useMemo } from "react";
import { useCriteriaContext } from ".";
import { useCampaignContext } from "../..";
import CancelIcon from "@mui/icons-material/Cancel";
import { useGetABrand, useGetBrands } from "queries/brands";
import { useGetEnterpriseDetails } from "queries/enterprise";

const SelectProducts = ({ item, subtitle, isBasicOffer }) => {
  const { errors, setErrors, formData } = useCampaignContext();
  const { selected, setSelected } = useCriteriaContext();

  const brand = item === "offer" ? formData?.brand : selected?.[item]?.brand ?? formData?.brand;

  const { data: brands } = useGetBrands();
  const { data: brand_details, isFetching: isLoadingBrandDetails } = useGetABrand(brand?.internal_id);
  const { data: enterpriseDetails } = useGetEnterpriseDetails();

  const handleSelectProducts = (product, key) =>
    setSelected((state) => {
      if (!state?.[key]?.data?.some((x) => x?.product_id === product?.product_id)) {
        // remove all products selected from previous brand
        const brandSpecificProducts = state?.[key]?.data?.filter((x) => x?.brand === brand?.name) ?? [];

        // reset errors
        setErrors((curr) => ({ ...curr, purchase_requirements: null }));

        return {
          ...state,
          [key]: { ...state?.[key], data: [...brandSpecificProducts, product] },
        };
      } else
        return {
          ...state,
          [key]: {
            ...state?.[key],
            data: state?.[key]?.data?.filter((x) => x.product_id !== product.product_id),
          },
        };
    });

  const handleRemoveProduct = (product, key) => {
    setSelected((state) => ({
      ...state,
      [key]: {
        ...state?.[key],
        data: state?.[key].data?.filter((x) => x.product_id !== product.product_id),
      },
    }));
  };

  const handleSelectAllProducts = () => {
    const brandProducts = brand_details?.products;

    // reset errors
    setErrors((curr) => ({ ...curr, purchase_requirements: null }));

    setSelected((state) => ({
      ...state,
      [item]: {
        ...state?.[item],
        data: brandProducts,
      },
    }));
  };

  const productOptions = useMemo(() => {
    if (brand_details?.products?.length)
      return [{ name: `All of ${brand?.name}`, selectAll: true }, ...brand_details?.products];
    else return [];
  }, [brand_details, brand]);

  const displayProducts = useMemo(() => {
    return selected?.[item]?.data
      ?.sort((a, b) => {
        if (a.brand_id !== brand?.internal_id && b.brand_id === brand?.internal_id) {
          return -1; // 'a' has the brand?.internal_id and 'b' does not, so 'a' comes first
        } else if (b.brand_id !== brand?.internal_id && a.brand_id === brand?.internal_id) {
          return 1; // 'b' has the brand?.internal_id and 'a' does not, so 'b' comes first
        } else {
          // If neither or both have the brand?.internal_id, sort by another criteria (e.g., brand_id or product_name)
          return a.brand_id.localeCompare(b.brand_id); // Example: sorting alphabetically by brand_id
        }
      })
      ?.slice(0, 20)
      ?.map((product) => (
        <Chip
          key={product?.product_id}
          color={product?.brand_id !== brand?.internal_id ? "light-error" : "primary"}
          label={product?.name ?? product?.product_name}
          avatar={<Avatar variant="circular" src={product?.image} alt={product?.name ?? product?.product_name} />}
          onDelete={() => handleRemoveProduct(product, item)}
        />
      ));
  }, [selected, item]);

  const handleSelectBrand = (e) => {
    setSelected((state) => ({
      ...state,
      [item]: {
        ...state?.[item],
        data: [],
        brand: brands?.data?.find((x) => x?.internal_id === e.target.value),
      },
    }));
  };

  return (
    <>
      <div>
        <Typography variant="subtitle1">
          {item === "offer"
            ? "What products you want to offer in this campaign?"
            : "What products you want to include in this requirement?"}
        </Typography>
        {subtitle ? (
          <Typography color="gray.400" variant="subtitle2" maxWidth={800}>
            {subtitle}
          </Typography>
        ) : null}
      </div>
      {/* <InputAdornment position="end">
        <SearchIcon />
      </InputAdornment> */}
      {errors?.purchase_requirements?.active ? (
        <Alert severity={errors?.purchase_requirements?.type}>{errors?.purchase_requirements?.text}</Alert>
      ) : null}

      <div className="d-flex align-items-center gap-2">
        {!isBasicOffer && enterpriseDetails?.allow_multiple_brands ? (
          <FormControl sx={{ maxWidth: "30%" }}>
            <InputLabel htmlFor="grouped-select">Brand</InputLabel>
            <Select
              disabled={item === "offer"}
              placeholder="brand"
              label="brand"
              error={Boolean(errors?.brand)}
              value={brand?.internal_id}
              onChange={handleSelectBrand}
            >
              {brands?.data?.map((x) => (
                <MenuItem value={x?.internal_id} key={x?.internal_id}>
                  {x?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}
        <Autocomplete
          sx={{
            width: "40%",
          }}
          // value={selected?.[item]?.search}
          // onChange={(e, value) => handleSetValue(e.target.value, "search", value)}
          // filterOptions={filterOptions}
          disablePortal
          loading={isLoadingBrandDetails}
          options={productOptions}
          getOptionLabel={(option) => option?.name ?? option?.product_name}
          renderOption={(props, option) => {
            return (
              <MenuItem
                {...props}
                onClick={(e) => {
                  if (option?.selectAll) handleSelectAllProducts();
                  else handleSelectProducts(option, item);
                  props.onClick(e);
                }}
                className="d-flex gap-2 align-items-center p-2"
              >
                <ListItemIcon>
                  <Avatar src={option?.image} sx={{ height: 34, width: 34 }} alt={option.name} />
                </ListItemIcon>
                <ListItemText>{option?.name ?? option?.product_name}</ListItemText>
                {selected?.[item]?.data?.some((x) => x.product_id === option?.product_id) ? (
                  <CancelIcon color="gray" />
                ) : null}
              </MenuItem>
            );
          }}
          fullWidth
          renderInput={(params) => (
            <TextField {...params} variant="filled" placeholder="Search products" autoComplete="off" />
          )}
        />
      </div>

      <LoadingWrapper style={{ height: "100px", width: "40%" }} isLoading={isLoadingBrandDetails}>
        <div className="d-flex gap-2 flex-wrap align-items-center">
          {displayProducts}
          {selected?.[item]?.data?.length > 20 ? `+${selected?.[item]?.data?.length - 20}` : null}
        </div>
        <div>{selected?.[item]?.data?.length > 20 ? <Button variant="text">See all</Button> : null}</div>
      </LoadingWrapper>
    </>
  );
};

export default SelectProducts;
