import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryKeys } from "constants";
import { ApiConfig, LocalStorageKeys } from "constants";
import { getLocalStorageItem, setLocalStorageItem } from "hooks";
import { AxiosInstance } from "services";
import useGlobalState from "store";
import { usePostSwitchEnterprise } from "./enterprise";

const postlogin = (data) => {
  const method = "POST";
  const url = ApiConfig.SIGN_IN;
  return AxiosInstance({ method, url, data });
};

export const usePostLogin = () => {
  const QueryClient = useQueryClient();
  return useMutation(postlogin, {
    onSuccess: (res) => {
      handleTokens(res);
      QueryClient.setQueryData([QueryKeys.ME], res?.user);
    },
  });
};

const getUserDetails = () => {
  const method = "GET";
  const url = ApiConfig.ME;
  return AxiosInstance({ method, url });
};

export const useGetUserDetails = () => {
  const { setApprovedEnterprises } = useGlobalState();

  const { handleSwitchEnterprise } = usePostSwitchEnterprise();
  const isAuthenticated = localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN);

  return useQuery([QueryKeys.ME], () => getUserDetails(), {
    enabled: !!isAuthenticated,
    onSuccess: (data) => {
      const ApprovedEnterprises = data?.enterprises?.filter(
        (obj) => obj.status === "Approved" || obj.status === "Blocked"
      );

      setApprovedEnterprises(ApprovedEnterprises);
      if (!data?.current_enterprise && ApprovedEnterprises?.length) handleSwitchEnterprise(ApprovedEnterprises?.[0]);
    },
  });
};

const postRegister = (data) => {
  const method = "POST";
  const url = ApiConfig.SIGN_UP;
  return AxiosInstance({ method, url, data });
};

export const usePostRegister = () => {
  return useMutation(postRegister, {
    onSuccess: (res) => {
      handleTokens(res);
    },
  });
};

const postVerifyEmail = (data) => {
  const method = "POST";
  const url = ApiConfig.VERIFY_EMAIL;
  return AxiosInstance({ method, url, data });
};

export const usePostVerifyEmail = () => {
  return useMutation(postVerifyEmail, {});
};

const postLogout = () => {
  const method = "POST";
  const url = ApiConfig.LOGOUT;
  return AxiosInstance({ method, url });
};

export const usePostLogout = () => {
  const QueryClient = useQueryClient();
  return useMutation(postLogout, {
    onSuccess: () => {
      localStorage.clear();
      QueryClient.clear();
    },
  });
};

const postForgotPassword = (data) => {
  const method = "POST";
  const url = ApiConfig.FORGOT_PASSWORD;
  return AxiosInstance({ method, url, data });
};

export const usePostForgotPassword = () => {
  return useMutation(postForgotPassword);
};

const postResetPassword = (data) => {
  const method = "POST";
  const url = ApiConfig.RESET_PASSWORD;
  return AxiosInstance({ method, url, data });
};

export const usePostResetPassword = () => {
  return useMutation(postResetPassword);
};

const patchChangePassword = (data) => {
  const method = "PATCH";
  const url = ApiConfig.CHANGE_PASSWORD;
  return AxiosInstance({ method, url, data });
};

export const usePatchChangePassword = () => {
  return useMutation(patchChangePassword);
};

export const RefreshAccessToken = () => {
  const method = "POST";
  const url = "";
  const data = {
    refresh_token: getLocalStorageItem(LocalStorageKeys.REFRESH_TOKEN),
  };
  return AxiosInstance({ method, url, data });
};

export const handleTokens = (response) => {
  const { access_token, refresh_token } = response;
  setLocalStorageItem(LocalStorageKeys.ACCESS_TOKEN, access_token);
  if (refresh_token) setLocalStorageItem(LocalStorageKeys.REFRESH_TOKEN, refresh_token);
};

export const postRefreshToken = (data) => {
  const method = "POST";
  const url = ApiConfig.REFRESH_TOKEN;
  return AxiosInstance({ method, url, data });
};
