import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import useBlockNavigation from "hooks/useBlockNavigation";
import { useEffect, useState } from "react";

const BlockNavigationModal = ({ title, condition }) => {
  const [isDirty, setIsDirty] = useState(false);
  const { openPrompt, handleOnClose, handleOnNavigate } = useBlockNavigation(isDirty);

  useEffect(() => {
    if (condition) setIsDirty(true);
    else setIsDirty(false);
  }, [condition]);

  return (
    <Dialog open={openPrompt} onClose={handleOnClose} maxWidth="xs">
      <DialogTitle>
        <Typography variant="h6" fontWeight={500}>
          You are in the middle of {title}. Are you sure you want to exit?
        </Typography>
      </DialogTitle>
      <DialogContent></DialogContent>
      <DialogActions>
        <Button color="light-error" onClick={handleOnNavigate}>
          Yes
        </Button>
        <Button onClick={handleOnClose}>No</Button>
      </DialogActions>
    </Dialog>
  );
};

export default BlockNavigationModal;
