import React, { useCallback, useEffect } from "react";
import { useCampaignContext } from ".";
import { Button } from "@mui/material";
import { useGetCampaignsList } from "queries/campaign";

const StepperControls = ({ isDisabled, onClickNext, onClickPrev, isSubmit, isErrorMode }) => {
  const { activeStep, seiIsStepperOpen, setActiveStep, editStep, setEditStep } = useCampaignContext();

  const { data: campaigns } = useGetCampaignsList();

  const handleOnBack = () => {
    if (activeStep === 0) seiIsStepperOpen(false);
    else setActiveStep((prev) => prev - 1);
    if (onClickPrev) onClickPrev();
  };

  const handleOnNext = () => {
    if (!isSubmit) {
      if (activeStep < 5 && !isErrorMode) setActiveStep((prev) => prev + 1);
      if (onClickNext) onClickNext();
    }
  };

  const handleOnSave = () => {
    if (!isSubmit) {
      if (!isErrorMode) {
        setActiveStep(5);
        setEditStep(false);
      }
      if (onClickNext) onClickNext();
    }
  };

  const handleCloseEditMode = () => {
    setEditStep(false);
    setActiveStep(5);
  };

  return (
    <div className="d-flex gap-2 mt-4">
      {!editStep ? (
        <>
          {!campaigns?.baseGs1s?.length && activeStep === 0 ? null : (
            <Button variant="outlined" onClick={handleOnBack}>
              BACK
            </Button>
          )}
          <Button type={isSubmit && "submit"} disabled={isDisabled} variant="contained" onClick={handleOnNext}>
            NEXT
          </Button>
        </>
      ) : (
        <>
          <Button variant="outlined" onClick={handleCloseEditMode}>
            BACK
          </Button>
          <Button type={isSubmit && "submit"} disabled={isDisabled} variant="contained" onClick={handleOnSave}>
            SAVE & REVIEW
          </Button>
        </>
      )}
    </div>
  );
};

export default StepperControls;
